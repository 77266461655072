import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertCircle, Send } from 'lucide-react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { toast } from 'sonner';

const mockWithdrawalMethods = {
  fiat: [
    { id: '1', name: 'Bank Transfer', logo: '🏦' },
    { id: '2', name: 'Mobile Money', logo: '📱' },
  ],
  crypto: [
    { id: '1', name: 'External Wallet', logo: '👛' },
  ]
};

const mockBalances = {
  '1': { available: 1250.50, symbol: 'USD', fee: 5 },
  '2': { available: 450.75, symbol: 'EUR', fee: 5 },
  '3': { available: 0.2, symbol: 'ETH', fee: 0.001 },
  '4': { available: 0.003, symbol: 'BTC', fee: 0.0001 },
};

const WithdrawPage = () => {
  const { assetId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const assetName = location.state?.assetName || 'Unknown Asset';
  
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [withdrawalAddress, setWithdrawalAddress] = useState('');
  
  const isCrypto = ['3', '4'].includes(assetId || '');
  
  const balanceInfo = assetId ? mockBalances[assetId as keyof typeof mockBalances] : null;
  
  const withdrawalMethods = isCrypto 
    ? mockWithdrawalMethods.crypto 
    : mockWithdrawalMethods.fiat;

  useEffect(() => {
    document.title = `EvoCash | Withdraw ${assetName}`;
    
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    
    return () => clearTimeout(timer);
  }, [assetName]);

  const handleWithdraw = () => {
    if (!withdrawalAmount || parseFloat(withdrawalAmount) <= 0) {
      toast.error('Please enter a valid amount');
      return;
    }
    
    if (parseFloat(withdrawalAmount) > (balanceInfo?.available || 0)) {
      toast.error('Withdrawal amount exceeds available balance');
      return;
    }
    
    if (isCrypto && !withdrawalAddress) {
      toast.error('Please enter a withdrawal address');
      return;
    }
    
    setSubmitting(true);
    
    setTimeout(() => {
      toast.success(`Withdrawal request for ${withdrawalAmount} ${balanceInfo?.symbol} submitted successfully`);
      setSubmitting(false);
      navigate('/dashboard/wallet');
    }, 1500);
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center max-w-4xl mx-auto animate-fade-in">
        <div className="flex items-center gap-2 self-start mb-6 w-full">
          <Button 
            variant="ghost" 
            size="icon"
            onClick={() => navigate('/dashboard/wallet')}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-xl sm:text-3xl font-bold tracking-tight">Withdraw {assetName}</h1>
        </div>

        {loading ? (
          <div className="flex justify-center py-10 w-full">
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary"></div>
          </div>
        ) : (
          <div className="w-full">
            <Card className="w-full max-w-2xl mx-auto">
              <CardHeader>
                <CardTitle>Withdraw {assetName}</CardTitle>
                <CardDescription>
                  {isCrypto 
                    ? `Send ${assetName} to an external wallet` 
                    : `Withdraw ${assetName} to your bank account`}
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6 pt-6">
                {balanceInfo && (
                  <div className="bg-muted rounded-md p-4 border">
                    <div className="flex flex-col sm:flex-row sm:justify-between">
                      <div>
                        <p className="text-sm text-muted-foreground">Available balance</p>
                        <p className="text-2xl font-bold">{balanceInfo.available} {balanceInfo.symbol}</p>
                      </div>
                      {!isCrypto && (
                        <div className="mt-2 sm:mt-0 text-right">
                          <p className="text-sm text-muted-foreground">Withdrawal fee</p>
                          <p className="text-base font-medium">{balanceInfo.fee} {balanceInfo.symbol}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                
                <div className="space-y-4">
                  <div>
                    <Label htmlFor="amount">Amount to withdraw</Label>
                    <div className="relative mt-1.5">
                      <Input
                        id="amount"
                        type="number"
                        placeholder="0.00"
                        value={withdrawalAmount}
                        onChange={(e) => setWithdrawalAmount(e.target.value)}
                      />
                      {balanceInfo && (
                        <div className="absolute inset-y-0 right-3 flex items-center">
                          <span className="text-muted-foreground">{balanceInfo.symbol}</span>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between mt-1.5">
                      <button 
                        type="button" 
                        className="text-xs text-primary hover:text-primary/80"
                        onClick={() => balanceInfo && setWithdrawalAmount((balanceInfo.available / 4).toString())}
                      >
                        25%
                      </button>
                      <button 
                        type="button" 
                        className="text-xs text-primary hover:text-primary/80"
                        onClick={() => balanceInfo && setWithdrawalAmount((balanceInfo.available / 2).toString())}
                      >
                        50%
                      </button>
                      <button 
                        type="button" 
                        className="text-xs text-primary hover:text-primary/80"
                        onClick={() => balanceInfo && setWithdrawalAmount((balanceInfo.available * 0.75).toString())}
                      >
                        75%
                      </button>
                      <button 
                        type="button" 
                        className="text-xs text-primary hover:text-primary/80"
                        onClick={() => balanceInfo && setWithdrawalAmount(balanceInfo.available.toString())}
                      >
                        Max
                      </button>
                    </div>
                  </div>
                  
                  {isCrypto ? (
                    <div>
                      <Label htmlFor="address">Recipient address</Label>
                      <Input
                        id="address"
                        type="text"
                        placeholder={`Enter your ${assetName} address`}
                        value={withdrawalAddress}
                        onChange={(e) => setWithdrawalAddress(e.target.value)}
                        className="mt-1.5"
                      />
                    </div>
                  ) : (
                    <div>
                      <Label htmlFor="method">Withdrawal method</Label>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-1.5">
                        {withdrawalMethods.map((method) => (
                          <div 
                            key={method.id}
                            className="p-3 bg-muted border rounded-md flex items-center gap-2 cursor-pointer hover:border-primary transition-colors"
                          >
                            <span className="text-xl">{method.logo}</span>
                            <span>{method.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="rounded-md bg-muted p-4 text-sm border">
                  <div className="flex">
                    <AlertCircle className="h-4 w-4 mr-2 mt-0.5 text-foreground" />
                    <div>
                      <p className="font-semibold">Important:</p>
                      <ul className="list-disc list-inside space-y-1 mt-1">
                        {isCrypto ? (
                          <>
                            <li>Verify the recipient address carefully.</li>
                            <li>Withdrawals may take 10-30 minutes to complete.</li>
                            <li>Network fee: {balanceInfo?.fee} {balanceInfo?.symbol}</li>
                          </>
                        ) : (
                          <>
                            <li>Withdrawals typically process within 1-3 business days.</li>
                            <li>Minimum withdrawal: 10 {balanceInfo?.symbol}</li>
                            <li>Fee: {balanceInfo?.fee} {balanceInfo?.symbol}</li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </CardContent>
              <CardFooter className="border-t pt-6">
                <Button 
                  className="w-full"
                  onClick={handleWithdraw}
                  disabled={
                    submitting || 
                    !withdrawalAmount || 
                    parseFloat(withdrawalAmount) <= 0 ||
                    parseFloat(withdrawalAmount) > (balanceInfo?.available || 0) ||
                    (isCrypto && !withdrawalAddress)
                  }
                >
                  {submitting ? (
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-primary-foreground mr-2"></div>
                  ) : (
                    <Send className="h-4 w-4 mr-2" />
                  )}
                  {submitting ? 'Processing...' : 'Withdraw'}
                </Button>
              </CardFooter>
            </Card>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default WithdrawPage;
