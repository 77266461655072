import React, { createContext, useContext, useState, useEffect } from 'react';
import walletService from '@/services/walletService';
import { toast } from 'sonner';
import { useUser } from '@/contexts/UserContext';
import { Asset, UserBalance, DepositCrypto } from '@/components/wallet/WalletModels';

import { Transaction } from '@/components/transaction/TransactionModels';
import { PaymentMethod } from '@/components/payment_method/PaymentMethodModel';


interface WalletContextType {
    assets: Asset[];
    userBalances: UserBalance[];
    paymentMethods: PaymentMethod[];
    depositCrypto: DepositCrypto | null;
    buyedAGC: Transaction | null;
    buyedTransaction: Transaction | null;

    loading: boolean;
    error: string | null;
    fetchAssets: () => Promise<void>;
    fetchUserBalance: () => Promise<void>;
    fetchPaymentMethods: (countryId: string, assetId: string) => Promise<void>;
    fetchDepositCrypto: (assetName: string) => Promise<void>;
    transferCrypto: (amount: number, recipientIdentifier: string) => Promise<boolean>;
    buyAGC: (paymentMethodId: string, amount: number, meta?: object) => Promise<void>;
    getBuyTransactionStatus: (transactionId: string) => Promise<void>;

}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [assets, setAssets] = useState<Asset[]>([]);
    const [userBalances, setUserBalances] = useState<UserBalance[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [depositCrypto, setDepositCrypto] = useState<DepositCrypto | null>(null);
    const [buyedAGC, setBuyedAGC] = useState<Transaction | null>(null);
    const [buyedTransaction, setBuyedTransaction] = useState<Transaction | null>(null);


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { user } = useUser();

    const fetchAssets = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await walletService.getAssets();
            if (response.status) {
                setAssets(Asset.fromApiResponse(response.data));
            } else {
                setError(response);
            }
        } catch (error: any) {
            console.error('Error fetching assets:', error);
            // Specifically handle the 403 verification error
            if (error.response?.status === 403 && 
                error.response?.data?.message?.includes("country, date of birth and phone number")) {
                setError(error.response.data);
            } else {
                setError('Failed to fetch assets');
            }
            
            if (error.response?.data?.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('Failed to fetch assets');
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchUserBalance = async () => {
        setError(null);
        try {
            const response = await walletService.getUserBalance();
            if (response.status) {
                //toast.success(response.message);
                setUserBalances(response.data);
            } else {
                toast.error(response.message);
                setError('Error fetching user balance');
            }
        } catch (error: any) {
            console.error('Error fetching user balance:', error);
            setError('Error fetching user balance');

            if (error.response && error.response.data) {
                toast.error(error.response.data.message || 'Failed to fetch user balance');
            } else {
                toast.error('Failed to fetch user balance');
            }
        }
    };

    const fetchPaymentMethods = async (countryCode: string, assetId: string) => {
        setError(null);
        try {
            const response = await walletService.getPaymentMethods(countryCode, assetId);
            if (response.status) {
                setPaymentMethods(response.data);
            } else {
                toast.error(response.message);
                setError('Error fetching payment methods');
            }
        } catch (error: any) {
            console.error('Error fetching payment methods:', error);
            setError('Error fetching payment methods');

            if (error.response && error.response.data) {
                toast.error(error.response.data.message || 'Failed to fetch payment methods');
            } else {
                toast.error('Failed to fetch payment methods');
            }
        }
    };

    const fetchDepositCrypto = async (assetName: string) => {
        setError(null);
        try {
            const response = await walletService.depositCrypto(assetName);
            if (response.status) {
                //toast.success(response.message);
                setDepositCrypto(new DepositCrypto(response.data));
            } else {
                toast.error(response.message);
                setError('Error generating deposit address');
            }
        } catch (error: any) {
            console.error('Error generating deposit address:', error);
            setError('Error generating deposit address');

            if (error.response && error.response.data) {
                toast.error(error.response.data.message || 'Failed to generate deposit address');
            } else {
                toast.error('Failed to generate deposit address');
            }
        }
    };

    const transferCrypto = async (amount: number, recipientIdentifier: string) => {
        setError(null);
        try {
            const response = await walletService.transferCrypto({ amount, recipientIdentifier });
            if (response.success) {
                toast.success(response.message);
            } else {
                toast.error(response.message);
                setError('Error initiating transfer');
            }
            return response.success;
        } catch (error: any) {
            console.error('Error initiating transfer:', error);
            setError('Error initiating transfer');
            if (error.response && error.response.data) {
                toast.error(error.response.data.message || 'Failed to initiate transfer');
            } else {
                toast.error('Failed to initiate transfer');
            }
        }
    };


    const buyAGC = async (paymentMethodId: string, amount: number, meta: object = {}) => {
        setError(null);
        try {
            const response = await walletService.buyAGC({paymentMethodId: paymentMethodId, amount: amount, meta: meta});
            if (response.status) {
                toast.success(response.message);
                setBuyedAGC(response.data.transaction);
            } else {
                toast.error(response.message);
                setError('Error purchasing AGC');
            }
        } catch (error: any) {
            console.error('Error buying AGC:', error);
            setError('Error purchasing AGC');
            toast.error(error.response?.data?.message || 'Failed to buy AGC');
        }
    };

    const getBuyTransactionStatus = async (transactionId: string) => {
        setError(null);
        try {
            const response = await walletService.getBuyTransactionStatus(transactionId);
            if (response.status) {
                setBuyedTransaction(response.data);
            } else {
                toast.error(response.message);
                setError('Error fetching transaction status');
            }
        } catch (error: any) {
            console.error('Error fetching transaction status:', error);
            toast.error(error.response?.data?.message || 'Failed to fetch transaction status');
            setError('Error fetching transaction status');
        }
    };

    // Removed duplicate fetchDepositCrypto implementation

    return (
        <WalletContext.Provider value={{
            assets,
            userBalances,
            paymentMethods,
            depositCrypto,
            buyedAGC,
            buyedTransaction,
            loading,
            error,
            fetchAssets,
            fetchUserBalance,
            fetchPaymentMethods,
            fetchDepositCrypto,
            transferCrypto,
            buyAGC,
            getBuyTransactionStatus,
        }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => {
    const context = useContext(WalletContext);
    if (!context) {
        throw new Error('useWallet must be used within a useWallet');
    }
    return context;
};
