import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, Copy, Check, ExternalLink } from 'lucide-react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { toast } from 'sonner';
import { Asset } from '@/components/wallet/WalletModels';
import { useWallet } from '@/contexts/WalletContext';

// Mock data - will be replaced with API calls
const mockPaymentMethods = {
  fiat: [
    { id: '1', name: 'Bank Transfer', logo: '🏦' },
    { id: '2', name: 'Debit/Credit Card', logo: '💳' },
    { id: '3', name: 'MTN Mobile Money', logo: '📱' },
    { id: '4', name: 'Orange Money', logo: '📱' },
  ],
  crypto: [
    { id: '1', name: 'On-chain Transfer', logo: '🔗' },
  ]
};


const DepositPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { depositCrypto, error, fetchDepositCrypto } = useWallet();
  const asset: Asset = location.state?.asset ? new Asset(location.state.asset) : new Asset({});
  
  const [copied, setCopied] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  
  // Determine if this is a crypto or fiat asset based on mock data
  // In a real implementation, this would come from the API
  const isCrypto = asset.type === 'Crypto';
  
  // Get appropriate payment methods based on asset type
  const paymentMethods = isCrypto 
    ? mockPaymentMethods.crypto 
    : mockPaymentMethods.fiat;

  

  useEffect(() => {
    document.title = `EvoCash | Deposit ${asset.assetName}`;
    
    if (isCrypto) {
      fetchDepositCrypto(asset.assetName);
      setLoadingPage(false);
      
    }else{
      setLoadingPage(false);
    }
  }, []);


  const handleCopyAddress = () => {

    if (!navigator.clipboard) {
      toast.error('Clipboard API not supported. Please use a modern browser or ensure you are using HTTPS.');
      return;
    }

    if (!depositCrypto || !depositCrypto.depositAddress) {
      toast.error('No address to copy');
      return;
    }


    navigator.clipboard.writeText(depositCrypto.depositAddress)
      .then(() => {
        setCopied(true);
        toast.success('Address copied to clipboard');
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(() => {
        toast.error('Failed to copy address');
      });
  };

  const handleSelectPaymentMethod = (methodId: string) => {
    // In a real implementation, this would navigate to a specific payment method page
    // For now, we'll just show a toast
    toast.info(`Selected payment method: ${
      paymentMethods.find(m => m.id === methodId)?.name
    }`);
    
    // This would eventually navigate to a payment-method specific page
    // navigate(`/dashboard/wallet/deposit/${assetId}/method/${methodId}`);
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6 animate-fade-in">
        <div className="flex items-center gap-2">
          <Button 
            variant="ghost" 
            size="icon"
            onClick={() => navigate('/dashboard/wallet')}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-xl sm:text-3xl font-bold tracking-tight">Deposit {asset.assetName}</h1>
        </div>

        {loadingPage ? (
          <div className="flex justify-center py-10">
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-6">
            {isCrypto && depositCrypto ? (
              <Card>
                <CardHeader>
                  <CardTitle>Deposit {asset.assetName}</CardTitle>
                  <CardDescription>
                    Send {asset.assetName} to the address below to deposit funds to your account
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  <div className="space-y-2">
                    <p className="text-sm font-medium">Network</p>
                    <div className="flex items-center gap-2 p-2 bg-muted rounded-md">
                      <span>{depositCrypto.network}</span>
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <p className="text-sm font-medium">Deposit Address</p>
                    <div className="flex items-center gap-2 p-2 border rounded-md">
                      <input 
                        type="text"
                        readOnly
                        value={depositCrypto.depositAddress}
                        className="flex-1 bg-transparent border-none focus:outline-none text-sm break-all"
                      />
                      <Button 
                        variant="ghost" 
                        size="sm"
                        onClick={handleCopyAddress}
                      >
                        {copied ? (
                          <Check className="h-4 w-4 text-green-500" />
                        ) : (
                          <Copy className="h-4 w-4" />
                        )}
                      </Button>
                    </div>
                  </div>
                  
                  <div className="rounded-md bg-amber-50 dark:bg-amber-950 p-4 text-sm text-amber-800 dark:text-amber-200">
                    <p className="font-semibold">Important:</p>
                    <ul className="list-disc list-inside space-y-1 mt-2">
                      <li>Only send {asset.assetName} to this address.</li>
                      <li>Make sure you're using the {depositCrypto.network} network.</li>
                      <li>Sending any other asset may result in permanent loss.</li>
                    </ul>
                  </div>
                  
                  <div className="text-center pt-4">
                    <Button 
                      variant="outline" 
                      size="sm"
                      className="w-full md:w-auto"
                      onClick={() => window.open(`${depositCrypto.link}`, '_blank')}
                    >
                      <ExternalLink className="h-3.5 w-3.5 mr-2" />
                      View on {depositCrypto.network === 'Ethereum' ? 'Etherscan' : 'Blockchain Explorer'}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <div className="space-y-4">
                <h2 className="text-xl font-semibold">Select Payment Method</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {paymentMethods.map((method) => (
                    <Card 
                      key={method.id}
                      className="cursor-pointer hover:border-primary transition-colors"
                      onClick={() => handleSelectPaymentMethod(method.id)}
                    >
                      <CardHeader className="pb-2">
                        <div className="flex items-center gap-2">
                          <span className="text-2xl">{method.logo}</span>
                          <CardTitle className="text-lg">{method.name}</CardTitle>
                        </div>
                      </CardHeader>
                      <CardContent>
                        <CardDescription>
                          Deposit {asset.assetName} using {method.name}
                        </CardDescription>
                      </CardContent>
                    </Card>
                  ))}
                </div>
                
                <div className="rounded-md bg-muted p-4 text-sm">
                  <p>
                    Select a payment method to continue with your {asset.assetName} deposit.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default DepositPage;
