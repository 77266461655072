export const getTransactionType = (transactionType: string): string => {
  if (transactionType.toLowerCase().includes('deposit')) {
    return 'deposit';
  } else if (transactionType.toLowerCase().includes('withdrawal')) {
    return 'withdrawal';
  } else if (transactionType.toLowerCase().includes('transfer')) {
    return 'transfer';
  }else {
    return 'buy';
  }
};

export const getTransactionStatus = (status: string): string => {
  switch (status.toLowerCase()) {
    case 'pending':
      return 'Pending';
    case 'successful':
      return 'Successful';
    case 'processing':
      return 'Processing';
    case 'failed':
      return 'Failed';
    default:
      return 'Unknown';
  }
};