
import { useEffect, useState } from 'react';
import Dashboard from '@/components/layout/Dashboard';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Search, Wallet, Shield, UserCircle, Coins, HelpCircle, Globe, ArrowRight } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
  category: string;
}

interface FAQCategory {
  [key: string]: {
    question: string;
    answer: string;
  }[];
}

// FAQ categories and their respective questions
const faqData: FAQCategory = {
  general: [
    {
      question: "What is EvoCash?",
      answer: "EvoCash is a digital platform that allows users to buy, sell, and manage AGC tokens securely. Our platform provides real-time metrics, interactive charts, and robust security features to give you full control over your digital assets. AGC tokens are unique because they're 100% backed by physical gold stored in secure Dubai vaults, combining the stability of gold with the efficiency of blockchain technology."
    },
    {
      question: "How do I create an account?",
      answer: "Creating an account is simple:\n\n1. Click the \"Sign Up\" button in the top right corner\n2. Enter your email address and create a secure password\n3. Verify your email through the confirmation link\n4. Complete your profile information\n5. Set up two-factor authentication (2FA)\n6. Complete the KYC verification process\n\nOnce verified, you can start using all EvoCash features."
    },
    {
      question: "Is EvoCash available worldwide?",
      answer: "EvoCash is available in most countries worldwide, with some exceptions due to regulatory requirements. Currently, our services are not available in:\n\n• Countries under international sanctions\n• Regions where cryptocurrency trading is prohibited\n• Specific jurisdictions with restrictive digital asset laws\n\nPlease check our \"Supported Countries\" page for the most up-to-date information about service availability in your region."
    },
    {
      question: "When is the platform launching?",
      answer: "EvoCash is scheduled to launch in Q3 2025. Our current timeline includes:\n\n• Q2 2025: Final security audits and testing\n• Q3 2025: Platform launch and initial token distribution\n• Q4 2025: Additional features rollout\n\nYou can register for early access now to receive updates and potential early-bird benefits."
    }
  ],
  account: [
    {
      question: "What is KYC and why is it required?",
      answer: "KYC (Know Your Customer) is a mandatory verification process that helps prevent fraud and ensure compliance with financial regulations. The process includes:\n\n1. Identity Verification\n• Government-issued ID (passport, driver's license)\n• Facial verification\n• Proof of address\n\n2. Additional Documentation\n• Source of funds declaration\n• Proof of residence\n• Tax identification number\n\nProcessing typically takes 24-48 hours. Enhanced verification may be required for higher transaction limits."
    },
    {
      question: "What should I do if I forget my password?",
      answer: "To reset your password:\n\n1. Click \"Forgot Password\" on the login page\n2. Enter your registered email address\n3. Check your email for reset instructions\n4. Click the reset link (valid for 24 hours)\n5. Create a new strong password\n\nIf you don't receive the email:\n• Check your spam folder\n• Verify the email address is correct\n• Contact support if issues persist\n\nNote: For security, you'll need to re-enable 2FA after resetting your password."
    },
    {
      question: "How can I enhance my account security?",
      answer: "We recommend these security measures:\n\n1. Two-Factor Authentication (2FA)\n• Enable via Security Settings\n• Choose between authenticator app or SMS\n• Store backup codes safely\n\n2. Strong Password Practices\n• Use unique, complex passwords\n• Change regularly\n• Never share credentials\n\n3. Additional Security Features\n• Email notifications for login attempts\n• IP address whitelisting\n• Withdrawal address whitelisting\n• Session management"
    }
  ],
  transactions: [
    {
      question: "How do I deposit funds to my account?",
      answer: "You can deposit funds through multiple methods:\n\n1. Bank Transfer\n• SWIFT/SEPA transfers\n• Local bank transfers\n• Processing time: 1-3 business days\n\n2. Credit/Debit Card\n• Instant processing\n• 3D Secure verification required\n• Fees: 2.5% of transaction\n\n3. Cryptocurrency\n• Multiple networks supported\n• Instant processing\n• Network fees apply\n\nMinimum deposit amounts and fees vary by method."
    },
    {
      question: "How can I withdraw my funds?",
      answer: "To withdraw funds:\n\n1. Navigate to Wallet > Withdraw\n2. Select withdrawal method:\n• Bank transfer\n• Cryptocurrency\n• Internal transfer\n\n3. Security Verification\n• 2FA confirmation\n• Email verification\n• Additional KYC for large amounts\n\n4. Processing Times\n• Bank transfers: 1-3 business days\n• Crypto: 10-30 minutes\n• Internal: Instant\n\nNote: First-time withdrawals require 24-hour security hold."
    },
    {
      question: "What are the fees for trading on EvoCash?",
      answer: "Our fee structure includes:\n\n1. Trading Fees\n• Maker: 0.1%\n• Taker: 0.2%\n• Volume discounts available\n\n2. Deposit Fees\n• Bank transfer: Free\n• Card: 2.5%\n• Crypto: Network fees only\n\n3. Withdrawal Fees\n• Bank transfer: $25 flat fee\n• Crypto: Network fee + 0.1%\n• Internal: Free\n\n4. Special Conditions\n• VIP members get reduced fees\n• Monthly trading volume discounts\n• Promotional fee waivers"
    }
  ],
  security: [
    {
      question: "How secure is my data and digital assets?",
      answer: "We implement multiple security layers:\n\n1. Asset Security\n• 95% of assets in cold storage\n• Multi-signature wallets\n• Regular security audits\n\n2. Platform Security\n• 256-bit encryption\n• DDoS protection\n• 24/7 monitoring\n\n3. Account Protection\n• Mandatory 2FA\n• IP monitoring\n• Suspicious activity detection\n\n4. Compliance\n• Regular third-party audits\n• Regulatory compliance\n• Insurance coverage"
    },
    {
      question: "What happens if there's a security breach?",
      answer: "Our security incident response plan includes:\n\n1. Immediate Actions\n• Account freezing if compromised\n• User notification\n• Investigation initiation\n\n2. Recovery Process\n• Asset securing\n• Damage assessment\n• Account recovery support\n\n3. Preventive Measures\n• Security audit\n• System updates\n• Enhanced monitoring\n\n4. User Support\n• Dedicated support team\n• Step-by-step recovery guidance\n• Documentation assistance"
    }
  ],
  support: [
    {
      question: "What customer support options are available?",
      answer: "We offer comprehensive support:\n\n1. 24/7 Support Channels\n• Live chat\n• Email: support@evocash.org\n• Phone: +1 (555) 123-4567\n\n2. Self-Help Resources\n• Knowledge base\n• Video tutorials\n• FAQs\n\n3. Priority Support\n• VIP account managers\n• Premium support line\n• Dedicated email channel\n\n4. Response Times\n• Live chat: < 5 minutes\n• Email: < 24 hours\n• Emergency: Immediate"
    },
    {
      question: "How can I report issues or provide feedback?",
      answer: "Multiple channels available:\n\n1. Technical Issues\n• In-app bug report\n• Support ticket system\n• Emergency hotline\n\n2. Feature Requests\n• Feedback forum\n• Community voting\n• Beta testing program\n\n3. Compliance Concerns\n• Compliance hotline\n• Anonymous reporting\n• Whistleblower protection"
    }
  ]
};

const FAQ = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('all');
  const [filteredQuestions, setFilteredQuestions] = useState<FAQItem[]>([]);

  useEffect(() => {
    document.title = 'EvoCash | FAQ';
  }, []);

  useEffect(() => {
    // Filter questions based on search query and active tab
    const allQuestions: FAQItem[] = Object.entries(faqData).flatMap(([category, questions]) => 
      questions.map(q => ({ ...q, category }))
    );

    const filtered = allQuestions.filter(q => {
      const matchesSearch = searchQuery.trim() === '' || 
        q.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        q.answer.toLowerCase().includes(searchQuery.toLowerCase());
      
      const matchesTab = activeTab === 'all' || q.category === activeTab;

      return matchesSearch && matchesTab;
    });

    setFilteredQuestions(filtered);
  }, [searchQuery, activeTab]);

  return (
    <Dashboard>
      <div className="flex flex-col space-y-16">
        {/* Hero Section */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/20 via-primary/10 to-background p-10 md:p-16">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
          
          {/* Floating elements */}
          <div className="absolute top-20 right-10 w-24 h-24 bg-primary/10 rounded-full blur-xl animate-pulse" />
          <div className="absolute bottom-10 left-10 w-32 h-32 bg-primary/5 rounded-full blur-xl animate-pulse [animation-delay:2s]" />
          
          <div className="relative z-10 max-w-3xl">
            <h1 className="animate-fade-up text-4xl md:text-6xl font-bold tracking-tight mb-6">
              Frequently Asked <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/70">Questions</span>
            </h1>
            <p className="text-xl text-muted-foreground mb-8 animate-fade-up [--animation-delay:200ms] leading-relaxed">
              Find answers to common questions about EvoCash and AGC tokens. Can't find what you're looking for? Contact our support team.
            </p>

            {/* Search Section */}
            <div className="relative max-w-2xl animate-fade-up [--animation-delay:400ms]">
              <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-muted-foreground" />
              <Input
                placeholder="Search for answers..."
                className="pl-12 py-6 text-lg bg-background/60 backdrop-blur-sm border-muted/30"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </section>

        {/* FAQ Content Section */}
        <section className="flex flex-col space-y-8">
          {/* Categories Tabs */}
          <Tabs defaultValue="all" className="w-full" onValueChange={setActiveTab}>
            <TabsList className="w-full justify-start mb-8 p-1 bg-muted/30 backdrop-blur-sm">
              <TabsTrigger value="all" className="flex items-center gap-2">
                <Globe className="h-4 w-4" />
                All
              </TabsTrigger>
              <TabsTrigger value="general" className="flex items-center gap-2">
                <HelpCircle className="h-4 w-4" />
                General
              </TabsTrigger>
              <TabsTrigger value="account" className="flex items-center gap-2">
                <UserCircle className="h-4 w-4" />
                Account
              </TabsTrigger>
              <TabsTrigger value="transactions" className="flex items-center gap-2">
                <Coins className="h-4 w-4" />
                Transactions
              </TabsTrigger>
              <TabsTrigger value="security" className="flex items-center gap-2">
                <Shield className="h-4 w-4" />
                Security
              </TabsTrigger>
              <TabsTrigger value="support" className="flex items-center gap-2">
                <Wallet className="h-4 w-4" />
                Support
              </TabsTrigger>
            </TabsList>

            {/* FAQ Accordion */}
            <div className="bg-card rounded-xl border border-border/50 shadow-sm">
              <Accordion type="single" collapsible className="p-6">
                {filteredQuestions.map((item, index) => (
                  <AccordionItem 
                    key={index} 
                    value={`item-${index}`} 
                    className="border-b border-border/50 last:border-0"
                  >
                    <AccordionTrigger className="text-left hover:no-underline py-4">
                      <div className="flex items-start">
                        <span className="font-medium">{item.question}</span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="text-muted-foreground">
                      {item.answer}
                    </AccordionContent>
                  </AccordionItem>
                ))}
                {filteredQuestions.length === 0 && (
                  <div className="text-center py-12">
                    <div className="bg-primary/10 rounded-full p-4 w-16 h-16 flex items-center justify-center mx-auto mb-6">
                      <HelpCircle className="h-8 w-8 text-primary" />
                    </div>
                    <h3 className="text-xl font-semibold mb-2">No results found</h3>
                    <p className="text-muted-foreground mb-6">
                      Try adjusting your search or browse all categories
                    </p>
                    <Button variant="outline" onClick={() => setSearchQuery('')}>
                      Clear Search
                    </Button>
                  </div>
                )}
              </Accordion>
            </div>
          </Tabs>
        </section>

        {/* Contact Support Section */}
        <section className="relative overflow-hidden rounded-xl bg-primary/5 border border-primary/10 p-8 text-center">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
          <div className="relative z-10">
            <h2 className="text-2xl font-semibold mb-3">Still have questions?</h2>
            <p className="text-muted-foreground mb-6">
              Our support team is here to help you 24/7
            </p>
            <Button asChild>
              <a href="/contact" className="flex items-center">
                Contact Support
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
            </Button>
          </div>
        </section>
      </div>
    </Dashboard>
  );
};

export default FAQ;
