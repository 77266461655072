// Notifications.tsx
import { useEffect, useState, useRef, useCallback } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { useNotification } from '@/contexts/NotificationContext';
import { useUser } from '@/contexts/UserContext';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { CheckCircle, Trash2 } from 'lucide-react';
import { useLocation } from "react-router-dom";

const Notifications = () => {
  const { notifications, loading, error, fetchNotifications, markAsRead, deleteNotification, deleteAllNotifications } = useNotification();
  const { user } = useUser();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const isFetching = useRef(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (location.pathname !== "/dashboard/notifications") return;
      if (!user || !user._id) return;
      if (error == null && !isFetching.current) {
        isFetching.current = true;
        try {
          const newNotifications = await fetchNotifications(page);
          // Check if there are more notifications to load
          if (newNotifications.length === 0) {
            setHasMore(false); // No more notifications to load
          }
        } catch (error) {
          console.error("Error fetching notifications:", error);
        } finally {
          isFetching.current = false;
        }
      }
    };

    fetchData();
  }, [user, page ,location.pathname]);

  const lastNotificationElementRef = useCallback(
    (node) => {
      if (!node || !hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      observer.current.observe(node);
    },
    [hasMore, loading]
  );

  const handleMarkAsRead = async (notificationId: string) => {
    await markAsRead(notificationId);
  };

  const handleDeleteNotification = async (notificationId: string) => {
    await deleteNotification(notificationId);
  };

  const handleDeleteAllNotifications = async () => {
    await deleteAllNotifications();
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-8">
        <section>
          <h1 className="text-3xl font-bold tracking-tight">Notifications</h1>
          <p className="text-muted-foreground mt-1">
            View all your notifications here
          </p>
        </section>

        <div className="rounded-lg border border-border p-8">
          {notifications.length > 0 ? (
            <>
              <Button onClick={handleDeleteAllNotifications} className="mb-4">
                Delete All Notifications
              </Button>
              <ul className="space-y-4">
                {notifications.map((notification, index) => (
                  <li
                    key={notification._id}
                    ref={index === notifications.length - 1 ? lastNotificationElementRef : null}
                    className={`text-muted-foreground ${notification.read ? '' : ''}`}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <CheckCircle className={`h-5 w-5 ${notification.read ? 'text-blue-500' : 'text-gray-500'}`} />
                        <span>{notification.type}</span>
                        <span>{new Date(notification.createdAt).toLocaleString()}</span>
                      </div>
                      <div className="flex space-x-2">
                        <Button variant="ghost" size="icon" onClick={() => handleDeleteNotification(notification._id)}>
                          <Trash2 className="text-red-500" />
                        </Button>
                      </div>
                    </div>
                    <details onClick={() => handleMarkAsRead(notification._id)}>
                      <summary>View Details</summary>
                      <p>{notification.message}</p>
                      <p><strong>Date:</strong> {new Date(notification.createdAt).toLocaleString()}</p>
                      <p><strong>Type:</strong> {notification.type}</p>
                    </details>
                  </li>
                ))}
              </ul>

              {loading && (
                <div className="flex justify-center items-center mt-4">
                  <span className="text-muted-foreground">Loading more notifications...</span>
                </div>
              )}

              {!hasMore && !loading && (
                <div className="text-center mt-4 text-muted-foreground">
                  No more notifications to load.
                </div>
              )}
            </>
          ) : (
            !loading && (
              <div className="text-center">
                <h2 className="text-xl font-medium mb-2">No Notifications</h2>
                <p className="text-muted-foreground">You have no notifications at the moment.</p>
              </div>
            )
          )}

          {loading && notifications.length === 0 && (
            <div className="flex justify-center items-center">
              <span className="text-muted-foreground">Loading notifications...</span>
            </div>
          )}
          
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Notifications;