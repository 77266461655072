
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIsMobile } from '@/hooks/use-mobile';
import Dashboard from '@/components/layout/Dashboard';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import AICallButton from '@/components/chat/CallBot';
import {
  ArrowRight,
  Send,
  Loader2,
  HelpCircle,
  Mail,
  MessageSquare,
  Phone,
  MapPin
} from 'lucide-react';

interface FormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const Contact = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [showAICall, setShowAICall] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isMobile = useIsMobile();

  const handleStartLiveChat = () => {
    setShowAICall(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsSubmitting(false);
    setSubmitted(true);
  };

  return (
    <Dashboard>
      <div className="flex flex-col space-y-16 relative">
        {/* Enhanced Hero Section */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/20 via-primary/10 to-background p-6 sm:p-10 md:p-16">
          {/* Background Pattern */}
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
          
          {/* Animated Background Elements */}
          <div className="absolute top-1/2 right-0 w-72 h-72 bg-primary/5 rounded-full blur-3xl animate-pulse" />
          <div className="absolute bottom-0 left-1/4 w-96 h-96 bg-primary/10 rounded-full blur-3xl animate-pulse [animation-delay:1s]" />
          <div className="absolute top-1/4 left-0 w-48 h-48 bg-primary/5 rounded-full blur-2xl animate-pulse [animation-delay:2s]" />
          
          {/* Floating Icons */}
          <div className="absolute top-12 right-16 animate-float-slow">
            <div className="relative">
              <div className="absolute -inset-2 bg-primary/20 rounded-full blur-md" />
              <div className="relative bg-background/80 backdrop-blur-sm p-3 rounded-xl border border-primary/20">
                <Mail className="w-6 h-6 text-primary" />
              </div>
            </div>
          </div>
          <div className="absolute bottom-16 right-32 animate-float-slow [animation-delay:1s]">
            <div className="relative">
              <div className="absolute -inset-2 bg-primary/20 rounded-full blur-md" />
              <div className="relative bg-background/80 backdrop-blur-sm p-3 rounded-xl border border-primary/20">
                <MessageSquare className="w-6 h-6 text-primary" />
              </div>
            </div>
          </div>
          <div className="absolute top-32 right-48 animate-float-slow [animation-delay:2s]">
            <div className="relative">
              <div className="absolute -inset-2 bg-primary/20 rounded-full blur-md" />
              <div className="relative bg-background/80 backdrop-blur-sm p-3 rounded-xl border border-primary/20">
                <Phone className="w-6 h-6 text-primary" />
              </div>
            </div>
          </div>
          
          {/* Content */}
          <div className="relative z-10 max-w-3xl">
            <div className="inline-flex items-center rounded-full border border-primary/20 bg-background/50 backdrop-blur-xl p-1 pr-4 mb-8 animate-fade-up">
              <div className="bg-primary/10 rounded-full p-2 mr-2">
                <HelpCircle className="w-4 h-4 text-primary" />
              </div>
              <span className="text-sm text-muted-foreground">
                24/7 contact Available
              </span>
            </div>
            
            <h1 className="animate-fade-up text-4xl md:text-6xl font-bold tracking-tight mb-6">
              Need Help? <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary via-primary/80 to-primary/50">
                We're Here for You
              </span>
            </h1>
            
            <p className="text-xl text-muted-foreground mb-8 animate-fade-up [--animation-delay:200ms] leading-relaxed max-w-2xl">
              Our dedicated contact team is ready to assist you with any questions about EvoCash, AGC tokens, or your account. Get in touch with us today.
            </p>

            <div className="flex flex-col sm:flex-row gap-4 animate-fade-up [--animation-delay:400ms]">
              <Button 
                size="lg" 
                className="group" 
                onClick={handleStartLiveChat}
              >
                Start Live Call
                <MessageSquare className="ml-2 h-4 w-4 transition-transform group-hover:scale-110" />
              </Button>
              <Button 
                size="lg" 
                variant="outline" 
                className="group" 
                asChild
              >
                <Link to="/faq">
                  View contact Guides
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Link>
              </Button>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 mt-16 animate-fade-up [--animation-delay:600ms]">
              <div className="space-y-2">
                <h4 className="text-4xl font-bold text-foreground">24/7</h4>
                <p className="text-muted-foreground">contact Available</p>
              </div>
              <div className="space-y-2">
                <h4 className="text-4xl font-bold text-foreground">&lt;1hr</h4>
                <p className="text-muted-foreground">Average Response</p>
              </div>
              <div className="space-y-2">
                <h4 className="text-4xl font-bold text-foreground">98%</h4>
                <p className="text-muted-foreground">Satisfaction Rate</p>
              </div>
            </div>
          </div>
        </section>

        {/* Moved AI Call Button Container outside the section */}
        {showAICall && (
          <div className="fixed inset-0 pointer-events-none z-[100]">
            <div className="relative h-full w-full max-w-[100vw] flex items-end justify-center sm:items-end sm:justify-start">
              <div className={`
                pointer-events-auto
                p-4 sm:p-6 md:p-8
                ${isMobile ? 'w-full max-w-lg mx-auto' : 'w-auto'}
              `}>
                <AICallButton 
                  className={`
                    !relative
                    ${isMobile ? 'w-full' : 'w-auto'}
                  `}
                  theme="auto"
                  proactiveMode={false}
                />
              </div>
            </div>
          </div>
        )}

        {/* Contact Information Cards */}
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Card className="bg-background/60 border-primary/10">
            <CardContent className="flex items-center gap-4 p-6">
              <div className="p-2 rounded-full bg-primary/10">
                <Mail className="h-5 w-5 text-primary" />
              </div>
              <div>
                <div className="font-semibold">Email</div>
                <div className="text-muted-foreground text-sm">contact@evocash.org</div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-background/60 border-primary/10">
            <CardContent className="flex items-center gap-4 p-6">
              <div className="p-2 rounded-full bg-primary/10">
                <MessageSquare className="h-5 w-5 text-primary" />
              </div>
              <div>
                <div className="font-semibold">Live Chat</div>
                <div className="text-muted-foreground text-sm">24/7 contact</div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-background/60 border-primary/10">
            <CardContent className="flex items-center gap-4 p-6">
              <div className="p-2 rounded-full bg-primary/10">
                <Phone className="h-5 w-5 text-primary" />
              </div>
              <div>
                <div className="font-semibold">Phone</div>
                <div className="text-muted-foreground text-sm">+1 (234) 567-8900</div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-background/60 border-primary/10">
            <CardContent className="flex items-center gap-4 p-6">
              <div className="p-2 rounded-full bg-primary/10">
                <MapPin className="h-5 w-5 text-primary" />
              </div>
              <div>
                <div className="font-semibold">Location</div>
                <div className="text-muted-foreground text-sm">Dubai, UAE</div>
              </div>
            </CardContent>
          </Card>
        </section>

        {/* Contact Form Section */}
        <section className="grid md:grid-cols-2 gap-8">
          <Card className="bg-background/60 border-primary/10">
            <CardContent className="p-6">
              {submitted ? (
                <div className="text-center py-12">
                  <div className="bg-primary/10 rounded-full p-4 w-16 h-16 flex items-center justify-center mx-auto mb-6">
                    <HelpCircle className="h-8 w-8 text-primary" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Thank You!</h3>
                  <p className="text-muted-foreground mb-6">
                    Your message has been sent successfully. We'll get back to you as soon as possible.
                  </p>
                  <Button
                    onClick={() => setSubmitted(false)}
                    className="group"
                  >
                    Send Another Message
                    <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                  </Button>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="space-y-4">
                    <div>
                      <Input
                        type="text"
                        id="name"
                        placeholder="Full Name"
                        value={formData.name}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div>
                      <Input
                        type="email"
                        id="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div>
                      <Input
                        type="text"
                        id="subject"
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div>
                      <Textarea
                        id="message"
                        placeholder="Your Message"
                        value={formData.message}
                        onChange={handleChange}
                        rows={6}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full group"
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Sending...
                      </>
                    ) : (
                      <>
                        Send Message
                        <Send className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                      </>
                    )}
                  </Button>
                </form>
              )}
            </CardContent>
          </Card>

          {/* FAQ Preview */}
          <Card className="bg-background/60 border-primary/10">
            <CardContent className="p-6">
              <h2 className="text-2xl font-bold mb-6">Common Questions</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold mb-2">How do I get started with AGC?</h3>
                  <p className="text-muted-foreground">
                    Register for early access through our website. Complete the registration form, 
                    and you'll be notified when we launch in Q2 2025.
                  </p>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">What makes AGC different?</h3>
                  <p className="text-muted-foreground">
                    AGC is 100% backed by physical gold stored in secure Dubai vaults, 
                    combining gold's stability with blockchain efficiency.
                  </p>
                </div>
                <Button variant="outline" className="w-full group" asChild>
                  <a href="/faq">
                    View All FAQs
                    <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                  </a>
                </Button>
              </div>
            </CardContent>
          </Card>
        </section>
      </div>
    </Dashboard>
  );
};

export default Contact;
