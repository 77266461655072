import * as React from 'react';
import { useEffect, useState } from 'react';
import Dashboard from '@/components/layout/Dashboard';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Scale,
  Coins,
  Globe,
  Wallet,
  Shield,
  ArrowRight,
  ShieldCheck,
  TrendingUp,
  TrendingDown,
  LineChart,
  BadgeCheck,
  BarChart3,
  DollarSign,
  Clock,
  Sparkles,
} from 'lucide-react';
import { formatCurrency, formatNumber } from '@/lib/utils';
import { useGoldPrice } from '@/hooks/useGoldPrice';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip as TooltipProvider, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Badge } from '@/components/ui/badge';
import { Link } from 'react-router-dom';
import GoldPriceChart from './GoldPriceChart';

const GOLD_MG_PER_TOKEN = 10; // 10mg of gold per token
const GOLD_TROY_OUNCE_TO_MG = 31103.4768; // 1 troy ounce = 31,103.4768 mg

const Markets = () => {
  const { data: goldPriceData, isLoading: isLoadingGold } = useGoldPrice();
  const [currentGoldPrice, setCurrentGoldPrice] = useState<number | null>(null);

  useEffect(() => {
    document.title = 'EvoCash | Markets';
  }, []);

  // Calculate AGC price based on gold price (10mg of gold)
  const calculateAGCPrice = () => {
    if (!currentGoldPrice) return null;
    const goldPricePerMg = currentGoldPrice / GOLD_TROY_OUNCE_TO_MG;
    return goldPricePerMg * GOLD_MG_PER_TOKEN;
  };

  const agcPrice = calculateAGCPrice();
  // Get the actual 24h change from the API data
  const priceChange = goldPriceData?.gold?.usd_24h_change;

  return (
    <Dashboard>
      <div className="flex flex-col space-y-8">
        {/* Hero Section */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/30 via-primary/20 to-background p-10 md:p-16">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
          <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-primary/5 rounded-full blur-3xl" />
          <div className="relative z-10 max-w-3xl">
            <Badge variant="outline" className="bg-primary/10 text-primary border-primary/20 mb-4 px-3 py-1.5 gap-2">
              <BadgeCheck className="h-4 w-4" />
              <span className="font-medium">Verified Gold-Backed Token</span>
            </Badge>
            
            <h1 className="animate-fade-up text-4xl sm:text-5xl font-bold tracking-tight mb-4">
              African Gold Coin <span className="text-primary">(AGC)</span>
            </h1>
            
            <p className="text-xl text-muted-foreground mb-8 animate-fade-up [--animation-delay:200ms] leading-relaxed">
              The future of gold-backed digital assets. Each AGC token represents 10mg of 
              certified physical gold, stored in secure vaults and regularly audited.
            </p>
            
            <div className="flex flex-wrap gap-4 animate-fade-up [--animation-delay:400ms]">
              <Button size="lg" className="group bg-primary hover:bg-primary/90 text-white shadow-lg shadow-primary/20" asChild>
                <Link to="/dashboard/trade">
                  Buy AGC Now
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Link>
              </Button>
              <Button size="lg" variant="outline" className="group border-primary/20 hover:bg-primary/5" asChild>
                <a href="https://africangoldcoin.org/documentation#whitepaper" target="_blank" rel="noopener noreferrer">
                  Read Whitepaper
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </a>
              </Button>
            </div>
          </div>
        </section>

        {/* Gold Price Chart Section */}
        <section className="animate-fade-up [--animation-delay:200ms]">
          <div className="mb-6">
            <h2 className="text-2xl font-bold">Live Gold & AGC Price Chart</h2>
            <p className="text-muted-foreground">Track real-time gold and AGC token prices</p>
          </div>
          <GoldPriceChart onPriceUpdate={(price) => {
            setCurrentGoldPrice(price);
          }} />
        </section>

        {/* Live Price Card */}
        <Card className="border-none bg-gradient-to-br from-card to-background shadow-xl overflow-hidden">
          <CardContent className="p-8">
            <div className="flex items-center mb-6">
              <div className="p-2 rounded-full bg-primary/10 mr-3">
                <BarChart3 className="h-5 w-5 text-primary" />
              </div>
              <h2 className="text-2xl font-bold">Live Market Data</h2>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <div className="space-y-2 bg-card/50 p-4 rounded-lg border border-border/50">
                <h3 className="text-sm font-medium text-muted-foreground flex items-center gap-2">
                  <Coins className="h-4 w-4 text-primary" />
                  AGC Price
                </h3>
                {isLoadingGold ? (
                  <Skeleton className="h-9 w-32" />
                ) : (
                  <div className="flex items-baseline gap-2">
                    <span className="text-3xl font-bold">{formatCurrency(agcPrice || 0)}</span>
                    {priceChange !== undefined && (
                      <span className={`flex items-center text-sm font-medium px-2 py-0.5 rounded-full ${
                        priceChange >= 0 
                          ? 'bg-green-500/10 text-green-500' 
                          : 'bg-red-500/10 text-red-500'
                      }`}>
                        {priceChange >= 0 
                          ? <TrendingUp className="h-3 w-3 mr-1" /> 
                          : <TrendingDown className="h-3 w-3 mr-1" />
                        }
                        {Math.abs(priceChange).toFixed(2)}%
                      </span>
                    )}
                  </div>
                )}
                <p className="text-sm text-muted-foreground">Per Token (10mg Gold)</p>
              </div>

              <div className="space-y-2 bg-card/50 p-4 rounded-lg border border-border/50">
                <h3 className="text-sm font-medium text-muted-foreground flex items-center gap-2">
                  <Scale className="h-4 w-4 text-primary" />
                  Gold Price (24k)
                </h3>
                {isLoadingGold || !currentGoldPrice ? (
                  <Skeleton className="h-9 w-32" />
                ) : (
                  <div className="flex items-baseline">
                    <span className="text-3xl font-bold">{formatCurrency(currentGoldPrice)}</span>
                  </div>
                )}
                <p className="text-sm text-muted-foreground">Per Troy Ounce</p>
              </div>

              <div className="space-y-2 bg-card/50 p-4 rounded-lg border border-border/50">
                <h3 className="text-sm font-medium text-muted-foreground flex items-center gap-2">
                  <DollarSign className="h-4 w-4 text-primary" />
                  Market Cap
                </h3>
                <div className="flex items-baseline">
                  <span className="text-3xl font-bold">{formatCurrency((agcPrice || 0) * 1000000)}</span>
                </div>
                <p className="text-sm text-muted-foreground">Fully Diluted</p>
              </div>

              <div className="space-y-2 bg-card/50 p-4 rounded-lg border border-border/50">
                <h3 className="text-sm font-medium text-muted-foreground flex items-center gap-2">
                  <Wallet className="h-4 w-4 text-primary" />
                  Total Supply
                </h3>
                <div className="flex items-baseline">
                  <span className="text-3xl font-bold">1,000,000</span>
                </div>
                <p className="text-sm text-muted-foreground">AGC Tokens</p>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Token Metrics */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <MetricCard
            icon={<Scale className="text-primary" />}
            title="Gold Backing"
            value="10mg"
            subtitle=" Gold per Token"
            tooltip="Each AGC token is backed by 10mg of certified gold"
          />
          <MetricCard
            icon={<LineChart className="text-primary" />}
            title="Price Stability"
            value="1:1"
            subtitle="Gold-Backed Ratio"
            tooltip="AGC maintains a 1:1 relationship with the backing gold's value"
          />
          <MetricCard
            icon={<Shield className="text-primary" />}
            title="Security"
            value="100%"
            subtitle="Vault Secured"
            tooltip="All physical gold is stored in high-security vaults with regular audits"
          />
          <MetricCard
            icon={<Globe className="text-primary" />}
            title="Accessibility"
            value="24/7"
            subtitle="Global Trading"
            tooltip="Trade AGC tokens anytime, anywhere on supported exchanges"
          />
        </div>

        {/* Features Tabs */}
        <Tabs defaultValue="features" className="w-full">
          <TabsList className="w-full max-w-md mx-auto grid grid-cols-3 mb-6">
            <TabsTrigger value="features" className="text-sm">Key Features</TabsTrigger>
            <TabsTrigger value="details" className="text-sm">Token Details</TabsTrigger>
            <TabsTrigger value="roadmap" className="text-sm">Roadmap</TabsTrigger>
          </TabsList>
          
          <TabsContent value="features">
            <Card className="animate-fade-in-up border-t-4 border-t-primary">
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Shield className="h-5 w-5 text-primary" />
                  Key Features
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <FeatureCard
                    icon={<Scale />}
                    title="Physical Gold Backing"
                    description="Each token is backed by 10mg of certified physical gold, ensuring real-world value"
                  />
                  <FeatureCard
                    icon={<Wallet />}
                    title="BNB Smart Chain"
                    description="Fast and cost-effective transactions on BSC network with minimal gas fees"
                  />
                  <FeatureCard
                    icon={<ShieldCheck />}
                    title="Secure Storage"
                    description="Gold reserves stored in high-security vaults with regular third-party audits"
                  />
                  <FeatureCard
                    icon={<Globe />}
                    title="Global Trading"
                    description="Trade AGC 24/7 from anywhere in the world on supported exchanges"
                  />
                </div>
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="details">
            <Card className="animate-fade-in-up border-t-4 border-t-primary">
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Coins className="h-5 w-5 text-primary" />
                  Token Details
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-4">
                    <DetailItem label="Token Standard" value="BEP-20" icon={<Sparkles className="h-4 w-4 text-primary" />} />
                    <DetailItem label="Gold per Token" value="10mg of Gold" icon={<Scale className="h-4 w-4 text-primary" />} />
                    <DetailItem label="Total Supply" value="1,000,000 AGC" icon={<Coins className="h-4 w-4 text-primary" />} />
                  </div>
                  <div className="space-y-4">
                    <DetailItem label="Launch Date" value="Q2 2025" icon={<Clock className="h-4 w-4 text-primary" />} />
                    <DetailItem label="Audit Status" value="Pending" icon={<ShieldCheck className="h-4 w-4 text-primary" />} />
                    <DetailItem label="Storage Location" value="Secure Vaults" icon={<Shield className="h-4 w-4 text-primary" />} />
                  </div>
                </div>
              </CardContent>
            </Card>
          </TabsContent>
          
          <TabsContent value="roadmap">
            <Card className="animate-fade-in-up border-t-4 border-t-primary">
              <CardHeader>
                <CardTitle className="flex items-center gap-2">
                  <Clock className="h-5 w-5 text-primary" />
                  Roadmap
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-8">
                  <RoadmapItem
                    quarter="Q2 2025"
                    title="Initial Launch"
                    items={[
                      "Token launch on BNB Smart Chain",
                      "First gold acquisition and vault storage",
                      "Listing on EvoCash exchange",
                      "Mobile money integration",
                      "Virtual debit card integration"
                    ]}
                    status="current"
                  />
                  <RoadmapItem
                    quarter="Q3 2025"
                    title="Expansion Phase"
                    items={[
                      "Additional exchange listings",
                      "Partnership announcements",

                    ]}
                    status="upcoming"
                  />
                  <RoadmapItem
                    quarter="Q4 2025"
                    title="Ecosystem Growth"
                    items={[
                      "Staking program launch",
                      "Liquidity pool expansion",
                      "Community governance features"
                    ]}
                    status="upcoming"
                  />
                </div>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>

        {/* CTA Section */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/30 via-primary/20 to-background p-10">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_80%)]" />
          <div className="absolute bottom-0 right-0 w-[300px] h-[300px] bg-primary/10 rounded-full blur-3xl" />
          <div className="relative z-10 flex flex-col md:flex-row justify-between items-center gap-8">
            <div>
              <h2 className="text-2xl sm:text-3xl font-bold mb-3">Start Trading AGC</h2>
              <p className="text-muted-foreground text-lg">
                Join the future of gold-backed digital assets today.
              </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
              <Button size="lg" className="min-w-[200px] bg-primary hover:bg-primary/90 shadow-lg shadow-primary/20" asChild>
                <Link to="/dashboard/trade">
                  Buy AGC
                </Link>
              </Button>
              <Button size="lg" variant="outline" className="min-w-[200px]" asChild>
                <a href="https://africangoldcoin.org/documentation" target="_blank" rel="noopener noreferrer">
                  Learn More
                </a>
              </Button>
            </div>
          </div>
        </section>
      </div>
    </Dashboard>
  );
};

const MetricCard = ({ icon, title, value, subtitle, tooltip }: { 
  icon: React.ReactNode; 
  title: string; 
  value: string; 
  subtitle: string;
  tooltip?: string; 
}) => (
  <Card className="hover:shadow-md transition-shadow duration-300 overflow-hidden">
    <CardContent className="pt-6">
      <div className="flex justify-between items-center mb-4">
        <TooltipProvider>
          <TooltipTrigger asChild>
            <span className="text-muted-foreground flex items-center">
              {title}
              {tooltip && (
                <span className="inline-flex ml-1 cursor-help">ⓘ</span>
              )}
            </span>
          </TooltipTrigger>
          {tooltip && <TooltipContent>{tooltip}</TooltipContent>}
        </TooltipProvider>
        <div className="p-2 rounded-full bg-primary/10">
          {icon}
        </div>
      </div>
      <p className="text-2xl font-bold">{value}</p>
      <span className="text-muted-foreground text-sm">{subtitle}</span>
    </CardContent>
  </Card>
);

const FeatureCard = ({ icon, title, description }: {
  icon: React.ReactNode;
  title: string;
  description: string;
}) => (
  <div className="bg-card/50 p-6 rounded-lg border border-border/50 hover:shadow-md transition-shadow">
    <div className="rounded-full bg-primary/10 p-3 inline-flex mb-4">
      {React.cloneElement(icon as React.ReactElement, { className: "h-5 w-5 text-primary" })}
    </div>
    <h3 className="font-medium text-lg mb-2">{title}</h3>
    <p className="text-muted-foreground">{description}</p>
  </div>
);

const DetailItem = ({ label, value, icon }: { 
  label: string; 
  value: string;
  icon?: React.ReactNode;
}) => (
  <div className="flex justify-between items-center pb-4 border-b">
    <span className="text-muted-foreground flex items-center gap-2">
      {icon}
      {label}
    </span>
    <span className="font-medium bg-primary/5 px-3 py-1 rounded-md">{value}</span>
  </div>
);

const RoadmapItem = ({ quarter, title, items, status }: {
  quarter: string;
  title: string;
  items: string[];
  status: 'completed' | 'current' | 'upcoming';
}) => {
  const getStatusBadge = () => {
    switch(status) {
      case 'completed':
        return <Badge variant="default" className="bg-green-500">Completed</Badge>;
      case 'current':
        return <Badge variant="default" className="bg-primary">Current</Badge>;
      case 'upcoming':
        return <Badge variant="outline">Upcoming</Badge>;
      default:
        return null;
    }
  };
  
  return (
    <div className="flex gap-6">
      <div className="relative flex flex-col items-center">
        <div className={`w-8 h-8 rounded-full flex items-center justify-center z-10 ${
          status === 'completed' ? 'bg-green-500' : 
          status === 'current' ? 'bg-primary' : 
          'bg-muted border border-border'
        }`}>
          {status === 'completed' && <BadgeCheck className="h-4 w-4 text-white" />}
          {status === 'current' && <Clock className="h-4 w-4 text-white" />}
        </div>
        <div className="w-0.5 bg-border h-full absolute top-8 bottom-0 z-0"></div>
      </div>
      <div className="flex-1 pb-8">
        <div className="flex items-center justify-between mb-2">
          <h3 className="font-bold text-lg">{quarter} - {title}</h3>
          {getStatusBadge()}
        </div>
        <ul className="space-y-2 mt-4">
          {items.map((item, i) => (
            <li key={i} className="flex items-start gap-2">
              <div className="rounded-full bg-primary/10 p-1 mt-0.5">
                <ArrowRight className="h-3 w-3 text-primary" />
              </div>
              <span className="text-muted-foreground">{item}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Markets;