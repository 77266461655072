
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '@/components/layout/Dashboard';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { 
  ArrowRight, 
  Users, 
  Target, 
  Lightbulb, 
  Shield, 
  Globe, 
  Award,
  Building,
  Wallet,
  Coins,
  Lock,
  Gem,
  LinkedinIcon
} from 'lucide-react';

const About = () => {
  useEffect(() => {
    document.title = 'EvoCash | About Us';
  }, []);

  return (
    <Dashboard>
      <div className="flex flex-col space-y-16">
        {/* Hero Section with Enhanced Gradient Animation */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/20 via-primary/10 to-background p-10 md:p-16">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
          
          {/* Floating elements for visual interest */}
          <div className="absolute top-20 right-10 w-24 h-24 bg-primary/10 rounded-full blur-xl animate-pulse" />
          <div className="absolute bottom-10 left-10 w-32 h-32 bg-primary/5 rounded-full blur-xl animate-pulse [animation-delay:2s]" />
          
          <div className="relative z-10 max-w-3xl">
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-primary/10 text-primary mb-8 animate-fade-up">
              <Building className="h-4 w-4" />
              <span className="text-sm font-medium">Trusted by 10,000+ Investors</span>
            </div>
            <h1 className="animate-fade-up text-4xl md:text-6xl font-bold tracking-tight mb-6">
              Bridging Traditional Assets
              <span className="block bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/70">
                With Digital Innovation
              </span>
            </h1>
            <p className="text-xl text-muted-foreground mb-8 animate-fade-up [--animation-delay:200ms] leading-relaxed">
              EvoCash is revolutionizing asset ownership through blockchain technology, making premium assets accessible, tradeable, and secure for everyone.
            </p>
            <Button size="lg" asChild className="animate-fade-up [--animation-delay:400ms] group">
              <Link to="/signup" className="flex items-center gap-2">
                Join Our Ecosystem 
                <ArrowRight className="group-hover:translate-x-1 transition-transform" />
              </Link>
            </Button>
          </div>
        </section>

        {/* Key Features Section */}
        <section className="px-2">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-3">Key Features</h2>
            <p className="text-muted-foreground max-w-2xl mx-auto">
              Revolutionizing asset ownership through innovative technology
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card className="group hover:shadow-md transition-all duration-300 hover:translate-y-[-4px] border-muted/80 hover:border-primary/30">
              <CardContent className="pt-6">
                <div className="flex flex-col gap-3 mb-4">
                  <div className="p-2 w-fit rounded-lg bg-primary/10 mb-2">
                    <Gem className="h-6 w-6 text-primary" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Asset Tokenization</h3>
                  <p className="text-muted-foreground">
                    Converting premium physical assets into digital tokens, enabling fractional ownership and seamless trading.
                  </p>
                </div>
              </CardContent>
            </Card>

            <Card className="group hover:shadow-md transition-all duration-300 hover:translate-y-[-4px] border-muted/80 hover:border-primary/30">
              <CardContent className="pt-6">
                <div className="flex flex-col gap-3 mb-4">
                  <div className="p-2 w-fit rounded-lg bg-primary/10 mb-2">
                    <Lock className="h-6 w-6 text-primary" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Secure Storage</h3>
                  <p className="text-muted-foreground">
                    State-of-the-art security for both digital tokens and physical assets, with regular audits and insurance.
                  </p>
                </div>
              </CardContent>
            </Card>

            <Card className="group hover:shadow-md transition-all duration-300 hover:translate-y-[-4px] border-muted/80 hover:border-primary/30">
              <CardContent className="pt-6">
                <div className="flex flex-col gap-3 mb-4">
                  <div className="p-2 w-fit rounded-lg bg-primary/10 mb-2">
                    <Globe className="h-6 w-6 text-primary" />
                  </div>
                  <h3 className="text-xl font-bold mb-2">Global Access</h3>
                  <p className="text-muted-foreground">
                    24/7 trading platform accessible worldwide, with instant settlements and minimal fees.
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        </section>

        {/* Stats Section */}
        <section className="bg-muted/30 rounded-3xl p-8 md:p-12">
          <div className="grid md:grid-cols-4 gap-8 text-center">
            <div>
              <div className="text-4xl font-bold text-primary mb-2">10,000+</div>
              <div className="text-muted-foreground">Early Signups</div>
            </div>
            <div>
              <div className="text-4xl font-bold text-primary mb-2">24/7</div>
              <div className="text-muted-foreground">Trading Platform</div>
            </div>
            <div>
              <div className="text-4xl font-bold text-primary mb-2">100%</div>
              <div className="text-muted-foreground">Asset-Backed</div>
            </div>
            <div>
              <div className="text-4xl font-bold text-primary mb-2">Q2 2025</div>
              <div className="text-muted-foreground">Launch Date</div>
            </div>
          </div>
        </section>

        {/* Mission & Vision Section */}
        <section className="px-2">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-3">Our Mission & Vision</h2>
            <p className="text-muted-foreground max-w-2xl mx-auto">Building the future of global asset ownership</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Card className="group hover:shadow-md transition-all duration-300 hover:translate-y-[-4px] border-muted/80 hover:border-primary/30">
              <CardContent className="pt-8">
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-2.5 rounded-full bg-primary/10">
                    <Target className="h-8 w-8 text-primary" />
                  </div>
                  <h2 className="text-2xl font-bold">Our Mission</h2>
                </div>
                <p className="text-lg text-muted-foreground mb-4">
                  To democratize access to premium assets globally through blockchain technology, with a special focus on connecting African markets to international financial ecosystems, enabling seamless cross-border transactions and innovative financial solutions for everyone.
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-primary" />
                    <span>Bridging African markets with global financial networks</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-primary" />
                    <span>Facilitating instant, low-cost international transactions</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-primary" />
                    <span>Creating inclusive global investment opportunities</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-primary" />
                    <span>Building trust through worldwide regulatory compliance</span>
                  </li>
                </ul>
              </CardContent>
            </Card>

            <Card className="group hover:shadow-md transition-all duration-300 hover:translate-y-[-4px] border-muted/80 hover:border-primary/30">
              <CardContent className="pt-8">
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-2.5 rounded-full bg-primary/10">
                    <Lightbulb className="h-8 w-8 text-primary" />
                  </div>
                  <h2 className="text-2xl font-bold">Our Vision</h2>
                </div>
                <p className="text-lg text-muted-foreground mb-4">
                  To become a leading global financial technology platform that seamlessly connects African innovation with international markets, while fostering worldwide economic growth and financial inclusion through asset tokenization and blockchain technology.
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-primary" />
                    <span>Foster worldwide economic growth through technology</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-primary" />
                    <span>Enable seamless cross-border transactions</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="h-1.5 w-1.5 rounded-full bg-primary" />
                    <span>Drive financial inclusion through innovation</span>
                  </li>
                </ul>
              </CardContent>
            </Card>
          </div>
        </section>

        {/* Core Values Section */}
        <section className="mb-20">
          <div className="text-center mb-12">
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-primary/10 text-primary mb-4">
              <Award className="h-4 w-4" />
              <span className="text-sm font-medium">Our Values</span>
            </div>
            <h2 className="text-3xl font-bold mb-3">Core Values</h2>
            <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
              The principles that drive our innovation and shape our global platform
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            <ValueCard
              icon={<Shield />}
              title="Security First"
              description="Enterprise-grade security for both digital and physical assets, with regular audits and comprehensive insurance coverage."
            />
            <ValueCard
              icon={<Coins />}
              title="Asset Integrity"
              description="100% backed by physical assets stored in secure facilities with complete transparency and regular third-party audits."
            />
            <ValueCard
              icon={<Award />}
              title="Global Innovation"
              description="Pioneering blockchain solutions that bridge African markets with global financial systems while maintaining local relevance."
            />
          </div>
        </section>

        {/* Team Section */}
        <section className="px-2">
          <div className="text-center mb-12">
            <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-primary/10 text-primary mb-4">
              <Users className="h-4 w-4" />
              <span className="text-sm font-medium">Global Leadership</span>
            </div>
            <h2 className="text-3xl font-bold mb-3">Meet Our Team</h2>
            <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
              A diverse group of experts committed to connecting African innovation with global markets
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <TeamMemberCard
              name=" "
              title="Chief Executive Officer"
              description=""
              linkedin="https://linkedin.com/in/"
            />
            <TeamMemberCard
              name=""
              title="Chief Technology Officer"
              description=""
              linkedin="https://linkedin.com/in/sarah-okonjo"
            />
            <TeamMemberCard
              name=""
              title="Chief Operations Officer"
              description=""
              linkedin="https://linkedin.com/in/michael-adebayo"
            />
            <TeamMemberCard
              name=""
              title="Head of Legal & Compliance"
              description=""
              linkedin="https://linkedin.com/in/grace-mutua"
            />
            <TeamMemberCard
              name=""
              title="Head of Product"
              description=""
              linkedin="https://linkedin.com/in/omar-hassan"
            />
            <TeamMemberCard
              name=""
              title="Head of Partnerships"
              description=""
              linkedin="https://linkedin.com/in/claire-nkrumah"
            />
          </div>
        </section>

        {/* CTA Section */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/30 via-primary/20 to-background p-10 md:p-12">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_80%)]" />
          
          <div className="relative z-10 flex flex-col md:flex-row justify-between items-center gap-8">
            <div className="max-w-2xl">
              <h2 className="text-3xl font-bold mb-4">Join the Future of Global Asset Investment</h2>
              <p className="text-lg text-muted-foreground mb-6">
                Be part of the revolution in digital asset ownership. Launching globally in Q2 2025.
              </p>
            </div>
            <div className="flex flex-wrap gap-4">
              <Button size="lg" className="min-w-36" asChild>
                <Link to="/signup">Register for Early Access</Link>
              </Button>
              <Button size="lg" variant="outline" className="min-w-36" asChild>
                <Link to="/contact">Contact Our Team</Link>
              </Button>
            </div>
          </div>
        </section>
      </div>
    </Dashboard>
  );
};

const ValueCard = ({ icon, title, description }: { icon: React.ReactNode, title: string, description: string }) => (
  <Card className="text-center hover:shadow-lg transition-all duration-300 group">
    <CardContent className="pt-8">
      <div className="mb-4 inline-flex p-3 rounded-full bg-primary/10 group-hover:bg-primary/20 transition-colors">
        <div className="size-8 text-primary">{icon}</div>
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-muted-foreground">{description}</p>
    </CardContent>
  </Card>
);

const TeamMemberCard = ({ 
  name, 
  title, 
  description, 
  linkedin 
}: { 
  name: string;
  title: string;
  description: string;
  linkedin: string;
}) => (
  <Card className="group hover:shadow-lg transition-all duration-300">
    <CardContent className="pt-8 text-center">
      <div className="mb-4 relative">
        <a
          href={linkedin}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center bg-primary text-white p-2 rounded-full 
            hover:bg-primary/90 transition-colors duration-300"
        >
          <LinkedinIcon className="h-4 w-4" />
        </a>
      </div>
      <h3 className="text-xl font-semibold mb-1">{name}</h3>
      <div className="text-primary font-medium mb-2">{title}</div>
      <p className="text-muted-foreground text-sm">{description}</p>
    </CardContent>
  </Card>
);

export default About;
