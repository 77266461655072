import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Wallet,
  LineChart,
  Clock,
  HelpCircle,
  Bell,
  Settings,
  User,
  Search,
  CreditCard,
  History,
  TrendingUp,
  Shield,
  MessageSquare,
  ExternalLink,
  Sparkles,
  Bot,
  ArrowRight,
  Loader2,
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { useChat } from '@/hooks/useChat';
import { geminiService } from '@/services/geminiService';
import type { GeminiResponse } from '@/services/geminiService';
import { AIContext } from '@/contexts/AIContext';

interface SearchResult {
  id: string;
  title: string;
  icon: JSX.Element;
  url: string;
  category: string;
  description?: string;
  keywords?: string[];
  shortcut?: string[];
  aiSuggestions?: string[];
  aiSuggested?: boolean;
  relevanceScore?: number;
  contextualHint?: string;
}

interface SearchDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onAskAI?: (question: string) => void;
}

export function SearchDialog({ open, setOpen, onAskAI }: SearchDialogProps) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [showAIHelp, setShowAIHelp] = useState(false);
  const [isAIThinking, setIsAIThinking] = useState(false);
  const [aiError, setAIError] = useState<string | null>(null);
  const [aiSuggestions, setAISuggestions] = useState<Array<{
    text: string;
    category: string;
    confidence: number;
  }>>([]);
  const [aiContext, setAIContext] = useState<{
    userIntent: string | null;
    relevantTopics: string[];
  }>({
    userIntent: null,
    relevantTopics: []
  });

  // Add new state for AI features
  const [aiAnalysis, setAiAnalysis] = useState<{
    intent: string | null;
    confidence: number;
    suggestedActions: string[];
    relatedTopics: string[];
  }>({
    intent: null,
    confidence: 0,
    suggestedActions: [],
    relatedTopics: []
  });

  // Enhanced search with real-time AI analysis
  const analyzeSearchQuery = useCallback(async (query: string) => {
    if (query.length < 2) return;

    setIsAIThinking(true);
    try {
      const aiResponse = await geminiService.chat(query, {
        executeActions: false,
        priority: 'low',
        contextType: 'search',
        contextWindowSize: 5
      });

      // Update AI analysis
      setAiAnalysis({
        intent: aiResponse.intent || null,
        confidence: aiResponse.confidence || 0,
        suggestedActions: aiResponse.actions?.map(a => a.description) || [],
        relatedTopics: aiResponse.relevantTopics || []
      });

      // Update search results with AI insights
      if (searchResults.length > 0) {
        const enhancedResults = await enhanceResultsWithAI(searchResults, aiResponse);
        setSearchResults(enhancedResults);
      }

      // Show AI help for questions or when confidence is high
      if (aiResponse.isQuestion || aiResponse.confidence > 0.8) {
        setShowAIHelp(true);
      }

    } catch (error) {
      console.error('AI analysis error:', error);
      setAIError('Error analyzing search with AI');
    } finally {
      setIsAIThinking(false);
    }
  }, [searchResults]);

  // Enhanced handler for asking EVA
  const handleAskEVA = async (question: string) => {
    setIsAIThinking(true);
    setAIError(null);

    try {
      // First, add the question to recent searches
      setRecentSearches(prev => [question, ...prev.slice(0, 4)]);

      const response = await geminiService.chat(question, {
        executeActions: true,
        priority: 'high',
        contextType: 'conversation',
        contextWindowSize: 10
      });

      // Process AI response
      if (response.text) {
        // Close dialog first
        setOpen(false);

        // Important: Ensure onAskAI is called immediately, not in setTimeout
        if (onAskAI) {
          onAskAI(question);
        }

        // Update suggestions if available
        if (response.suggestions) {
          setAISuggestions(response.suggestions.map(suggestion => ({
            text: suggestion,
            category: 'suggestion',
            confidence: response.confidence || 0.5
          })));
        }

        // Update context if relevant topics are available
        if (response.relevantTopics) {
          setAIContext(prev => ({
            ...prev,
            relevantTopics: response.relevantTopics
          }));
        }
      }
    } catch (error) {
      console.error('Error processing AI request:', error);
      setAIError('Unable to process your request. Please try again.');
    } finally {
      setIsAIThinking(false);
    }
  };

  // Enhanced handler for AI suggestion clicks
  const handleAISuggestionClick = async (suggestion: string) => {
    setIsAIThinking(true);
    setAIError(null);

    try {
      const response = await geminiService.chat(suggestion, {
        executeActions: true,
        priority: 'medium',
        contextType: 'suggestion',
        contextWindowSize: 5
      });

      if (response.text) {
        // Handle the AI response
        setOpen(false);
        if (onAskAI) {
          onAskAI(suggestion);
        }

        // Update suggestions if available
        if (response.suggestions?.length) {
          setAISuggestions(response.suggestions.map(suggestion => ({
            text: suggestion,
            category: 'suggestion',
            confidence: response.confidence || 0.5
          })));
        }
      }
    } catch (error) {
      console.error('Error processing suggestion:', error);
      setAIError('Unable to process this suggestion. Please try again.');
    } finally {
      setIsAIThinking(false);
    }
  };

  // Helper function to enhance results with AI insights
  const enhanceResultsWithAI = async (
    results: SearchResult[], 
    aiResponse: GeminiResponse
  ): Promise<SearchResult[]> => {
    return results.map(result => ({
      ...result,
      aiSuggested: aiResponse.relevantPages?.includes(result.url) || false,
      contextualHint: aiResponse.pageHints?.[result.url],
      relevanceScore: calculateRelevanceScore(result, aiResponse)
    })).sort((a, b) => (b.relevanceScore || 0) - (a.relevanceScore || 0));
  };

  // Helper function to calculate relevance score
  const calculateRelevanceScore = (
    result: SearchResult, 
    aiResponse: GeminiResponse
  ): number => {
    let score = 0;
    
    // Basic text matching
    if (result.title.toLowerCase().includes(searchQuery.toLowerCase())) score += 0.4;
    if (result.description?.toLowerCase().includes(searchQuery.toLowerCase())) score += 0.3;
    
    // AI-suggested relevance
    if (aiResponse.relevantPages?.includes(result.url)) score += 0.5;
    if (aiResponse.pageHints?.[result.url]) score += 0.2;
    
    return score;
  };

  // Actual pages from your application with AI suggestions
  const defaultResults: SearchResult[] = [
    {
      id: 'dashboard',
      title: 'Dashboard',
      description: 'View your portfolio overview and quick actions',
      icon: <LineChart className="h-4 w-4" />,
      url: '/dashboard',
      category: 'Main',
      keywords: ['home', 'overview', 'summary'],
      shortcut: ['g', 'd'],
      aiSuggestions: [
        'Show me my portfolio performance',
        'What are my recent transactions?',
        'How is my AGC balance doing?'
      ]
    },
    {
      id: 'transactions',
      title: 'Transactions',
      description: 'View and manage your transaction history',
      icon: <History className="h-4 w-4" />,
      url: '/dashboard/transactions',
      category: 'Finance',
      keywords: ['payments', 'history', 'transfers'],
      shortcut: ['g', 't']
    },
    {
      id: 'trade',
      title: 'Trade',
      description: 'Buy and sell digital assets',
      icon: <TrendingUp className="h-4 w-4" />,
      url: '/dashboard/trade',
      category: 'Finance',
      keywords: ['exchange', 'buy', 'sell'],
      aiSuggestions: [
        'How do I buy AGC?',
        'Show current exchange rates',
        'Explain trading fees'
      ]
    },
    {
      id: 'wallet',
      title: 'Wallet',
      description: 'Manage your digital wallet and assets',
      icon: <Wallet className="h-4 w-4" />,
      url: '/dashboard/wallet',
      category: 'Finance',
      keywords: ['balance', 'assets', 'funds'],
      aiSuggestions: [
        'How do I deposit funds?',
        'What are my wallet addresses?',
        'Show my AGC balance'
      ]
    },
    {
      id: 'deposit',
      title: 'Deposit',
      description: 'Add funds to your account',
      icon: <CreditCard className="h-4 w-4" />,
      url: '/dashboard/deposit',
      category: 'Finance',
      keywords: ['fund', 'add money', 'payment'],
      aiSuggestions: [
        'How do I deposit funds?',
        'What payment methods are available?',
        'help me deposit money'
      ]
    },
    {
      id: 'withdraw',
      title: 'Withdraw',
      description: 'Withdraw funds from your account',
      icon: <CreditCard className="h-4 w-4" />,
      url: '/dashboard/withdraw',
      category: 'Finance',
      keywords: ['money', 'transfer out'],
      aiSuggestions: [
        'How do I withdraw funds?',
        'What are the withdrawal fees?',
        'help me withdraw money'
      ]
    },
    {
      id: 'profile',
      title: 'Profile',
      description: 'View and edit your profile',
      icon: <User className="h-4 w-4" />,
      url: '/dashboard/profile',
      category: 'Account',
      keywords: ['account', 'personal', 'settings'],
    },
    {
      id: 'settings',
      title: 'Settings',
      description: 'Manage your account settings',
      icon: <Settings className="h-4 w-4" />,
      url: '/dashboard/settings',
      category: 'Account',
      keywords: ['preferences', 'configuration'],
    },
    {
      id: 'notifications',
      title: 'Notifications',
      description: 'View your notifications and updates',
      icon: <Bell className="h-4 w-4" />,
      url: '/dashboard/notifications',
      category: 'Account',
      keywords: ['alerts', 'messages'],
    },
    {
      id: 'markets',
      title: 'Markets',
      description: 'Explore available markets',
      icon: <Sparkles className="h-4 w-4" />,
      url: '/markets',
      category: 'Trading',
      keywords: ['prices', 'exchange', 'rates'],
    },
    {
      id: 'help',
      title: 'Help Center',
      description: 'Get help and support',
      icon: <HelpCircle className="h-4 w-4" />,
      url: '/help',
      category: 'Support',
      keywords: ['support', 'faq', 'assistance'],
    },
  ];

  // Update the search function to use Gemini
  const enhanceSearchWithGemini = async (query: string): Promise<SearchResult[]> => {
    if (!query) return defaultResults;

    try {
      const response = await geminiService.chat(query, {
        executeActions: false,
        priority: 'low',
        contextWindowSize: 5
      });

      // Enhance results with Gemini insights
      const enhancedResults = defaultResults.map(result => ({
        ...result,
        aiSuggested: response.context?.relevantPages?.includes(result.url) || false,
        contextualHint: response.context?.pageHints?.[result.url],
        relevanceScore: calculateRelevanceScore(result, response)
      }));

      // Sort by relevance score
      return enhancedResults.sort((a, b) => (b.relevanceScore || 0) - (a.relevanceScore || 0));
    } catch (error) {
      console.error('Error enhancing search with Gemini:', error);
      return defaultResults;
    }
  };

  useEffect(() => {
    const performSearch = async () => {
      if (searchQuery.startsWith('?') || 
          searchQuery.toLowerCase().includes('how') || 
          searchQuery.toLowerCase().includes('what') || 
          searchQuery.toLowerCase().includes('why')) {
        setShowAIHelp(true);
        // Get AI suggestions
        const response = await geminiService.getSearchSuggestions(searchQuery);
        if (response.suggestions) {
          setAISuggestions(response.suggestions.map(suggestion => ({
            text: suggestion,
            category: 'suggestion',
            confidence: response.confidence || 0.5
          })));
        }
      } else {
        setShowAIHelp(false);
        const enhancedResults = await enhanceSearchWithGemini(searchQuery);
        setSearchResults(enhancedResults);
      }
    };

    const debounceTimer = setTimeout(performSearch, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  const handleSelect = (result: SearchResult) => {
    setOpen(false);
    setRecentSearches(prev => {
      const newSearches = [result.title, ...prev.filter(s => s !== result.title)].slice(0, 3);
      localStorage.setItem('recentSearches', JSON.stringify(newSearches));
      return newSearches;
    });
    navigate(result.url);
  };

  const handleAskAI = (question: string = searchQuery) => {
    if (onAskAI) {
      setOpen(false); // Close the dialog first
      onAskAI(question.startsWith('?') ? question.slice(1).trim() : question.trim());
    }
  };

  useEffect(() => {
    const saved = localStorage.getItem('recentSearches');
    if (saved) {
      setRecentSearches(JSON.parse(saved));
    }
  }, []);

  // Add real-time AI suggestions as user types
  useEffect(() => {
    const getSuggestions = async () => {
      if (searchQuery.length < 2) return;
      
      setIsAIThinking(true);
      try {
        const response = await geminiService.chat(searchQuery, {
          executeActions: false,
          priority: 'low'
        });
        
        if (response.suggestions) {
          setAISuggestions(response.suggestions.map(suggestion => ({
            text: suggestion,
            category: 'suggestion',
            confidence: response.confidence || 0.5
          })));
        }
      } catch (error) {
        console.error('AI suggestion error:', error);
      } finally {
        setIsAIThinking(false);
      }
    };

    const debounceTimer = setTimeout(getSuggestions, 300);
    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  // Enhanced search ranking function
  const rankSearchResults = async (query: string, results: SearchResult[]) => {
    if (!query) return results;

    const response = await geminiService.chat(query, {
      executeActions: false,
      priority: 'low',
      contextWindowSize: 5
    });

    return results.map(result => {
      let relevanceScore = 0;
      
      // Basic text matching
      if (result.title.toLowerCase().includes(query.toLowerCase())) relevanceScore += 0.4;
      if (result.description?.toLowerCase().includes(query.toLowerCase())) relevanceScore += 0.3;
      if (result.keywords?.some(k => k.toLowerCase().includes(query.toLowerCase()))) relevanceScore += 0.2;
      
      // AI-suggested relevance
      if (response.context?.relevantPages?.includes(result.url)) relevanceScore += 0.5;
      
      return {
        ...result,
        relevanceScore,
        aiSuggested: response.context?.relevantPages?.includes(result.url),
        contextualHint: response.context?.pageHints?.[result.url]
      };
    }).sort((a, b) => b.relevanceScore - a.relevanceScore);
  };

  // Enhanced AI feedback component

  // Enhanced AI help section
  const renderAIHelp = () => (
    <div className="p-4">
      {isAIThinking ? (
        <div className="flex items-center justify-center gap-2">
          <Loader2 className="h-4 w-4 animate-spin" />
          <span>EVA is thinking...</span>
        </div>
      ) : (
        <>
          {aiError ? (
            <div className="text-red-500 text-sm mb-4">{aiError}</div>
          ) : null}
          <div className="flex flex-wrap gap-2 justify-center mb-4 max-w-md">
            {aiSuggestions.map((suggestion, idx) => (
              <Button
                key={idx}
                variant="outline"
                size="sm"
                onClick={() => handleAISuggestionClick(suggestion.text)}
                className={cn(
                  "flex items-center gap-2",
                  suggestion.confidence > 0.8 ? "bg-primary/10" : "bg-primary/5",
                  "hover:bg-primary/20"
                )}
              >
                <Bot className="h-3 w-3" />
                {suggestion.text}
                {suggestion.confidence > 0.8 && (
                  <span className="ml-1 text-xs text-green-500">★</span>
                )}
              </Button>
            ))}
          </div>
          {aiContext.userIntent && (
            <div className="mb-4 text-sm text-muted-foreground">
              <p>I think you're looking for: <span className="font-medium">{aiContext.userIntent}</span></p>
            </div>
          )}
          <p className="text-sm text-muted-foreground mb-4">
            I can help you with:
          </p>
          <div className="flex flex-wrap gap-2 justify-center mb-4 max-w-md">
            {aiSuggestions.map((suggestion, idx) => (
              <Button
                key={idx}
                variant="outline"
                size="sm"
                onClick={() => handleAISuggestionClick(suggestion.text)}
                className="flex items-center gap-2 bg-primary/5 hover:bg-primary/10"
              >
                <Bot className="h-3 w-3" />
                {suggestion.text}
                {suggestion.confidence > 0.8 && (
                  <span className="ml-1 text-xs text-green-500">★</span>
                )}
              </Button>
            ))}
          </div>
          {aiContext.relevantTopics.length > 0 && (
            <div className="mt-4">
              <p className="text-sm font-medium mb-2">Related topics:</p>
              <div className="flex flex-wrap gap-2">
                {aiContext.relevantTopics.map((topic, idx) => (
                  <Badge key={idx} variant="outline" className="cursor-pointer"
                         onClick={() => setSearchQuery(topic)}>
                    {topic}
                  </Badge>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  // Add notification polling
  useEffect(() => {
    const pollInterval = setInterval(async () => {
      const suggestions = await geminiService.getSuggestedActions();
      if (suggestions.length > 0) {
        setAINotifications(suggestions.map(s => ({
          message: s.text,
          priority: s.priority || 'low',
          expiresAt: Date.now() + 300000 // 5 minutes
        })));
      }
    }, 60000); // Poll every minute

    return () => clearInterval(pollInterval);
  }, []);


  // Add a keydown handler
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchQuery.startsWith('?')) {
        const question = searchQuery.slice(1).trim();
        if (question) {
          handleAskEVA(question);
        }
      } else if (showAIHelp) {
        handleAskEVA(searchQuery);
      }
    }
  };

  return (
    <CommandDialog 
      open={open} 
      onOpenChange={setOpen} 
      className="overflow-hidden max-w-[1200px] w-[98vw] bg-background backdrop-filter-none backdrop-blur-0"
      style={{ backdropFilter: 'none' }}
    >
      <div className="flex items-center border-b px-3 bg-background w-full">
        <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
        <div className="flex-1 min-w-0">
          <CommandInput 
            placeholder="Search or ask EVA (? for help)"
            className="h-14 border-0 focus:ring-0 focus:border-0 text-base w-full px-0"
            value={searchQuery}
            onValueChange={setSearchQuery}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <CommandList className="max-h-[500px] overflow-y-auto bg-background">
        <CommandEmpty>
          {showAIHelp ? renderAIHelp() : (
            <div className="flex flex-col items-center justify-center py-6 text-sm text-muted-foreground">
              <Search className="h-8 w-8 mb-2 opacity-40" />
              <p className="text-base font-medium">No pages found for "{searchQuery}"</p>
              <p className="mt-2 text-xs">
                Type ? or ask a question like "How do I..." to get help from EVA
              </p>
              <Button 
                variant="outline" 
                size="sm" 
                className="mt-4"
                onClick={() => handleAskEVA(searchQuery)}
              >
                <Bot className="mr-2 h-4 w-4" />
                Ask EVA Instead
              </Button>
            </div>
          )}
        </CommandEmpty>

        {recentSearches.length > 0 && !searchQuery && (
          <>
            <CommandGroup heading="Recent Searches">
              {recentSearches.map((title) => {
                const result = defaultResults.find(r => r.title === title);
                if (!result) return null;
                return (
                  <CommandItem
                    key={result.id}
                    onSelect={() => handleSelect(result)}
                    className="px-4 py-2 cursor-pointer hover:bg-accent"
                  >
                    <div className="flex items-center">
                      <Clock className="h-4 w-4 mr-2 opacity-50" />
                      <span>{result.title}</span>
                    </div>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            <CommandSeparator />
          </>
        )}

        {Object.entries(
          searchResults.reduce((acc, item) => {
            if (!acc[item.category]) {
              acc[item.category] = [];
            }
            acc[item.category].push(item);
            return acc;
          }, {} as Record<string, SearchResult[]>)
        ).map(([category, items], categoryIndex) => (
          <div key={category}>
            <CommandGroup heading={category} className="px-2">
              {items.map((result) => (
                <div key={result.id}>
                  <CommandItem
                    onSelect={() => handleSelect(result)}
                    className={cn(
                      "px-4 py-3 cursor-pointer hover:bg-accent rounded-lg transition-colors",
                      result.aiSuggested && "border-l-2 border-primary"
                    )}
                  >
                    <div className="flex items-center justify-between flex-1">
                      <div className="flex items-center space-x-3">
                        <div className="bg-primary/10 p-2 rounded-lg">
                          {result.icon}
                        </div>
                        <div className="flex flex-col">
                          <div className="font-medium">{result.title}</div>
                          {result.description && (
                            <span className="text-xs text-muted-foreground">
                              {result.description}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center space-x-3">
                        {result.shortcut && (
                          <div className="hidden md:flex items-center space-x-1">
                            {result.shortcut.map((key, index) => (
                              <kbd
                                key={index}
                                className="h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100"
                              >
                                {key}
                              </kbd>
                            ))}
                          </div>
                        )}
                        <ExternalLink className="h-4 w-4 opacity-50" />
                      </div>
                    </div>
                  </CommandItem>
                  {result.aiSuggestions && (
                    <div className="ml-12 mb-2 flex flex-wrap gap-2">
                      {result.aiSuggestions.map((suggestion, idx) => (
                        <button
                          key={idx}
                          onClick={() => handleAISuggestionClick(suggestion)}
                          className="text-xs px-2 py-1 rounded-full bg-primary/5 hover:bg-primary/10 
                                   text-primary transition-colors flex items-center gap-1"
                        >
                          <Bot className="h-3 w-3" />
                          {suggestion}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </CommandGroup>
            {categoryIndex < Object.entries(searchResults).length - 1 && (
              <CommandSeparator />
            )}
          </div>
        ))}
      </CommandList>
    </CommandDialog>
  );
}
