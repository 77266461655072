// Interface for API response
export interface ApiAsset {
  _id?: string;
  assetName?: string;
  symbol?: string;
  type?: string;
  balance?: number;
}

// Model class for Asset
export class Asset {
  id?: string;
  assetName: string;
  symbol: string;
  type: string;
  totalBalance: number;
  availableBalance: number;
  pendingBalance: number;
  logo: string;

  constructor(init: ApiAsset) {
    this.id = init._id || '';
    this.assetName = init.assetName || 'Unknown';
    this.symbol = init.symbol || 'N/A';
    this.type = init.type || 'Unknown';
    this.totalBalance = init.balance ?? 0;
    this.availableBalance = 0; // Set default or compute based on logic
    this.pendingBalance = 0; // Set default or compute based on logic
    this.logo = '🔷'; // Default icon
  }

  static fromApiResponse(apiResponse: ApiAsset[]): Asset[] {
    return apiResponse.map(asset => new Asset(asset));
  }
}



// Interface for UserBalance
export interface ApiUserBalance {
  Asset: string;
  Balance: number;
}

// Class for UserBalance with default value
export class UserBalance implements ApiUserBalance {
  Asset: string;
  Balance: number;

  constructor(Asset: string = 'Unknown', Balance: number = 0) {
    this.Asset = Asset;
    this.Balance = Balance;
  }
}

// Interface for API DepositCrypto
export interface ApiDepositCrypto {
  depositAddress: string;
  network?: string;
  link?: string;
}

// Class for DepositCrypto with default values
export class DepositCrypto implements ApiDepositCrypto {
  depositAddress: string;
  network: string;
  link: string;

  constructor(data: Partial<ApiDepositCrypto> = {}) {
    this.depositAddress = data.depositAddress || '0x0000000000000000000000000000000000000000';
    this.network = data.network || 'Binance Smart Chain (BSC)';
    this.link = data.link || `https://testnet.bscscan.com/address/${this.depositAddress}`;
  }
}