import { useNavigate } from 'react-router-dom';
import { ArrowDown, ArrowUp, ArrowLeftRight, ShoppingCart, DollarSign } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { useIsMobile } from '@/hooks/use-mobile';
import { Asset } from '@/components/wallet/WalletModels';

interface AssetTableProps {
  assets: Asset[];
}

const AssetTable = ({ assets }: AssetTableProps) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleDeposit = (asset: Asset) => {
    navigate(`/dashboard/wallet/deposit`, { state: { asset } });
  };

  const handleWithdraw = (asset: Asset) => {
    navigate(`/dashboard/wallet/withdraw`, { state: { asset } });
  };

  const handleTransfer = (asset: Asset) => {
    navigate(`/dashboard/wallet/transfer`, { state: { asset } });
  };

  const handleBuy = (asset: Asset) => {
    //need to select the payment method
    navigate(`/dashboard/wallet/payment`, { state: { asset } });
   console.log(asset.id);
  };

  const handleSell = (asset: Asset) => {
    navigate(`/dashboard/wallet/sell`, { state: { asset } });
  };

  if (assets.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={6} className="text-center py-6">
          No assets found matching your criteria.
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Asset</TableHead>
          <TableHead className="hidden sm:table-cell">Type</TableHead>
          <TableHead className="text-center">Balance</TableHead>
          <TableHead className={isMobile ? "text-center w-[100px]" : "w-[140px]"}>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {assets.map((asset) => (
          <TableRow key={asset.id}>
            <TableCell>
              {isMobile ? (
                <div className="flex items-center">
                  <span className="text-xl mr-2">{asset.logo}</span>
                  <span className="font-medium">{asset.symbol}</span>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <span className="text-xl">{asset.logo}</span>
                  <div>
                    <p className="font-medium">{asset.assetName}</p>
                    <p className="text-xs text-muted-foreground">{asset.symbol}</p>
                  </div>
                </div>
              )}
            </TableCell>
            <TableCell className="hidden sm:table-cell">
              <span className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium bg-secondary text-secondary-foreground">
                {asset.type}
              </span>
            </TableCell>
            <TableCell className="text-center">
              {isMobile ? (
                <div className="space-y-1 text-center">
                  <p className="font-medium text-lg">{asset.totalBalance.toLocaleString()} {asset.symbol}</p>
                  <div className="text-xs text-muted-foreground space-y-0.5">
                    <p>Available: {asset.totalBalance.toLocaleString()}</p>
                    <p>Pending: {asset.pendingBalance.toLocaleString()}</p>
                  </div>
                </div>
              ) : (
                <div className="space-y-1 text-center">
                  <p className="font-medium">{asset.totalBalance.toLocaleString()} {asset.symbol}</p>
                  <div className="flex flex-col text-xs text-muted-foreground">
                    <span>Available: {asset.totalBalance.toLocaleString()} {asset.symbol}</span>
                    <span>Pending: {asset.pendingBalance.toLocaleString()} {asset.symbol}</span>
                  </div>
                </div>
              )}
            </TableCell>
            <TableCell className={isMobile ? "text-center" : ""}>
              {isMobile ? (
                <div className="flex gap-2 justify-center">
                  <Button 
                    size="icon" 
                    variant="outline"
                    onClick={() => handleDeposit(asset)}
                    className="h-8 w-8"
                  >
                    <ArrowDown className="h-3 w-3" />
                  </Button>
                  <Button 
                    size="icon" 
                    variant="outline"
                    onClick={() => handleWithdraw(asset)}
                    className="h-8 w-8"
                    disabled
                  >
                    <ArrowUp className="h-3 w-3" />
                  </Button>
                  <Button 
                    size="icon" 
                    variant="outline"
                    onClick={() => handleTransfer(asset)}
                    className="h-8 w-8"
                  >
                    <ArrowLeftRight className="h-3 w-3" />
                  </Button>
                  <Button 
                    size="icon" 
                    variant="outline"
                    onClick={() => handleBuy(asset)}
                    className="h-8 w-8"
                  >
                    <ShoppingCart className="h-3 w-3" />
                  </Button>
                  <Button 
                    size="icon" 
                    variant="outline"
                    onClick={() => handleSell(asset)}
                    className="h-8 w-8"
                    disabled
                  >
                    <DollarSign className="h-3 w-3" />
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col sm:flex-row gap-2">
                  <Button 
                    size="sm" 
                    variant="outline"
                    onClick={() => handleDeposit(asset)}
                    className="w-full sm:w-auto justify-center"
                  >
                    <ArrowDown className="h-3 w-3 mr-1" />
                    <span className="sr-only sm:not-sr-only">Deposit</span>
                  </Button>
                  <Button 
                    size="sm" 
                    variant="outline"
                    onClick={() => handleWithdraw(asset)}
                    className="w-full sm:w-auto justify-center"
                    disabled
                  >
                    <ArrowUp className="h-3 w-3 mr-1" />
                    <span className="sr-only sm:not-sr-only">Withdraw</span>
                  </Button>
                  <Button 
                    size="sm" 
                    variant="outline"
                    onClick={() => handleTransfer(asset)}
                    className="w-full sm:w-auto justify-center"
                  >
                    <ArrowLeftRight className="h-3 w-3 mr-1" />
                    <span className="sr-only sm:not-sr-only">Transfer</span>
                  </Button>
                  <Button 
                    size="sm" 
                    variant="outline"
                    onClick={() => handleBuy(asset)}
                    className="w-full sm:w-auto justify-center"
                  >
                    <ShoppingCart className="h-3 w-3 mr-1" />
                    <span className="sr-only sm:not-sr-only">Buy</span>
                  </Button>
                  <Button 
                    size="sm" 
                    variant="outline"
                    onClick={() => handleSell(asset)}
                    className="w-full sm:w-auto justify-center"
                    disabled
                  >
                    <DollarSign className="h-3 w-3 mr-1" />
                    <span className="sr-only sm:not-sr-only">Sell</span>
                  </Button>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default AssetTable;