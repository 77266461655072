import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '@/components/layout/Dashboard';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Search,
  HelpCircle,
  MessageSquare,
  Book,
  FileText,
  Mail,
  Phone,
  ArrowRight,
  ExternalLink,
  Sparkles,
  Clock,
  Shield,
  Wallet,
  RefreshCw,
} from 'lucide-react';
import { usePageTitleEffect } from '@/hooks/usePageTitleEffect';

const Help = () => {
  const [searchQuery, setSearchQuery] = useState('');
  usePageTitleEffect('Help Center');

  const helpCategories = [
    {
      title: 'Getting Started',
      icon: <Book className="h-6 w-6" />,
      description: 'New to EvoCash? Start here',
      items: [
        { 
          title: 'Account Creation', 
          link: '/faq#account-creation',
          content: `To create your EvoCash account:
          1. Click "Sign Up" in the top right corner
          2. Enter your email, name, and create a password
          3. Verify your email through the OTP sent
          4. Complete your profile with required information
          5. Set up 2FA for enhanced security`
        },
        { 
          title: 'Identity Verification', 
          link: '/faq#kyc',
          content: `Complete KYC verification to unlock full platform features:
          1. Submit valid government ID
          2. Provide proof of address
          3. Complete facial verification
          4. Wait for verification (usually 24-48 hours)`
        },
        { 
          title: 'First Steps', 
          link: '/faq#first-steps',
          content: `After account creation:
          1. Explore the dashboard
          2. Set up security features
          3. Add payment methods
          4. Make your first deposit
          5. Start trading AGC tokens`
        },
      ]
    },
    {
      title: 'Security Features',
      icon: <Shield className="h-6 w-6" />,
      description: 'Protect your account and assets',
      items: [
        { 
          title: 'Two-Factor Authentication', 
          link: '/faq#2fa',
          content: `Enable 2FA for extra security:
          1. Go to Security Settings
          2. Choose authentication method (Email or Authenticator app)
          3. Follow setup instructions
          4. Store backup codes safely
          Note: 2FA is required for withdrawals and major account changes`
        },
        { 
          title: 'Security Alerts', 
          link: '/faq#security-alerts',
          content: `Stay informed about account activity:
          • Login notifications
          • Withdrawal alerts
          • Suspicious activity warnings
          • Device authorization notices
          Configure alert preferences in Security Settings`
        },
        { 
          title: 'Password Management', 
          link: '/faq#password',
          content: `Maintain strong account security:
          • Use complex passwords
          • Change passwords regularly
          • Never share credentials
          • Enable login notifications
          Reset password through "Forgot Password" if needed`
        },
      ]
    },
    {
      title: 'Trading & Transactions',
      icon: <RefreshCw className="h-6 w-6" />,
      description: 'Learn about trading AGC tokens',
      items: [
        { 
          title: 'How to Trade AGC', 
          link: '/faq#trading',
          content: `Trading AGC tokens:
          1. Navigate to Trading section
          2. Select AGC trading pair
          3. Enter amount to trade
          4. Review transaction details
          5. Confirm trade
          Monitor your portfolio for performance tracking`
        },
        { 
          title: 'Deposits & Withdrawals', 
          link: '/faq#transactions',
          content: `Managing your funds:
          Deposits:
          1. Go to Wallet section
          2. Select "Deposit"
          3. Choose payment method
          4. Follow payment instructions

          Withdrawals:
          1. Select "Withdraw"
          2. Enter withdrawal amount
          3. Provide recipient address
          4. Complete 2FA verification
          5. Wait for processing`
        },
        { 
          title: 'Transaction Fees', 
          link: '/faq#fees',
          content: `Fee Structure:
          • Trading: 0.1% per trade
          • Deposits: Free for most methods
          • Withdrawals: Network fee + 0.1%
          • Internal transfers: Free
          Volume discounts available for high-volume traders`
        },
      ]
    },
  ];

  const popularTopics = [
    { 
      title: 'Buy AGC Tokens', 
      link: '/faq#buying-agc', 
      icon: <Wallet />,
      content: 'Step-by-step guide to purchasing AGC tokens'
    },
    { 
      title: 'Account Security', 
      link: '/faq#security', 
      icon: <Shield />,
      content: 'Essential security features and best practices'
    },
    { 
      title: 'Trading Hours', 
      link: '/faq#trading-hours', 
      icon: <Clock />,
      content: '24/7 trading available for all pairs'
    },
  ];

  // Filtered search results
  const getFilteredContent = () => {
    if (!searchQuery) return [];
    
    const results = [];
    helpCategories.forEach(category => {
      category.items.forEach(item => {
        if (
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.content.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          results.push({
            category: category.title,
            ...item
          });
        }
      });
    });
    return results;
  };

  const filteredResults = getFilteredContent();

  return (
    <Dashboard>
      <div className="max-w-7xl mx-auto px-4 py-8 space-y-8">
        {/* Hero Section */}
        <section className="text-center space-y-4 mb-12">
          <div className="inline-flex items-center rounded-full border border-primary/20 bg-background/50 backdrop-blur-xl p-1 pr-4 mb-4">
            <div className="bg-primary/10 rounded-full p-2 mr-2">
              <HelpCircle className="h-4 w-4 text-primary" />
            </div>
            <span className="text-sm">Help Center</span>
          </div>
          <h1 className="text-4xl font-bold">How can we help you?</h1>
          <div className="max-w-2xl mx-auto relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground h-4 w-4" />
            <Input
              type="search"
              placeholder="Search for help..."
              className="pl-10"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </section>

        {/* Search Results */}
        {searchQuery && (
          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Search Results</h2>
            {filteredResults.length > 0 ? (
              <div className="grid gap-4">
                {filteredResults.map((result, index) => (
                  <Card key={index}>
                    <CardHeader>
                      <CardTitle className="text-lg">
                        <div className="flex justify-between items-center">
                          <span>{result.title}</span>
                          <span className="text-sm text-muted-foreground">
                            {result.category}
                          </span>
                        </div>
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="prose prose-sm max-w-none">
                        <p className="whitespace-pre-line">{result.content}</p>
                      </div>
                      <Link 
                        to={result.link} 
                        className="inline-flex items-center mt-4 text-primary hover:underline"
                      >
                        Learn more
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </Link>
                    </CardContent>
                  </Card>
                ))}
              </div>
            ) : (
              <Card>
                <CardContent className="text-center py-8">
                  <MessageSquare className="h-12 w-12 mx-auto mb-4 text-muted-foreground" />
                  <p className="text-lg mb-2">No results found</p>
                  <p className="text-muted-foreground">
                    Try adjusting your search terms or browse the categories below
                  </p>
                </CardContent>
              </Card>
            )}
          </section>
        )}

        {/* Show categories when not searching */}
        {!searchQuery && (
          <>
            {/* Popular Topics */}
            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4">Popular Topics</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {popularTopics.map((topic, index) => (
                  <Link to={topic.link} key={index}>
                    <Card className="hover:bg-muted/50 transition-colors">
                      <CardHeader>
                        <CardTitle className="flex items-center">
                          {topic.icon}
                          <span className="ml-2">{topic.title}</span>
                        </CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-muted-foreground">{topic.content}</p>
                      </CardContent>
                    </Card>
                  </Link>
                ))}
              </div>
            </section>

            {/* Help Categories */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">Browse Categories</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {helpCategories.map((category, index) => (
                  <Card key={index}>
                    <CardHeader>
                      <CardTitle className="flex items-center">
                        {category.icon}
                        <span className="ml-2">{category.title}</span>
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      <p className="text-muted-foreground mb-4">
                        {category.description}
                      </p>
                      <ul className="space-y-2">
                        {category.items.map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <Link
                              to={item.link}
                              className="flex items-center text-primary hover:underline"
                            >
                              {item.title}
                              <ArrowRight className="ml-2 h-4 w-4" />
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </section>
          </>
        )}

        {/* Quick Contact Section */}
        <Card className="mt-12 bg-primary/5 border-primary/10">
          <CardContent className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="text-center group hover:transform hover:scale-105 transition-all">
                <MessageSquare className="h-8 w-8 mx-auto mb-4 text-primary group-hover:scale-110 transition-transform" />
                <h3 className="font-semibold mb-2">Live Chat</h3>
                <p className="text-sm text-muted-foreground mb-4">
                  Get instant help from our support team
                </p>
                <Button asChild>
                  <Link to="/contact">Start Chat</Link>
                </Button>
              </div>
              
              <div className="text-center group hover:transform hover:scale-105 transition-all">
                <Mail className="h-8 w-8 mx-auto mb-4 text-primary group-hover:scale-110 transition-transform" />
                <h3 className="font-semibold mb-2">Email Support</h3>
                <p className="text-sm text-muted-foreground mb-4">
                  Response within 24 hours
                </p>
                <Button variant="outline" asChild>
                  <a href="mailto:support@evocash.org">
                    support@evocash.org
                  </a>
                </Button>
              </div>
              
              <div className="text-center group hover:transform hover:scale-105 transition-all">
                <Phone className="h-8 w-8 mx-auto mb-4 text-primary group-hover:scale-110 transition-transform" />
                <h3 className="font-semibold mb-2">Phone Support</h3>
                <p className="text-sm text-muted-foreground mb-4">
                  Available 9 AM - 5 PM EST
                </p>
                <Button variant="outline">
                  +1 (555) 123-4567
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Additional Resources */}
        <section className="mt-12">
          <h2 className="text-2xl font-semibold mb-6">Additional Resources</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Button variant="outline" className="justify-between group" asChild>
              <a 
                href="https://africangoldcoin.org/documentation" 
                target="_blank" 
                rel="noopener noreferrer"
                className="h-auto py-4"
              >
                <div className="text-left">
                  <div className="font-semibold">AGC Token Documentation</div>
                  <div className="text-sm text-muted-foreground">Detailed technical documentation</div>
                </div>
                <ExternalLink className="h-4 w-4 ml-2 group-hover:translate-x-1 transition-transform" />
              </a>
            </Button>
            <Button variant="outline" className="justify-between group" asChild>
              <a 
                href="https://africangoldcoin.org/get-started" 
                target="_blank" 
                rel="noopener noreferrer"
                className="h-auto py-4"
              >
                <div className="text-left">
                  <div className="font-semibold">Getting Started Guide</div>
                  <div className="text-sm text-muted-foreground">Step-by-step tutorials</div>
                </div>
                <ExternalLink className="h-4 w-4 ml-2 group-hover:translate-x-1 transition-transform" />
              </a>
            </Button>
          </div>
        </section>
      </div>
    </Dashboard>
  );
};

export default Help;
