import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useWallet } from '@/contexts/WalletContext';
import { Asset } from '@/components/wallet/WalletModels';
import { toast } from 'sonner';

const TransferForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { transferCrypto } = useWallet();
  const asset: Asset = location.state.asset;
  const [amount, setAmount] = useState<string>('');
  const [recipientIdentifier, setRecipientIdentifier] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [amountError, setAmountError] = useState<string | null>(null);
  const [recipientError, setRecipientError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleTransfer = async () => {
    const amountNumber = Number(amount);

    if (!amount) {
      setAmountError('Amount is required');
      return;
    }else{
      setAmountError(null);
    }

    if (amountNumber <= 0) {
      setError('Cannot transfer 0 ' + asset.symbol + ' or less. Please change the amount');
      return;
    } 
    // else if (asset.availableBalance < amountNumber) {
    //     setError('Insufficient balance for transfer. You only have ' + asset.availableBalance + ' ' + asset.symbol + ' left');
    //   return;
    // } 
    else{
        setError(null);
    }

    if (!recipientIdentifier) {
        setRecipientError('Recipient identifier is required');
        return;
      }else{
          setRecipientError(null);
      }

    setLoading(true);

    try {
      const success = await transferCrypto(amountNumber, recipientIdentifier);
      if (success) {
        navigate('/dashboard/wallet');
      }
    } catch (err) {
      setError('An error occurred during the transfer. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <p className="mb-4">Your balance: {asset.totalBalance} {asset.symbol}</p>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Amount in {asset.symbol}</label>
        <div className="flex">
          <Input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            min="0"
            className="w-full"
            disabled={loading}
          />
        </div>
        {amountError && <p className="text-red-500 mt-2">{amountError}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">User email or phone</label>
        <Input
          type="text"
          value={recipientIdentifier}
          onChange={(e) => setRecipientIdentifier(e.target.value)}
          className="w-full"
          disabled={loading}
        />
        {recipientError && <p className="text-red-500 mt-2">{recipientError}</p>}
      </div>
      <Button onClick={handleTransfer} className="w-full" disabled={loading}>
        {loading ? 'Transferring...' : 'Transfer'}
      </Button>
    </div>
  );
};

export default TransferForm;