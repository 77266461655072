import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import userService from '@/services/userService';
import authService from '@/services/authService';
import { toast } from 'sonner';
import User  from '@/components/profile/UserModel';
import { LoginHistory } from '@/components/profile/LoginHistoryModel';

interface UserContextType {
  user: User | null;
  loginHistory: LoginHistory[];
  isLoading: boolean;
  isAuthenticated: boolean;
  fetchUserProfile: () => Promise<void>;
  updateUserProfile: (profileData: { lastName: string; firstName: string, avatar: File, deleteAvatar: boolean }) => Promise<void>;
  updateUserEmail: (emailData: { oldEmail: string; email: string }) => Promise<void>;
  updateUserKYC: (kycData: { country: string; phoneNumber: string; dateOfBirth: string }) => Promise<boolean>;
  getLoginHistory: () => Promise<void>;
  clearUser: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loginHistory, setLoginHistory] = useState<LoginHistory[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Load user from localStorage on initial render
    const loadUser = () => {
      try {
        const currentUser = authService.getCurrentUser();
        setUser(currentUser);
      } catch (error) {
        console.error('Error loading user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadUser();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await userService.getProfile();
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      toast.error('Failed to fetch user profile');
    } 
  };

  const updateUserProfile = async (profileData: { lastName: string; firstName: string, avatar: File, deleteAvatar: boolean }) => {
    try {
      const response = await userService.updateProfile(profileData);
      if (response.status) { // No need for response.data.status
        toast.success(response.message);
        //setUser(response); // If needed
      } else {
          toast.error(response.message); // Access response.message directly
      }
    } catch (error: any) {
        console.error('Error updating user names:', error);

        // Handling backend error response properly
        if (error.response && error.response.data) {
            toast.error(error.response.data.message || 'Failed to update user names');
        } else {
            toast.error('Failed to update user names');
        }
    } 
  };

  const updateUserEmail = async (emailData: { oldEmail: string; email: string }) => {
    try {
      const response = await userService.updateEmail(emailData);
      if (response.status) { // No need for response.data.status
        toast.success(response.message);
        // setUser(response); // If needed
      } else {
        toast.error(response.message); // Access response.message directly
      }
    } catch (error: any) {
        console.error('Error updating user Email:', error);

        // Handling backend error response properly
        if (error.response && error.response.data) {
            toast.error(error.response.data.message || 'Failed to update user Email');
        } else {
            toast.error('Failed to update user Email');
        }
    } 
  };

  const updateUserKYC = async (kycData: { country: string; phoneNumber: string; dateOfBirth: string }) => {
    
    try {
        const response = await userService.userKYC(kycData); // response is already response.data

        if (response.status) { // No need for response.data.status
            toast.success(response.message);
        } else {
            toast.error(response.message); // Access response.message directly
        }
        return response.status;
    } catch (error: any) {
        console.error('Error updating user KYC:', error);
        // Handling backend error response properly
        if (error.response && error.response.data) {
            toast.error(error.response.data.message || 'Failed to update user KYC');
        } else {
            toast.error('Failed to update user KYC');
        }
    }
};

const getLoginHistory = async () => {
    
  try {
      const response = await userService.loginHistory(); 

      if (response.status) { 
          //toast.success(response.message);
          setLoginHistory(response.data);
      } else {
          toast.error(response.message); // Access response.message directly
      }
    } catch (error: any) {
      console.error('Error fetching login history:', error);
      
      // Handling backend error response properly
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || 'Failed to fetch login history');
      } else {
        toast.error('Failed to fetch login history');
      }
    }
  };




  const clearUser = () => {
    setUser(null);
    // Clear from localStorage is handled by authService.logout
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      loginHistory,
      isLoading, 
      isAuthenticated: !!user, 
      fetchUserProfile,
      updateUserProfile,
      updateUserEmail,
      updateUserKYC,
      getLoginHistory,
      clearUser 
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};