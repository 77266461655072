
import { ReactNode } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import MainContent from './MainContent';

interface DashboardProps {
  children: ReactNode;
}

const Dashboard = ({ children }: DashboardProps) => {
  return (
    <div className="min-h-screen flex flex-col bg-background">
      <Navbar />
      <MainContent>{children}</MainContent>
      <Footer />
    </div>
  );
};

export default Dashboard;
