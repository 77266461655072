import { useEffect, useState } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Wallet, ArrowUpRight, ArrowDownRight, LineChart, ArrowRight, ArrowLeftRight } from 'lucide-react';
import MetricCard from '@/components/ui-components/MetricCard';
import { useUser } from '@/contexts/UserContext';
import { useWallet } from '@/contexts/WalletContext';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { AlertCircle } from 'lucide-react';
import { Card } from '@/components/ui/card';
import { Loader2 } from 'lucide-react';
import { formatCurrency, formatNumber } from '@/lib/utils';
import axios from 'axios';

interface ApiError {
  status?: boolean;
  code?: number;
  message?: string;
  response?: {
    data?: {
      status?: boolean;
      code?: number;
      message?: string;
    };
  };
}

const Dashboard = () => {
  const { user } = useUser();
  const { assets, fetchAssets, loading: isLoadingWallet, error } = useWallet();
  const [needsVerification, setNeedsVerification] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'EvoCash | Dashboard';

    // Safely check kycLevel
    const kycLevel = user?.kycLevel ?? 0;
    console.log('KYC Level:', kycLevel);
    
    const fetchData = async () => {
      try {
        await fetchAssets();
      } catch (error: unknown) {
        if (axios.isAxiosError(error) && error.response?.status === 403) {
          setNeedsVerification(true);
        }
        console.error("Error fetching assets:", error);
      }
    };

    fetchData();
  }, []); 

  // Use a separate useEffect to check the error from context
  useEffect(() => {
    if (error) {
      const isVerificationError = 
        typeof error === 'object' && 
        'code' in error && 
        error.code === 403 &&
        error.message?.includes("country, date of birth and phone number");
      
      if (isVerificationError || (user?.kycLevel ?? 0) === 0) {
        setNeedsVerification(true);
      }
    }
  }, [error, user?.kycLevel]);

  // Navigate to profile for verification
  const handleVerificationRedirect = () => {
    navigate('/dashboard/profile');
  };

  // Verification Card Component
    const VerificationCard = () => (
      <Card className="p-6 mb-6 border-amber-500 dark:border-amber-400 border-2">
        <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
          <div className="flex items-center justify-center p-2 rounded-full bg-amber-100 dark:bg-amber-900">
            <AlertCircle className="h-6 w-6 text-amber-500 dark:text-amber-400" />
          </div>
          <div className="flex-1">
            <h3 className="text-lg font-semibold mb-1">Verification Required</h3>
            <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
              You need to complete your profile by setting your country, date of birth, and phone number to access wallet features.
            </p>
            <Button 
              onClick={handleVerificationRedirect}
              className="bg-amber-500 hover:bg-amber-600 text-white dark:bg-amber-600 dark:hover:bg-amber-700"
            >
              Complete Verification
            </Button>
          </div>
        </div>
      </Card>
    );

     // Remove the conditional useEffect and replace with a simple derived state variable
  const shouldShowVerificationCard = needsVerification || (
    error && 
    typeof error === 'object' && 
    ((error as ApiError).code === 403 || 
     (error as ApiError).status === false && (error as ApiError).code === 403 ||
     (error as ApiError).message?.includes("country, date of birth and phone number") ||
     (error as ApiError).response?.data?.code === 403 ||
     (error as ApiError).response?.data?.message?.includes("country, date of birth and phone number"))
  );


  if (isLoadingWallet) {
    return (
      <DashboardLayout>
        <div className="flex items-center justify-center h-[60vh]">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-8">
        <section className="mb-8">
          <div className="rounded-xl bg-gradient-to-r from-primary/10 via-primary/5 to-background p-8 backdrop-blur-sm border">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                    <span className="text-primary font-semibold">
                      {user.firstName?.[0] || user.email?.[0]?.toUpperCase() || 'U'}
                    </span>
                  </div>
                  <h1 className="text-2xl font-bold tracking-tight">
                    Welcome back, {user.firstName || 'there'}!
                  </h1>
                </div>
                <p className="text-muted-foreground">
                  Manage your AGC tokens and track your portfolio performance
                </p>
              </div>
              
              <div className="flex gap-3">
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => navigate('/dashboard/wallet')}
                  className="hidden sm:flex"
                >
                  <Wallet className="mr-2 h-4 w-4" />
                  View Wallet
                </Button>
                <Button 
                  size="sm"
                  onClick={() => navigate('/dashboard/trade')}
                >
                  <ArrowLeftRight className="mr-2 h-4 w-4" />
                  Trade Now
                </Button>
              </div>
            </div>
          </div>
        </section>

        {shouldShowVerificationCard && <VerificationCard />}
            <div className="mx-auto max-w-4xl">
              <Card className="overflow-hidden">
                <div className="overflow-x-auto">
                  {loading ? (
                    <div className="flex justify-center items-center p-6">
                      <Loader2 className="h-6 w-6 animate-spin mr-2" />
                      <span>Loading...</span>
                    </div>
                  ) : needsVerification ? (
                    <div className="flex justify-center items-center p-6">
                      <span>Complete verification to view your wallet</span>
                    </div>
                  ) : error ? (
                    <div className="flex justify-center items-center p-6">
                      <span>{typeof error === 'string' ? error : 'An error occurred loading your wallet'}</span>
                    </div>
                  ) : (
                    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                      {assets.map((asset, index) => (
                        <MetricCard
                          key={index}
                          title={"Wallet Balance for " + asset.type}
                          value={asset.totalBalance + " " + asset.assetName}
                          icon={<Wallet className="h-4 w-4" />}
                          description={"No description available"}
                          variant="glass"
                        />
                      ))}
                    </section>
                  )}
                </div>
              </Card>
            </div>
        {/* Future implementation notes:
        
        The following components have been temporarily removed as requested:
        
        1. Token price charts and metrics with mock data
        2. Transaction list with mock data
        3. Security status indicators with mock data
        4. Wallet actions that are not yet implemented
        
        These will be implemented when connected to real backend data.
        */}

      </div>
    </DashboardLayout>
  );
};

const quickActions = [
  { label: 'Deposit', icon: <ArrowUpRight className="h-6 w-6 text-primary" />, path: '/dashboard/wallet/deposit' },
  { label: 'Withdraw', icon: <ArrowDownRight className="h-6 w-6 text-primary" />, path: '/dashboard/wallet/withdraw' },
  { label: 'Trade', icon: <LineChart className="h-6 w-6 text-primary" />, path: '/dashboard/trade' },
  { label: 'Wallet', icon: <Wallet className="h-6 w-6 text-primary" />, path: '/dashboard/wallet' },
];

const MarketOverview = ({ navigate, marketData }) => (
  <Card className="p-6">
    <div className="flex justify-between items-center mb-6">
      <h2 className="font-semibold">Market Overview</h2>
      <Button variant="ghost" size="sm" className="text-primary" onClick={() => navigate('/dashboard/markets')}>
        View All <ArrowRight className="ml-2 h-4 w-4" />
      </Button>
    </div>
    <div className="space-y-4">
      <MarketItem 
        symbol="AGC"
        name="African Gold Coin"
        price={formatCurrency(marketData?.AGC?.price || 0)}
        change={formatNumber(marketData?.AGC?.change || 0)}
        volume={formatCurrency(marketData?.AGC?.volume || 0)}
      />
      <MarketItem 
        symbol="BTC"
        name="Bitcoin"
        price="45,678.90"
        change="-2.34"
        volume="28.5B"
      />
    </div>
  </Card>
);

const RecentTransactions = ({ navigate, walletData }) => (
  <Card className="p-6">
    <div className="flex justify-between items-center mb-6">
      <h2 className="font-semibold">Recent Transactions</h2>
      <Button variant="ghost" size="sm" className="text-primary" onClick={() => navigate('/dashboard/transactions')}>
        View All <ArrowRight className="ml-2 h-4 w-4" />
      </Button>
    </div>
    <div className="space-y-4">
      <TransactionItem 
        type="Buy"
        symbol="AGC"
        amount="10.5"
        value="1,052.41"
        time="2h ago"
      />
      <TransactionItem 
        type="Sell"
        symbol="AGC"
        amount="5.0"
        value="501.15"
        time="5h ago"
      />
    </div>
  </Card>
);

const MarketItem = ({ symbol, name, price, change, volume }) => {
  const isPositive = change.startsWith('+');
  
  return (
    <div className="flex items-center justify-between p-3 hover:bg-muted/50 rounded-lg transition-colors">
      <div className="flex items-center space-x-3">
        <div className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center">
          <span className="font-semibold text-primary">{symbol[0]}</span>
        </div>
        <div>
          <p className="font-medium">{symbol}</p>
          <p className="text-sm text-muted-foreground">{name}</p>
        </div>
      </div>
      <div className="text-right">
        <p className="font-medium">${price}</p>
        <p className={`text-sm ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
          {change}%
        </p>
      </div>
    </div>
  );
};

const TransactionItem = ({ type, symbol, amount, value, time }) => {
  const isBuy = type === 'Buy';
  
  return (
    <div className="flex items-center justify-between p-3 hover:bg-muted/50 rounded-lg transition-colors">
      <div className="flex items-center space-x-3">
        <div className={`p-2 rounded-full ${isBuy ? 'bg-green-500/10' : 'bg-red-500/10'}`}>
          {isBuy ? (
            <ArrowUpRight className={`h-4 w-4 ${isBuy ? 'text-green-500' : 'text-red-500'}`} />
          ) : (
            <ArrowDownRight className={`h-4 w-4 ${isBuy ? 'text-green-500' : 'text-red-500'}`} />
          )}
        </div>
        <div>
          <p className="font-medium">{type} {symbol}</p>
          <p className="text-sm text-muted-foreground">{amount} {symbol}</p>
        </div>
      </div>
      <div className="text-right">
        <p className="font-medium">${value}</p>
        <p className="text-sm text-muted-foreground">{time}</p>
      </div>
    </div>
  );
};

export default Dashboard;
