import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { TrendingUp, TrendingDown, Loader2, XCircle, RefreshCw } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface GoldPrice {
  timestamp: number;
  price: number;
  agcPrice: number;
}

interface TimeRange {
  label: string;
  days: number;
}

interface GoldPriceChartProps {
  onPriceUpdate?: (price: number) => void;
}

const TIME_RANGES: TimeRange[] = [
  { label: '1W', days: 7 },
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  
];

const GoldPriceChart = ({ onPriceUpdate }: GoldPriceChartProps) => {
  const [priceData, setPriceData] = useState<GoldPrice[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedRange, setSelectedRange] = useState<TimeRange>(TIME_RANGES[4]); // Default to 1Y
  const [hoveredPrice, setHoveredPrice] = useState<GoldPrice | null>(null);

  const fetchHistoricalGoldPrice = async (days: number) => {
    setLoading(true);
    setError(null);
    try {
      const endDate = Math.floor(Date.now() / 1000);
      const startDate = endDate - (days * 24 * 60 * 60);

      const response = await axios.get(
        `https://api.coingecko.com/api/v3/coins/tether-gold/market_chart/range?vs_currency=usd&from=${startDate}&to=${endDate}`,
        { timeout: 5000 } // 5 second timeout
      );
      
      const historicalData = response.data.prices.map(([timestamp, price]: [number, number]) => ({
        timestamp,
        price,
        agcPrice: (price * 10) / 31103.4768
      }));

      setPriceData(historicalData);
    } catch (err) {
      console.error('Error fetching historical gold price:', err);
      let errorMessage = 'Failed to fetch historical price data'; //'Failed to fetch historical price data'
      
      if (axios.isAxiosError(err)) {
        if (err.code === 'ECONNABORTED' || err.message.includes('timeout')) {
          errorMessage = 'Request timed out. Please check your connection and try again.';
        } else if (!err.response) {
          errorMessage = 'Network error. Please check your connection and try again.';
        } else if (err.response.status === 429) {
          errorMessage = 'Too many requests. Please wait a moment and try again.';
        } else if (err.response.status >= 500) {
          errorMessage = 'Service temporarily unavailable. Please try again later.';
        }
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHistoricalGoldPrice(selectedRange.days);
    const interval = setInterval(() => fetchHistoricalGoldPrice(selectedRange.days), 3600000);
    return () => clearInterval(interval);
  }, [selectedRange]);

  useEffect(() => {
    if (priceData.length > 0 && onPriceUpdate) {
      const currentPrice = priceData[priceData.length - 1].price;
      onPriceUpdate(currentPrice);
    }
  }, [priceData, onPriceUpdate]);

  const priceStats = useMemo(() => {
    if (!priceData.length) return null;

    const current = priceData[priceData.length - 1];
    const start = priceData[0];
    const priceChange = ((current.agcPrice - start.agcPrice) / start.agcPrice) * 100;
    const high = Math.max(...priceData.map(d => d.agcPrice));
    const low = Math.min(...priceData.map(d => d.agcPrice));

    return { current, priceChange, high, low };
  }, [priceData]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active || !payload) return null;
    
    const date = new Date(label);
    const goldPrice = payload[0]?.value;
    const agcPrice = payload[1]?.value;
    
    return (
      <Card className="shadow-xl border-border/50 !bg-background/95 backdrop-blur-sm">
        <CardContent className="p-4">
          <p className="text-sm font-medium text-foreground/80">
            {date.toLocaleDateString('en-US', { 
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </p>
          <div className="mt-3 space-y-2">
            <p className="text-sm flex justify-between gap-6">
              <span className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-amber-500" />
                <span className="font-medium">Gold Price:</span>
              </span>
              <span className="font-semibold">${goldPrice?.toFixed(2)}</span>
            </p>
            <p className="text-sm flex justify-between gap-6">
              <span className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full bg-primary" />
                <span className="font-medium">AGC Price:</span>
              </span>
              <span className="font-semibold">${agcPrice?.toFixed(2)}</span>
            </p>
          </div>
        </CardContent>
      </Card>
    );
  };

  if (loading && priceData.length === 0) {
    return (
      <Card className="w-full">
        <CardContent className="flex justify-center items-center min-h-[400px]">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Loader2 className="h-5 w-5 animate-spin" />
            <span>Loading price data...</span>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error && priceData.length === 0) {
    return (
      <Card className="w-full">
        <CardContent className="flex flex-col items-center justify-center min-h-[400px] text-center">
          <XCircle className="h-12 w-12 text-destructive mb-4" />
          <p className="text-lg font-medium mb-4">{error}</p>
          <div className="flex gap-3">
            <Button
              onClick={() => fetchHistoricalGoldPrice(selectedRange.days)}
              className="gap-2"
            >
              <RefreshCw className="h-4 w-4" />
              Try Again
            </Button>
            <Button
              variant="outline"
              onClick={() => setSelectedRange(TIME_RANGES[0])}
            >
              Switch to 1W
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Add this function to calculate Y-axis domain with padding
  const calculateYAxisDomain = (data: GoldPrice[], key: 'price' | 'agcPrice') => {
    if (!data.length) return [0, 0];
    
    const values = data.map(d => d[key]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    
    // Add 5% padding to the top and bottom
    const padding = (max - min) * 0.05;
    return [min - padding, max + padding];
  };

  return (
    <Card className="w-full bg-card/50 backdrop-blur-sm border-muted/20">
      <CardHeader className="space-y-2">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <div>
            <CardTitle className="text-2xl font-bold bg-gradient-to-r from-amber-500 to-yellow-500 bg-clip-text text-transparent">
              AGC Price Chart
            </CardTitle>
            <p className="text-sm text-muted-foreground">Track AGC and Gold price movements</p>
          </div>

          {priceStats && (
            <div className="flex flex-col items-end">
              <div className="flex items-center gap-3">
                <span className="text-3xl font-bold tracking-tight">
                  ${hoveredPrice?.agcPrice.toFixed(2) ?? priceStats.current.agcPrice.toFixed(2)}
                </span>
                <div className={cn(
                  "flex items-center gap-1.5 text-sm px-3 py-1.5 rounded-full font-medium transition-colors",
                  priceStats.priceChange >= 0 
                    ? "bg-success/15 text-success-foreground" 
                    : "bg-destructive/15 text-destructive-foreground"
                )}>
                  {priceStats.priceChange >= 0 ? (
                    <TrendingUp className="h-3.5 w-3.5" />
                  ) : (
                    <TrendingDown className="h-3.5 w-3.5" />
                  )}
                  <span>{Math.abs(priceStats.priceChange).toFixed(2)}%</span>
                </div>
              </div>
              <p className="text-sm text-muted-foreground mt-1">
                {selectedRange.label} change
              </p>
            </div>
          )}
        </div>

        {/* Time Range Selector */}
        <div className="flex flex-wrap items-center gap-2 pt-2">
          {TIME_RANGES.map((range) => (
            <Button
              key={range.label}
              variant={selectedRange.label === range.label ? "default" : "outline"}
              size="sm"
              onClick={() => setSelectedRange(range)}
              className={cn(
                "min-w-[60px] font-medium transition-all duration-200",
                selectedRange.label === range.label 
                  ? "bg-primary/90 hover:bg-primary/80 shadow-sm" 
                  : "hover:bg-muted"
              )}
            >
              {range.label}
            </Button>
          ))}
        </div>
      </CardHeader>

      <CardContent>
        {/* Chart Container */}
        <div className="h-[400px] w-full mt-4">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={priceData}
              onMouseMove={(e) => {
                if (e.activePayload) {
                  setHoveredPrice(e.activePayload[0].payload);
                }
              }}
              onMouseLeave={() => setHoveredPrice(null)}
              margin={{ top: 20, right: 30, left: 10, bottom: 0 }}
            >
              <defs>
                <linearGradient id="goldGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="rgb(245, 158, 11)" stopOpacity={0.25} />
                  <stop offset="95%" stopColor="rgb(245, 158, 11)" stopOpacity={0.05} />
                </linearGradient>
                <linearGradient id="agcGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="rgb(59, 130, 246)" stopOpacity={0.25} />
                  <stop offset="95%" stopColor="rgb(59, 130, 246)" stopOpacity={0.05} />
                </linearGradient>
              </defs>
              <CartesianGrid 
                strokeDasharray="3 3" 
                className="stroke-muted/20" 
                horizontal={true}
                vertical={false}
              />
              <XAxis
                dataKey="timestamp"
                tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                className="text-xs text-muted-foreground/70"
                minTickGap={50}
                tickLine={false}
                axisLine={false}
                dy={10}
              />
              <YAxis
                yAxisId="left"
                className="text-xs text-muted-foreground/70"
                tickFormatter={(value) => `$${value.toLocaleString()}`}
                domain={calculateYAxisDomain(priceData, 'price')}
                tickLine={false}
                axisLine={false}
                dx={-10}
                allowDataOverflow={false}
                scale="linear"
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                className="text-xs text-muted-foreground/70"
                tickFormatter={(value) => `$${value.toLocaleString()}`}
                domain={calculateYAxisDomain(priceData, 'agcPrice')}
                tickLine={false}
                axisLine={false}
                dx={10}
                allowDataOverflow={false}
                scale="linear"
              />
              <Tooltip 
                content={<CustomTooltip />}
                cursor={{ 
                  stroke: 'rgb(59, 130, 246)', 
                  strokeWidth: 1, 
                  strokeDasharray: '4 4' 
                }}
              />
              <Legend 
                verticalAlign="top" 
                height={36}
                iconType="circle"
                iconSize={8}
                wrapperStyle={{
                  paddingBottom: '20px',
                }}
              />
              <Area
                yAxisId="left"
                type="monotone"
                dataKey="price"
                name="Gold Price (per oz)"
                stroke="rgb(245, 158, 11)"
                fill="url(#goldGradient)"
                strokeWidth={2}
                dot={false}
                activeDot={{ 
                  r: 6, 
                  strokeWidth: 2, 
                  stroke: '#fff',
                  className: "drop-shadow-md" 
                }}
              />
              <Area
                yAxisId="right"
                type="monotone"
                dataKey="agcPrice"
                name="AGC Price"
                stroke="rgb(59, 130, 246)"
                fill="url(#agcGradient)"
                strokeWidth={2}
                dot={false}
                activeDot={{ 
                  r: 6, 
                  strokeWidth: 2, 
                  stroke: '#fff',
                  className: "drop-shadow-md"
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default GoldPriceChart;
