import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from 'sonner';
import { Loader2 } from 'lucide-react';
import authService from '@/services/authService';

interface VerifyOTPModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  onVerificationSuccess: () => void;
}

const VerifyOTPModal = ({ isOpen, onClose, email, onVerificationSuccess }: VerifyOTPModalProps) => {
  const [otp, setOtp] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const handleVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!otp || otp.length !== 5) {
      toast.error('Please enter a valid 5-digit verification code');
      return;
    }
    
    setIsVerifying(true);
    
    try {
      await authService.verifyOTP({ login: email, otp: otp });
      toast.success('Email verified successfully!');
      onVerificationSuccess();
      onClose();
    } catch (error: any) {
      console.error('Verification error:', error);
      toast.error(error.response?.data?.message || 'Failed to verify email');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResendOTP = async () => {
    setIsResending(true);
    
    try {
      await authService.resendOTP({ login: email });
      toast.success('Verification code resent to your email');
    } catch (error: any) {
      console.error('Resend OTP error:', error);
      toast.error(error.response?.data?.message || 'Failed to resend verification code');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Verify Your Email</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleVerify} className="space-y-4">
          <div className="text-center">
            <p className="text-sm text-muted-foreground">
              We've sent a verification code to {email}. Please enter the 5-digit code to verify your account.
            </p>
          </div>
          
          <div className="space-y-2">
            <Input
              type="text"
              placeholder="Enter 5-digit code"
              value={otp}
              onChange={(e) => setOtp(e.target.value.replace(/\D/g, '').slice(0, 5))}
              maxLength={5}
              className="text-center text-xl tracking-widest"
            />
          </div>
          
          <div className="flex flex-col gap-3">
            <Button type="submit" disabled={isVerifying}>
              {isVerifying && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              {isVerifying ? 'Verifying...' : 'Verify Email'}
            </Button>
            
            <div className="text-center text-sm">
              <Button
                variant="link"
                type="button"
                className="p-0 h-auto"
                onClick={handleResendOTP}
                disabled={isResending}
              >
                {isResending ? (
                  <>
                    <Loader2 className="mr-1 h-3 w-3 animate-spin" />
                    Resending...
                  </>
                ) : (
                  'Resend code'
                )}
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default VerifyOTPModal; 