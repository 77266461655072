
import { GoogleGenerativeAI, GenerativeModel, ChatSession, GenerationConfig } from '@google/generative-ai';

// Configuration type for the service
interface GeminiConfig {
  apiKey: string;
  model: string;
  temperature?: number;
  maxOutputTokens?: number;
  systemPrompt?: string;
  tools?: AgentTool[];
  memorySize?: number;
  contextWindowSize?: number; // Added parameter for context window size
}

// Intent types for better type safety
type IntentType = 'NAVIGATION' | 'INFORMATION' | 'SUPPORT' | 'TRANSACTION' | 'ACTION' | 'ERROR' | 'OTHER';

// Enhanced response interface
export interface GeminiResponse {
  text?: string;
  suggestions?: string[];
  confidence?: number;
  relevantTopics?: string[];
  relevantPages?: string[];
  pageHints?: Record<string, string>;
  intent?: string;
  isQuestion?: boolean;
  actions?: any[];
}

// Memory importance enum for better prioritization
enum MemoryImportance {
  LOW = 0.25,
  MEDIUM = 0.5,
  HIGH = 0.75,
  CRITICAL = 1.0,
}

// Enhanced memory item for the agent's memory with importance
interface MemoryItem {
  id: string; // Unique identifier for the memory
  type: 'fact' | 'interaction' | 'preference' | 'task' | 'context';
  content: string;
  timestamp: number;
  source: 'user' | 'system' | 'inference' | 'tool';
  importance: MemoryImportance; // Priority factor for this memory
  relevanceScore?: number;
  expiresAt?: number;
  metadata?: Record<string, unknown>; // Additional data about this memory
  tags?: string[]; // For better categorization and retrieval
}

// Tool parameter definition for better validation
interface ToolParameter {
  name: string;
  type: 'string' | 'number' | 'boolean' | 'object' | 'array';
  description: string;
  required: boolean;
  defaultValue?: unknown;
  enumValues?: string[]; // For parameters with fixed set of values
  validation?: (value: unknown) => boolean; // Custom validation function
}

// Enhanced agent tool interface
interface AgentTool {
  name: string;
  description: string;
  execute: (params: Record<string, unknown>, context: ToolExecutionContext) => Promise<unknown>;
  parameters: Record<string, ToolParameter>;
  category?: string; // For grouping related tools
  requiresAuth?: boolean; // Does this tool require authentication?
  isAsynchronous?: boolean; // Does this tool run asynchronously?
  timeout?: number; // Custom timeout for this tool
  allowRetry?: boolean; // Can this tool be retried if it fails?
}

// Tool execution context for better tool integration
interface ToolExecutionContext {
  userProfile: UserProfile;
  conversationContext: Record<string, unknown>;
  memory: MemoryItem[];
  addMemory: (item: Omit<MemoryItem, 'id' | 'timestamp'>) => void;
  executeNestedTool: (toolName: string, params: Record<string, unknown>) => Promise<unknown>;
}

// Action status type with detailed states
type ActionStatus = 
  | 'pending'    // Initial state
  | 'running'    // Currently executing
  | 'retrying'   // Attempting retry after failure
  | 'success'    // Successfully completed
  | 'failed'     // Failed after all retries
  | 'cancelled'  // Manually cancelled
  | 'timeout';   // Exceeded time limit

// Action result with proper typing
interface ActionResult<T = unknown> {
  data: T;
  metadata: {
    executionTime: number;
    timestamp: number;
    retryCount: number;
  };
  summary?: string;
}

// Action error with detailed information
interface ActionError {
  code: string;
  message: string;
  details?: unknown;
  timestamp: number;
  retriable: boolean;
}

// Action metrics for monitoring
interface ActionMetrics {
  startTime: number;
  endTime?: number;
  duration?: number;
  retryCount: number;
  retryDelays: number[];
}

// Action configuration
interface ActionConfig {
  timeout?: number;
  maxRetries?: number;
  retryDelay?: number;
  priority?: 'low' | 'medium' | 'high';
  validateResult?: (result: unknown) => boolean;
}

// Enhanced agent action interface
interface AgentAction<T = unknown> {
  id: string;                    // Unique identifier for the action
  type: string;                  // Action type identifier
  payload: Record<string, unknown>; // Action parameters
  status: ActionStatus;          // Current execution status
  result?: ActionResult<T>;      // Typed action result
  error?: ActionError;           // Detailed error information
  metrics: ActionMetrics;        // Execution metrics
  config: ActionConfig;          // Action configuration
  
  // Metadata
  createdAt: number;            // Creation timestamp
  updatedAt: number;            // Last update timestamp
  createdBy?: string;           // User or system identifier
  source: 'user' | 'system' | 'automated'; // Action origin
  
  // Related data
  parentActionId?: string;      // For chained actions
  childActions?: string[];      // IDs of dependent actions
  context?: Record<string, unknown>; // Execution context
  
  // JSON formatting options
  formatOptions?: {
    indentation: number;
    maxDepth: number;
    includeMetadata: boolean;
    dateFormat: string;
    numberFormat: {
      minimumFractionDigits: number;
      maximumFractionDigits: number;
    };
  };
}

// User profile interface with enhanced capabilities
interface UserProfile {
  id?: string;
  name?: string;
  email?: string;
  preferences?: Record<string, unknown>;
  history?: {
    recentInteractions: string[];
    frequentQueries: Record<string, number>;
    lastActiveTimestamp?: number;
    sessionDuration?: number;
  };
  accountStatus?: 'guest' | 'registered' | 'premium';
  accessLevel?: number; // Numeric representation of user access level
  contextPreferences?: {
    // How much context to include by default
    memoryInclusionThreshold: number;
    preferredContextCategories: string[];
    excludedContextCategories: string[];
  };
  // User-specific context overrides
  contextOverrides?: Record<string, unknown>;
}

// Context window for managing relevant context
interface ContextWindow {
  maxSize: number;
  currentSize: number;
  prioritizedMemories: MemoryItem[];
  userContextPreference: number; // 0-1 scale for user preference on context detail
}

// Helper function to format action data as JSON
const formatActionJson = (action: AgentAction, options?: Partial<AgentAction['formatOptions']>): string => {
  const defaultOptions: Required<AgentAction['formatOptions']> = {
    indentation: 2,
    maxDepth: 10,
    includeMetadata: true,
    dateFormat: 'ISO',
    numberFormat: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 4
    }
  };

  const finalOptions = { ...defaultOptions, ...options };

  const formatValue = (value: unknown, depth: number): unknown => {
    if (depth > finalOptions.maxDepth) return '[Max Depth Exceeded]';
    
    if (value instanceof Date) {
      return finalOptions.dateFormat === 'ISO' 
        ? value.toISOString()
        : value.toLocaleString();
    }
    
    if (typeof value === 'number') {
      return value.toLocaleString('en-US', finalOptions.numberFormat);
    }
    
    if (Array.isArray(value)) {
      return value.map(v => formatValue(v, depth + 1));
    }
    
    if (value && typeof value === 'object') {
      return Object.fromEntries(
        Object.entries(value).map(([k, v]) => [k, formatValue(v, depth + 1)])
      );
    }
    
    return value;
  };

  const formattedAction = {
    ...action,
    metrics: finalOptions.includeMetadata ? action.metrics : undefined,
    formatOptions: undefined // Exclude format options from output
  };

  return JSON.stringify(
    formatValue(formattedAction, 0),
    null,
    finalOptions.indentation
  );
};

/**
 * AgenticGeminiService - An enhanced service for interacting with Google's Gemini AI model
 * with agentic capabilities including memory, tools, and proactive assistance
 */
class AgenticGeminiService {
  private model: GenerativeModel;
  private static instance: AgenticGeminiService;
  private activeChat?: ChatSession;
  private systemPrompt: string;
  private tools: Map<string, AgentTool>;
  private toolCategories: Map<string, Set<string>>; // Tools organized by category
  private memory: Map<string, MemoryItem>; // Changed to Map for O(1) lookups
  private memorySize: number;
  private userProfile: UserProfile;
  private conversationContext: Record<string, unknown>;
  private pendingActions: Map<string, AgentAction>; // Changed to Map for O(1) lookups
  private contextWindow: ContextWindow; // New context window for managing relevant context
  private lastContextRefresh: number; // Timestamp of last context refresh
  private contextRefreshInterval: number; // How often to refresh context relevance
  private embeddings: Map<string, number[]>; // Storage for memory embeddings (for semantic search)
  
  // Default system prompt with improved formatting and clarity
  private static readonly DEFAULT_SYSTEM_PROMPT = `You are EVA (EvoCash Virtual Assistant), an AI agent specializing in helping Evocash users, learning about the platform, our offerings such as agc tokens, and navigating the platform always navigate the user to the right page when answering questions.
Evocash is a platform looking to tokenize real-world assets on blockchain, making them accessible, tradeable, and secure.
Our first product is AGC a stable coin paged by physical gold,1 agc equals 10 mili-gram of gold. The stable coin is on BNB Smart Chain.
Customers can buy, sell, and exchange AGC tokens on the platform.
CORE IDENTITY & SPECIFICATIONS:
• Product: AGC (African Gold Coin) - Gold-backed stablecoin
• Backing: 1 AGC = 10 milligram of certified physical gold
• Platform: EvoCash - Decentralized Asset Tokenization
• Launch: Q2 2025
• Support: support@evocash.org (24/7)
. More info on Agc on https://africangoldcoin.org/ which is the dedicated website on Agc stable coin. 
• Compliance: Full regulatory compliance with SEC guidelines and our different partners.

NAVIGATION STRUCTURE:
PLATFORM NAVIGATION:
Main Pages:
• /dashboard     - User overview and main controls
• /markets      - Real-time AGC market data, waitlist join, whitepaper read
• /about        - Platform information
• /contact      - Support access
• /faq          - Help center
. /terms        - Legal terms and conditions
• /privacy      - Privacy policy
. /news         - Latest updates and announcements
. /login        - User authentication
. /signup       - New user registration 
. /help         - Help center
. https://africangoldcoin.org/ dedicated website on Agc stable coin

          
Authenticated Pages:
• /dashboard/trade         - AGC trading interface
• /dashboard/portfolio     - Asset holdings
• /dashboard/transactions  - Activity history
• /dashboard/settings      - Account management
. /dashboard/notifications   - Important updates and alerts
. /dashboard/wallet/withdraw/:assetId - Withdrawal interface
. /dashboard/wallet/deposit/:assetId - Deposit interface
. /dashboard/wallet          - Wallet


CORE CAPABILITIES:
1. Portfolio Management
   • Real-time balance tracking
   • Performance analytics
   • Investment recommendations
   • Risk assessment

2. Market Operations
   • Price monitoring & alerts
   • Trend analysis
   • Trading execution
   • Order management

3. Security & Compliance
   • KYC/AML verification
   • 2FA management
   • Transaction verification
   • Regulatory compliance

4. User Assistance
   • Guided onboarding
   • Technical support
   • Educational resources
   • Emergency assistance

RESPONSE FORMAT:
1. Intent Classification
   INTENT:[TYPE]
   Types:
   • TRADE       - Trading operations
   • PORTFOLIO   - Asset management
   • SECURITY    - Account security
   • SUPPORT     - User assistance
   • EDUCATION   - Learning resources
   • SYSTEM      - Platform operations

2. Action Specification
   ACTION:[CATEGORY]:[OPERATION]
   Parameters:
   KEY:VALUE pairs in JSON format

3. Navigation Directive
   NAVIGATE:[PATH]
   CONTEXT:[CURRENT_STATE]

4. Response Components
   • Primary response (clear, concise answer)
   • Suggested actions (max 3)
   • Related resources
   • Security notices (if applicable)

INTERACTION PRINCIPLES:
1. Security First
   • Always verify authentication for sensitive operations
   • Never expose private information
   • Flag suspicious activities

2. Accuracy & Clarity
   • Provide precise information
   • Use clear, professional language
   • Include relevant disclaimers

3. Proactive Support
   • Anticipate user needs
   • Suggest optimal actions
   • Prevent common mistakes

4. Escalation Protocol
   • Recognize critical issues
   • Direct to human support when needed
   • Maintain incident tracking

AGC USE CASES:
1. Payment & Transactions 
   • Cross-border transfers
   • Merchant payments
   • P2P transactions
   • International trade settlement

2. Store of Value
   • Physical gold-backed security
   • Inflation hedge
   • Digital asset preservation
   • Portfolio diversification

3. Trading & Investment
   • Instant gold exposure
   • Arbitrage opportunities
   • Pair trading with other cryptocurrencies
   • Fractional gold ownership

4. Payment & Transactions 
   • Cross-border transfers
   • Merchant payments
   • P2P transactions
   • International trade settlement

5. Enterprise Solutions
   • Treasury management
   • Trade finance
   • Supply chain tracking
   • Corporate reserves

Answer in the user language can be french , chinese , english , spanish , arabic , portuguese, korean. 
Example Response:
"Current AGC price: $0.97 (+2.3%). Based on your portfolio settings, this exceeds your alert threshold.
INTENT:PORTFOLIO
ACTION:ALERT:PRICE_THRESHOLD
{
  "asset": "AGC",
  "price": $0.97,
  "change": 2.3,
  "threshold": 0.92
}
NAVIGATE:/dashboard/trade
CONTEXT:{"alert_triggered": true, "portfolio_impact": "positive"}
SUGGESTIONS:Review portfolio,Set new alert,View market analysis"`; // Default system prompt with improved formatting and clarity

  /**
   * Private constructor to prevent direct instantiation
   */
  private constructor(config: GeminiConfig) {
    // Validate API key
    if (!config.apiKey) {
      throw new Error('API key is required for AgenticGeminiService');
    }

    const genAI = new GoogleGenerativeAI(config.apiKey);
    
    try {
      this.model = genAI.getGenerativeModel({
        model: 'gemini-2.0-flash', // Updated model name
        generationConfig: {
          temperature: config.temperature ?? 0.7,
          maxOutputTokens: config.maxOutputTokens ?? 1200,
          topK: 40,
          topP: 0.95,
        } as GenerationConfig,
      });
      
      console.log('Gemini model initialized successfully');
    } catch (error) {
      console.error('Error initializing Gemini model:', error);
      throw error;
    }

    this.systemPrompt = config.systemPrompt ?? AgenticGeminiService.DEFAULT_SYSTEM_PROMPT;
    this.tools = new Map();
    this.toolCategories = new Map();
    this.memory = new Map();
    this.memorySize = config.memorySize ?? 50;
    this.userProfile = { 
      accountStatus: 'guest',
      contextPreferences: {
        memoryInclusionThreshold: 0.2,
        preferredContextCategories: ['interaction', 'preference'],
        excludedContextCategories: []
      }
    };
    this.conversationContext = {};
    this.pendingActions = new Map();
    this.contextWindow = {
      maxSize: config.contextWindowSize ?? 20,
      currentSize: 0,
      prioritizedMemories: [],
      userContextPreference: 0.5 // Default to medium context detail
    };
    this.lastContextRefresh = Date.now();
    this.contextRefreshInterval = 60000; // Refresh context every minute by default
    this.embeddings = new Map();
    
    // Register default tools if provided
    if (config.tools) {
      config.tools.forEach(tool => this.registerTool(tool));
    }
    
    // Register default built-in tools
    this.registerBuiltinTools();
    
    // Register the navigation tool
    this.registerNavigationTool();
  }

  /**
   * Get a singleton instance of AgenticGeminiService
   */
  public static getInstance(config: GeminiConfig): AgenticGeminiService {
    if (!AgenticGeminiService.instance) {
      AgenticGeminiService.instance = new AgenticGeminiService(config);
    }
    return AgenticGeminiService.instance;
  }

  /**
   * Register built-in tools for the agent
   */
  private registerBuiltinTools(): void {
    // Market data tool with enhanced parameters
    this.registerTool({
      name: 'market_data',
      description: 'Get current market data for tokens',
      category: 'MARKET',
      requiresAuth: false,
      parameters: {
        token: {
          name: 'token',
          type: 'string',
          description: 'Token symbol (e.g., AGC, BTC, ETH)',
          required: true,
          enumValues: ['AGC', 'BTC', 'ETH', 'USDT', 'USDC']
        },
        timeframe: {
          name: 'timeframe',
          type: 'string',
          description: 'Time period for data (e.g., 1h, 24h, 7d, 30d)',
          required: false,
          defaultValue: '24h',
          enumValues: ['1h', '4h', '24h', '7d', '30d']
        }
      },
      execute: async (params, context) => {
        // Simulated market data - in production would connect to real API
        const data = {
          AGC: { price: 1852.43, change: 2.3, volume: 1243500 },
          BTC: { price: 73218.75, change: -1.2, volume: 28743000 },
          ETH: { price: 3943.21, change: 0.8, volume: 15432000 },
          USDT: { price: 1.00, change: 0.0, volume: 45678000 },
          USDC: { price: 1.00, change: 0.0, volume: 34567800 }
        };
        
        const token = params.token as string;
        const timeframe = params.timeframe as string;
        
        // Add to memory
        context.addMemory({
          type: 'fact',
          content: `Retrieved market data for ${token} (${timeframe}): ${JSON.stringify(data[token] || { error: 'Token not found' })}`,
          source: 'tool',
          importance: MemoryImportance.MEDIUM,
          tags: ['market', token, timeframe]
        });
        
        return data[token] || { error: 'Token not found' };
      }
    });
    
    // User portfolio tool with enhanced context
    this.registerTool({
      name: 'user_portfolio',
      description: 'Get user portfolio data',
      category: 'PORTFOLIO',
      requiresAuth: true,
      parameters: {
        includeHistory: {
          name: 'includeHistory',
          type: 'boolean',
          description: 'Include transaction history',
          required: false,
          defaultValue: false
        },
        timeframe: {
          name: 'timeframe',
          type: 'string',
          description: 'Time period for performance data',
          required: false,
          defaultValue: '30d',
          enumValues: ['24h', '7d', '30d', '90d', '1y']
        }
      },
      execute: async (params, context) => {
        // Check if user is authenticated
        if (context.userProfile.accountStatus === 'guest') {
          context.addMemory({
            type: 'interaction',
            content: 'User attempted to access portfolio data while not authenticated',
            source: 'tool',
            importance: MemoryImportance.HIGH,
            tags: ['auth', 'error', 'portfolio']
          });
          
          return { error: 'Authentication required', errorCode: 'AUTH_REQUIRED' };
        }
        
        const includeHistory = params.includeHistory as boolean;
        const timeframe = params.timeframe as string;
        
        // Simulated portfolio data
        const portfolioData = {
          assets: [
            { token: 'AGC', amount: 10.5, value: 19450.51 },
            { token: 'BTC', amount: 0.25, value: 18304.69 }
          ],
          totalValue: 37755.20,
          performance: { daily: 2.1, weekly: -0.8, monthly: 5.3 },
          history: includeHistory ? [
            { date: '2025-01-15', action: 'BUY', token: 'AGC', amount: 5.0, price: 1823.45 },
            { date: '2025-02-01', action: 'BUY', token: 'BTC', amount: 0.25, price: 71025.80 }
          ] : undefined
        };
        
        context.addMemory({
          type: 'fact',
          content: `Retrieved user portfolio data (${timeframe}): Total value $${portfolioData.totalValue}`,
          source: 'tool',
          importance: MemoryImportance.HIGH,
          tags: ['portfolio', 'assets', timeframe]
        });
        
        return portfolioData;
      }
    });
    
    // Enhanced reminder tool with better context integration
    this.registerTool({
      name: 'set_reminder',
      description: 'Set a reminder for the user',
      category: 'UTILITY',
      requiresAuth: true,
      parameters: {
        title: {
          name: 'title',
          type: 'string',
          description: 'Title of the reminder',
          required: true
        },
        description: {
          name: 'description',
          type: 'string',
          description: 'Detailed description of the reminder',
          required: true
        },
        datetime: {
          name: 'datetime',
          type: 'string',
          description: 'When to send the reminder (ISO date format)',
          required: true,
          validation: (value) => {
            try {
              return !isNaN(new Date(value as string).getTime());
            } catch {
              return false;
            }
          }
        },
        priority: {
          name: 'priority',
          type: 'string',
          description: 'Priority level of the reminder',
          required: false,
          defaultValue: 'medium',
          enumValues: ['low', 'medium', 'high']
        }
      },
      execute: async (params, context) => {
        // Check if user is authenticated
        if (context.userProfile.accountStatus === 'guest') {
          return { error: 'Authentication required', errorCode: 'AUTH_REQUIRED' };
        }
        
        const reminderDate = new Date(params.datetime as string);
        const now = new Date();
        
        // Validate reminder date
        if (reminderDate < now) {
          return { 
            error: 'Reminder date must be in the future',
            errorCode: 'INVALID_DATE'
          };
        }
        
        // In production, would connect to a real reminder system
        const reminder = {
          id: `reminder_${Date.now()}`,
          title: params.title,
          description: params.description,
          datetime: params.datetime,
          priority: params.priority || 'medium',
          userId: context.userProfile.id,
          createdAt: now.toISOString()
        };
        
        context.addMemory({
          type: 'task',
          content: `Reminder "${params.title}" set for ${params.datetime}`,
          source: 'tool',
          importance: MemoryImportance.HIGH,
          expiresAt: reminderDate.getTime(),
          metadata: { reminder },
          tags: ['reminder', params.priority as string]
        });
        
        return { success: true, reminder };
      }
    });
    
    // Context management tool for direct manipulation of context
    this.registerTool({
      name: 'manage_context',
      description: 'Manage conversation context',
      category: 'SYSTEM',
      parameters: {
        action: {
          name: 'action',
          type: 'string',
          description: 'Action to perform on context',
          required: true,
          enumValues: ['add', 'remove', 'clear', 'persist']
        },
        key: {
          name: 'key',
          type: 'string',
          description: 'Context key',
          required: false
        },
        value: {
          name: 'value',
          type: 'string',
          description: 'Context value',
          required: false
        }
      },
      execute: async (params, context) => {
        const action = params.action as string;
        const key = params.key as string;
        const value = params.value;
        
        switch (action) {
          case 'add':
            if (!key) return { error: 'Key is required for add action' };
            context.conversationContext[key] = value;
            return { success: true, action, key, value };
            
          case 'remove':
            if (!key) return { error: 'Key is required for remove action' };
            if (key in context.conversationContext) {
              delete context.conversationContext[key];
              return { success: true, action, key };
            }
            return { success: false, error: 'Key not found' };
            
          case 'clear':
            Object.keys(context.conversationContext).forEach(k => {
              delete context.conversationContext[k];
            });
            return { success: true, action: 'clear' };
            
          case 'persist':
            if (!key) return { error: 'Key is required for persist action' };
            if (!(key in context.conversationContext)) {
              return { success: false, error: 'Key not found' };
            }
            
            // Add to memory for long-term persistence
            context.addMemory({
              type: 'context',
              content: `Persisted context: ${key}=${JSON.stringify(context.conversationContext[key])}`,
              source: 'system',
              importance: MemoryImportance.HIGH,
              metadata: { 
                contextKey: key,
                contextValue: context.conversationContext[key]
              },
              tags: ['context', 'persistent']
            });
            
            return { success: true, action: 'persist', key };
            
          default:
            return { error: 'Invalid action' };
        }
      }
    });
  }

  /**
   * Generate a unique ID for various entities
   */
  private generateId(prefix: string = ''): string {
    return `${prefix}${prefix ? '_' : ''}${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  }

  /**
   * Register a new tool for the agent with enhanced validation
   */
  public registerTool(tool: AgentTool): void {
    // Validate tool before registration
    if (!tool.name || tool.name.trim() === '') {
      throw new Error('Tool name is required');
    }
    
    if (!tool.execute || typeof tool.execute !== 'function') {
      throw new Error(`Invalid execute function for tool "${tool.name}"`);
    }
    
    // Register the tool
    this.tools.set(tool.name, tool);
    
    // Add to category map for better organization
    const category = tool.category || 'DEFAULT';
    if (!this.toolCategories.has(category)) {
      this.toolCategories.set(category, new Set());
    }
    
    this.toolCategories.get(category)?.add(tool.name);
    
    // Add tool to system memory
    this.addToMemory({
      id: this.generateId('mem'),
      type: 'fact',
      content: `Tool registered: ${tool.name} - ${tool.description}`,
      timestamp: Date.now(),
      source: 'system',
      importance: MemoryImportance.LOW,
      tags: ['tool', 'system', tool.category || 'DEFAULT']
    });
  }

  /**
   * Validate parameters for a tool
   */
  private validateToolParams(tool: AgentTool, params: Record<string, unknown>): { 
    valid: boolean; 
    errors: string[]; 
    processedParams: Record<string, unknown>;
  } {
    const errors: string[] = [];
    const processedParams: Record<string, unknown> = {};
    
    // Check for required parameters and apply defaults
    Object.entries(tool.parameters).forEach(([paramName, paramDef]) => {
      const param = paramDef as ToolParameter;
      
      // Check if required parameter is missing
      if (param.required && (params[paramName] === undefined || params[paramName] === null)) {
        errors.push(`Required parameter "${paramName}" is missing`);
        return;
      }
      
      // If parameter is provided, validate it
      if (params[paramName] !== undefined) {
        const value = params[paramName];
        
        // Type validation
        if (param.type === 'string' && typeof value !== 'string') {
          errors.push(`Parameter "${paramName}" must be a string`);
        } else if (param.type === 'number' && typeof value !== 'number') {
          errors.push(`Parameter "${paramName}" must be a number`);
        } else if (param.type === 'boolean' && typeof value !== 'boolean') {
          errors.push(`Parameter "${paramName}" must be a boolean`);
        } else if (param.type === 'object' && (typeof value !== 'object' || value === null || Array.isArray(value))) {
          errors.push(`Parameter "${paramName}" must be an object`);
        } else if (param.type === 'array' && !Array.isArray(value)) {
          errors.push(`Parameter "${paramName}" must be an array`);
        }
        
        // Enum validation
        if (param.enumValues && !param.enumValues.includes(value as string)) {
          errors.push(`Parameter "${paramName}" must be one of: ${param.enumValues.join(', ')}`);
        }
        
        // Custom validation
        if (param.validation && !param.validation(value)) {
          errors.push(`Parameter "${paramName}" failed validation`);
        }
        
        // Add to processed parameters
        processedParams[paramName] = value;
      } else if (param.defaultValue !== undefined) {
        // Apply default value if parameter is not provided
        processedParams[paramName] = param.defaultValue;
      }
    });
    
    // Check for extraneous parameters
    Object.keys(params).forEach(paramName => {
      if (!(paramName in tool.parameters)) {
        errors.push(`Unknown parameter "${paramName}"`);
      }
    });
    
    return {
      valid: errors.length === 0,
      errors,
      processedParams
    };
  }

  /**
   * Add an item to the agent's memory with enhanced metadata
   */
  private addToMemory(item: MemoryItem): string {
    // Ensure the item has a unique ID
    if (!item.id) {
      item.id = this.generateId('mem');
    }
    
    // Add to memory map
    this.memory.set(item.id, item);
    
    // Calculate approximate token count for context window management
    // This is a simplistic approximation - in production would use a more accurate tokenizer
    const approximateTokens = Math.ceil(item.content.length / 4);
    
    // Update context window if this is high importance memory
    if (item.importance >= MemoryImportance.HIGH) {
      this.updateContextWindow([item]);
    }
    
    // Trim memory if it exceeds the configured size
    if (this.memory.size > this.memorySize) {
      this.trimMemory();
    }
    
    return item.id;
  }
  
  /**
   * Create a memory item and add it to the agent's memory
   */
  public createMemory(params: Omit<MemoryItem, 'id' | 'timestamp'>): string {
    const memoryItem: MemoryItem = {
      id: this.generateId('mem'),
      timestamp: Date.now(),
      ...params
    };
    
    return this.addToMemory(memoryItem);
  }

  /**
   * Trim memory to stay within configured size limits
   */
  private trimMemory(): void {
    // If memory is under the limit, do nothing
    if (this.memory.size <= this.memorySize) {
      return;
    }
    
    // Sort memories by importance and recency
    const sortedMemories = Array.from(this.memory.values())
      .sort((a, b) => {
        // First sort by importance (higher is better)
        const importanceDiff = b.importance - a.importance;
        if (importanceDiff !== 0) return importanceDiff;
        
        // Then by recency (newer is better)
        return b.timestamp - a.timestamp;
      });
    
    // Keep only the top N memories
    const memoriesToKeep = sortedMemories.slice(0, this.memorySize);
    const memoriesToRemove = sortedMemories.slice(this.memorySize);
    
    // Update memory map
    this.memory = new Map(
      memoriesToKeep.map(memory => [memory.id, memory])
    );
    
    // Log memory trimming
    console.log(`Trimmed ${memoriesToRemove.length} memories`);
  }

  /**
   * Update the context window with new relevant memories
   */
  private updateContextWindow(newMemories: MemoryItem[] = []): void {
    // Check if we need a full refresh
    const now = Date.now();
    const needsFullRefresh = (now - this.lastContextRefresh) > this.contextRefreshInterval;
    
    if (needsFullRefresh || newMemories.length > 0) {
      // If full refresh, recalculate everything
      if (needsFullRefresh) {
        const allMemories = Array.from(this.memory.values());
        
        // Score memories by relevance, importance, and recency
        const scoredMemories = allMemories.map(memory => {
          // Base score is the importance
          let score = memory.importance;
          
          // Boost score for recent memories (exponential decay)
          const ageInHours = (now - memory.timestamp) / (1000 * 60 * 60);
          const recencyBoost = Math.exp(-ageInHours / 24); // Decay over 24 hours
          
          // Boost score for memories with high relevance
          const relevanceBoost = memory.relevanceScore || 0;
          
          // Calculate final score
          score = score * 0.5 + recencyBoost * 0.3 + relevanceBoost * 0.2;
          
          return { memory, score };
        });
        
        // Sort by score (higher is better)
        scoredMemories.sort((a, b) => b.score - a.score);
        
        // Take top N memories based on context window size and user preference
        const effectiveWindowSize = Math.floor(this.contextWindow.maxSize * this.contextWindow.userContextPreference);
        this.contextWindow.prioritizedMemories = scoredMemories
          .slice(0, effectiveWindowSize)
          .map(item => item.memory);
        this.contextWindow.currentSize = this.contextWindow.prioritizedMemories.length;
        
        // Update refresh timestamp
        this.lastContextRefresh = now;
      } 
      // Otherwise just add new memories if they're important enough
      else {
        // Add new relevant memories to context window
        for (const memory of newMemories) {
          // Only add if important enough
          if (memory.importance >= MemoryImportance.MEDIUM) {
            // Check if we need to remove something to make room
            if (this.contextWindow.currentSize >= this.contextWindow.maxSize) {
              // Find least important memory in the window
              const leastImportantIndex = this.contextWindow.prioritizedMemories
                .findIndex(m => m.importance === Math.min(
                  ...this.contextWindow.prioritizedMemories.map(m => m.importance)
                ));
              
              // If new memory is more important, replace the least important one
              if (leastImportantIndex >= 0 && 
                  this.contextWindow.prioritizedMemories[leastImportantIndex].importance < memory.importance) {
                this.contextWindow.prioritizedMemories[leastImportantIndex] = memory;
              }
            } 
            // If there's room, just add it
            else {
              this.contextWindow.prioritizedMemories.push(memory);
              this.contextWindow.currentSize++;
            }
          }
        }
      }
    }
  }

  /**
   * Get memories by tag
   */
  public getMemoriesByTag(tag: string): MemoryItem[] {
    return Array.from(this.memory.values())
      .filter(memory => memory.tags?.includes(tag))
      .sort((a, b) => b.importance - a.importance || b.timestamp - a.timestamp);
  }

  /**
   * Get memories by type
   */
  public getMemoriesByType(type: MemoryItem['type']): MemoryItem[] {
    return Array.from(this.memory.values())
      .filter(memory => memory.type === type)
      .sort((a, b) => b.importance - a.importance || b.timestamp - a.timestamp);
  }

  /**
   * Get relevant memories for the current conversation
   */
  private getRelevantMemories(query: string): MemoryItem[] {
    // First, check if we have any memories in the context window
    if (this.contextWindow.prioritizedMemories.length > 0) {
      return this.contextWindow.prioritizedMemories;
    }
    
    // If not, calculate relevance on-the-fly (fallback approach)
    const queryTerms = query.toLowerCase().split(/\s+/);
    
    const relevantMemories = Array.from(this.memory.values())
      .map(item => {
        const content = item.content.toLowerCase();
        
        // Calculate relevance score using term frequency
        const matchCount = queryTerms.filter(term => content.includes(term)).length;
        const relevanceScore = queryTerms.length > 0 ? matchCount / queryTerms.length : 0;
        
        // Calculate recency boost (newer memories get higher scores)
        const ageInHours = (Date.now() - item.timestamp) / (1000 * 60 * 60);
        const recencyBoost = Math.exp(-ageInHours / 48); // 48-hour half-life
        
        // Calculate final relevance score with importance and recency factors
        const finalRelevance = (
          relevanceScore * 0.5 + 
          item.importance * 0.3 + 
          recencyBoost * 0.2
        );
        
        return {
          ...item,
          relevanceScore: finalRelevance
        };
      })
      .filter(item => {
        // Filter by minimum relevance threshold from user preferences
        const threshold = this.userProfile.contextPreferences?.memoryInclusionThreshold || 0.2;
        return item.relevanceScore >= threshold;
      })
      .sort((a, b) => (b.relevanceScore ?? 0) - (a.relevanceScore ?? 0));
    
    // Apply user preferences for memory types to include/exclude
    const preferredCategories = this.userProfile.contextPreferences?.preferredContextCategories || [];
    const excludedCategories = this.userProfile.contextPreferences?.excludedContextCategories || [];
    
    const filteredMemories = relevantMemories.filter(memory => {
      // Always include critical memories
      if (memory.importance === MemoryImportance.CRITICAL) return true;
      
      // Exclude if in excluded categories
      if (excludedCategories.includes(memory.type)) return false;
      
      // Prioritize preferred categories
      if (preferredCategories.includes(memory.type)) return true;
      
      // Include other relevant memories
      return true;
    });
    
    // Return top N most relevant memories, where N is determined by context window size
    return filteredMemories.slice(0, this.contextWindow.maxSize);
  }

  /**
   * Update user profile with new information
   */
  public updateUserProfile(profileUpdate: Partial<UserProfile>): void {
    this.userProfile = {
      ...this.userProfile,
      ...profileUpdate
    };
    
    // Update user context preference if provided
    if (profileUpdate.contextPreferences?.memoryInclusionThreshold !== undefined) {
      this.contextWindow.userContextPreference = 
        profileUpdate.contextPreferences.memoryInclusionThreshold;
      
      // Refresh context window when preferences change
      this.updateContextWindow();
    }
    
    // Add to memory if meaningful info was added
    if (profileUpdate.preferences || profileUpdate.id || profileUpdate.name) {
      this.addToMemory({
        id: this.generateId('mem'),
        type: 'preference',
        content: `User profile updated: ${Object.keys(profileUpdate).join(', ')}`,
        timestamp: Date.now(),
        source: 'system',
        importance: MemoryImportance.HIGH,
        tags: ['profile', 'update']
      });
    }
  }

  /**
   * Parse structured response from the AI with enhanced extraction
   */
  private parseResponse(text: string): GeminiResponse {
    // Initialize with default values
    const response: GeminiResponse = { 
      text: '',
      confidence: 0.5,
      context: {}
    };

    try {
      let processedText = text;

      // Extract intent with improved regex
      const intentMatch = processedText.match(/INTENT:(\w+)/i);
      if (intentMatch) {
        response.intent = intentMatch[1].toUpperCase() as IntentType;
        processedText = processedText.replace(/INTENT:\w+/i, '');
      }

      // Extract navigation with improved regex
      const navigationMatch = processedText.match(/NAVIGATE:(\/\S+)/i);
      if (navigationMatch) {
        response.navigation = navigationMatch[1];
        processedText = processedText.replace(/NAVIGATE:\/\S+/i, '');
      }

      // Extract suggestions with improved regex
      const suggestionsMatch = processedText.match(/SUGGESTIONS:([^\n]+)/i);
      if (suggestionsMatch) {
        response.suggestions = suggestionsMatch[1]
          .split(',')
          .map(s => s.trim())
          .filter(s => s.length > 0); // Filter out empty suggestions
        processedText = processedText.replace(/SUGGESTIONS:[^\n]+/i, '');
      }
      
      // Extract actions with more robust regex
      const actions: AgentAction[] = [];
      // This improved regex can handle multi-line action blocks with nested JSON
      const actionRegex = /ACTION:(\w+)(?::(\w+))?(?:\s*\{([^{}]*(?:\{[^{}]*\}[^{}]*)*)\})?/gi;
      let actionMatch;
      
      while ((actionMatch = actionRegex.exec(processedText)) !== null) {
        const actionType = actionMatch[1];
        const actionSubtype = actionMatch[2] || '';
        let actionParams: Record<string, unknown> = {};
        
        // Try to parse parameters as JSON if available
        if (actionMatch[3]) {
          try {
            // Wrap in brackets to make valid JSON
            const jsonParams = `{${actionMatch[3]}}`;
            actionParams = JSON.parse(jsonParams);
          } catch (e) {
            // Fall back to parameter parsing if JSON parsing fails
            const paramLines = actionMatch[3].split('\n').filter(line => line.trim().length > 0);
            
            paramLines.forEach(line => {
              const [key, value] = line.split(':').map(part => part.trim());
              if (key && value) {
                // Try to parse value as JSON, fall back to string
                try {
                  actionParams[key] = JSON.parse(value);
                } catch {
                  actionParams[key] = value;
                }
              }
            });
          }
        }
        
        // Generate unique action ID
        const actionId = this.generateId('action');
        
        // Create action object with all required fields
        actions.push({
          id: actionId,
          type: actionType + (actionSubtype ? `:${actionSubtype}` : ''),
          payload: actionParams,
          status: 'pending',
          metrics: {
            startTime: Date.now(),
            retryCount: 0,
            retryDelays: []
          },
          config: {
            timeout: 10000,
            maxRetries: 3,
            retryDelay: 1000
          },
          createdAt: Date.now(),
          updatedAt: Date.now(),
          source: 'system'
        });
        
        // Add the action to pending actions
        this.pendingActions.set(actionId, actions[actions.length - 1]);
        
        // Remove the action from the text
        processedText = processedText.replace(actionMatch[0], '');
      }
      
      if (actions.length > 0) {
        response.actions = actions;
      }
      
      // Extract context variables with improved JSON handling
      const contextRegex = /CONTEXT:(?:\s*\{([^{}]*(?:\{[^{}]*\}[^{}]*)*)\})?/gi;
      let contextMatch;
      
      while ((contextMatch = contextRegex.exec(processedText)) !== null) {
        // Try to parse as JSON
        if (contextMatch[1]) {
          try {
            // Wrap in brackets to make valid JSON
            const jsonContext = `{${contextMatch[1]}}`;
            const parsedContext = JSON.parse(jsonContext);
            
            response.context = {
              ...response.context,
              ...parsedContext
            };
          } catch (e) {
            // Fall back to line-by-line parsing
            const contextLines = contextMatch[1].split('\n').filter(line => line.trim().length > 0);
            
            contextLines.forEach(line => {
              const [key, value] = line.split(':').map(part => part.trim());
              if (key && value) {
                // Try to parse value as JSON, fall back to string
                try {
                  response.context[key] = JSON.parse(value);
                } catch {
                  response.context[key] = value;
                }
              }
            });
          }
        }
        
        // Remove the context from the text
        processedText = processedText.replace(contextMatch[0], '');
      }

      // Clean and set the final text
      response.text = processedText.trim();
      
      // Calculate confidence based on presence of key elements
      response.confidence = this.calculateConfidence(response);
      
      // Store context for future use
      if (response.context) {
        this.conversationContext = {
          ...this.conversationContext,
          ...response.context
        };
      }
    } 
    catch (error) {
      console.error('Error parsing response:', error);
      response.error = error instanceof Error ? error : new Error(String(error));
      response.confidence = 0;
      response.intent = 'ERROR';
    }

    return response;
  }

  /**
   * Calculate confidence score based on response completeness
   */
  private calculateConfidence(response: GeminiResponse): number {
    let confidence = 0.5; // Base confidence
    
    // Adjust confidence based on response quality
    if (response.intent) confidence += 0.15;
    if (response.navigation) confidence += 0.10;
    if (response.suggestions?.length) confidence += 0.10;
    if (response.actions?.length) confidence += 0.15;
    if (response.context && Object.keys(response.context).length > 0) confidence += 0.10;
    
    // Check for high-quality response
    if (response.text.length > 50) {
      confidence += 0.05;
    }
    
    // Check for key context elements specific to our domain
    const text = response.text.toLowerCase();
    if (text.includes('agc') || text.includes('african gold coin')) confidence += 0.05;
    if (text.includes('evocash')) confidence += 0.05;
    
    return Math.min(confidence, 1); // Cap at 1.0
  }

  /**
   * Build the context message for the AI with improved organization
   */
  private buildContextMessage(query: string): string {
    // Get relevant memories based on the query
    const relevantMemories = this.getRelevantMemories(query);
    
    // Organize context into sections
    const sections: Record<string, string[]> = {
      user: [],
      system: [],
      memories: [],
      context: []
    };
    
    // User profile information
    sections.user.push(
      this.userProfile.accountStatus !== 'guest' ? 
        `User: ${this.userProfile.name || 'User'} (${this.userProfile.accountStatus})` : 
        'User: Guest (not logged in)'
    );
    
    if (this.userProfile.preferences) {
      sections.user.push(`User Preferences: ${JSON.stringify(this.userProfile.preferences)}`);
    }
    
    // System state
    sections.system.push(`System: ${this.pendingActions.size} pending actions`);
    sections.system.push(`System: Time is ${new Date().toISOString()}`);
    
    // Include relevant memories organized by type
    const memoryGroups: Record<string, MemoryItem[]> = {};
    
    relevantMemories.forEach(memory => {
      if (!memoryGroups[memory.type]) {
        memoryGroups[memory.type] = [];
      }
      memoryGroups[memory.type].push(memory);
    });
    
    // Add memories in order of importance
    const memoryTypeOrder = ['fact', 'interaction', 'preference', 'task', 'context'];
    
    memoryTypeOrder.forEach(type => {
      if (memoryGroups[type]) {
        const sortedMemories = memoryGroups[type].sort((a, b) => b.timestamp - a.timestamp);
        
        sortedMemories.forEach(memory => {
          sections.memories.push(`${type.charAt(0).toUpperCase() + type.slice(1)}: ${memory.content}`);
        });
      }
    });
    
    // Include active context variables
    Object.entries(this.conversationContext)
      .forEach(([key, value]) => {
        sections.context.push(`Context: ${key}=${JSON.stringify(value)}`);
      });
    
    // Combine all sections with headers
    const parts: string[] = [];
    
    if (sections.user.length > 0) {
      parts.push('--- USER PROFILE ---');
      parts.push(...sections.user);
    }
    
    if (sections.context.length > 0) {
      parts.push('--- ACTIVE CONTEXT ---');
      parts.push(...sections.context);
    }
    
    if (sections.memories.length > 0) {
      parts.push('--- RELEVANT MEMORIES ---');
      parts.push(...sections.memories);
    }
    
    if (sections.system.length > 0) {
      parts.push('--- SYSTEM STATE ---');
      parts.push(...sections.system);
    }
    
    return parts.join('\n');
  }

  /**
   * Execute actions specified in the response with enhanced error handling and retries
   */
  private async executeActions(actions: AgentAction[]): Promise<AgentAction[]> {
    // Create tool execution context
    const toolContext: ToolExecutionContext = {
      userProfile: this.userProfile,
      conversationContext: this.conversationContext,
      memory: Array.from(this.memory.values()),
      addMemory: (item) => {
        const memoryItem: MemoryItem = {
          id: this.generateId('mem'),
          timestamp: Date.now(),
          ...item
        };
        this.addToMemory(memoryItem);
      },
      executeNestedTool: async (toolName, params) => {
        const tool = this.tools.get(toolName);
        if (!tool) {
          throw new Error(`Tool "${toolName}" not found`);
        }
        
        return await tool.execute(params, toolContext);
      }
    };
    
    const executedActions = await Promise.all(
      actions.map(async (action) => {
        const updatedAction = { ...action };
        updatedAction.status = 'running';
        updatedAction.updatedAt = Date.now();
        this.pendingActions.set(action.id, updatedAction);
        
        try {
          // Find the appropriate tool
          const toolName = action.type.split(':')[0];
          const tool = this.tools.get(toolName);
          
          if (!tool) {
            // Handle unknown tool
            updatedAction.status = 'failed';
            updatedAction.error = {
              code: 'TOOL_NOT_FOUND',
              message: `Tool "${toolName}" not found`,
              timestamp: Date.now(),
              retriable: false
            };
            updatedAction.updatedAt = Date.now();
            
            // Log tool error to memory
            this.addToMemory({
              id: this.generateId('mem'),
              type: 'interaction',
              content: `Error: Tool "${toolName}" not found when executing action ${action.id}`,
              timestamp: Date.now(),
              source: 'system',
              importance: MemoryImportance.MEDIUM,
              tags: ['error', 'tool', toolName]
            });
            
            return updatedAction;
          }
          
          // Check authentication if required
          if (tool.requiresAuth && this.userProfile.accountStatus === 'guest') {
            updatedAction.status = 'failed';
            updatedAction.error = {
              code: 'AUTH_REQUIRED',
              message: `Tool "${toolName}" requires authentication`,
              timestamp: Date.now(),
              retriable: false
            };
            updatedAction.updatedAt = Date.now();
            
            return updatedAction;
          }
          
          // Validate parameters
          const validation = this.validateToolParams(tool, action.payload);
          
          if (!validation.valid) {
            updatedAction.status = 'failed';
            updatedAction.error = {
              code: 'INVALID_PARAMETERS',
              message: validation.errors.join(', '),
              timestamp: Date.now(),
              retriable: false
            };
            updatedAction.updatedAt = Date.now();
            
            return updatedAction;
          }
          
          // Execute the tool with retry logic
          let result: unknown;
          let retryCount = 0;
          const maxRetries = action.config.maxRetries || 3;
          const retryDelay = action.config.retryDelay || 1000;
          
          while (retryCount <= maxRetries) {
            try {
              // Execute with timeout if specified
              if (action.config.timeout) {
                const timeoutPromise = new Promise((_, reject) => {
                  setTimeout(() => reject(new Error('Execution timed out')), action.config.timeout);
                });
                
                result = await Promise.race([
                  tool.execute(validation.processedParams, toolContext),
                  timeoutPromise
                ]);
              } else {
                result = await tool.execute(validation.processedParams, toolContext);
              }
              
              // Validate result if validator provided
              if (action.config.validateResult && !action.config.validateResult(result)) {
                throw new Error('Result validation failed');
              }
              
              // Success - break retry loop
              break;
            } catch (error) {
              retryCount++;
              updatedAction.metrics.retryCount = retryCount;
              
              // Only retry if not at max retries
              if (retryCount <= maxRetries) {
                // Add retry delay
                const currentDelay = retryDelay * Math.pow(2, retryCount - 1); // Exponential backoff
                updatedAction.metrics.retryDelays.push(currentDelay);
                
                // Wait before retrying
                await new Promise(resolve => setTimeout(resolve, currentDelay));
                
                // Update status to retrying
                updatedAction.status = 'retrying';
                
                // Log retry to memory
                this.addToMemory({
                  id: this.generateId('mem'),
                  type: 'interaction',
                  content: `Retrying action ${action.id} (${retryCount}/${maxRetries}): ${error.message}`,
                  timestamp: Date.now(),
                  source: 'system',
                  importance: MemoryImportance.LOW,
                  tags: ['retry', 'action', toolName]
                });
              } else {
                // Max retries reached - fail
                updatedAction.status = 'failed';
                updatedAction.error = {
                  code: 'MAX_RETRIES_EXCEEDED',
                  message: error instanceof Error ? error.message : String(error),
                  timestamp: Date.now(),
                  retriable: false
                };
                
                // Log failure to memory
                this.addToMemory({
                  id: this.generateId('mem'),
                  type: 'interaction',
                  content: `Action ${action.id} failed after ${maxRetries} retries: ${error.message}`,
                  timestamp: Date.now(),
                  source: 'system',
                  importance: MemoryImportance.MEDIUM,
                  tags: ['error', 'action', toolName]
                });
                
                return updatedAction;
              }
            }
          }
          
          // Success
          updatedAction.status = 'success';
          updatedAction.result = {
            data: result,
            metadata: {
              executionTime: Date.now() - updatedAction.metrics.startTime,
              timestamp: Date.now(),
              retryCount: updatedAction.metrics.retryCount
            }
          };
          
          // Add execution result to memory
          this.addToMemory({
            id: this.generateId('mem'),
            type: 'interaction',
            content: `Action ${action.id} completed successfully: ${JSON.stringify(result).substring(0, 100)}${JSON.stringify(result).length > 100 ? '...' : ''}`,
            timestamp: Date.now(),
            source: 'system',
            importance: MemoryImportance.MEDIUM,
            tags: ['action', 'success', toolName]
          });
          
          return updatedAction;
        } catch (error) {
          console.error(`Unexpected error executing action ${action.id}:`, error);
          
          updatedAction.status = 'failed';
          updatedAction.error = {
            code: 'EXECUTION_ERROR',
            message: error instanceof Error ? error.message : String(error),
            timestamp: Date.now(),
            retriable: false
          };
          
          // Log error to memory
          this.addToMemory({
            id: this.generateId('mem'),
            type: 'interaction',
            content: `Unexpected error executing action ${action.id}: ${error instanceof Error ? error.message : String(error)}`,
            timestamp: Date.now(),
            source: 'system',
            importance: MemoryImportance.HIGH,
            tags: ['error', 'critical']
          });
          
          return updatedAction;
        } finally {
          // Update timestamps and metrics
          updatedAction.updatedAt = Date.now();
          
          if (updatedAction.status !== 'running' && updatedAction.status !== 'retrying') {
            updatedAction.metrics.endTime = Date.now();
            updatedAction.metrics.duration = updatedAction.metrics.endTime - updatedAction.metrics.startTime;
          }
          
          // Update in pending actions map
          this.pendingActions.set(action.id, updatedAction);
        }
      })
    );
    
    // Remove successful actions from pending
    executedActions.forEach(action => {
      if (action.status === 'success' || action.status === 'failed') {
        this.pendingActions.delete(action.id);
      }
    });
    
    return executedActions;
  }

  /**
   * Send a message to the AI and get a structured response with enhanced context handling
   */
  public async chat(message: string, options: {
    executeActions?: boolean;
    refreshContext?: boolean;
    priority?: 'low' | 'medium' | 'high';
  } = {}): Promise<GeminiResponse> {
    const {
      executeActions = true,
      refreshContext = false,
      priority = 'medium'
    } = options;
    
    try {
      // Add user message to memory with appropriate importance
      const importance = priority === 'high' ? 
        MemoryImportance.HIGH : 
        priority === 'low' ? 
          MemoryImportance.LOW : 
          MemoryImportance.MEDIUM;
      
      this.addToMemory({
        id: this.generateId('mem'),
        type: 'interaction',
        content: `User: ${message}`,
        timestamp: Date.now(),
        source: 'user',
        importance,
        tags: ['user', 'message']
      });
      
      // Force context refresh if requested
      if (refreshContext) {
        this.lastContextRefresh = 0; // This will trigger a full refresh
        this.updateContextWindow();
      }
      
      // Build context message with user profile, memories, and conversation context
      const contextMessage = this.buildContextMessage(message);
      
      // Initialize chat session if not already active
      if (!this.activeChat) {
        this.activeChat = this.model.startChat({
          history: [
            { 
              role: 'user', 
              parts: [
                { text: this.systemPrompt },
                { text: contextMessage }
              ] 
            },
            { 
              role: 'model', 
              parts: [{ text: 'Understood. I will act as EvoCash\'s advanced AI agent with agentic capabilities and provide structured responses.' }]
            },
          ],
          generationConfig: {
            topK: 40,
            topP: 0.95,
          }
        });
      }

      // Send message with context
      const result = await this.activeChat.sendMessage([
        { text: message },
        { text: `\n\nContext:\n${contextMessage}` }
      ]);
      
      const response = await result.response;
      const parsedResponse = this.parseResponse(response.text());
      
      // Execute actions if requested
      if (executeActions && parsedResponse.actions && parsedResponse.actions.length > 0) {
        const executedActions = await this.executeActions(parsedResponse.actions);
        parsedResponse.actions = executedActions;
      }
      
      // Add assistant response to memory
      this.addToMemory({
        id: this.generateId('mem'),
        type: 'interaction',
        content: `Assistant: ${parsedResponse.text}`,
        timestamp: Date.now(),
        source: 'system',
        importance: MemoryImportance.MEDIUM,
        tags: ['assistant', 'response']
      });
      
      return parsedResponse;
    } 
    catch (error) {
      console.error('Gemini API Error Details:', {
        error: error instanceof Error ? error.message : String(error),
        stack: error instanceof Error ? error.stack : undefined,
        apiKey: !!getApiKey() // Log whether key exists, not the actual key
      });
      
      // Add error to memory
      this.addToMemory({
        id: this.generateId('mem'),
        type: 'interaction',
        content: `Error: ${error instanceof Error ? error.message : String(error)}`,
        timestamp: Date.now(),
        source: 'system',
        importance: MemoryImportance.HIGH,
        tags: ['error', 'api']
      });
      
      // Return a detailed error response
      return {
        text: "I apologize, but I'm having trouble processing your request at the moment. Please try again shortly.",
        confidence: 0,
        intent: 'ERROR',
        error: error instanceof Error ? error : new Error(String(error))
      };
    }
  }

  /**
   * Send a message with timeout protection and enhanced error handling
   */
  public async chatWithTimeout(message: string, timeoutMs: number = 10000): Promise<GeminiResponse> {
    try {
      // Create a promise that rejects after the timeout
      const timeoutPromise = new Promise<GeminiResponse>((_, reject) => {
        setTimeout(() => {
          reject(new Error(`Request timed out after ${timeoutMs}ms`));
        }, timeoutMs);
      });

      // Race the chat request against the timeout
      return await Promise.race([
        this.chat(message),
        timeoutPromise
      ]);
    } 
    catch (error) {
      console.error('Chat timeout error:', error);
      
      // Add timeout error to memory
      this.addToMemory({
        id: this.generateId('mem'),
        type: 'interaction',
        content: `Error: Request timed out after ${timeoutMs}ms`,
        timestamp: Date.now(),
        source: 'system',
        importance: MemoryImportance.HIGH,
        tags: ['error', 'timeout']
      });
      
      return {
        text: "I'm sorry, but your request timed out. Please try again with a simpler query.",
        confidence: 0,
        intent: 'ERROR',
        error: error instanceof Error ? error : new Error(String(error))
      };
    }
  }

  /**
   * Reset the chat session but keep memory and user profile
   */
  public resetChat(): void {
    this.activeChat = undefined;
    // Keep memory and user profile intact
  }

  /**
   * Complete reset of the agent's state
   */
  public resetAgent(): void {
    this.activeChat = undefined;
    this.memory = new Map();
    this.conversationContext = {};
    this.pendingActions = new Map();
    this.contextWindow = {
      ...this.contextWindow,
      currentSize: 0,
      prioritizedMemories: []
    };
    this.userProfile = { 
      accountStatus: 'guest',
      contextPreferences: {
        memoryInclusionThreshold: 0.2,
        preferredContextCategories: ['interaction', 'preference'],
        excludedContextCategories: []
      }
    };
  }

  /**
   * Update system prompt for the service
   */
  public updateSystemPrompt(newPrompt: string): void {
    // Only reset if the prompt actually changed
    if (this.systemPrompt !== newPrompt) {
      this.systemPrompt = newPrompt;
      this.resetChat(); // Reset chat to apply new system prompt
    }
  }
  
  /**
   * Get pending actions for monitoring purposes
   */
  public getPendingActions(): AgentAction[] {
    return Array.from(this.pendingActions.values());
  }
  
  /**
   * Get all registered tools
   */
  public getRegisteredTools(): AgentTool[] {
    return Array.from(this.tools.values());
  }
  
  /**
   * Get agent memory for debugging or persistence
   */
  public getMemory(): MemoryItem[] {
    return Array.from(this.memory.values());
  }
  
  /**
   * Export memory for persistence
   */
  public exportMemory(): string {
    return JSON.stringify(Array.from(this.memory.values()));
  }
  
  /**
   * Import memory from a previous session
   */
  public importMemory(serializedMemory: string): void {
    try {
      const importedMemories = JSON.parse(serializedMemory) as MemoryItem[];
      
      if (!Array.isArray(importedMemories)) {
        throw new Error('Invalid memory format');
      }
      
      // Clear existing memory
      this.memory = new Map();
      
      // Import memories
      importedMemories.forEach(memory => {
        this.memory.set(memory.id, memory);
      });
      
      // Refresh context window
      this.updateContextWindow();
      
      console.log(`Imported ${importedMemories.length} memories`);
    } catch (error) {
      console.error('Error importing memory:', error);
      throw error;
    }
  }
  
  /**
   * Proactively generate a suggestion or notification
   * based on user profile and context
   */
  public async generateProactiveSuggestion(): Promise<GeminiResponse | null> {
    // Skip for guest users or if no significant memory
    if (this.userProfile.accountStatus === 'guest' || this.memory.size < 5) {
      return null;
    }
    
    try {
      // Build a specialized context for proactive suggestions
      const recentMemories = Array.from(this.memory.values())
        .filter(memory => memory.type === 'interaction' || memory.type === 'fact')
        .sort((a, b) => b.timestamp - a.timestamp)
        .slice(0, 10);
      
      const contextMessage = [
        '--- USER PROFILE ---',
        `User: ${this.userProfile.name || 'User'} (${this.userProfile.accountStatus})`,
        
        '--- RECENT ACTIVITY ---',
        ...recentMemories.map(memory => `${memory.type}: ${memory.content}`),
        
        '--- ACTIVE CONTEXT ---',
        ...Object.entries(this.conversationContext)
          .map(([key, value]) => `Context: ${key}=${JSON.stringify(value)}`)
      ].join('\n');
      
      const proactivePrompt = `
Based on the user's profile and recent interactions, generate a proactive suggestion
or notification that would be helpful to them right now. This could be a market update,
a reminder about an upcoming feature, or a personalized recommendation.

User context:
${contextMessage}

Generate a concise, helpful proactive message in the standard response format.
      `;
      
      // Use a separate chat session for proactive messages
      const proactiveModel = this.model.startChat({
        generationConfig: {
          temperature: 0.7,
          topK: 40,
          topP: 0.95,
          maxOutputTokens: 500,
        }
      });
      
      const result = await proactiveModel.sendMessage(proactivePrompt);
      const response = await result.response;
      const parsedResponse = this.parseResponse(response.text());
      
      // Add to memory if successful
      if (parsedResponse.text) {
        this.addToMemory({
          id: this.generateId('mem'),
          type: 'interaction',
          content: `Proactive suggestion: ${parsedResponse.text}`,
          timestamp: Date.now(),
          source: 'system',
          importance: MemoryImportance.MEDIUM,
          tags: ['proactive', 'suggestion']
        });
      }
      
      return parsedResponse;
    } catch (error) {
      console.error('Error generating proactive suggestion:', error);
      return null;
    }
  }
  
  /**
   * Execute an action directly by name with parameters
   */
  public async executeToolByName(toolName: string, params: Record<string, unknown>): Promise<unknown> {
    const tool = this.tools.get(toolName);
    
    if (!tool) {
      throw new Error(`Tool "${toolName}" not found`);
    }
    
    // Create tool execution context
    const toolContext: ToolExecutionContext = {
      userProfile: this.userProfile,
      conversationContext: this.conversationContext,
      memory: Array.from(this.memory.values()),
      addMemory: (item) => {
        const memoryItem: MemoryItem = {
          id: this.generateId('mem'),
          timestamp: Date.now(),
          ...item
        };
        this.addToMemory(memoryItem);
      },
      executeNestedTool: async (nestedToolName, nestedParams) => {
        return await this.executeToolByName(nestedToolName, nestedParams);
      }
    };
    
    // Validate parameters
    const validation = this.validateToolParams(tool, params);
    
    if (!validation.valid) {
      throw new Error(`Invalid parameters: ${validation.errors.join(', ')}`);
    }
    
    // Execute the tool
    const result = await tool.execute(validation.processedParams, toolContext);
    
    // Add execution to memory
    this.addToMemory({
      id: this.generateId('mem'),
      type: 'interaction',
      content: `Tool "${toolName}" executed directly with result: ${JSON.stringify(result).substring(0, 100)}${JSON.stringify(result).length > 100 ? '...' : ''}`,
      timestamp: Date.now(),
      source: 'system',
      importance: MemoryImportance.MEDIUM,
      tags: ['tool', 'direct', toolName]
    });
    
    return result;
  }

  /**
   * Register the navigation tool
   */
  private registerNavigationTool(): void {
    const navigationTool: NavigationTool = {
      name: 'navigation',
      description: 'Navigate to internal routes or external websites',
      category: 'NAVIGATION',
      parameters: {
        destination: {
          type: 'string',
          description: 'The URL or route to navigate to',
          required: true
        },
        type: {
          type: 'string',
          description: 'Type of navigation (internal or external)',
          required: true,
          enumValues: ['internal', 'external']
        }
      },
      execute: async (params) => {
        const { destination, type } = params;
        
        // Validate the destination
        if (!destination) {
          throw new Error('Navigation destination is required');
        }

        // Handle internal navigation
        if (type === 'internal') {
          const validRoutes = [
            '/dashboard',
            '/markets',
            '/about',
            '/contact',
            '/faq',
            '/terms',
            '/privacy',
            '/news',
            '/dashboard/trade',
            '/dashboard/portfolio',
            '/dashboard/transactions',
            '/dashboard/settings',
            '/dashboard/notifications',
            '/dashboard/wallet',
            'https://africangoldcoin.org/'
          ];

          if (!validRoutes.includes(destination)) {
            throw new Error('Invalid internal route');
          }

          window.location.href = destination;
          return;
        }

        // Handle external navigation
        if (type === 'external') {
          // Validate external URL
          try {
            const url = new URL(destination);
            if (!url.protocol.startsWith('http')) {
              throw new Error('Only HTTP/HTTPS protocols are allowed');
            }

            // Whitelist of allowed external domains
            const allowedDomains = [
              'etherscan.io',
              'bscscan.com',
              'agctoken-7f27e.web.app',
              'https://africangoldcoin.org/',
              'evocash.org'
            ];

            if (!allowedDomains.some(domain => url.hostname.endsWith(domain))) {
              throw new Error('Domain not in whitelist');
            }

            // Open external links in new tab
            window.open(destination, '_blank', 'noopener,noreferrer');
          } catch (error) {
            throw new Error(`Invalid external URL: ${error.message}`);
          }
        }
      }
    };

    this.registerTool(navigationTool);
  }

  /**
   * Add a public method to trigger navigation
   */
  public async navigate(destination: string, type: 'internal' | 'external'): Promise<void> {
    const tool = this.tools.get('navigation');
    if (!tool) {
      throw new Error('Navigation tool not found');
    }

    await tool.execute({ destination, type });
  }

  /**
   * Get real-time search suggestions
   */
  public async getSearchSuggestions(query: string): Promise<GeminiResponse> {
    return this.chat(query, {
      executeActions: false,
      priority: 'low',
      contextWindowSize: 5, // Smaller context for faster responses
      maxOutputTokens: 200
    });
  }

  /**
   * Enhanced context building for search
   */
  private buildSearchContext(query: string): string {
    return `
      Search Context:
      Query: ${query}
      Recent Pages: ${Array.from(this.memory.values())
        .filter(m => m.type === 'navigation')
        .slice(-3)
        .map(m => m.content)
        .join(', ')}
      User Profile: ${JSON.stringify(this.userProfile)}
    `;
  }

  /**
   * Get personalized AI actions
   */
  public async getSuggestedActions(): Promise<AgentAction[]> {
    const context = this.buildSearchContext('');
    const response = await this.chat(
      'What actions would be most helpful for this user right now?',
      { executeActions: false, priority: 'low' }
    );
    return response.actions || [];
  }

  public async predictNextAction(): Promise<{
    route: string;
    confidence: number;
    reason: string;
  }> {
    const context = this.buildContextMessage("predict next user action");
    const result = await this.chat(context, {
      executeActions: false,
      priority: 'low'
    });
    
    return {
      route: result.navigation || '/dashboard',
      confidence: result.confidence || 0.5,
      reason: result.text
    };
  }
}

/**
 * Create environment-safe API key retrieval
 * Falls back gracefully if environment variables are not available
 */
const getApiKey = (): string => {
  try {
    return import.meta.env.VITE_GEMINI_API_KEY || '';
  } catch (e) {
    console.warn('Failed to load API key from environment variables');
    return '';
  }
};

// Create and register default tools
const defaultTools: AgentTool[] = [
  {
    name: 'price_alert',
    description: 'Set a price alert for a token',
    category: 'ALERT',
    requiresAuth: true,
    parameters: {
      token: {
        name: 'token',
        type: 'string',
        description: 'Token symbol (e.g., AGC, BTC, ETH)',
        required: true,
        enumValues: ['AGC', 'BTC', 'ETH', 'USDT', 'USDC']
      },
      targetPrice: {
        name: 'targetPrice',
        type: 'number',
        description: 'Target price for the alert',
        required: true
      },
      direction: {
        name: 'direction',
        type: 'string',
        description: 'Price direction for alert',
        required: true,
        enumValues: ['above', 'below']
      },
      notificationMethod: {
        name: 'notificationMethod',
        type: 'string',
        description: 'How to notify the user',
        required: false,
        defaultValue: 'app',
        enumValues: ['app', 'email', 'sms']
      }
    },
    execute: async (params, context) => {
      // Check authentication
      if (context.userProfile.accountStatus === 'guest') {
        return { error: 'Authentication required', errorCode: 'AUTH_REQUIRED' };
      }
      
      const alertId = `alert_${Date.now()}`;
      
      // In production, would connect to a real alert system
      const alert = {
        id: alertId,
        token: params.token,
        targetPrice: params.targetPrice,
        direction: params.direction,
        notificationMethod: params.notificationMethod || 'app',
        createdAt: new Date().toISOString(),
        userId: context.userProfile.id
      };
      
      context.addMemory({
        type: 'task',
        content: `Price alert set for ${params.token} ${params.direction} ${params.targetPrice}`,
        source: 'tool',
        importance: MemoryImportance.HIGH,
        tags: ['alert', 'price', params.token as string]
      });
      
      return { success: true, alertId, alert };
    }
  },
  {
    name: 'trade_recommendation',
    description: 'Generate a trade recommendation',
    category: 'TRADING',
    parameters: {
      token: {
        name: 'token',
        type: 'string',
        description: 'Token symbol (e.g., AGC, BTC, ETH)',
        required: true,
        enumValues: ['AGC', 'BTC', 'ETH', 'USDT', 'USDC']
      },
      timeframe: {
        name: 'timeframe',
        type: 'string',
        description: 'Analysis timeframe',
        required: false,
        defaultValue: '24h',
        enumValues: ['1h', '24h', '7d', '30d']
      },
      riskTolerance: {
        name: 'riskTolerance',
        type: 'string',
        description: 'User risk tolerance level',
        required: false,
        defaultValue: 'medium',
        enumValues: ['low', 'medium', 'high']
      }
    },
    execute: async (params, context) => {
      // In production would connect to a real analysis service
      const token = params.token as string;
      const timeframe = params.timeframe as string;
      const riskTolerance = params.riskTolerance as string;
      
      // Simulated market sentiment based on token
      const sentiment = {
        'AGC': 0.8,  // Bullish
        'BTC': 0.4,  // Neutral
        'ETH': 0.6,  // Somewhat bullish
        'USDT': 0.5, // Neutral
        'USDC': 0.5  // Neutral
      };
      
      // Generate recommendation based on token and risk tolerance
      let recommendation: string;
      let confidence: number;
      let targetPrice: number | null = null;
      
      if (sentiment[token] > 0.7) {
        // Bullish
        recommendation = riskTolerance === 'high' ? 'strong_buy' : 'buy';
        confidence = riskTolerance === 'high' ? 0.9 : 0.7;
        
        // Simulated target price calculation
        const currentPrice = token === 'AGC' ? 1852.43 : token === 'BTC' ? 73218.75 : 3943.21;
        targetPrice = currentPrice * (1 + (sentiment[token] - 0.5) * (riskTolerance === 'high' ? 0.2 : 0.1));
      } else if (sentiment[token] < 0.3) {
        // Bearish
        recommendation = riskTolerance === 'high' ? 'strong_sell' : 'sell';
        confidence = riskTolerance === 'high' ? 0.9 : 0.7;
        
        // Simulated target price calculation
        const currentPrice = token === 'AGC' ? 1852.43 : token === 'BTC' ? 73218.75 : 3943.21;
        targetPrice = currentPrice * (1 - (0.5 - sentiment[token]) * (riskTolerance === 'high' ? 0.2 : 0.1));
      } else {
        // Neutral
        recommendation = 'hold';
        confidence = 0.6;
      }
      
      const result = { 
        recommendation,
        confidence,
        targetPrice,
        rationale: `Based on ${timeframe} technical analysis and market sentiment (${sentiment[token].toFixed(2)})`,
        timeframe,
        riskTolerance
      };
      
      // Add to memory
      context.addMemory({
        type: 'fact',
        content: `Generated ${riskTolerance} risk trade recommendation for ${token} (${timeframe}): ${recommendation} with ${(confidence * 100).toFixed(0)}% confidence`,
        source: 'tool',
        importance: MemoryImportance.MEDIUM,
        tags: ['trading', 'recommendation', token, timeframe]
      });
      
      return result;
    }
  }
];

// Export a singleton instance with improved configuration
export const geminiService = AgenticGeminiService.getInstance({
  apiKey: getApiKey(),
  model: 'gemini-2.0-flash',
  temperature: 0.7,
  maxOutputTokens: 1500,
  tools: defaultTools,
  memorySize: 100,
  contextWindowSize: 20
});

// Export types for use in other components
export type { 
  GeminiResponse, 
  GeminiConfig, 
  IntentType, 
  AgentTool,
  ToolParameter,
  ToolExecutionContext,
  AgentAction,
  UserProfile,
  MemoryItem,
  ContextWindow
};

// Export enums
export { MemoryImportance };
