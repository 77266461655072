import React from 'react';
import TransferForm from '@/components/transfer/TransferForm';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Button } from '@/components/ui/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Asset } from '@/components/wallet/WalletModels';

const TransferPage = () => {
    const navigate = useNavigate();
     const location = useLocation();
    const asset: Asset = location.state.asset;


  return (
    <DashboardLayout>
        <div className="flex items-center gap-2">
          <Button 
            variant="ghost" 
            size="icon"
            onClick={() => navigate('/dashboard/wallet')}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-xl sm:text-3xl font-bold tracking-tight">Transfer {asset.assetName} </h1>
        </div>
      <TransferForm />
    </DashboardLayout>
  );
};

export default TransferPage;