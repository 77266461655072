import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Asset } from '@/components/wallet/WalletModels';
import { PaymentMethod } from '@/components/payment_method/PaymentMethodModel';
import { useState, useEffect } from 'react';
import { toast } from 'sonner';

import { useWallet } from '@/contexts/WalletContext';
import { set } from 'date-fns';

const BuyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { buyedAGC, buyedTransaction, buyAGC, getBuyTransactionStatus } = useWallet();
  const asset: Asset = location.state?.asset;
  const paymentMethod: PaymentMethod = location.state?.paymentMethod || {};
  
  const [amount, setAmount] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [creditCardNumber, setCreditCardNumber] = useState<string>('');  
  const [creditCardError, setCreditCardError] = useState<string | null>(null);
  const [expirationDate, setExpirationDate] = useState<string>('');  
  const [expirationDateError, setExpirationDateError] = useState<string | null>(null);
  const [cvv, setCvv] = useState<string>('');  
  const [cvvError, setCvvError] = useState<string | null>(null);
  
  const [amountError, setAmountError] = useState<string | null>(null);
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    
  }, []);

  const handleBuy = async () => {
    const amountNumber = Number(amount);
    let valid = true;

    if (amountNumber <= 0) {
      setAmountError('Please enter a valid amount');
      valid = false;
    } else {
      setAmountError(null);
    }

    if (!phoneNumber) {
      setPhoneNumberError('Please enter a valid phone number');
      valid = false;
    } else {
      setPhoneNumberError(null);
    }

    if (!valid) return;

    setLoading(true);

    try {
      await buyAGC(paymentMethod._id, amountNumber, { phone: phoneNumber });
      console.log(buyedAGC.status);
      if (buyedAGC.status === 'Pending') {
        toast.info('Transaction is pending. Ceck your email for confirmation.');
        navigate('/dashboard/wallet');  
    } 
     setLoading(false);

    } catch (error) {
      setLoading(false);
    }
  };


  const renderForm = () => {
    if (asset.type === 'Crypto') {
      return (
        <>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Amount</label>
            <Input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              min="0"
              className="w-full"
              disabled={loading}
            />
            {amountError && <p className="text-red-500 mt-2">{amountError}</p>}
          </div>
          
          {paymentMethod.paymentName != 'CREDITCARD' && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Phone Number</label>
              <Input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full"
                disabled={loading}
              />
              {phoneNumberError && <p className="text-red-500 mt-2">{phoneNumberError}</p>}
            </div>
          )}

            {paymentMethod.paymentName === 'CREDITCARD' && (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Credit Card Number</label>
                <Input
                  type="text"
                  value={creditCardNumber}
                  onChange={(e) => setCreditCardNumber(e.target.value)}
                  className="w-full"
                  disabled={loading}
                />
                {creditCardError && <p className="text-red-500 mt-2">{creditCardError}</p>}
              </div>
            )}

            {paymentMethod.paymentName === 'CREDITCARD' && (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Expiration Date</label>
                <Input
                  type="text"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  className="w-full"
                  placeholder="MM/YY"
                  disabled={loading}
                />
                {expirationDateError && <p className="text-red-500 mt-2">{expirationDateError}</p>}
              </div>
            )}

           {paymentMethod.paymentName === 'CREDITCARD' && (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">CVV</label>
                <Input
                  type="text"
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                  className="w-full"
                  disabled={loading}
                />
                {cvvError && <p className="text-red-500 mt-2">{cvvError}</p>}
              </div>
            )}

        </>
      );
    } else if (asset.type === 'Fiat') {
      return (
        <>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Amount</label>
            <Input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              min="0"
              className="w-full"
              disabled={loading}
            />
            {amountError && <p className="text-red-500 mt-2">{amountError}</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Phone Number</label>
            <Input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-full"
              disabled={loading}
            />
            {phoneNumberError && <p className="text-red-500 mt-2">{phoneNumberError}</p>}
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6 animate-fade-in">
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => navigate('/dashboard/wallet/payment', { state: { asset } })}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-xl sm:text-3xl font-bold tracking-tight">Buy {asset.assetName}</h1>
        </div>

        <div className="max-w-md mx-auto p-4">
          {renderForm()}
          <Button onClick={handleBuy} className="w-full" disabled={loading}>
            {loading ? 'Processing...' : 'Buy'}
          </Button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BuyPage;