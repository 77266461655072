
import { ReactNode, useState, useEffect } from 'react';
import DashboardSidebar from './DashboardSidebar';
import DashboardNavbar from './DashboardNavbar';
import { SearchDialog } from './SearchDialog';
import { MobileLayout } from '../layouts/MobileLayout';
import { useIsMobile } from '@/hooks/use-mobile';
import { cn } from '@/lib/utils';

interface DashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const isMobile = useIsMobile();
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    setSidebarOpen(!isMobile);
  }, [isMobile]);

  if (isMobile) {
    return (
      <MobileLayout>
        <DashboardNavbar onOpenSearch={() => setSearchOpen(true)} />
        <div className="flex-1 overflow-y-auto px-4 sm:px-6">
          {children}
        </div>
        <SearchDialog open={searchOpen} setOpen={setSearchOpen} />
      </MobileLayout>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden">
      <DashboardSidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      <div className="flex-1 flex flex-col overflow-hidden">
        <DashboardNavbar onOpenSearch={() => setSearchOpen(true)} />
        <div className="flex-1 overflow-y-auto px-6">
          {children}
        </div>
      </div>
      <SearchDialog open={searchOpen} setOpen={setSearchOpen} />
    </div>
  );
};

export default DashboardLayout;
