import { useState, useRef } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Camera, Upload } from 'lucide-react';
import { toast } from 'sonner';
import { useUser } from '@/contexts/UserContext';

interface ProfileImageUploaderProps {
  imageUrl: string | null;
  initials: string;
}

const ProfileImageUploader = ({ imageUrl, initials }: ProfileImageUploaderProps) => {
  const { updateUserProfile, fetchUserProfile, user } = useUser();
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Local state
  const [previewImage, setPreviewImage] = useState<string | null>(imageUrl);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  
  // Handle image upload click
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  
  // Handle image file change
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImageFile(file);
      
      // Create preview URL
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setPreviewImage(event.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  
// Handle profile image upload
const handleUploadProfileImage = async () => {
  if (!imageFile) return;

  setIsUploading(true);
  
  try {     
      // Call the updateUserProfile function with the userProfile object
      const response = await updateUserProfile({
        avatar: imageFile, deleteAvatar: false,
        lastName: user?.lastName || '',
        firstName: user?.firstName || ''
      });

      fetchUserProfile();
      toast.success("Profile image updated successfully");
  } catch (error) {
      console.error('Error uploading profile image:', error);
      toast.error("Failed to upload profile image");
  } finally {
      setIsUploading(false);
      setImageFile(null);
  }
};


  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="relative">
        <Avatar className="h-24 w-24">
          <AvatarImage src={previewImage || ""} />
          <AvatarFallback className="text-2xl bg-primary text-primary-foreground">
            {initials}
          </AvatarFallback>
        </Avatar>
        
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="image/*"
          onChange={handleImageChange}
        />
        
        {/* Camera icon button */}
        <Button
          size="icon"
          variant="outline"
          className="absolute bottom-0 right-0 rounded-full bg-background border-2 border-background hover:bg-accent"
          onClick={handleUploadClick}
        >
          <Camera className="h-4 w-4" />
        </Button>
      </div>
      
      {/* Show upload button if image is selected */}
      {imageFile && (
        <Button 
          variant="outline" 
          size="sm" 
          onClick={handleUploadProfileImage}
          disabled={isUploading}
        >
          {isUploading ? 'Uploading...' : 'Upload New Image'}
          <Upload className="ml-2 h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default ProfileImageUploader;