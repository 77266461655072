
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import NavigationItems, { publicNavItems } from './navbar/NavigationItems';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { 
  Wallet, 
  Menu, 
  ExternalLink,
  LineChart,
  HelpCircle,
  Info,
  Mail,
  ShieldCheck,
  Lock,
  FileText,
  CircleDollarSign,
  ArrowLeftRight,
  Newspaper,
  Home,
  BarChart
} from 'lucide-react';
import { useUser } from '@/contexts/UserContext';
import { DialogTitle } from "@/components/ui/dialog";

import NotificationsPopover from '../dashboard/NotificationsPopover';
import ThemeToggle from './navbar/ThemeToggle';
import { Badge } from '@/components/ui/badge';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, isAuthenticated } = useUser();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navigationItems = [
    {
      title: "Trading",
      items: [
        {
          title: "Markets Overview",
          href: "/markets",
          description: "Explore available markets and trading pairs",
          icon: <CircleDollarSign className="w-6 h-6" />
        },
        {
          title: "Visit Dashboard",
          href: "/dashboard",
          description: "Access your trading dashboard",
          icon: <BarChart className="w-6 h-6" />,
          highlight: true
        }
      ]
    },
    {
      title: "Resources",
      items: [
        {
          title: "Help Center",
          href: "/help",
          description: "Get support and find answers",
          icon: <HelpCircle className="w-6 h-6" />
        },
        {
          title: "News & Updates",
          href: "/news",
          description: "Latest updates and announcements",
          icon: <Newspaper className="w-6 h-6" />
        },
        {
          title: "About Us",
          href: "/about",
          description: "Learn about our mission and team",
          icon: <Info className="w-6 h-6" />
        },
      ]
    },
    {
      title: "Legal",
      items: [
        {
          title: "Terms of Service",
          href: "/terms",
          description: "Our terms and conditions",
          icon: <Lock className="w-6 h-6" />
        },
        {
          title: "Privacy Policy",
          href: "/privacy",
          description: "How we handle your data",
          icon: <ShieldCheck className="w-6 h-6" />
        }
      ]
    }
  ];
  
  const mainNavItems = [
    { title: 'Home', path: '/', icon: <Home size={16} /> },
    { title: 'FAQ', path: '/faq', icon: <HelpCircle size={16} /> }
  ];

  const legalNavItems = [
    { title: 'Terms', path: '/terms', icon: <FileText size={16} /> },
    { title: 'Privacy', path: '/privacy', icon: <Lock size={16} /> }
  ];

  return (
    <>
      {/* Backdrop overlay for mobile menu */}
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-background/80 backdrop-blur-sm z-40 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      <header
        className={cn(
          "fixed top-0 left-0 right-0 z-50 transition-all duration-500",
          "before:absolute before:inset-0 before:z-[-1]",
          isScrolled 
            ? "before:bg-background/95 before:backdrop-blur-xl before:border-b before:border-border/40 h-16"
            : "before:bg-gradient-to-b before:from-background/80 before:to-transparent h-20",
          "px-2 sm:px-4 safe-area-inset-top" // Reduced padding on mobile
        )}
      >
        <div className="flex h-full items-center justify-between container">
          {/* Logo Section - Reduced size for mobile and pushed left */}
          <Link 
            to="/" 
            className="flex items-center gap-2 lg:gap-4 relative group -ml-2" // Added -ml-2 to push left
          >
            <div className="relative">
              <div 
                className={cn(
                  "absolute -inset-4 lg:-inset-6 rounded-3xl transition-all duration-500",
                  "bg-gradient-to-r from-primary/80 via-primary/50 to-primary/30",
                  "blur-xl opacity-60 group-hover:opacity-100",
                  "animate-pulse group-hover:animate-none"
                )} 
              />
              <div 
                className={cn(
                  "relative bg-background/90 backdrop-blur-md rounded-2xl p-2 lg:p-2.5",
                  "ring-1 ring-border/50 shadow-md",
                  "transition-transform duration-300 group-hover:scale-105",
                  "flex items-center justify-center" 
                )}
                style={{ 
                  width: '3rem',      // Increased from 2.5rem
                  height: '3rem',     // Increased from 2.5rem
                  '@media (min-width: 1024px)': {
                    width: '3.5rem',  // Even larger on desktop
                    height: '3.5rem'  // Even larger on desktop
                  }
                }}
              >
                <img 
                  src="/logo.svg"
                  alt="EvoCash Logo" 
                  className="w-full h-full object-contain"
                  style={{
                    transform: 'scale(3) translateY(-1px)' // Increased from 2.5
                  }}
                />
              </div>
            </div>
            <div className="relative -ml-1"> {/* Added -ml-1 to bring EvoCash text closer */}
              <span className="text-xl lg:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/70">
                EvoCash
              </span>
              <Badge 
                variant="outline" 
                className={cn(
                  "absolute -top-3 -right-12 lg:-top-4 lg:-right-16", // Adjusted positioning
                  "bg-background/80 backdrop-blur-sm",
                  "border-primary/20 text-primary/80",
                  "animate-float",
                  "text-xs lg:text-sm"
                )}
              >
                Beta
              </Badge>
            </div>
          </Link>

          {/* Mobile Navigation - Adjusted spacing */}
          <div className="lg:hidden flex items-center gap-2">
            {!isAuthenticated && (
              <div className="flex items-center gap-1">
                <Button 
                  asChild 
                  variant="ghost" 
                  size="sm" 
                  className="h-8 px-2 hover:bg-accent/50 text-sm"
                >
                  <Link to="/login">Login</Link>
                </Button>
                <Button 
                  asChild 
                  variant="default" 
                  size="sm" 
                  className="h-8 px-2 bg-gradient-to-r from-primary to-primary/90 text-sm"
                >
                  <Link to="/signup">Sign up</Link>
                </Button>
              </div>
            )}

            <ThemeToggle />

            {isAuthenticated && (
              <div className="flex items-center gap-1.5">
                <NotificationsPopover />
                <Button 
                  asChild 
                  variant="default" 
                  size="sm" 
                  className="h-8 px-2 bg-gradient-to-r from-primary to-primary/90 text-sm"
                >
                  <Link to="/dashboard">Dashboard</Link>
                </Button>
              </div>
            )}

            <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
              <SheetTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="sm" 
                  className="h-8 w-8 p-0 hover:bg-accent/50"
                >
                  <Menu className="h-4 w-4" />
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent 
                side="right" 
                className="w-full sm:w-80 p-0 border-l border-border/50 backdrop-blur-xl bg-background/95"
              >
                <DialogTitle className="sr-only">Navigation Menu</DialogTitle>
                <div className="flex flex-col gap-6 p-6">
                  {/* Main Navigation Items */}
                  {navigationItems.map((section) => (
                    <div key={section.title} className="space-y-3">
                      <h2 className="text-lg font-semibold tracking-tight">
                        {section.title}
                      </h2>
                      <div className="flex flex-col gap-1">
                        {section.items.map((item) => (
                          <Link
                            key={item.title}
                            to={item.href}
                            className={cn(
                              "flex items-center gap-3 p-3 rounded-lg",
                              "transition-all duration-300",
                              "hover:bg-accent hover:text-accent-foreground",
                              "hover:shadow-md hover:-translate-y-0.5",
                              item.highlight && "bg-primary/5"
                            )}
                            onClick={() => setIsMobileMenuOpen(false)}
                          >
                            <div className="p-1 rounded-md bg-primary/10">
                              {item.icon}
                            </div>
                            <div>
                              <div className="font-medium">{item.title}</div>
                              <p className="text-sm text-muted-foreground line-clamp-1">
                                {item.description}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}

                  {/* Quick Links Section */}
                  <div className="space-y-3">
                    <h2 className="text-lg font-semibold tracking-tight">Quick Links</h2>
                    <div className="flex flex-col gap-1">
                      {mainNavItems.map((item) => (
                        <Link
                          key={item.path}
                          to={item.path}
                          className="flex items-center gap-3 p-3 rounded-lg hover:bg-accent hover:text-accent-foreground"
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          <div className="p-1 rounded-md bg-primary/10">
                            {item.icon}
                          </div>
                          <span className="font-medium">{item.title}</span>
                        </Link>
                      ))}
                    </div>
                  </div>

                  {/* Legal Links Section */}
                  <div className="space-y-3">
                    <h2 className="text-sm font-medium text-muted-foreground">Legal</h2>
                    <div className="flex flex-col gap-1">
                      {legalNavItems.map((item) => (
                        <Link
                          key={item.path}
                          to={item.path}
                          className="flex items-center gap-3 p-3 rounded-lg hover:bg-accent hover:text-accent-foreground"
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          <div className="p-1 rounded-md bg-primary/10">
                            {item.icon}
                          </div>
                          <span className="font-medium">{item.title}</span>
                        </Link>
                      ))}
                    </div>
                  </div>
                  
                  {/* Authentication Buttons */}
                  <div className="flex flex-col gap-2 mt-4 pt-4 border-t border-border/50">
                    {!isAuthenticated ? (
                      <>
                        <Button 
                          asChild 
                          variant="outline" 
                          className="w-full hover:bg-accent/50"
                        >
                          <Link 
                            to="/login" 
                            onClick={() => setIsMobileMenuOpen(false)}
                          >
                            Login
                          </Link>
                        </Button>
                        <Button 
                          asChild 
                          className={cn(
                            "w-full",
                            "transition-all duration-300",
                            "hover:shadow-md hover:-translate-y-0.5",
                            "bg-gradient-to-r from-primary to-primary/90"
                          )}
                        >
                          <Link 
                            to="/signup" 
                            onClick={() => setIsMobileMenuOpen(false)}
                          >
                            Sign Up
                          </Link>
                        </Button>
                      </>
                    ) : (
                      <Button 
                        asChild
                        className={cn(
                          "transition-all duration-300",
                          "hover:shadow-md hover:-translate-y-0.5",
                          "bg-gradient-to-r from-primary to-primary/90"
                        )}
                      >
                        <Link 
                          to="/dashboard"
                          onClick={() => setIsMobileMenuOpen(false)}
                          className="flex items-center justify-center gap-2"
                        >
                          Dashboard
                          <ExternalLink className="h-4 w-4" />
                        </Link>
                      </Button>
                    )}
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>

          {/* Desktop Navigation remains unchanged */}
          <div className="hidden lg:flex items-center gap-4">
            <NavigationMenu>
              <NavigationMenuList className="gap-1">
                {navigationItems.map((section) => (
                  <NavigationMenuItem key={section.title}>
                    <NavigationMenuTrigger 
                      className={cn(
                        "text-sm font-medium px-4 py-2",
                        "data-[state=open]:bg-accent/50",
                        "transition-colors duration-300"
                      )}
                    >
                      {section.title}
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                        {section.items.map((item) => (
                          <li key={item.title}>
                            <Link
                              to={item.href}
                              className={cn(
                                "block select-none space-y-1 rounded-lg p-3.5",
                                "leading-none no-underline outline-none",
                                "transition-all duration-300",
                                "hover:bg-accent hover:text-accent-foreground",
                                "focus:bg-accent focus:text-accent-foreground",
                                "border border-transparent hover:border-border/50",
                                "hover:shadow-md hover:-translate-y-0.5",
                                item.highlight && "bg-primary/5 border-primary/10"
                              )}
                            >
                              <div className="flex items-center gap-2">
                                <div className="p-1 rounded-md bg-primary/10">
                                  {item.icon}
                                </div>
                                <div className="font-medium">{item.title}</div>
                              </div>
                              <p className="line-clamp-2 text-sm leading-relaxed text-muted-foreground mt-1.5">
                                {item.description}
                              </p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                ))}
              </NavigationMenuList>
            </NavigationMenu>

            <div className="flex items-center gap-4">
              <ThemeToggle />
              
              {isAuthenticated ? (
                <div className="flex items-center gap-4">
                  <NotificationsPopover />
                  <Button 
                    asChild 
                    variant="default" 
                    size="sm" 
                    className={cn(
                      "h-9 px-4",
                      "transition-all duration-300",
                      "hover:shadow-md hover:-translate-y-0.5",
                      "bg-gradient-to-r from-primary to-primary/90"
                    )}
                  >
                    <Link to="/dashboard" className="flex items-center gap-2">
                      Dashboard
                      <ExternalLink className="h-4 w-4" />
                    </Link>
                  </Button>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  <Button 
                    asChild 
                    variant="ghost" 
                    size="sm" 
                    className="h-9 px-4 hover:bg-accent/50"
                  >
                    <Link to="/login">Login</Link>
                  </Button>
                  <Button 
                    asChild 
                    variant="default" 
                    size="sm" 
                    className={cn(
                      "h-9 px-4",
                      "transition-all duration-300",
                      "hover:shadow-md hover:-translate-y-0.5",
                      "bg-gradient-to-r from-primary to-primary/90"
                    )}
                  >
                    <Link to="/signup">Sign up</Link>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
