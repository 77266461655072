import apiClient from './apiClient';

const walletService = {
  // Get assets
  getAssets: async () => {
    const response = await apiClient.get('/get-assets');
    return response.data;
  },

  // Get user balance
  getUserBalance: async () => {
    const response = await apiClient.get('/get-user-balance');
    return response.data;
  },

  // Get payment methods
  getPaymentMethods: async (countryCode: string, assetId: string) => {
    const response = await apiClient.get(`/payment-methods?country=${countryCode}&assetId=${assetId}`);
    return response.data;
  },

  // Deposit crypto
  depositCrypto: async (assetName: string) => {
    const response = await apiClient.post('/deposit/crypto-address', { assetName });
    return response.data;
  },

  // Crypto transfer
  transferCrypto: async (transferData: { amount: number, recipientIdentifier: string }) => {
    const response = await apiClient.post('/transfer/crypto', transferData);
    return response.data;
  },

  // Buy AGC with local fiat currency
    buyAGC: async (buyAGCData : {paymentMethodId: string, amount: number, meta: object}) => {
      const response = await apiClient.post('/buy', buyAGCData);
      return response.data;
    },
  
    // Get the status of a buy transaction
    getBuyTransactionStatus: async (transactionId: string) => {
      const response = await apiClient.get(`/buy/${transactionId}/status`);
      return response.data;
    },


};

export default walletService;