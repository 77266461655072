
import apiClient from './apiClient';

interface UpdatePasswordParams {
  oldPassword: string;
  password: string;
}

interface VerifyOTPParams {
  email: string;
  otp: string;
}

interface ResetPasswordParams {
  token: string;
  newPassword: string;
}

const passwordService = {
  // Forgot Password
  forgotPassword: async (email: string) => {
    // Use "login" parameter name instead of "email" as expected by API
    const response = await apiClient.post('/forgot-password', { login: email });
    return response.data;
  },

  // Reset Password
  resetPassword: async (params: ResetPasswordParams) => {
    const response = await apiClient.post('/reset-password', params);
    return response.data;
  },

  // Verify OTP for password reset
  verifyOTPResetPassword: async (params: VerifyOTPParams) => {
    // Convert email to login parameter for consistency with backend
    const response = await apiClient.post('/verify-otp-reset-password', {
      login: params.email,
      otp: params.otp
    });
    return response.data;
  },

  // Update user password
  updatePassword: async (params: UpdatePasswordParams) => {
    const response = await apiClient.put('/update-password', params);
    return response.data;
  },
};

export default passwordService;
