import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardContent, CardFooter, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { 
  MessageSquare, X, Send, Loader2, Bot, User as UserIcon, Minimize, RefreshCw,
  Bell, CheckCircle, AlertCircle, PanelRight, Calendar, Gem, Settings,
  Sparkles, ChevronRight, ZapIcon, Clock, Database, Layout, MoreHorizontal,
  InfoIcon, ChevronUp, ChevronDown, Copy, Trash, Star
} from 'lucide-react';
import { geminiService, GeminiResponse, MemoryImportance, UserProfile as GeminiUserProfile } from '@/services/geminiService';
import { motion, AnimatePresence } from 'framer-motion';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { useToast } from '@/components/ui/use-toast';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Skeleton } from "@/components/ui/skeleton";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { useUser } from '@/contexts/UserContext';

import userService from '@/services/userService';

// Define action types
type ActionType = 'navigate' | 'tool';
type MessageStatus = 'sending' | 'sent' | 'error';
type ChatState = 'closed' | 'minimized' | 'open';
type ChatTab = 'chat' | 'actions' | 'memory';
type ThemeMode = 'light' | 'dark' | 'system';

// Define the AgentAction interface
interface AgentAction {
  type: string;
  payload: Record<string, unknown>;
  status: 'pending' | 'success' | 'failed';
  result?: unknown;
  timestamp: number;
}

// Define the Message interface with proper typing
interface Message {
  id: string;
  role: 'user' | 'assistant' | 'system';
  content: string;
  action?: {
    type: ActionType;
    path?: string;
    toolDetails?: AgentAction;
  };
  timestamp: Date;
  status?: MessageStatus;
  suggestions?: string[];
  context?: Record<string, unknown>;
  thinking?: string;
  isPinned?: boolean;
}

// Define user profile interface
interface UserProfile {
  id: string;
  name: string;
  email: string;
  accountStatus: 'guest' | 'premium' | 'registered';
  preferences: {
    theme: ThemeMode;
    notifications: boolean;
    language: string;
    currency: string;
    favoriteAssets: string[];
  };
}

// Define memory item interface
interface MemoryItem {
  id: string;
  type: 'fact' | 'interaction' | 'preference' | 'task';
  content: string;
  timestamp: number;
  expiresAt?: number;
  source: 'user' | 'system' | 'agent';
  context?: Record<string, unknown>;
}

// Define the welcome message constant with proper typing
const WELCOME_MESSAGE: Message = {
  id: 'welcome',
  role: 'assistant',
  content: "👋 Hi! I'm Eva, your EvoCash AI agent. I can help you:\n• Navigate the platform\n• Learn about AGC tokens\n• Find specific features\n• Answer your questions\n• Set price alerts\n• Track your portfolio\n\nWhat can I help you with today?",
  timestamp: new Date(),
  status: 'sent',
  suggestions: ['Tell me about AGC tokens', 'AGC use cases', 'Tell me more about the platform', 'Help me trade AGC'],
};

// Define chat settings interface
interface ChatSettings {
  enableThinking: boolean;
  enableTypingEffect: boolean;
  enableSuggestions: boolean;
  enableProactiveMessages: boolean;
  messageRetention: number;
}

// Helper function to format agent output
const formatAgentOutput = (content: string, navigate: (path: string) => void) => {
  const sections = {
    intent: '',
    navigation: '',
    suggestions: [] as string[],
    actions: [] as AgentAction[],
    mainContent: ''
  };

  // Process the content to extract structured data
  let processedContent = content;

  // Extract metadata markers
  const metadataRegex = {
    intent: /INTENT:(\w+)/i,
    navigate: /NAVIGATE:(\/\S+)/i,
    suggestions: /SUGGESTIONS:([^\n]+)/i,
    action: /ACTION:(\w+)(?::(\w+))?(?:\s*\{([^{}]*(?:\{[^{}]*\}[^{}]*)*)\})?/gi
  };

  // Extract and remove metadata
  Object.entries(metadataRegex).forEach(([key, regex]) => {
    const match = processedContent.match(regex);
    if (match) {
      if (key === 'suggestions') {
        sections.suggestions = match[1]
          .split(',')
          .map(s => s.trim())
          .filter(Boolean);
      } else if (key === 'intent') {
        sections.intent = match[1];
      } else if (key === 'navigate') {
        sections.navigation = match[1];
      }
      processedContent = processedContent.replace(regex, '');
    }
  });

  // Clean up content
  processedContent = processedContent
    .replace(/\n{3,}/g, '\n\n')
    .trim();

  // Format different content types
  const formatContent = (content: string) => {
    const parts = content.split('\n');
    return parts.map((part, idx) => {
      // Category headers (e.g., "TRADING & INVESTMENT:")
      if (/^[A-Z\s&]+:$/.test(part.trim())) {
        return (
          <h3 key={idx} className="font-semibold text-lg mt-4 mb-2 text-primary">
            {part.trim()}
          </h3>
        );
      }

      // Numbered sections (e.g., "1. Store of Value")
      if (/^\d+\.\s/.test(part.trim())) {
        const [num, ...text] = part.split('.');
        return (
          <div key={idx} className="flex items-start gap-3 my-2">
            <span className="flex-shrink-0 w-6 h-6 rounded-full bg-primary/10 
              flex items-center justify-center text-primary font-medium">
              {num}
            </span>
            <span className="flex-1 font-medium">
              {text.join('.').trim()}
            </span>
          </div>
        );
      }

      // Bullet points
      if (/^[•-]/.test(part.trim())) {
        return (
          <div key={idx} className="flex items-start gap-2 py-1 pl-6">
            <span className="text-primary">•</span>
            <span className="flex-1">{part.replace(/^[•-]\s*/, '')}</span>
          </div>
        );
      }

      // Code blocks
      if (part.trim().startsWith('```')) {
        const code = part.replace(/```/g, '').trim();
        return (
          <pre key={idx} className="bg-muted p-3 rounded-md my-2 overflow-x-auto">
            <code className="text-sm font-mono">{code}</code>
          </pre>
        );
      }

      // Regular paragraphs with emphasis
      return part.trim() && (
        <p key={idx} className="mb-2 last:mb-0">
          {part.split(/(\*\*.*?\*\*|\*.*?\*|\`.*?\`)/).map((segment, sIdx) => {
            if (segment.startsWith('**') && segment.endsWith('**')) {
              return (
                <strong key={sIdx} className="font-semibold bg-primary/5 px-1 rounded">
                  {segment.slice(2, -2)}
                </strong>
              );
            }
            if (segment.startsWith('*') && segment.endsWith('*')) {
              return (
                <em key={sIdx} className="italic text-foreground/90">
                  {segment.slice(1, -1)}
                </em>
              );
            }
            if (segment.startsWith('`') && segment.endsWith('`')) {
              return (
                <code key={sIdx} className="font-mono text-sm bg-muted px-1.5 py-0.5 rounded">
                  {segment.slice(1, -1)}
                </code>
              );
            }
            return segment;
          })}
        </p>
      );
    });
  };

  return (
    <div className="space-y-4">
      {/* Main formatted content */}
      <div className="prose prose-sm dark:prose-invert max-w-none">
        {formatContent(processedContent, navigate)}
      </div>

      {/* Navigation button */}
      {sections.navigation && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-3"
        >
          <Button
            variant="outline"
            size="sm"
            className="group flex items-center gap-2 hover:bg-primary/10"
            onClick={() => navigate(sections.navigation)}
          >
            <Layout className="h-4 w-4" />
            <span>Navigate to {sections.navigation}</span>
            <ChevronRight className="h-4 w-4 transition-transform group-hover:translate-x-1" />
          </Button>
        </motion.div>
      )}

      {/* Suggestions */}
      {sections.suggestions.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-3 flex flex-wrap gap-2"
        >
          {sections.suggestions.map((suggestion, idx) => (
            <Button
              key={idx}
              variant="secondary"
              size="sm"
              className="bg-accent/50 hover:bg-accent/70 transition-all duration-200 
                flex items-center gap-2"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              <Sparkles className="h-3 w-3" />
              <span className="text-xs">{suggestion}</span>
            </Button>
          ))}
        </motion.div>
      )}
    </div>
  );
};

// Add this constant at the top of the file with other constants
const AGC_WEBSITE_URL = 'https://africangoldcoin.org/';

export const ChatBot = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user, isAuthenticated, fetchUserProfile } = useUser();
  const [chatState, setChatState] = useState<ChatState>('closed');
  const [activeTab, setActiveTab] = useState<ChatTab>('chat');
  const [messages, setMessages] = useState<Message[]>([WELCOME_MESSAGE]);
  const [pendingActions, setPendingActions] = useState<AgentAction[]>([]);
  const [memories, setMemories] = useState<MemoryItem[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile>({ accountStatus: 'guest' });
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [enableThinking, setEnableThinking] = useState(true);
  const [enableTypingEffect, setEnableTypingEffect] = useState(true);
  const [pinnedMessages, setPinnedMessages] = useState<string[]>([]);
  
  // Store chat history in local storage
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [hasProactiveSuggestion, setHasProactiveSuggestion] = useState(false);
  
  // Refs for DOM elements
  const inputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  
  // Generate a unique ID for messages
  const generateId = () => `msg_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

  // Focus input when chat opens
  useEffect(() => {
    if (chatState === 'open') {
      setTimeout(() => inputRef.current?.focus(), 100);
      setHasUnreadMessages(false);
      setHasProactiveSuggestion(false);
    }
  }, [chatState]);

  // Save chat history to localStorage
  useEffect(() => {
    if (messages.length > 1) { // Don't save if it's just the welcome message
      localStorage.setItem('evocash_chat_history', JSON.stringify(messages));
      localStorage.setItem('evocash_pinned_messages', JSON.stringify(pinnedMessages));
    }
    
    // Set unread flag if there are new assistant messages and chat is not open
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.role === 'assistant' && chatState !== 'open') {
      setHasUnreadMessages(true);
    }
  }, [messages, chatState, pinnedMessages]);

  // Load chat history from localStorage and initialize user profile
  useEffect(() => {
    try {
      // Load preferences first
      const savedPreferences = localStorage.getItem('evocash_chat_preferences');
      if (savedPreferences) {
        const { thinking, typingEffect } = JSON.parse(savedPreferences);
        setEnableThinking(thinking ?? true);
        setEnableTypingEffect(typingEffect ?? true);
      }
      
      // Sync user profile with backend and Gemini service
      const syncUserProfile = async () => {
        try {
          if (isAuthenticated && user) {
            // Check if auth token exists
            const token = localStorage.getItem('authToken');
            if (!token) {
              throw new Error('No auth token found');
            }

            // Fetch fresh profile data
            await fetchUserProfile();
            
            const authenticatedProfile: UserProfile = {
              id: user.id || '',
              name: `${user.firstName} ${user.lastName}`.trim(),
              email: user.email,
              accountStatus: user.kycLevel ? 'premium' : 'registered',
              preferences: {
                theme: user.preferences?.theme || 'dark',
                notifications: user.preferences?.notifications ?? true,
                favoriteAssets: user.preferences?.favoriteAssets || ['AGC'],
                language: user.preferences?.language || '',
                currency: user.preferences?.currency || ''
              }
            };

            // Update local state
            setUserProfile(authenticatedProfile);
            
            // Update Gemini service
            await geminiService.updateUserProfile(authenticatedProfile);
            
          } else {
            // Handle guest user
            const guestProfile: UserProfile = {
              id: 'guest',
              name: 'Guest User',
              email: '',
              accountStatus: 'guest',
              preferences: {
                theme: 'dark',
                notifications: false,
                favoriteAssets: ['AGC'],
                language: '',
                currency: ''
              }
            };
            
            setUserProfile(guestProfile);
            await geminiService.updateUserProfile(guestProfile);
          }
        } catch (error) {
          console.error('Error syncing user profile:', error);
          
          // Handle authentication errors
          if (error.response?.status === 401) {
            // Clear invalid auth state
            localStorage.removeItem('authToken');
            
            // Redirect to login
            navigate('/login', { 
              state: { 
                from: window.location.pathname,
                message: 'Session expired. Please log in again.' 
              }
            });
            
            toast({
              title: 'Authentication Required',
              description: 'Please log in to continue.',
              variant: 'destructive'
            });
          } else {
            toast({
              title: 'Profile Sync Error',
              description: 'Failed to sync user profile. Some features may be limited.',
              variant: 'destructive'
            });
          }
        }
      };

      syncUserProfile();
    } catch (error) {
      console.error('Failed to load chat history or user profile:', error);
    }
  }, []);

  // Save preferences when they change
  useEffect(() => {
    localStorage.setItem('evocash_chat_preferences', JSON.stringify({
      thinking: enableThinking,
      typingEffect: enableTypingEffect
    }));
  }, [enableThinking, enableTypingEffect]);

  // Fetch pending actions periodically
  useEffect(() => {
    const updatePendingActions = () => {
      const actions = geminiService.getPendingActions();
      setPendingActions(actions);
      
      // Update memories too
      setMemories(geminiService.getMemory());
      
      // If we have pending actions, show a notification badge
      if (actions.length > 0 && chatState !== 'open') {
        setHasUnreadMessages(true);
      }
    };
    
    // Update immediately and then every 30 seconds
    updatePendingActions();
    const interval = setInterval(updatePendingActions, 30000);
    
    return () => clearInterval(interval);
  }, [chatState]);
  
  // Check for proactive suggestions periodically when chat is not open
  useEffect(() => {
    if (chatState !== 'open' && userProfile.accountStatus !== 'guest') {
      const checkForSuggestions = async () => {
        try {
          const suggestion = await geminiService.generateProactiveSuggestion();
          if (suggestion && suggestion.text.trim()) {
            setHasProactiveSuggestion(true);
          }
        } catch (error) {
          console.error('Failed to generate proactive suggestion:', error);
        }
      };
      
      // Check every 5 minutes
      const interval = setInterval(checkForSuggestions, 5 * 60 * 1000);
      
      // Also check immediately if profile changes to non-guest
      if (userProfile.accountStatus !== 'guest') {
        checkForSuggestions();
      }
      
      return () => clearInterval(interval);
    }
  }, [chatState, userProfile.accountStatus]);

  // Scroll to latest message
  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (chatState === 'open') {
      scrollToBottom();
    }
  }, [messages, isTyping, chatState, scrollToBottom]);

  // Toggle pinned message
  const togglePinnedMessage = (messageId: string) => {
    if (pinnedMessages.includes(messageId)) {
      setPinnedMessages(pinnedMessages.filter(id => id !== messageId));
    } else {
      setPinnedMessages([...pinnedMessages, messageId]);
    }
    
    // Update messages with isPinned flag
    setMessages(messages.map(msg => 
      msg.id === messageId 
        ? { ...msg, isPinned: !msg.isPinned } 
        : msg
    ));
  };

  // Copy message content to clipboard
  const copyMessageContent = (content: string) => {
    navigator.clipboard.writeText(content);
    toast({
      title: "Copied to clipboard",
      duration: 2000
    });
  };

  // Simulate thinking process before response
  const simulateThinking = async () => {
    if (!enableThinking) return;
    
    setIsThinking(true);
    
    // Generate thinking process with several steps
    const thinkingSteps = [
      "Analyzing query...",
      "Retrieving relevant information...",
      "Connecting with financial data...",
      "Formulating response..."
    ];
    
    const typingMessageId = generateId();
    
    // Add temporary thinking message
    setMessages(prev => [
      ...prev,
      {
        id: typingMessageId,
        role: 'assistant',
        content: '',
        thinking: thinkingSteps[0],
        timestamp: new Date(),
        status: 'sending'
      }
    ]);
    
    // Update thinking steps over time
    for (let i = 1; i < thinkingSteps.length; i++) {
      await new Promise(resolve => setTimeout(resolve, 800));
      
      setMessages(prev => 
        prev.map(msg => 
          msg.id === typingMessageId 
            ? { ...msg, thinking: thinkingSteps[i] }
            : msg
        )
      );
    }
    
    // Pause a bit on the last step
    await new Promise(resolve => setTimeout(resolve, 800));
    
    setIsThinking(false);
    
    return typingMessageId;
  };

  // Simulate typing animation for a more natural feel
  const simulateTyping = async (content: string) => {
    if (!enableTypingEffect) return content;
    
    setIsTyping(true);
    
    // Typing speed based on content length (faster for shorter messages)
    const baseDelay = 500;
    const charDelay = 10; // ms per character
    const maxDelay = 2000;
    const typingDelay = Math.min(maxDelay, Math.max(baseDelay, content.length * charDelay));
    
    await new Promise(resolve => setTimeout(resolve, typingDelay));
    setIsTyping(false);
    return content;
  };

  // Show the proactive suggestion from the agent
  const showProactiveSuggestion = async () => {
    setIsLoading(true);
    
    try {
      const suggestion = await geminiService.generateProactiveSuggestion();
      
      if (suggestion && suggestion.text.trim()) {
        // Add system message explaining the proactive suggestion
        setMessages(prev => [...prev, { 
          id: generateId(),
          role: 'system',
          content: 'Based on your activity, I have a suggestion for you:',
          timestamp: new Date(),
          status: 'sent'
        }]);
        
        // Add the suggestion as an assistant message
        const content = await simulateTyping(suggestion.text);
        
        const assistantMessage: Message = {
          id: generateId(),
          role: 'assistant',
          content,
          timestamp: new Date(),
          status: 'sent',
          suggestions: suggestion.suggestions,
          context: suggestion.context
        };
        
        if (suggestion.navigation) {
          assistantMessage.action = {
            type: 'navigate',
            path: suggestion.navigation
          };
        }
        
        if (suggestion.actions && suggestion.actions.length > 0) {
          assistantMessage.action = {
            type: 'tool',
            toolDetails: suggestion.actions[0]
          };
        }
        
        setMessages(prev => [...prev, assistantMessage]);
        setHasProactiveSuggestion(false);
      }
    } catch (error) {
      console.error('Failed to get proactive suggestion:', error);
      toast({
        title: "Suggestion Error",
        description: "Unable to generate a suggestion at this time.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleNavigation = async (destination: string, type: 'internal' | 'external') => {
    try {
      // Special handling for AGC website command variations
      const agcCommands = ['AGC WEBSITE', 'AGC TOKEN', 'AGC PAGE', 'AGC'];
      if (agcCommands.includes(destination.toUpperCase())) {
        window.open(AGC_WEBSITE_URL, '_blank', 'noopener,noreferrer');
        toast({
          title: "Opening AGC Website",
          description: "Redirecting to AGC token website in a new tab",
          duration: 3000
        });
        return;
      }

      // Special handling for direct AGC website URL
      if (destination === AGC_WEBSITE_URL) {
        window.open(destination, '_blank', 'noopener,noreferrer');
        toast({
          title: "Opening AGC Website",
          description: "Redirecting to AGC token website in a new tab",
          duration: 3000
        });
        return;
      }

      // Handle other navigation cases...
      if (type === 'external') {
        window.open(destination, '_blank', 'noopener,noreferrer');
      } else {
        navigate(destination);
      }
    } catch (error) {
      console.error('Navigation failed:', error);
      toast({
        title: "Navigation Failed",
        description: "Unable to navigate to the requested page",
        variant: "destructive",
        duration: 3000
      });
    }
  };
  
  // Handle sending a message
  const handleSend = async () => {
    if (!input.trim() || isLoading) return;
    
    const userMessage: Message = {
      id: generateId(),
      role: 'user',
      content: input,
      timestamp: new Date(),
      status: 'sent'
    };

    // Clear input immediately after getting its value
    setInput('');
    
    try {
      setIsLoading(true);
      setMessages(prev => [...prev, userMessage]);

      // Handle INFO commands
      if (input.toUpperCase().startsWith('INFO:')) {
        const command = input.split(':')[1].trim();
        if (command.toUpperCase() === 'AGC WEBSITE') {
          const infoMessage: Message = {
            id: generateId(),
            role: 'assistant',
            content: `The AGC (African Gold Coin) website provides detailed information about our gold-backed stablecoin. Key features include:
• 1 AGC = 10mg of certified physical gold
• Launching in Q2 2025
• Built on BNB Smart Chain
• Full regulatory compliance

Would you like to visit the AGC website? Click here: ${AGC_WEBSITE_URL}`,
            timestamp: new Date(),
            status: 'sent',
            suggestions: ['NAVIGATION:AGC WEBSITE', 'Tell me more about AGC'],
            action: {
              type: 'navigate',
              path: AGC_WEBSITE_URL
            }
          };
          
          setMessages(prev => [...prev, infoMessage]);
          setInput('');
          setIsLoading(false);
          return;
        }
      }

      // Existing navigation command handling
      if (input.toUpperCase().startsWith('NAVIGATION:') || input.toUpperCase().startsWith('NAVIGATE:')) {
        const destination = input.includes(':') ? input.split(':')[1].trim() : input;
        const type = destination.startsWith('http') ? 'external' : 'internal';
        handleNavigation(destination, type);
        setInput('');
        setIsLoading(false);
        return;
      }

      // Simulate thinking process first if enabled
      const typingMessageId = await simulateThinking() || generateId();
      
      // If thinking wasn't enabled, add a typing message
      if (!enableThinking) {
        setMessages(prev => [
          ...prev,
          {
            id: typingMessageId,
            role: 'assistant',
            content: '',
            timestamp: new Date(),
            status: 'sending'
          }
        ]);
      }
      
      // Get response from Gemini with timeout protection
      const response = await geminiService.chatWithTimeout(userMessage.content, 15000);
      
      // Process the response after simulating typing
      const content = await simulateTyping(response.text);
      
      const assistantMessage: Message = {
        id: typingMessageId,
        role: 'assistant',
        content,
        timestamp: new Date(),
        status: 'sent',
        suggestions: response.suggestions,
        context: response.context
      };

      if (response.navigation) {
        assistantMessage.action = {
          type: 'navigate',
          path: response.navigation
        };
        
        // Navigate after showing the message but keep chat open
        setTimeout(() => {
          toast({
            title: "Navigating",
            description: `Taking you to ${response.navigation}`,
            duration: 3000
          });
          
          // Navigate but keep chat open
          navigate(response.navigation);
          
          // Add a system message to confirm navigation
          setMessages(prev => [...prev, { 
            id: generateId(),
            role: 'system',
            content: `✓ Navigated to ${response.navigation}`,
            timestamp: new Date(),
            status: 'sent'
          }]);
        }, 1500);
      }

      // If we have actions, add them to the message
      if (response.actions && response.actions.length > 0) {
        assistantMessage.action = {
          type: 'tool',
          toolDetails: response.actions[0]
        };
        
        // Show toast notification about action
        toast({
          title: "Action Performed",
          description: `${response.actions[0].type.replace(/_/g, ' ')} completed`,
          duration: 3000
        });
        
        // Update pending actions
        setPendingActions(geminiService.getPendingActions());
        
        // Update memories too
        setMemories(geminiService.getMemory());
      }

      // Replace the temporary message with the real one
      setMessages(prev => 
        prev.map(msg => msg.id === typingMessageId ? assistantMessage : msg)
      );
      
    } catch (error) {
      console.error('Failed to get response:', error);
      
      // Show error message
      toast({
        title: "Connection Error",
        description: "Failed to connect to the assistant. Please try again.",
        variant: "destructive",
        duration: 3000
      });
      
      setMessages(prev => [...prev.filter(msg => msg.status !== 'sending'), { 
        id: generateId(),
        role: 'assistant', 
        content: 'I encountered an error processing your request. Please try again.',
        timestamp: new Date(),
        status: 'error'
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle keyboard submit
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  // Handle clicking on a suggestion
  const handleSuggestionClick = (suggestion: string) => {
    setInput(suggestion);
    inputRef.current?.focus();
  };

  // Format timestamp for display
  const formatTime = (date: Date) => {
    return date.toLocaleTimeString('en-US', { 
      hour: 'numeric', 
      minute: '2-digit',
      hour12: true 
    });
  };

  // Format date for display
  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    });
  };

  // Clear chat history
  const handleClearChat = () => {
    setMessages([WELCOME_MESSAGE]);
    setPinnedMessages([]);
    localStorage.removeItem('evocash_chat_history');
    localStorage.removeItem('evocash_pinned_messages');
    geminiService.resetChat(); // Reset the agent's chat session
    toast({
      title: "Chat Cleared",
      description: "Your conversation history has been cleared.",
      duration: 3000
    });
  };

  // Reset agent completely
  const handleResetAgent = () => {
    setMessages([WELCOME_MESSAGE]);
    setPinnedMessages([]);
    localStorage.removeItem('evocash_chat_history');
    localStorage.removeItem('evocash_pinned_messages');
    geminiService.resetAgent(); // Reset the agent completely
    setPendingActions([]);
    setMemories([]);
    toast({
      title: "Agent Reset",
      description: "Your agent has been completely reset.",
      duration: 3000
    });
  };

  // Toggle chat state between open, minimized, and closed
  const toggleChat = (newState: ChatState) => {
    setChatState(newState);
    if (newState === 'open') {
      setHasUnreadMessages(false);
      setHasProactiveSuggestion(false);
      // Refresh actions and memories when opening chat
      setPendingActions(geminiService.getPendingActions());
      setMemories(geminiService.getMemory());
    }
  };

  // Keyboard shortcuts for the chat
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Escape key to close chat
      if (e.key === 'Escape' && chatState === 'open') {
        setChatState('minimized');
      }
      
      // Ctrl+Space to open chat
      if (e.ctrlKey && e.code === 'Space' && chatState !== 'open') {
        setChatState('open');
        e.preventDefault();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [chatState]);

  function cn(...classes: string[]): string {
    return classes.filter(Boolean).join(' ');
  }

  // Format action status for display
  const formatActionStatus = (status: string) => {
    switch (status) {
      case 'pending':
        return <Badge variant="outline" className="bg-amber-500/10 text-amber-500">Pending</Badge>;
      case 'success':
        return <Badge variant="outline" className="bg-emerald-500/10 text-emerald-500">Completed</Badge>;
      case 'failed':
        return <Badge variant="outline" className="bg-red-500/10 text-orange-500">Partial</Badge>;
      default:
        return <Badge variant="outline">Unknown</Badge>;
    }
  };

  // Format memory type for display
  const getMemoryIcon = (type: string) => {
    switch (type) {
      case 'fact':
        return <Database className="h-3 w-3 text-blue-500" />;
      case 'interaction':
        return <MessageSquare className="h-3 w-3 text-violet-500" />;
      case 'preference':
        return <Settings className="h-3 w-3 text-amber-500" />;
      case 'task':
        return <CheckCircle className="h-3 w-3 text-emerald-500" />;
      default:
        return <Clock className="h-3 w-3 text-gray-500" />;
    }
  };

  // Determines if we should show the date separator for messages
  const shouldShowDateSeparator = (currentMsg: Message, prevMsg: Message | undefined) => {
    if (!prevMsg) return true;
    
    const currentDate = new Date(currentMsg.timestamp);
    const prevDate = new Date(prevMsg.timestamp);
    
    return (
      currentDate.getDate() !== prevDate.getDate() ||
      currentDate.getMonth() !== prevDate.getMonth() ||
      currentDate.getFullYear() !== prevDate.getFullYear()
    );
  };

  // Get pinned messages
  const getPinnedMessages = () => {
    return messages.filter(msg => pinnedMessages.includes(msg.id));
  };

  // Render message content with paragraphs and formatting
  const renderMessageContent = (content: string) => {
    const sections = content.split('\n\n').filter(Boolean);

    return (
      <div className="prose prose-sm dark:prose-invert max-w-none">
        {sections.map((section, sectionIdx) => {
          const lines = section.split('\n');
          
          // Numbered list with title
          if (lines.length > 1 && lines[0].trim() && !lines[0].match(/^\d+\./)) {
            return (
              <div key={sectionIdx} className="mb-6 last:mb-0">
                {/* Title with gradient underline */}
                <h3 className="font-semibold text-foreground relative inline-block mb-4">
                  {lines[0]}
                  <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-primary/50 to-transparent" />
                </h3>
                
                {/* Enhanced list styling */}
                <div className="space-y-3 pl-1">
                  {lines.slice(1).map((line, lineIdx) => {
                    const match = line.match(/^(\d+)\.(.*)/);
                    if (match) {
                      return (
                        <div 
                          key={lineIdx} 
                          className="flex items-start gap-4 group p-2 rounded-lg
                            hover:bg-muted/50 transition-colors duration-200"
                        >
                          {/* Number bubble */}
                          <div className="flex-shrink-0 w-7 h-7 rounded-full bg-primary/10 
                            flex items-center justify-center border border-primary/20
                            group-hover:bg-primary/20 transition-colors duration-200"
                          >
                            <span className="text-sm font-medium text-primary">
                              {match[1]}
                            </span>
                          </div>
                          
                          {/* Content with hover effect */}
                          <div className="flex-1 pt-0.5">
                            {formatTextWithEmphasis(match[2].trim(), lineIdx)}
                          </div>
                        </div>
                      );
                    }
                    return <p key={lineIdx} className="text-muted-foreground">{formatTextWithEmphasis(line, lineIdx)}</p>;
                  })}
                </div>
              </div>
            );
          }

          // Regular content
          return (
            <div key={sectionIdx} className="mb-4 last:mb-0">
              {lines.map((line, lineIdx) => {
                // Bullet points
                if (line.trim().startsWith('•') || line.trim().startsWith('-')) {
                  return (
                    <div 
                      key={lineIdx} 
                      className="flex items-start gap-3 py-2 group hover:bg-muted/50 
                        rounded-lg transition-colors duration-200 px-2"
                    >
                      <span className="text-primary/70 group-hover:text-primary 
                        transition-colors duration-200">•</span>
                      <span className="flex-1">{line.replace(/^[•-]\s*/, '')}</span>
                    </div>
                  );
                }

                // Code blocks
                if (line.trim().startsWith('```')) {
                  const code = line.replace(/```/g, '').trim();
                  return (
                    <div key={lineIdx} className="my-3 relative group">
                      <pre className="bg-muted/50 p-4 rounded-lg overflow-x-auto border 
                        border-border/50 backdrop-blur-sm">
                        <code className="text-sm font-mono text-foreground/90">{code}</code>
                      </pre>
                      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 
                        transition-opacity duration-200">
                        {/* Add copy button if needed */}
                      </div>
                    </div>
                  );
                }

                // Single numbered items
                const match = line.match(/^(\d+)\.(.*)/);
                if (match) {
                  return (
                    <div 
                      key={lineIdx} 
                      className="flex items-start gap-4 group p-2 rounded-lg
                        hover:bg-muted/50 transition-colors duration-200"
                    >
                      <div className="flex-shrink-0 w-7 h-7 rounded-full bg-primary/10 
                        flex items-center justify-center border border-primary/20
                        group-hover:bg-primary/20 transition-colors duration-200"
                      >
                        <span className="text-sm font-medium text-primary">{match[1]}</span>
                      </div>
                      <div className="flex-1 pt-0.5">
                        {formatTextWithEmphasis(match[2].trim(), lineIdx)}
                      </div>
                    </div>
                  );
                }

                // Regular text with enhanced formatting
                return line.trim() && (
                  <p key={lineIdx} className="mb-3 last:mb-0 leading-relaxed 
                    text-foreground/90 hover:text-foreground transition-colors duration-200">
                    {formatTextWithEmphasis(line, lineIdx)}
                  </p>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  // Enhanced text formatting
  const formatTextWithEmphasis = (text: string, key: number) => {
    return (
      <>{text.split(/(\*\*.*?\*\*|\*.*?\*|\`.*?\`)/).map((part, pIdx) => {
        if (part.startsWith('**') && part.endsWith('**')) {
          return (
            <strong 
              key={`${key}-${pIdx}`} 
              className="font-semibold text-foreground bg-primary/5 px-1 rounded"
            >
              {part.slice(2, -2)}
            </strong>
          );
        }
        if (part.startsWith('*') && part.endsWith('*')) {
          return (
            <em 
              key={`${key}-${pIdx}`} 
              className="italic text-foreground/90 bg-muted/50 px-1 rounded"
            >
              {part.slice(1, -1)}
            </em>
          );
        }
        if (part.startsWith('`') && part.endsWith('`')) {
          return (
            <code 
              key={`${key}-${pIdx}`} 
              className="font-mono text-sm bg-muted px-1.5 py-0.5 rounded-md 
                border border-border/50"
            >
              {part.slice(1, -1)}
            </code>
          );
        }
        return part;
      })}</>
    );
  };

  // Update the message container styles
  const renderMessage = (message: Message) => {
    return (
      <div className={cn(
        "group relative flex gap-3 px-4 py-3.5",
        message.role === 'assistant' 
          ? 'bg-muted/50 border-y border-border/5' 
          : 'bg-background'
      )}>
        {/* Avatar */}
        <div className={cn(
          "flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-full",
          message.role === 'assistant' 
            ? "bg-primary/10 border border-primary/20" 
            : "bg-background border border-border"
        )}>
          {message.role === 'assistant' ? (
            <Bot className="h-4 w-4 text-primary" />
          ) : (
            <UserIcon className="h-4 w-4 text-foreground/70" />
          )}
        </div>

        {/* Message Content */}
        <div className={cn(
          "flex-1 space-y-2",
          message.role === 'assistant' ? "text-foreground/90" : "text-foreground"
        )}>
          {message.thinking ? (
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <Loader2 className="h-4 w-4 animate-spin" />
              {message.thinking}
            </div>
          ) : (
            renderMessageContent(message.content)
          )}
          
          {/* Suggestions */}
          {message.suggestions && message.suggestions.length > 0 && message.role === 'assistant' && (
            <div className="mt-3 flex flex-wrap gap-2">
              {message.suggestions.map((suggestion, i) => (
                <Button 
                  key={i} 
                  variant="secondary" 
                  size="sm" 
                  className="h-7 text-xs px-3 py-0 bg-background/50 hover:bg-background 
                    border border-border/50 rounded-full transition-colors"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  // Handle INFO commands
  const handleInfoCommand = async (command: string) => {
    try {
      if (command.toUpperCase() === 'AGC WEBSITE') {
        const infoMessage: Message = {
          id: generateId(),
          role: 'assistant',
          content: `The AGC (African Gold Coin) website provides detailed information about our gold-backed stablecoin. Key features include:
• 1 AGC = 10mg of certified physical gold
• Launching in Q2 2025
• Built on BNB Smart Chain
• Full regulatory compliance

Would you like to visit the AGC website? Click here: ${AGC_WEBSITE_URL}`,
          timestamp: new Date(),
          status: 'sent',
          suggestions: ['NAVIGATION:AGC WEBSITE', 'Tell me more about AGC'],
          action: {
            type: 'navigate',
            path: AGC_WEBSITE_URL
          }
        };
        
        setMessages(prev => [...prev, infoMessage]);
      }
    } catch (error) {
      console.error('Info command failed:', error);
      toast({
        title: "Command Failed",
        description: "Unable to process the info request",
        variant: "destructive",
        duration: 3000
      });
    }
  };

  return (
    <div className="fixed bottom-0 left-0 sm:right-6 sm:left-auto z-50 sm:bottom-6 mb-[4.5rem] sm:mb-0">
      <AnimatePresence>
        {chatState === 'open' && (
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            transition={{ type: "spring", stiffness: 400, damping: 30 }}
            className="w-full sm:w-[400px] h-[calc(100dvh-4rem)] sm:h-[600px] sm:max-h-[calc(100vh-6rem)]" // Adjusted height for mobile
          >
            <Card className="flex flex-col h-full rounded-none sm:rounded-xl border-0 sm:border shadow-2xl">
              <CardHeader className="px-6 py-4 border-b flex-row items-center justify-between space-y-0 bg-gradient-to-r from-primary/5 to-background/5">
                <div className="flex items-center gap-3.5">
                  <div className="relative">
                    <Avatar className="h-11 w-11 ring-2 ring-primary/20 shadow-md">
                      <AvatarImage src="/bot-avatar.png" />
                      <AvatarFallback className="bg-gradient-to-br from-primary/30 to-primary/10">
                        <Bot className="h-5 w-5 text-primary" />
                      </AvatarFallback>
                    </Avatar>
                    <span className="absolute bottom-0 right-0 w-3 h-3 rounded-full bg-emerald-500 border-2 border-background animate-pulse" />
                  </div>
                  <div>
                    <CardTitle className="text-base font-medium tracking-tight">
                      {userProfile.accountStatus === 'premium' ? (
                        <div className="flex items-center gap-1.5">
                          Evocash AI Agent 
                          <Badge 
                            variant="outline" 
                            className="h-5 px-2 bg-gradient-to-r from-amber-500/10 to-amber-400/5 border-amber-500/20 text-amber-500 flex items-center gap-1"
                          >
                            <Gem className="h-3.5 w-3.5" />
                            <span className="text-[10px] font-medium">PREMIUM</span>
                          </Badge>
                        </div>
                      ) : (
                        'EvoCash Agent'
                      )}
                    </CardTitle>
                    <p className="text-xs text-muted-foreground flex items-center gap-1.5">
                      {userProfile.accountStatus === 'premium' ? 'Premium Support' : 'AI-powered assistant'}
                    </p>
                  </div>
                </div>
                <div className="flex gap-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-9 w-9 rounded-full hover:bg-accent transition-colors duration-200"
                      >
                        <MoreHorizontal className="h-4.5 w-4.5" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-60 p-1 rounded-xl shadow-lg bg-background/95 backdrop-blur-md">
                      <DropdownMenuItem onClick={showProactiveSuggestion} className="cursor-pointer rounded-lg h-10">
                        <div className="p-1.5 bg-amber-500/10 rounded-full mr-3">
                          <Sparkles className="h-3.5 w-3.5 text-amber-500" />
                        </div>
                        Get AI Suggestion
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={handleClearChat} className="cursor-pointer rounded-lg h-10">
                        <div className="p-1.5 bg-blue-500/10 rounded-full mr-3">
                          <RefreshCw className="h-3.5 w-3.5 text-blue-500" />
                        </div>
                        Clear Chat History
                      </DropdownMenuItem>
                      <DropdownMenuSeparator className="my-1" />
                      <div className="p-3">
                        <div className="flex items-center justify-between mb-3">
                          <Label htmlFor="thinking-effect" className="text-sm flex items-center">
                            <div className="p-1.5 bg-indigo-500/10 rounded-full mr-2">
                              <Loader2 className="h-3.5 w-3.5 text-indigo-500" />
                            </div>
                            Show thinking process
                          </Label>
                          <Switch 
                            id="thinking-effect" 
                            checked={enableThinking}
                            onCheckedChange={setEnableThinking}
                            className="data-[state=checked]:bg-indigo-500"
                          />
                        </div>
                        <div className="flex items-center justify-between">
                          <Label htmlFor="typing-effect" className="text-sm flex items-center">
                            <div className="p-1.5 bg-emerald-500/10 rounded-full mr-2">
                              <MessageSquare className="h-3.5 w-3.5 text-emerald-500" />
                            </div>
                            Enable typing animation
                          </Label>
                          <Switch 
                            id="typing-effect" 
                            checked={enableTypingEffect}
                            onCheckedChange={setEnableTypingEffect}
                            className="data-[state=checked]:bg-emerald-500"
                          />
                        </div>
                      </div>
                      <DropdownMenuSeparator className="my-1" />
                      <DropdownMenuItem onClick={handleResetAgent} className="cursor-pointer rounded-lg h-10 text-red-500 hover:text-red-600">
                        <div className="p-1.5 bg-red-500/10 rounded-full mr-3">
                          <AlertCircle className="h-3.5 w-3.5 text-red-500" />
                        </div>
                        Reset Agent Completely
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-9 w-9 rounded-full hover:bg-accent transition-colors duration-200"
                    onClick={() => toggleChat('minimized')}
                  >
                    <Minimize className="h-4.5 w-4.5" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-9 w-9 rounded-full hover:bg-accent transition-colors duration-200"
                    onClick={() => toggleChat('closed')}
                  >
                    <X className="h-4.5 w-4.5" />
                  </Button>
                </div>
              </CardHeader>

              <Tabs value={activeTab} onValueChange={(v) => setActiveTab(v as ChatTab)} className="flex-1 flex flex-col overflow-hidden">
                <div className="border-b px-2 bg-background/30">
                  <TabsList className="bg-transparent h-12 w-full gap-1 p-1">
                    <TabsTrigger 
                      value="chat" 
                      className="data-[state=active]:bg-primary/10 data-[state=active]:text-primary rounded-xl border border-transparent data-[state=active]:border-primary/20 h-10 transition-all duration-200 hover:bg-accent/50 flex gap-2 items-center flex-1 justify-center"
                    >
                      <MessageSquare className="h-4 w-4" />
                      Chat
                    </TabsTrigger>
                    <TabsTrigger 
                      value="actions" 
                      className="data-[state=active]:bg-primary/10 data-[state=active]:text-primary rounded-xl border border-transparent data-[state=active]:border-primary/20 h-10 transition-all duration-200 hover:bg-accent/50 flex gap-2 items-center flex-1 justify-center"
                    >
                      <ZapIcon className="h-4 w-4" />
                      Actions
                      {pendingActions.length > 0 && (
                        <Badge variant="primary" className="h-5 min-w-[20px] flex items-center justify-center p-0 text-xs bg-primary/90 text-primary-foreground">
                          {pendingActions.length}
                        </Badge>
                      )}
                    </TabsTrigger>
                    <TabsTrigger 
                      value="memory" 
                      className="data-[state=active]:bg-primary/10 data-[state=active]:text-primary rounded-xl border border-transparent data-[state=active]:border-primary/20 h-10 transition-all duration-200 hover:bg-accent/50 flex gap-2 items-center flex-1 justify-center"
                    >
                      <Database className="h-4 w-4" />
                      Memory
                    </TabsTrigger>
                  </TabsList>
                </div>

                <TabsContent value="chat" className="flex-1 overflow-hidden">
                  <ScrollArea className="h-full">
                    <div className="flex flex-col p-4 gap-4">
                      {/* Pinned Messages */}
                      {pinnedMessages.length > 0 && (
                        <Collapsible className="w-full bg-gradient-to-r from-amber-500/5 to-background/10 border-b">
                          <CollapsibleTrigger className="flex items-center justify-between w-full py-3 px-5 text-sm hover:bg-background/20 transition-colors">
                            <div className="flex items-center gap-2.5">
                              <div className="bg-amber-500/10 p-1.5 rounded-full">
                                <Star className="h-3.5 w-3.5 text-amber-500" />
                              </div>
                              <span className="font-medium">Pinned Messages</span>
                              <Badge variant="secondary" className="h-5 px-2 text-xs bg-amber-500/15 text-amber-700 dark:text-amber-400">
                                {pinnedMessages.length}
                              </Badge>
                            </div>
                            <ChevronDown className="h-4 w-4 text-muted-foreground transition-transform duration-200 ui-expanded:rotate-180" />
                          </CollapsibleTrigger>
                          <CollapsibleContent className="border-t border-border/30">
                            <div className="px-5 py-3 space-y-2.5 max-h-[150px] overflow-auto">
                              {getPinnedMessages().map((msg) => (
                                <Card key={msg.id} className="p-3 bg-background/50 hover:bg-accent/10 transition-colors border border-border/50 shadow-sm rounded-xl">
                                  <div className="flex gap-2.5 items-start">
                                    <div 
                                      className={cn(
                                        "p-1.5 rounded-full flex-shrink-0",
                                        msg.role === 'user' ? "bg-primary/10" : "bg-secondary/10"
                                      )}
                                    >
                                      {msg.role === 'user' ? (
                                        <UserIcon className="h-3 w-3 text-primary" />
                                      ) : (
                                        <Bot className="h-3 w-3 text-secondary" />
                                      )}
                                    </div>
                                    <div className="flex-1 text-xs line-clamp-2">
                                      {msg.content}
                                    </div>
                                    <Button 
                                      variant="ghost" 
                                      size="icon" 
                                      className="h-7 w-7 opacity-50 hover:opacity-100 hover:bg-red-500/10 hover:text-red-500 rounded-full"
                                      onClick={() => togglePinnedMessage(msg.id)}
                                    >
                                      <X className="h-3.5 w-3.5" />
                                    </Button>
                                  </div>
                                </Card>
                              ))}
                            </div>
                          </CollapsibleContent>
                        </Collapsible>
                      )}

                      {hasProactiveSuggestion && (
                        <motion.div
                          initial={{ opacity: 0, y: 5 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="bg-gradient-to-r from-amber-500/15 to-amber-500/5 border border-amber-500/20 rounded-xl p-4 text-sm flex items-center gap-3 shadow-sm"
                        >
                          <div className="bg-amber-500/20 p-2 rounded-full">
                            <Sparkles className="h-4 w-4 text-amber-500" />
                          </div>
                          <div className="flex-1">
                            <p className="font-medium text-amber-700 dark:text-amber-400">Smart Suggestion</p>
                            <p className="text-muted-foreground">I have a personalized insight based on your recent activity.</p>
                          </div>
                          <Button 
                            size="sm" 
                            className="h-9 rounded-lg bg-amber-500/90 hover:bg-amber-500 text-white shadow-sm" 
                            onClick={showProactiveSuggestion}
                          >
                            View
                          </Button>
                        </motion.div>
                      )}

                      {messages.map((message, index) => {
                        const prevMessage = index > 0 ? messages[index - 1] : undefined;
                        const showDateSeparator = shouldShowDateSeparator(message, prevMessage);
                        
                        return (
                          <div key={message.id}>
                            {showDateSeparator && (
                              <div className="flex justify-center my-4">
                                <Badge variant="outline" className="font-normal text-xs bg-background px-3 py-1">
                                  {new Date(message.timestamp).toLocaleDateString('en-US', {
                                    weekday: 'short',
                                    month: 'short',
                                    day: 'numeric'
                                  })}
                                </Badge>
                              </div>
                            )}
                        
                            {message.role === 'system' ? (
                              <motion.div
                                initial={{ opacity: 0, y: 5 }}
                                animate={{ opacity: 1, y: 0 }}
                                className="flex justify-center my-2"
                              >
                                <Badge 
                                  variant="secondary" 
                                  className={cn(
                                    "font-normal text-xs px-3 py-1",
                                    message.content.includes("✓") ? "bg-emerald-500/10 text-emerald-500 border-emerald-500/20" : ""
                                  )}
                                >
                                  {message.content}
                                </Badge>
                              </motion.div>
                            ) : (
                              <motion.div
                                initial={{ opacity: 0, y: 5 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2 }}
                                className={cn(
                                  "flex gap-2.5 max-w-[95%] group",
                                  message.role === 'user' ? "ml-auto flex-row-reverse" : "mr-auto"
                                )}
                              >
                                {/* Avatar */}
                                <Avatar className={cn(
                                  "h-8 w-8 shrink-0 transition-transform",
                                  message.role === 'user' ? "group-hover:translate-x-1" : "group-hover:-translate-x-1"
                                )}>
                                  {message.role === 'user' ? (
                                    <AvatarFallback className="bg-primary text-primary-foreground">
                                      <UserIcon className="h-4 w-4" />
                                    </AvatarFallback>
                                  ) : (
                                    <>
                                      <AvatarImage src="/bot-avatar.png" />
                                      <AvatarFallback className="bg-secondary">
                                        <Bot className="h-4 w-4" />
                                      </AvatarFallback>
                                    </>
                                  )}
                                </Avatar>
                                
                                {/* Message Bubble */}
                                <div
                                  className={cn(
                                    "px-4 py-3 text-sm relative group shadow-sm max-w-full break-words",
                                    message.role === 'user' 
                                      ? "bg-gradient-to-br from-primary to-primary/90 text-primary-foreground rounded-2xl rounded-tr-sm" 
                                      : message.status === 'error'
                                        ? "bg-gradient-to-br from-red-500/15 to-red-500/10 text-foreground border border-red-500/20 rounded-2xl rounded-tl-sm" 
                                        : message.status === 'sending'
                                          ? "bg-gradient-to-br from-muted/90 to-muted/80 rounded-2xl rounded-tl-sm"
                                          : "bg-gradient-to-br from-muted/90 to-muted/80 rounded-2xl rounded-tl-sm"
                                  )}
                                >
                                  {/* Thinking Process */}
                                  {message.thinking && (
                                    <div className="flex items-center gap-2 text-xs opacity-80 mb-2 bg-background/40 py-1.5 px-2 rounded">
                                      <Loader2 className="h-3 w-3 animate-spin" />
                                      <span>{message.thinking}</span>
                                    </div>
                                  )}
                                  
                                  {/* Message Content */}
                                  <div className="relative">
                                    <div className="prose prose-sm dark:prose-invert max-w-none">
                                      {renderMessageContent(message.content)}
                                    </div>
                                    
                                    {message.status === 'sending' && !message.thinking && (
                                      <div className="mt-2 flex gap-1.5 items-center">
                                        <motion.span 
                                          className="w-1.5 h-1.5 rounded-full bg-foreground/50"
                                          animate={{ y: [0, -4, 0] }}
                                          transition={{ duration: 0.6, repeat: Infinity, delay: 0 }}
                                        />
                                        <motion.span 
                                          className="w-1.5 h-1.5 rounded-full bg-foreground/50"
                                          animate={{ y: [0, -4, 0] }}
                                          transition={{ duration: 0.6, repeat: Infinity, delay: 0.2 }}
                                        />
                                        <motion.span 
                                          className="w-1.5 h-1.5 rounded-full bg-foreground/50"
                                          animate={{ y: [0, -4, 0] }}
                                          transition={{ duration: 0.6, repeat: Infinity, delay: 0.4 }}
                                        />
                                      </div>
                                    )}
                                    
                                    {/* Message Actions */}
                                    <div className="absolute -right-1 -top-2 opacity-0 group-hover:opacity-100 transition-all scale-90 group-hover:scale-100 flex gap-1 backdrop-blur-sm bg-background/50 rounded-full p-0.5 shadow-lg">
                                      <TooltipProvider>
                                        <Tooltip>
                                          <TooltipTrigger asChild>
                                            <Button 
                                              variant="ghost" 
                                              size="icon" 
                                              className="h-6 w-6 rounded-full hover:bg-background"
                                              onClick={() => copyMessageContent(message.content)}
                                            >
                                              <Copy className="h-3 w-3" />
                                            </Button>
                                          </TooltipTrigger>
                                          <TooltipContent side="top" className="text-xs rounded-lg px-2 py-1">
                                            Copy message
                                          </TooltipContent>
                                        </Tooltip>
                                      </TooltipProvider>
                                      
                                      <TooltipProvider>
                                        <Tooltip>
                                          <TooltipTrigger asChild>
                                            <Button 
                                              variant="ghost" 
                                              size="icon" 
                                              className={cn(
                                                "h-6 w-6 rounded-full hover:bg-background",
                                                message.isPinned && "bg-amber-500/20 text-amber-500 hover:text-amber-500"
                                              )}
                                              onClick={() => togglePinnedMessage(message.id)}
                                            >
                                              <Star className="h-3 w-3" />
                                            </Button>
                                          </TooltipTrigger>
                                          <TooltipContent side="top" className="text-xs rounded-lg px-2 py-1">
                                            {message.isPinned ? "Unpin message" : "Pin message"}
                                          </TooltipContent>
                                        </Tooltip>
                                      </TooltipProvider>
                                    </div>
                                  </div>
                                  
                                  {/* Action Information */}
                                  {message.action?.type === 'navigate' && (
                                    <div className="mt-3 text-xs flex items-center bg-primary/10 py-1.5 px-2.5 rounded border border-primary/20">
                                      <Loader2 className="h-3.5 w-3.5 mr-2 text-primary animate-spin" />
                                      <span>Navigating to <span className="font-medium">{message.action.path}</span></span>
                                    </div>
                                  )}
                                  
                                  {message.action?.type === 'tool' && (
                                    <Popover>
                                      <PopoverTrigger asChild>
                                        <div className="mt-2.5 text-xs font-medium flex items-center border-t pt-2 cursor-pointer hover:text-primary">
                                          <ZapIcon className="h-3.5 w-3.5 mr-2 text-amber-500" />
                                          <span className="capitalize flex-1">
                                            {message.action.toolDetails?.type.replace(/_/g, ' ')} executed
                                          </span>
                                          <ChevronRight className="h-3.5 w-3.5" />
                                        </div>
                                      </PopoverTrigger>
                                      <PopoverContent side="left" className="w-64 p-0">
                                        <div className="p-3 border-b">
                                          <div className="font-medium text-sm flex items-center gap-2">
                                            <div className="bg-amber-500/20 p-1.5 rounded-full">
                                              <ZapIcon className="h-3.5 w-3.5 text-amber-500" />
                                            </div>
                                            <span className="capitalize">
                                              {message.action.toolDetails?.type.replace(/_/g, ' ')}
                                            </span>
                                          </div>
                                          <div className="text-xs text-muted-foreground mt-2">
                                            {formatActionStatus(message.action.toolDetails?.status || 'pending')}
                                          </div>
                                        </div>
                                        <div className="p-3">
                                          <h4 className="text-xs font-medium mb-2 flex items-center gap-1.5">
                                            <Settings className="h-3 w-3" /> Parameters
                                          </h4>
                                          <div className="space-y-1.5 text-xs">
                                            {message.action.toolDetails?.payload && 
                                              Object.entries(message.action.toolDetails.payload).map(([key, value]) => (
                                                <div key={key} className="flex justify-between gap-3 bg-muted/40 p-1.5 rounded">
                                                  <span className="text-muted-foreground capitalize">
                                                    {key.replace(/_/g, ' ')}:
                                                  </span>
                                                  <span className="font-medium truncate">{String(value)}</span>
                                                </div>
                                              ))
                                            }
                                          </div>
                                        </div>
                                        {message.action.toolDetails?.result && (
                                          <div className="p-3 border-t bg-muted/30">
                                            <h4 className="text-xs font-medium mb-2 flex items-center gap-1.5">
                                              <CheckCircle className="h-3 w-3 text-emerald-500" /> Result
                                            </h4>
                                            <div className="rounded bg-background/70 p-2 text-xs font-mono max-h-28 overflow-auto">
                                              <pre className="whitespace-pre-wrap break-all">
                                                {JSON.stringify(message.action.toolDetails.result, null, 1)}
                                              </pre>
                                            </div>
                                          </div>
                                        )}
                                      </PopoverContent>
                                    </Popover>
                                  )}
                                  
                                  {/* Suggestions */}
                                  {message.suggestions && message.suggestions.length > 0 && message.role === 'assistant' && (
                                    <div className="mt-3 flex flex-wrap gap-2">
                                      {message.suggestions.slice(0, 3).map((suggestion, i) => (
                                        <Button 
                                          key={i} 
                                          variant="secondary" 
                                          size="sm" 
                                          className="h-7 text-xs px-2.5 py-0 bg-background hover:bg-accent rounded-full"
                                          onClick={() => handleSuggestionClick(suggestion)}
                                        >
                                          {suggestion}
                                        </Button>
                                      ))}
                                    </div>
                                  )}
                                  
                                  {/* Timestamp */}
                                  <div className="text-[10px] opacity-60 mt-2 text-right flex items-center justify-end gap-1.5">
                                    {message.isPinned && (
                                      <Star className="h-2.5 w-2.5 text-amber-500" />
                                    )}
                                    {formatTime(message.timestamp)}
                                  </div>
                                </div>
                              </motion.div>
                            )}
                          </div>
                        );
                      })}

                      {isTyping && !isThinking && (
                        <motion.div 
                          initial={{ opacity: 0, y: 5 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="flex gap-3 max-w-[92%] mr-auto"
                        >
                          <Avatar className="h-8 w-8">
                            <AvatarImage src="/bot-avatar.png" />
                            <AvatarFallback className="bg-secondary">
                              <Bot className="h-4 w-4" />
                            </AvatarFallback>
                          </Avatar>
                          <div className="px-4 py-3 rounded-2xl bg-muted">
                            <div className="flex gap-1.5 items-center">
                              <motion.span 
                                className="w-2 h-2 rounded-full bg-foreground/50"
                                animate={{ y: [0, -4, 0] }}
                                transition={{ duration: 0.6, repeat: Infinity, delay: 0 }}
                              />
                              <motion.span 
                                className="w-2 h-2 rounded-full bg-foreground/50"
                                animate={{ y: [0, -4, 0] }}
                                transition={{ duration: 0.6, repeat: Infinity, delay: 0.2 }}
                              />
                              <motion.span 
                                className="w-2 h-2 rounded-full bg-foreground/50"
                                animate={{ y: [0, -4, 0] }}
                                transition={{ duration: 0.6, repeat: Infinity, delay: 0.4 }}
                              />
                            </div>
                          </div>
                        </motion.div>
                      )}
                      
                      <div ref={messagesEndRef} />
                    </div>
                  </ScrollArea>
                </TabsContent>
                
                {/* Actions Tab */}
                <TabsContent value="actions" className="flex-1 overflow-hidden">
                  <ScrollArea className="h-full">
                    <div className="flex flex-col gap-3 p-5">
                      <div className="flex items-center justify-between mb-2">
                        <h3 className="text-sm font-medium">Recent Actions</h3>
                        <Badge variant="outline" className="text-xs font-normal">
                          {pendingActions.length} total
                        </Badge>
                      </div>
                      
                      {pendingActions.length === 0 ? (
                        <div className="flex flex-col items-center justify-center h-64 text-center text-muted-foreground">
                          <div className="bg-muted/40 p-4 rounded-full mb-3">
                            <Layout className="h-8 w-8 opacity-40" />
                          </div>
                          <p className="text-base font-medium">No actions performed yet</p>
                          <p className="text-sm mt-2 max-w-xs">
                            When your agent performs actions on your behalf, they'll appear here for your reference
                          </p>
                          <Button 
                            variant="outline" 
                            size="sm" 
                            className="mt-5 rounded-full"
                            onClick={showProactiveSuggestion}
                          >
                            <Sparkles className="mr-2 h-4 w-4 text-amber-500" />
                            Get Suggestion
                          </Button>
                        </div>
                      ) : (
                        <div className="space-y-4">
                          {pendingActions.map((action, i) => (
                            <Card key={i} className="overflow-hidden bg-muted/30">
                              <div className="p-3 border-b bg-background/40">
                                <div className="flex justify-between items-center">
                                  <div className="flex items-center gap-2">
                                    <div className="bg-amber-500/10 p-1.5 rounded-full">
                                      <ZapIcon className="h-4 w-4 text-amber-500" />
                                    </div>
                                    <div className="font-medium text-sm capitalize">
                                      {action.type.replace(/_/g, ' ')}
                                    </div>
                                  </div>
                                  {formatActionStatus(action.status)}
                                </div>
                              </div>
                              
                              <div className="p-3">
                                <h4 className="text-xs font-semibold mb-2 text-muted-foreground">PARAMETERS</h4>
                                <div className="text-sm space-y-2 mb-3">
                                  {Object.entries(action.payload).map(([key, value]) => (
                                    <div key={key} className="flex items-center justify-between bg-background/50 p-2 rounded">
                                      <span className="text-muted-foreground capitalize text-xs">{key.replace(/_/g, ' ')}:</span>
                                      <span className="font-medium truncate max-w-[180px]">{String(value)}</span>
                                    </div>
                                  ))}
                                </div>
                                
                                {action.result && (
                                  <>
                                    <h4 className="text-xs font-semibold mb-2 text-muted-foreground">RESULT</h4>
                                    <Collapsible className="w-full border rounded bg-background/70">
                                      <CollapsibleTrigger className="flex w-full items-center justify-between p-2.5 text-xs">
                                        <span className="font-medium">View Result Data</span>
                                        <ChevronDown className="h-3.5 w-3.5" />
                                      </CollapsibleTrigger>
                                      <CollapsibleContent>
                                        <div className="p-2.5 border-t">
                                          <pre className="text-xs font-mono whitespace-pre-wrap break-all">
                                            {JSON.stringify(action.result, null, 2)}
                                          </pre>
                                        </div>
                                      </CollapsibleContent>
                                    </Collapsible>
                                  </>
                                )}

                                <div className="text-xs text-muted-foreground mt-3 text-right">
                                  {new Date().toLocaleDateString('en-US', {
                                    month: 'short',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    hour12: true
                                  })}
                                </div>
                              </div>
                            </Card>
                          ))}
                        </div>
                      )}
                    </div>
                  </ScrollArea>
                </TabsContent>
                
                {/* Memory Tab */}
                <TabsContent value="memory" className="flex-1 overflow-hidden">
                  <ScrollArea className="h-full">
                    <div className="flex flex-col gap-4 p-4 sm:p-5">
                      <div className="flex items-center justify-between mb-1">
                        <h3 className="text-sm font-medium flex items-center gap-2">
                          <Database className="h-4 w-4 text-primary/70" />
                          Agent Memory
                        </h3>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button 
                                variant="ghost" 
                                size="icon" 
                                className="h-7 w-7 rounded-full"
                                onClick={() => setMemories(geminiService.getMemory())}
                              >
                                <RefreshCw className="h-3.5 w-3.5" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p className="text-xs">Refresh memory</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      
                      {/* User Profile */}
                      <Card className="overflow-hidden bg-muted/30">
                        <div className="p-3 border-b bg-background/40">
                          <div className="flex items-center gap-2">
                            <div className="bg-blue-500/10 p-1.5 rounded-full">
                              <UserIcon className="h-4 w-4 text-blue-500" />
                            </div>
                            <h4 className="text-sm font-medium">User Profile</h4>
                          </div>
                        </div>
                        <div className="p-3">
                          <div className="text-sm space-y-2.5">
                            <div className="flex justify-between items-center">
                              <span className="text-muted-foreground text-xs">Name:</span>
                              <span className="font-medium">{userProfile.name || 'Guest'}</span>
                            </div>
                            <div className="flex justify-between items-center">
                              <span className="text-muted-foreground text-xs">Email:</span>
                              <span className="font-medium">{userProfile.email || 'Not provided'}</span>
                            </div>
                            <div className="flex justify-between items-center">
                              <span className="text-muted-foreground text-xs">Account:</span>
                              <Badge className="font-normal text-xs bg-primary/10 text-primary capitalize">
                                {userProfile.accountStatus}
                              </Badge>
                            </div>
                            {userProfile.preferences?.favoriteAssets && (
                              <div className="flex justify-between items-center">
                                <span className="text-muted-foreground text-xs">Favorites:</span>
                                <div className="flex gap-1.5">
                                  {userProfile.preferences.favoriteAssets.map(asset => (
                                    <Badge key={asset} variant="secondary" className="text-xs py-0 h-5">
                                      {asset}
                                    </Badge>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Card>
                      
                      {/* Recent Memories */}
                      <div className="text-xs font-medium text-muted-foreground mt-2 mb-2 flex items-center gap-2">
                        <Clock className="h-3.5 w-3.5" /> RECENT MEMORIES
                      </div>
                      
                      {memories.length === 0 ? (
                        <Card className="p-8 flex flex-col items-center justify-center text-center text-muted-foreground bg-muted/30">
                          <div className="bg-muted/40 p-4 rounded-full mb-3">
                            <Database className="h-8 w-8 opacity-40" />
                          </div>
                          <p className="text-base font-medium">No memories stored yet</p>
                          <p className="text-sm mt-2 max-w-xs">
                            As you interact with the agent, it will collect memories to provide more personalized assistance
                          </p>
                        </Card>
                      ) : (
                        <div className="space-y-3">
                          {memories
                            .slice()
                            .reverse()
                            .slice(0, 10)
                            .map((memory, i) => (
                              <Card key={i} className="overflow-hidden bg-muted/30 hover:bg-muted/60 transition-colors">
                                <div className="p-2.5 border-b bg-background/40">
                                  <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                      <div className={cn(
                                        "p-1 rounded-full",
                                        memory.type === 'fact' ? "bg-blue-500/10" : 
                                        memory.type === 'interaction' ? "bg-violet-500/10" :
                                        memory.type === 'preference' ? "bg-amber-500/10" :
                                        "bg-emerald-500/10"
                                      )}>
                                        {getMemoryIcon(memory.type)}
                                      </div>
                                      <div className="text-xs font-medium capitalize">
                                        {memory.type}
                                      </div>
                                    </div>
                                    <Badge variant="outline" className="text-[10px] h-4 px-1.5 font-normal capitalize">
                                      {memory.source}
                                    </Badge>
                                  </div>
                                </div>
                                <div className="p-2.5">
                                  <div className="text-sm">
                                    {memory.content}
                                  </div>
                                  <div className="text-[10px] text-muted-foreground mt-2 text-right">
                                    {formatDate(new Date(memory.timestamp))}
                                  </div>
                                </div>
                              </Card>
                            ))
                          }
                          
                          {memories.length > 10 && (
                            <Button variant="outline" className="w-full text-xs h-8">
                              Show {memories.length - 10} more memories
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </ScrollArea>
                </TabsContent>
              </Tabs>

              {/* Input Area */}
              <CardFooter className="p-4 border-t bg-gradient-to-b from-background/50 to-background/80">
                <form onSubmit={(e) => { e.preventDefault(); handleSend(); }} className="flex gap-2 w-full items-center">
                  <div className="relative flex-1 group">
                    <Input
                      ref={inputRef}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="Type your message..."
                      className="flex-1 bg-muted/30 border-muted/20 
                        group-focus-within:border-primary/40 group-hover:border-primary/20
                        rounded-2xl py-6 px-4 pr-10 
                        focus-visible:ring-1 focus-visible:ring-primary/30 focus-visible:ring-offset-0 
                        min-h-[52px] shadow-sm 
                        transition-all duration-200
                        placeholder:text-muted-foreground/70
                        text-base md:text-sm
                        disabled:opacity-60 disabled:cursor-not-allowed
                        hover:bg-muted/40"
                      disabled={isLoading}
                      autoComplete="off"
                      spellCheck="false"
                    />
                    {isLoading ? (
                      <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                        <div className="animate-pulse bg-primary/15 rounded-full p-1.5">
                          <Loader2 className="h-4 w-4 text-primary animate-spin" />
                        </div>
                      </div>
                    ) : input.trim() ? (
                      <div className="absolute right-4 top-1/2 transform -translate-y-1/2 opacity-50 group-hover:opacity-100 transition-opacity">
                        <div className="rounded-full p-1.5 bg-muted/50 group-hover:bg-muted/70 transition-colors">
                          <Send className="h-4 w-4 text-foreground/70" />
                        </div>
                      </div>
                    ) : (
                      <div className="absolute right-4 top-1/2 transform -translate-y-1/2 opacity-40">
                        <div className="rounded-full p-1.5">
                          <MessageSquare className="h-4 w-4" />
                        </div>
                      </div>
                    )}
                  </div>
                                      <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button 
                          type="submit" 
                          size="icon"
                          className="rounded-full shrink-0 h-10 w-10 
                            bg-gradient-to-br from-primary to-primary/85 
                            hover:from-primary/95 hover:to-primary/75 
                            hover:scale-105 hover:shadow-xl hover:ring-2 hover:ring-primary/20
                            active:scale-95
                            transform transition-all duration-200
                            disabled:opacity-50 disabled:pointer-events-none
                            disabled:hover:scale-100 disabled:hover:shadow-lg"
                          disabled={isLoading || !input.trim()}
                        >
                          <Send className="h-4 w-4 text-primary-foreground transition-transform duration-200 group-hover:translate-x-0.5" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top" className="bg-background/90 backdrop-blur-sm border border-border/50 rounded-lg">
                        <p className="text-xs font-medium">{isLoading ? "Processing..." : "Send message"}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </form>
              </CardFooter>
            </Card>
          </motion.div>
        )}

        {chatState === 'minimized' && (
          <motion.div
            initial={{ opacity: 0, y: 10, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 10, scale: 0.95 }}
            transition={{ type: "spring", stiffness: 400, damping: 30 }}
            className="w-full sm:w-auto max-w-full sm:max-w-none mx-4 sm:mx-0"
          >
            <Card 
              className="px-4 py-3 shadow-lg flex items-center gap-3 cursor-pointer hover:bg-accent/50 transition-colors border-none bg-background/95 backdrop-blur rounded-2xl"
              onClick={() => toggleChat('open')}
            >
              <Avatar className="h-9 w-9">
                <AvatarImage src="/bot-avatar.png" />
                <AvatarFallback className="bg-primary/10">
                  <Bot className="h-4 w-4 text-primary" />
                </AvatarFallback>
              </Avatar>
              <div className="flex-1">
                <h3 className="text-sm font-medium">Evocash agent</h3>
                <p className="text-xs text-muted-foreground">Click to expand chat</p>
              </div>
              <div className="relative">
                {hasUnreadMessages && (
                  <span className="h-2.5 w-2.5 rounded-full bg-primary absolute -top-1 -right-1 ring-2 ring-background" />
                )}
                {hasProactiveSuggestion && (
                  <Sparkles className="h-4 w-4 text-amber-400" />
                )}
              </div>
            </Card>
          </motion.div>
        )}

        {chatState === 'closed' && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="sm:ml-6 ml-4 mb-4"  // Added margin-left instead of margin-right
          >
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    size="icon"
                    className={cn(
                      "h-12 w-12 sm:h-14 sm:w-14 rounded-full shadow-lg relative group",
                      hasUnreadMessages || hasProactiveSuggestion ? "bg-primary" : "bg-primary/90"
                    )}
                    onClick={() => toggleChat('open')}
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    <Bot className="h-5 w-5 sm:h-6 sm:w-6 text-primary-foreground 
                      transform transition-transform duration-300 group-hover:scale-110" />
                    
                    <AnimatePresence>
                      {(hasUnreadMessages || hasProactiveSuggestion) && (
                        <motion.div
                          initial={{ scale: 0.5, y: 5, opacity: 0 }}
                          animate={{ scale: 1, y: 0, opacity: 1 }}
                          exit={{ scale: 0.5, y: 5, opacity: 0 }}
                          transition={{ type: "spring", stiffness: 500, damping: 25 }}
                          className="absolute -top-1 -right-1"
                        >
                          {hasProactiveSuggestion ? (
                            <div className="h-5 w-5 rounded-full bg-amber-400 ring-[3px] ring-background shadow-md flex items-center justify-center">
                              <Sparkles className="h-3 w-3 text-amber-900" />
                            </div>
                          ) : (
                            <div className="h-5 w-5 rounded-full bg-red-500 ring-[3px] ring-background shadow-md flex items-center justify-center">
                              <span className="text-[10px] font-bold text-white">!</span>
                            </div>
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="left" className="bg-background/90 backdrop-blur-sm border border-border/50 rounded-lg">
                  <p className="text-sm font-medium">
                    {hasProactiveSuggestion 
                      ? "Evocash agent (Suggestion available)" 
                      : hasUnreadMessages 
                        ? "Evocash agent (New messages)" 
                        : "Evocash agent"}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
