import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Dashboard from '@/components/layout/Dashboard';
import { Card, CardContent } from '@/components/ui/card';
import { Calendar, ArrowRight, Tag, X, Clock, Share2, Filter } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "@/components/ui/dialog";
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Separator } from '@/components/ui/separator';
import { Badge } from '@/components/ui/badge';

interface NewsItem {
  id: number;
  category: string;
  date: string;
  title: string;
  excerpt: string;
  content: string;
  readTime?: string;
  author?: string;
  authorRole?: string;
  authorAvatar?: string;
  featuredImage?: string;
}

const News = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedNews, setSelectedNews] = useState<NewsItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredNewsItems, setFilteredNewsItems] = useState<NewsItem[]>(newsItems);
  const [activeCategory, setActiveCategory] = useState<string>("All Updates");

  useEffect(() => {
    document.title = 'EvoCash | News & Updates';
    
    // Check if there's a news ID in the URL parameters
    const newsId = searchParams.get('id');
    if (newsId) {
      const newsItem = newsItems.find(item => item.id === parseInt(newsId));
      if (newsItem) {
        setSelectedNews(newsItem);
        setIsModalOpen(true);
      }
    }

    // Check if there's a category in the URL parameters
    const category = searchParams.get('category');
    if (category) {
      handleCategoryFilter(category);
    }
  }, [searchParams]);

  const handleNewsClick = (newsItem: NewsItem) => {
    setSelectedNews(newsItem);
    setIsModalOpen(true);
    // Update URL with the news ID
    setSearchParams(params => {
      params.set('id', newsItem.id.toString());
      return params;
    });
  };

  const handleCategoryFilter = (category: string) => {
    setActiveCategory(category);
    
    if (category === "All Updates") {
      setFilteredNewsItems(newsItems);
    } else {
      const filtered = newsItems.filter(item => item.category === category);
      setFilteredNewsItems(filtered);
    }

    // Update URL with the category
    setSearchParams(params => {
      params.set('category', category);
      if (params.has('id')) {
        params.delete('id');
      }
      return params;
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // Remove news ID from URL
    setSearchParams(params => {
      if (params.has('id')) {
        params.delete('id');
      }
      return params;
    });
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <Dashboard>
      <div className="flex flex-col space-y-8">
        {/* Enhanced Hero Section */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/20 via-primary/10 to-background p-10 md:p-16">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
          {/* Add floating elements for visual interest */}
          <div className="absolute top-20 right-10 w-24 h-24 bg-primary/10 rounded-full blur-xl animate-pulse" />
          <div className="absolute bottom-10 left-10 w-32 h-32 bg-primary/5 rounded-full blur-xl animate-pulse [animation-delay:2s]" />
          <div className="relative z-10 max-w-2xl">
            <h1 className="text-4xl md:text-5xl font-bold tracking-tight mb-4 animate-fade-up">
              Development Updates & Insights
            </h1>
            <p className="text-xl text-muted-foreground animate-fade-up [--animation-delay:200ms] md:pr-8">
              Track our progress as we prepare to revolutionize asset ownership through blockchain technology.
            </p>
          </div>
        </section>

        {/* Enhanced Categories Section */}
        <section className="sticky top-0 z-10 bg-background/80 backdrop-blur-md pt-safe-top -mt-safe-top pb-4 px-4 -mx-4 border-b">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">Categories</h2>
            <div className="flex items-center gap-2">
              <Filter className="h-4 w-4 text-primary" />
              <span className="text-sm text-muted-foreground">Filter by:</span>
            </div>
          </div>
          <div className="flex flex-wrap gap-3">
            {categories.map((category) => (
              <Badge 
                key={category}
                variant={activeCategory === category ? "default" : "outline"}
                className={`px-4 py-2 rounded-full text-sm font-medium cursor-pointer transition-all hover:scale-105 ${
                  activeCategory === category 
                    ? "bg-primary text-primary-foreground shadow-md" 
                    : "hover:bg-primary/10"
                }`}
                onClick={() => handleCategoryFilter(category)}
              >
                {category}
              </Badge>
            ))}
          </div>
        </section>

        {/* Enhanced Featured News */}
        {activeCategory === "All Updates" && (
          <section className="grid grid-cols-1 gap-6">
            <Card className="group hover:shadow-lg transition-all duration-300 bg-gradient-to-br from-primary/5 to-background overflow-hidden">
              <CardContent className="p-8">
                <div className="flex flex-col md:flex-row gap-8">
                  <div className="flex-1">
                    <div className="flex items-center gap-2 text-primary mb-3">
                      <Tag className="h-4 w-4" />
                      <span className="text-sm font-medium">Launch Update</span>
                    </div>
                    <h2 className="text-2xl md:text-3xl font-bold mb-4 group-hover:text-primary transition-colors">
                      EvoCash Platform Launch Countdown Begins
                    </h2>
                    <p className="text-muted-foreground text-lg mb-6">
                      With final security audits underway and regulatory approvals in progress, we're excited to announce our anticipated launch timeline for Q3 2025.
                    </p>
                    <div className="flex items-center gap-6 text-muted-foreground mb-6">
                      <div className="flex items-center gap-2">
                        <Calendar className="h-4 w-4" />
                        <span>March 10, 2025</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4" />
                        <span>3 min read</span>
                      </div>
                    </div>
                    <Button 
                      variant="default"
                      className="group-hover:translate-x-2 transition-transform"
                      onClick={() => {
                        const featuredNews = {
                          id: 0,
                          category: "Launch Update",
                          date: "2025-03-10",
                          title: "EvoCash Platform Launch Countdown Begins",
                          excerpt: "With final security audits underway and regulatory approvals in progress, we're excited to announce our anticipated launch timeline for Q3 2025.",
                          content: `We are thrilled to announce that the EvoCash platform launch countdown has officially begun! With final security audits underway and regulatory approvals in progress, we're on track for our anticipated launch in Q3 2025.

                      The journey to revolutionize asset ownership through blockchain technology has been incredible, and we're nearly ready to share it with the world.
                      
                      Stay tuned for more updates as we approach this exciting milestone!`,
                          readTime: "3 min read",
                          author: "",
                          authorRole: "CEO "
                        };
                        setSelectedNews(featuredNews);
                        setIsModalOpen(true);
                        setSearchParams(params => {
                          params.set('id', '0');
                          return params;
                        });
                      }}
                    >
                      Read announcement <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                    </Button>
                  </div>
                  <div className="hidden md:block w-1/3 bg-primary/5 rounded-xl overflow-hidden">
                    <div className="aspect-square relative">
                      <img
                        src="https://images.unsplash.com/photo-1501139083538-0139583c060f?auto=format&fit=crop&q=80"
                        alt="EvoCash Platform Launch"
                        className="absolute inset-0 w-full h-full object-cover"
                        loading="lazy"
                      />
                      <div className="absolute inset-0 bg-gradient-to-br from-primary/20 to-primary/5 mix-blend-overlay" />
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </section>
        )}

        {/* Enhanced News Grid */}
        <section className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ${filteredNewsItems.length === 0 ? 'min-h-[300px]' : ''}`}>
          {filteredNewsItems.length > 0 ? (
            filteredNewsItems.map((item) => (
              <Card 
                key={item.id} 
                className="group hover:shadow-lg transition-all duration-300 cursor-pointer overflow-hidden"
                onClick={() => handleNewsClick(item)}
              >
                <CardContent className="p-6">
                  <div className="flex items-center gap-2 text-primary mb-3">
                    <Tag className="h-4 w-4" />
                    <span className="text-sm font-medium">{item.category}</span>
                  </div>
                  <h3 className="font-semibold text-xl mb-3 group-hover:text-primary transition-colors">
                    {item.title}
                  </h3>
                  <p className="text-muted-foreground mb-4 line-clamp-3">{item.excerpt}</p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2 text-muted-foreground">
                      <Calendar className="h-4 w-4" />
                      <span>{formatDate(item.date)}</span>
                    </div>
                    <ArrowRight className="h-4 w-4 text-primary transform group-hover:translate-x-2 transition-transform" />
                  </div>
                </CardContent>
              </Card>
            ))
          ) : (
            <div className="col-span-full flex flex-col items-center justify-center text-center py-16">
              <div className="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mb-4">
                <Tag className="w-8 h-8 text-primary/70" />
              </div>
              <h3 className="text-xl font-medium mb-2">No news in this category yet</h3>
              <p className="text-muted-foreground mb-6">We'll be adding more updates in this category soon!</p>
              <Button 
                variant="outline" 
                onClick={() => handleCategoryFilter("All Updates")}
              >
                View all updates
              </Button>
            </div>
          )}
        </section>

        {/* News Modal */}
        <Dialog open={isModalOpen} onOpenChange={handleCloseModal}>
          <DialogContent className="max-w-4xl h-[85vh] p-0 gap-0">
            <DialogHeader className="sticky top-0 z-20 bg-background/80 backdrop-blur-sm border-b px-6 py-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 text-primary">
                  <Tag className="h-4 w-4" />
                  <span className="text-sm font-medium">{selectedNews?.category}</span>
                </div>
                <div className="flex items-center gap-4">
                  <Button
                    variant="ghost"
                    size="icon"
                    className="hover:bg-muted/50"
                    onClick={handleCloseModal}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </DialogHeader>
            
            <ScrollArea className="h-full">
              {selectedNews && (
                <article className="px-6 py-8">
                  {/* Header */}
                  <div className="max-w-3xl mx-auto">
                    <h1 className="text-4xl font-bold tracking-tight mb-6">
                      {selectedNews.title}
                    </h1>
                    
                    <div className="flex items-center gap-6 text-muted-foreground mb-8">
                      <div className="flex items-center gap-2">
                        <Calendar className="h-4 w-4" />
                        <span>{formatDate(selectedNews.date)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4" />
                        <span>{selectedNews.readTime || '5 min read'}</span>
                      </div>
                    </div>

                    {/* Author Info */}
                    {selectedNews.author && (
                      <div className="flex items-center gap-4 mb-8">
                        <div className="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center">
                          {selectedNews.authorAvatar ? (
                            <img 
                              src={selectedNews.authorAvatar} 
                              alt={selectedNews.author}
                              className="w-full h-full rounded-full object-cover"
                            />
                          ) : (
                            <span className="text-xl font-semibold text-primary">
                              {selectedNews.author[0]}
                            </span>
                          )}
                        </div>
                        <div>
                          <div className="font-medium">{selectedNews.author}</div>
                          <div className="text-sm text-muted-foreground">
                            {selectedNews.authorRole || 'EvoCash Team'}
                          </div>
                        </div>
                      </div>
                    )}

                    <Separator className="my-8" />

                    {/* Content */}
                    <div className="prose prose-lg dark:prose-invert max-w-none">
                      {selectedNews.content.split('\n\n').map((paragraph, index) => (
                        <p 
                          key={index} 
                          className="text-muted-foreground leading-relaxed mb-6"
                        >
                          {paragraph.trim()}
                        </p>
                      ))}
                    </div>

                    {/* Footer */}
                    <Separator className="my-8" />
                    <div className="flex items-center justify-between">
                      <div className="flex gap-2">
                        <Button 
                          variant="outline" 
                          size="sm"
                          onClick={() => {
                            handleCloseModal();
                            handleCategoryFilter(selectedNews.category);
                          }}
                        >
                          #{selectedNews.category.toLowerCase().replace(/\s+/g, '')}
                        </Button>
                        <Button variant="outline" size="sm">
                          #evocash
                        </Button>
                      </div>
                      <Button 
                        variant="ghost" 
                        size="icon" 
                        onClick={() => {
                          // Copy the URL to clipboard
                          const url = new URL(window.location.href);
                          url.searchParams.set('id', selectedNews.id.toString());
                          navigator.clipboard.writeText(url.toString());
                          // You could add a toast notification here
                        }}
                      >
                        <Share2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </article>
              )}
            </ScrollArea>
          </DialogContent>
        </Dialog>

        {/* Enhanced Newsletter Section */}
        <section className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/20 via-primary/10 to-background p-12">
          <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
          <div className="relative z-10 flex flex-col md:flex-row items-center justify-between gap-8">
            <div className="text-left md:w-2/3">
              <h2 className="text-2xl md:text-3xl font-bold mb-3">Stay Updated</h2>
              <p className="text-muted-foreground text-lg">
                Join our newsletter to receive development updates and early access opportunities.
              </p>
            </div>
            <Link 
              to="/signup" 
              className="inline-flex items-center gap-2 px-8 py-4 rounded-lg bg-primary text-primary-foreground hover:bg-primary/90 transition-all hover:scale-105"
            >
              Join Waitlist <ArrowRight className="h-4 w-4" />
            </Link>
          </div>
        </section>
      </div>
    </Dashboard>
  );
};

const categories = [
  "All Updates",
  "Development",
  "Partnerships",
  "Security",
  "Regulatory",
  "Community"
];

const newsItems: NewsItem[] = [
  {
    id: 1,
    category: "Development",
    date: "2025-03-20",
    title: "Smart Contract Audit Results",
    excerpt: "Independent security firm completes comprehensive audit of EvoCash smart contracts, marking a crucial milestone towards launch.",
    content: `We are pleased to announce the successful completion of our smart contract audit by leading security firm SecureChain Analytics. 
    
    Key Findings:
    • Zero critical vulnerabilities detected
    • All core functions validated for security
    • Compliance with industry best practices confirmed
    
    This milestone represents a crucial step towards our platform launch, reinforcing our commitment to security and reliability.
    
    The audit process included:
    1. Comprehensive code review
    2. Automated vulnerability scanning
    3. Manual penetration testing
    4. Gas optimization analysis
    
    Next Steps:
    • Implementation of recommended optimizations
    • Public release of audit report
    • Final testing phase initiation
    
    We remain committed to maintaining the highest security standards as we move forward with our launch preparations.`,
    readTime: "4 min read",
    author: "Sarah Chen",
    authorRole: "Lead Security Engineer",
  },
  {
    id: 2,
    category: "Partnerships",
    date: "2025-03-15",
    title: "Strategic Partnership with Major Gold Custodian",
    excerpt: "EvoCash partners with leading secure storage provider to ensure physical gold backing for AGC tokens.",
    content: `We are excited to announce a strategic partnership with GoldVault, a leading secure storage provider with over 20 years of experience in precious metals custody.

    This partnership is a cornerstone of our AGC token offering, ensuring that every digital token is backed by physical gold stored in high-security vaults across multiple jurisdictions.

    Key benefits of this partnership include:
    • 100% physical gold backing for all AGC tokens
    • Regular third-party audits to verify gold reserves
    • Insurance coverage for all stored assets
    • Geographical diversification of storage locations

    GoldVault's state-of-the-art security infrastructure includes 24/7 surveillance, biometric access controls, and comprehensive insurance coverage, providing our users with confidence that their digital assets have tangible, secure backing.

    This partnership represents a significant milestone in our mission to bridge traditional asset ownership with blockchain technology, offering unprecedented transparency and security.`,
    readTime: "5 min read",
    author: "Robert Kiyoshi",
    authorRole: "Partnerships Director",
  },
  {
    id: 3,
    category: "Regulatory",
    date: "2025-03-10",
    title: "Regulatory Framework Progress",
    excerpt: "Updates on our ongoing work with regulatory bodies to ensure compliant operations across key markets.",
    content: `We are pleased to provide an update on our regulatory compliance efforts as we prepare for our platform launch.

    Our legal and compliance teams have been working diligently with regulatory bodies across multiple jurisdictions to ensure that EvoCash operates in full compliance with all relevant laws and regulations.

    Recent developments include:
    • Successful registration as a Digital Asset Service Provider in the European Union
    • Advanced discussions with financial authorities in Singapore, Switzerland, and the UAE
    • Completion of AML/KYC framework implementation
    • Engagement with US regulators regarding pathway to compliant operations

    This comprehensive approach to regulatory compliance is fundamental to our mission of creating a trusted platform for tokenized asset ownership. By establishing clear regulatory frameworks from the outset, we aim to provide users with confidence in the longevity and legitimacy of our platform.

    We believe that embracing regulation, rather than avoiding it, is essential for mainstream adoption of blockchain-based asset ownership. Our proactive engagement with regulatory authorities positions EvoCash as a leader in compliant digital asset operations.

    We will continue to provide updates as we reach additional regulatory milestones in the coming months.`,
    readTime: "6 min read",
    author: "Elena Moretti",
    authorRole: "Chief Compliance Officer",
  },
  {
    id: 4,
    category: "Development",
    date: "2025-03-05",
    title: "Beta Testing Program Announcement",
    excerpt: "Details about our upcoming closed beta testing phase and how early supporters can participate.",
    content: `We are excited to announce the launch of our closed beta testing program, scheduled to begin next month.

    This critical phase in our development process will allow us to gather valuable user feedback and ensure the platform meets the highest standards of usability, security, and performance before our public launch.

    Beta testing will be conducted in three phases:
    1. Internal testing (already completed)
    2. Limited closed beta (starting April 2025)
    3. Expanded beta with early waitlist members (May 2025)

    Participants in our beta program will:
    • Gain early access to the EvoCash platform
    • Test core functionalities including wallet creation, AGC token transactions, and security features
    • Provide feedback on user experience and interface design
    • Help identify any potential issues before public release
    • Receive exclusive rewards for their participation

    To apply for the beta testing program, early supporters can join our waitlist and complete the beta tester application. Selected participants will be notified via email with instructions on how to access the platform.

    This beta testing phase represents an exciting milestone on our roadmap and brings us one step closer to our mission of revolutionizing asset ownership through blockchain technology.`,
    readTime: "4 min read",
    author: "David Park",
    authorRole: "Product Manager",
  },
  {
    id: 5,
    category: "Community",
    date: "2025-02-28",
    title: "Community Governance Structure",
    excerpt: "Introducing our planned approach to community-driven decision making and protocol governance.",
    content: "We are excited to introduce our community governance structure! This new approach will allow our community to have a greater say in the development and direction of EvoCash. Learn more about how you can get involved and contribute to the future of our platform.",
    readTime: "7 min read",
    author: "Maria Santos",
    authorRole: "Community Lead",
  },
  {
    id: 6,
    category: "Security",
    date: "2025-02-20",
    title: "Security Infrastructure Overview",
    excerpt: "Deep dive into our multi-layer security architecture and protection measures for user assets.",
    content: "We are committed to the security and protection of your assets! Our multi-layer security architecture is designed to provide you with the highest level of protection. Learn more about the measures we have in place to keep your assets safe.",
    readTime: "8 min read",
    author: "Alex Johnson",
    authorRole: "Chief Information Security Officer",
  }
];

export default News;
