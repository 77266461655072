
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { SignUpFormData } from './SignUpForm';

interface PersonalInfoFieldsProps {
  formData: SignUpFormData;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PersonalInfoFields = ({ formData, handleChange }: PersonalInfoFieldsProps) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <Label htmlFor="first-name-input">First Name</Label>
          <Input 
            id="first-name-input" 
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="space-y-2">
          <Label htmlFor="last-name-input">Last Name</Label>
          <Input 
            id="last-name-input" 
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      
      <div className="space-y-2">
        <Label htmlFor="email-input">Email</Label>
        <Input 
          id="email-input" 
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
    </>
  );
};
