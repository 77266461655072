import { useEffect } from 'react';
import { usePageTitle } from '@/contexts/PageTitleContext';

/**
 * Custom hook to set both document title and page title in navbar
 * @param title The title to set
 * @param description Optional description for meta tag
 */
export const usePageTitleEffect = (title: string, description?: string) => {
  const { setPageTitle } = usePageTitle();
  
  useEffect(() => {
    // Set document title with brand name
    document.title = `${title} | EvoCash - Digital Asset Platform`;
    
    // Update meta description if provided
    if (description) {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      }
    }
    
    // Set page title in navbar
    setPageTitle(title);
    
    return () => {
      setPageTitle('');
      // Reset meta description to default
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', 'EvoCash - Transform physical assets into digital tokens. Trade AGC gold-backed tokens, secure global transfers, and access innovative financial solutions.');
      }
    };
  }, [title, description, setPageTitle]);
}; 
