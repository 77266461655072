
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ActionButtonProps } from './types';

const ActionButton = ({ 
  icon: Icon, 
  label, 
  onClick, 
  disabled = false,
  variant = "ghost",
  isOpen
}: ActionButtonProps) => {
  const buttonClass = variant === "destructive" 
    ? `w-full justify-${!isOpen ? 'center' : 'start'} text-destructive hover:text-destructive hover:bg-destructive/10`
    : `w-full justify-${!isOpen ? 'center' : 'start'} text-muted-foreground hover:text-foreground`;

  if (!isOpen) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="sm"
              className={`${buttonClass} my-2 py-4`}
              onClick={onClick}
              disabled={disabled}
            >
              <Icon size={20} strokeWidth={1.5} />
            </Button>
          </TooltipTrigger>
          <TooltipContent side="right">{label}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <Button
      variant="ghost"
      size="sm"
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
    >
      <Icon size={20} strokeWidth={1.5} className={!isOpen ? '' : 'mr-2'} />
      {isOpen && <span>{label}</span>}
    </Button>
  );
};

export default ActionButton;
