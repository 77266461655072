
import { useEffect, useState, useRef } from 'react';
import Dashboard from '@/components/layout/Dashboard';
import { Card, CardContent } from '@/components/ui/card';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
  Shield,
  UserCircle,
  Database,
  Lock,
  Building,
  ScrollText,
  Settings,
  Mail,
  AlertCircle,
  ArrowRight
} from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';

interface Section {
  id: string;
  title: string;
  icon: React.ReactNode;
  description: string;
}

const sections: Section[] = [
  { 
    id: 'collection', 
    title: 'Information We Collect', 
    icon: <Database className="h-5 w-5" />,
    description: 'Learn about the types of data we collect and why'
  },
  { 
    id: 'usage', 
    title: 'How We Use Information', 
    icon: <Settings className="h-5 w-5" />,
    description: 'Understanding how your data helps us serve you better'
  },
  { 
    id: 'security', 
    title: 'Data Security', 
    icon: <Lock className="h-5 w-5" />,
    description: 'How we protect your personal information'
  },
  { 
    id: 'thirdparty', 
    title: 'Third-Party Services', 
    icon: <Building className="h-5 w-5" />,
    description: 'Our relationships with external service providers'
  },
  { 
    id: 'rights', 
    title: 'Your Rights', 
    icon: <UserCircle className="h-5 w-5" />,
    description: 'Understanding your data privacy rights'
  },
  { 
    id: 'changes', 
    title: 'Policy Changes', 
    icon: <ScrollText className="h-5 w-5" />,
    description: 'How we handle updates to this policy'
  },
  { 
    id: 'contact', 
    title: 'Contact Us', 
    icon: <Mail className="h-5 w-5" />,
    description: 'Get in touch about privacy concerns'
  },
];

const Privacy = () => {
  const [activeSection, setActiveSection] = useState<string>('collection');
  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    document.title = 'EvoCash | Privacy Policy';

    // Initialize section refs
    sections.forEach(section => {
      sectionRefs.current[section.id] = document.getElementById(section.id);
    });

    // Intersection Observer setup
    const observerOptions = {
      root: null,
      rootMargin: '-20% 0px -75% 0px',
      threshold: 0
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    Object.values(sectionRefs.current).forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  const scrollToSection = (sectionId: string) => {
    const element = sectionRefs.current[sectionId];
    if (element) {
      const headerOffset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Dashboard>
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-12"
        >
          <div className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/20 via-primary/10 to-background p-8 md:p-12">
            <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
            <div className="relative z-10">
              <div className="flex items-center gap-3 mb-6">
                <div className="p-3 bg-primary/10 rounded-xl">
                  <Shield className="h-8 w-8 text-primary" />
                </div>
                <div>
                  <h1 className="text-4xl font-bold tracking-tight">Privacy Policy</h1>
                  <p className="text-muted-foreground mt-1">
                    Last updated: {new Date().toLocaleDateString('en-US', { 
                      month: 'long', 
                      day: 'numeric', 
                      year: 'numeric' 
                    })}
                  </p>
                </div>
              </div>
              <p className="max-w-2xl text-lg text-muted-foreground">
                We are committed to protecting your privacy and ensuring the security of your personal information. 
                This policy explains how we collect, use, and safeguard your data.
              </p>
            </div>
          </div>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Sidebar Navigation */}
          <div className="lg:col-span-3">
            <div className="sticky top-24">
              <ScrollArea className="h-[calc(100vh-120px)]">
                <Card className="shadow-md">
                  <CardContent className="p-4">
                    <div className="flex items-center gap-2 mb-6">
                      <Shield className="h-5 w-5 text-primary" />
                      <h2 className="font-semibold">Quick Navigation</h2>
                    </div>
                    <nav className="space-y-2">
                      {sections.map((section) => (
                        <motion.button
                          key={section.id}
                          onClick={() => scrollToSection(section.id)}
                          className={cn(
                            "w-full text-left p-3 rounded-lg transition-all duration-200",
                            activeSection === section.id
                              ? "bg-primary text-primary-foreground shadow-sm"
                              : "hover:bg-muted",
                          )}
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.99 }}
                        >
                          <div className="flex items-center gap-3">
                            {section.icon}
                            <div>
                              <div className="font-medium">{section.title}</div>
                              <div className="text-xs text-muted-foreground/80 line-clamp-1">
                                {section.description}
                              </div>
                            </div>
                          </div>
                        </motion.button>
                      ))}
                    </nav>
                  </CardContent>
                </Card>
              </ScrollArea>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:col-span-9">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="space-y-12"
            >
              {/* Header Section */}
              <section className="mb-8">
                <h1 className="text-3xl font-bold tracking-tight">Privacy Policy</h1>
                <p className="text-muted-foreground mt-1">
                  Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                </p>
              </section>

              {/* Important Notice */}
              <Card className="bg-primary/5 border-primary/20">
                <CardContent className="p-4">
                  <div className="flex gap-3">
                    <AlertCircle className="h-5 w-5 text-primary shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium text-primary">Your Privacy Matters</h3>
                      <p className="text-sm text-muted-foreground mt-1">
                        This Privacy Policy explains how we collect, use, and protect your personal information. 
                        Please read it carefully to understand our practices regarding your data.
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Privacy Policy Sections */}
              {sections.map((section) => (
                <section
                  key={section.id}
                  id={section.id}
                  className="scroll-mt-24"
                  ref={el => sectionRefs.current[section.id] = el}
                >
                  <div className="flex items-center gap-3 mb-6">
                    <div className="p-2 bg-primary/10 rounded-lg">
                      {section.icon}
                    </div>
                    <h2 className="text-2xl font-bold">{section.title}</h2>
                  </div>
                  
                  <Card>
                    <CardContent className="p-6 prose prose-sm max-w-none dark:prose-invert">
                      {section.id === 'collection' && (
                        <>
                          <p>At EvoCash, we collect several types of information for various purposes to provide and improve our service to you. The types of data we collect include:</p>
                          <ul>
                            <li><strong>Personal Data:</strong> Name, email address, phone number, and address</li>
                            <li><strong>Usage Data:</strong> Information on how our Service is accessed and used</li>
                            <li><strong>KYC Information:</strong> Identity verification documents and information</li>
                            <li><strong>Transaction Data:</strong> Details about your transactions on our platform</li>
                            <li><strong>Technical Data:</strong> IP address, browser type, device information</li>
                            <li><strong>Communication Data:</strong> Your preferences for receiving information from us</li>
                          </ul>
                        </>
                      )}
                      {section.id === 'usage' && (
                        <>
                          <p>We use the collected data for various purposes:</p>
                          <ul>
                            <li><strong>Service Delivery:</strong> To provide and maintain our service</li>
                            <li><strong>Communication:</strong> To contact you and send important updates</li>
                            <li><strong>Improvement:</strong> To analyze and enhance our platform</li>
                            <li><strong>Security:</strong> To protect against fraud and unauthorized access</li>
                            <li><strong>Legal Compliance:</strong> To meet regulatory requirements</li>
                            <li><strong>Support:</strong> To provide customer assistance</li>
                          </ul>
                          <p>We process your data based on:</p>
                          <ul>
                            <li>Contract performance</li>
                            <li>Legal obligations</li>
                            <li>Legitimate business interests</li>
                            <li>Your consent</li>
                          </ul>
                        </>
                      )}
                      {section.id === 'security' && (
                        <>
                          <p>We implement robust security measures to protect your data:</p>
                          <ul>
                            <li><strong>Encryption:</strong> All data is encrypted in transit and at rest</li>
                            <li><strong>Access Controls:</strong> Strict authentication and authorization protocols</li>
                            <li><strong>Regular Audits:</strong> Continuous security assessments and updates</li>
                            <li><strong>Data Backups:</strong> Regular backups with secure storage</li>
                            <li><strong>Incident Response:</strong> Comprehensive breach detection and response procedures</li>
                          </ul>
                          <p className="mt-4">We regularly review and update our security practices to maintain the highest standards of data protection.</p>
                        </>
                      )}
                      {section.id === 'thirdparty' && (
                        <>
                          <p>We may share your data with trusted third parties:</p>
                          <ul>
                            <li><strong>Service Providers:</strong> Companies that assist in:</li>
                            <ul>
                              <li>Payment processing</li>
                              <li>Identity verification</li>
                              <li>Analytics services</li>
                              <li>Customer support</li>
                            </ul>
                            <li><strong>Legal Requirements:</strong> When required by law or regulation</li>
                            <li><strong>Business Transfers:</strong> In case of merger, acquisition, or asset sale</li>
                          </ul>
                          <p className="mt-4">All third parties are contractually obligated to protect your data and use it only for specified purposes.</p>
                        </>
                      )}
                      {section.id === 'rights' && (
                        <>
                          <p>You have several rights regarding your personal data:</p>
                          <ul>
                            <li><strong>Access:</strong> Request copies of your personal data</li>
                            <li><strong>Rectification:</strong> Correct inaccurate information</li>
                            <li><strong>Erasure:</strong> Request deletion of your data</li>
                            <li><strong>Restriction:</strong> Limit how we use your data</li>
                            <li><strong>Portability:</strong> Receive your data in a structured format</li>
                            <li><strong>Objection:</strong> Object to certain data processing</li>
                          </ul>
                          <p className="mt-4">To exercise these rights, contact our Data Protection Officer through our support channels.</p>
                        </>
                      )}
                      {section.id === 'changes' && (
                        <>
                          <p>We may update this Privacy Policy from time to time:</p>
                          <ul>
                            <li>Changes will be posted on this page</li>
                            <li>Significant changes will be notified via email</li>
                            <li>Previous versions will be archived</li>
                            <li>Continued use of our service implies acceptance of changes</li>
                          </ul>
                          <p className="mt-4">We recommend reviewing this policy periodically to stay informed about our data practices.</p>
                        </>
                      )}
                      {section.id === 'contact' && (
                        <>
                          <p>For any questions about this Privacy Policy:</p>
                          <ul>
                            <li><strong>Email:</strong> privacy@evocash.org</li>
                            <li><strong>Phone:</strong> +1 (555) 123-4567</li>
                            <li><strong>Address:</strong> 123 Privacy Street, Tech City, TC 12345</li>
                          </ul>
                          <p className="mt-4">Our Data Protection Officer can be reached at dpo@evocash.org</p>
                          <p className="mt-4">For urgent matters, our support team is available 24/7 through our platform's chat system.</p>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </section>
              ))}

              {/* Enhanced CTA Section */}
              <Card className="bg-primary/5 border-primary/20 mt-8">
                <CardContent className="p-8">
                  <div className="flex flex-col md:flex-row items-center justify-between gap-6">
                    <div className="flex-1">
                      <h3 className="text-2xl font-semibold mb-2">Have questions about your privacy?</h3>
                      <p className="text-muted-foreground">
                        Our dedicated privacy team is here to help you understand how we protect your data
                      </p>
                    </div>
                    <div className="flex gap-4">
                      <Button variant="outline" asChild>
                        <Link to="/faq">View FAQ</Link>
                      </Button>
                      <Button size="lg" className="min-w-[160px]" asChild>
                        <Link to="/contact" className="flex items-center gap-2">
                          Contact Support
                          <ArrowRight className="h-4 w-4" />
                        </Link>
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default Privacy;
