import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Button } from '@/components/ui/button';
import { Asset } from '@/components/wallet/WalletModels';
import PaymentMethodSelector from '@/components/payment_method/PaymentMethodSelector';
import { PaymentMethod } from '@/components/payment_method/PaymentMethodModel';

const PaymentMethodPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const asset: Asset = location.state?.asset;
  console.log(asset.id);

  const handleContinue = (paymentMethod: PaymentMethod) => {
    // Navigate to the BuyPage with the selected payment method and asset
    navigate('/dashboard/wallet/payment/buy', { state: { asset, paymentMethod } });
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6 animate-fade-in">
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => navigate('/dashboard/wallet')}
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <h1 className="text-xl sm:text-3xl font-bold tracking-tight">Buy {asset.assetName}</h1>
        </div>

        <PaymentMethodSelector
          assetId={asset.id}
          assetName={asset.assetName}
          countryCode='US'
          onContinue={handleContinue}
        />
      </div>
    </DashboardLayout>
  );
};

export default PaymentMethodPage;