import React from 'react';
import { X, CheckCheck, Bell, AlertTriangle, Info } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface NotificationItemProps {
  notification: {
    _id: string;
    message: string;
    read: boolean;
    createdAt: string;
    type: string;
  };
  onMarkAsRead: (id: string) => void;
  onDelete: (id: string) => void;
}

const NotificationItem = ({ notification, onMarkAsRead, onDelete }: NotificationItemProps) => {
  const getTypeIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'warning':
        return <AlertTriangle className="h-5 w-5 text-warning" />;
      case 'error':
        return <AlertTriangle className="h-5 w-5 text-destructive" />;
      case 'info':
        return <Info className="h-5 w-5 text-info" />;
      default:
        return <Bell className="h-5 w-5 text-muted-foreground" />;
    }
  };

  const getTypeStyles = (type: string) => {
    switch (type.toLowerCase()) {
      case 'warning':
        return 'border-l-warning bg-warning/5';
      case 'error':
        return 'border-l-destructive bg-destructive/5';
      case 'info':
        return 'border-l-info bg-info/5';
      default:
        return 'border-l-primary bg-primary/5';
    }
  };

  return (
    <div
      className={cn(
        'group flex items-start gap-4 p-4 transition-colors border-l-4',
        getTypeStyles(notification.type),
        !notification.read ? 'bg-muted/50' : 'hover:bg-muted/30'
      )}
    >
      <div className="flex-shrink-0 pt-1">
        {getTypeIcon(notification.type)}
      </div>
      
      <div className="flex-1 space-y-1">
        <p className={cn(
          "text-sm leading-tight",
          !notification.read && "font-medium"
        )}>
          {notification.message}
        </p>
        <p className="text-xs text-muted-foreground">
          {new Date(notification.createdAt).toLocaleString()}
        </p>
      </div>

      <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
        {!notification.read && (
          <Button
            variant="ghost"
            size="sm"
            className="h-8 w-8 p-0 hover:bg-background"
            onClick={() => onMarkAsRead(notification._id)}
          >
            <CheckCheck className="h-4 w-4" />
            <span className="sr-only">Mark as read</span>
          </Button>
        )}
        <Button
          variant="ghost"
          size="sm"
          className="h-8 w-8 p-0 hover:bg-background hover:text-destructive"
          onClick={() => onDelete(notification._id)}
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Delete notification</span>
        </Button>
      </div>
    </div>
  );
};

export default NotificationItem;
