import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { 
  Loader2, 
  ArrowLeft, 
  Lock,
  Mail,
  Shield,
  Sparkles,
  Brain,
  Globe,
  CreditCard,
  Coins
} from 'lucide-react';
import authService from '@/services/authService';
import { toast } from 'sonner';
import { useUser } from '@/contexts/UserContext';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { fetchUserProfile } = useUser();

  useEffect(() => {
    document.title = 'EvoCash | Login';
    if (authService.isAuthenticated()) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error('Please enter both email and password');
      return;
    }
    
    setIsLoading(true);
    try {
      const response = await authService.login({ email, password });
      if (response.data) {
        const userData = {
          id: response.data._id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber,
          country: response.data.country,
          role: response.data.role
        };
        await fetchUserProfile();
        toast.success('Logged in successfully');
        navigate('/dashboard');
      } else {
        toast.error('Login succeeded but no user data was received');
      }
    } catch (error: any) {
      console.error('Login error:', error);
      toast.error(error.response?.data?.message || 'Failed to log in');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-background relative flex flex-col lg:flex-row">
      {/* Enhanced Gradient Background */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-primary/5 rounded-full blur-[120px]" />
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-primary/10 rounded-full blur-[120px]" />
      </div>

      {/* Back Button - Adjusted positioning */}
      <Button
        variant="ghost"
        size="sm"
        className="absolute top-safe-top left-4 z-50 pointer-events-auto md:top-6"
        onClick={handleBackToHome}
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        <span className="hidden sm:inline">Back to Home</span>
      </Button>

      {/* Main Content - Adjusted for better mobile layout */}
      <div className="flex-1 flex items-center justify-center p-4 relative min-h-[100dvh] lg:min-h-screen">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-[min(400px,100%-2rem)] mx-auto"
        >
          <Card className="p-4 sm:p-6 md:p-8 backdrop-blur-xl bg-background/95 border-border/50 shadow-xl">
            {/* Enhanced Header - Adjusted spacing */}
            <div className="text-center space-y-3 mb-6 md:mb-8">
              <div className="flex items-center justify-center mb-4">
                <div className="size-12 sm:size-14 rounded-full bg-primary/10 flex items-center justify-center">
                  <Sparkles className="size-6 sm:size-7 text-primary" />
                </div>
              </div>
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-2xl sm:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary via-primary/80 to-primary/60"
              >
                Welcome Back to EvoCash
              </motion.h1>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                className="text-sm text-muted-foreground max-w-sm mx-auto px-4"
              >
                Access your portfolio and continue your journey in digital asset trading
              </motion.p>
            </div>

            {/* Login Form - Adjusted spacing */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-3">
                  <div className="space-y-2">
                    <Label htmlFor="email" className="text-sm">Email</Label>
                    <div className="relative">
                      <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                      <Input
                        id="email"
                        type="email"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="pl-10 h-10"
                      />
                    </div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <Label htmlFor="password" className="text-sm">Password</Label>
                      <Link 
                        to="/forgot-password" 
                        className="text-xs sm:text-sm text-primary hover:underline"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <div className="relative">
                      <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                      <Input 
                        id="password" 
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="pl-10 h-10"
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-4 pt-2">
                  <Button 
                    type="submit" 
                    className="w-full h-10"
                    disabled={isLoading}
                  >
                    {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                    {isLoading ? 'Signing in...' : 'Sign In'}
                  </Button>
                  <div className="text-center text-sm">
                    <span className="text-muted-foreground">New to EvoCash?</span>{' '}
                    <Link to="/signup" className="text-primary hover:underline">
                      Create an account
                    </Link>
                  </div>
                </div>
              </form>
            </motion.div>
          </Card>
        </motion.div>
      </div>

      {/* Side Banner - Only visible on large screens with improved responsive design */}
      <div className="hidden lg:flex flex-1 relative overflow-hidden bg-gradient-to-br from-primary/10 via-primary/5 to-background border-l border-border/50">
        <div className="absolute inset-0 bg-grid-white/5" />
        <div className="relative w-full h-full flex items-center justify-center p-8 xl:p-12">
          <div className="max-w-xl space-y-8 xl:space-y-10">
            <motion.h2
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="text-3xl xl:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-foreground to-foreground/70"
            >
              The Future of Finance
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="space-y-6 xl:space-y-8"
            >
              <p className="text-base xl:text-lg text-muted-foreground leading-relaxed">
                Experience the power of blockchain technology combined with traditional finance, powered by advanced AI.
              </p>
              <ul className="space-y-4 xl:space-y-6">
                {[
                  {
                    icon: Coins,
                    title: "Asset Tokenization",
                    description: "Transform real-world assets into digital tokens for fractional ownership"
                  },
                  {
                    icon: CreditCard,
                    title: "Virtual Debit Card",
                    description: "Spend your digital assets globally with our integrated virtual card"
                  },
                  {
                    icon: Brain,
                    title: "EVA - Your Financial AI Agent",
                    description: "First-of-its-kind AI agent which helps you navigate the platform and execute tasks"
                  },
                  {
                    icon: Globe,
                    title: "Cross-Border Transactions",
                    description: "Send and receive tokenized assets globally without traditional banking limitations"
                  }
                ].map((feature, index) => (
                  <motion.li
                    key={feature.title}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.7 + (index * 0.1), duration: 0.5 }}
                    className="flex items-start gap-3 text-foreground/80"
                  >
                    <div className="mt-1 size-7 xl:size-8 rounded-lg bg-primary/10 flex items-center justify-center shrink-0">
                      <feature.icon className="size-3.5 xl:size-4 text-primary" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-foreground text-sm xl:text-base">{feature.title}</h3>
                      <p className="text-xs xl:text-sm text-muted-foreground">{feature.description}</p>
                    </div>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
