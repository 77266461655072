import React, { useEffect, useRef, useState } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import NotificationItem from './NotificationItem';
import { useNotification } from '@/contexts/NotificationContext';
import { NavLink } from 'react-router-dom';
import { Trash2, Bell, Loader2 } from 'lucide-react';
import { Separator } from '@/components/ui/separator';

const NotificationList = () => {
  const { notifications = [], fetchNotifications, deleteNotification, markAsRead, deleteAllNotifications } = useNotification();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const isFetching = useRef(false);
  const [currentPage, setCurrentPage] = useState(1); // State to track the current page
  const [loadingMore, setLoadingMore] = useState(false); // State for loading more notifications
  const [hasMore, setHasMore] = useState(true); // State to track if there are more notifications

  useEffect(() => {
    const fetchData = async () => {
      if (!isFetching.current && hasMore) {
        isFetching.current = true;
        setLoadingMore(true);
        try {
          const newNotifications = await fetchNotifications(currentPage);
          // Check if there are more notifications to load
          if (newNotifications.length === 0) {
            setHasMore(false); // No more notifications to load
          }
        } catch (error) {
          console.error("Error fetching notifications:", error);
        } finally {
          isFetching.current = false;
          setLoadingMore(false);
        }
      }
    };

    fetchData();
  }, [currentPage, hasMore]);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore && hasMore) {
        // Fetch more notifications when scrolled to the bottom
        setCurrentPage(prevPage => prevPage + 1); // Increment the page number
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-between p-4 border-b">
        <h4 className="font-semibold">Notifications</h4>
        {notifications.length > 0 && (
          <NavLink to="/dashboard/notifications">
            <Button variant="ghost" size="sm" className="text-xs">
              View all
            </Button>
          </NavLink>
        )}
        {notifications.length > 0 && (
          <Button variant="ghost" size="sm" onClick={deleteAllNotifications} className="text-xs">
            Clear all
          </Button>
        )}
      </div>
      <ScrollArea className="h-[300px]">
        <div ref={scrollRef} onScroll={handleScroll} className="overflow-y-auto h-[300px]">
          <div className="space-y-1">
            {notifications.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-20 text-muted-foreground">
                <p>No notifications available.</p>
              </div>
            ) : (
              notifications.map((notification) => (
                <NotificationItem
                  key={notification._id} // Ensure you have a unique key
                  notification={notification}
                  onMarkAsRead={markAsRead}
                  onDelete={deleteNotification}
                />
              ))
            )}
            {loadingMore && (
              <div className="flex items-center justify-center h-20">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary"></div>
              </div>
            )}

          </div>
        </div>
      </ScrollArea>
      {!loadingMore && !hasMore && notifications.length > 0 && (
        <div className="flex items-center justify-center h-20 text-muted-foreground">
          <p>No more notifications to load.</p>
        </div>
      )}
    </>
  );
};

export default NotificationList;
