import * as React from "react"
import { Capacitor } from '@capacitor/core';

const MOBILE_BREAKPOINT = 768

export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState<boolean>(false)

  React.useEffect(() => {
    const checkMobile = () => {
      // Check if it's a native platform or small screen
      const isMobileDevice = 
        Capacitor.isNativePlatform() || 
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
        window.innerWidth < MOBILE_BREAKPOINT;
      
      setIsMobile(isMobileDevice)
    }

    // Initial check
    checkMobile()

    // Add event listener for resize
    const mql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT - 1}px)`)
    mql.addEventListener("change", checkMobile)

    return () => mql.removeEventListener("change", checkMobile)
  }, [])

  return isMobile
}
