import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { PaymentMethod } from '@/components/payment_method/PaymentMethodModel';
import { useWallet } from '@/contexts/WalletContext';
import defaultImage from '@/../assets/Flutterwave-Logo.png'; // Adjust the path to your image

interface PaymentMethodSelectorProps {
  assetId: string;
  assetName: string;
  countryCode: string;
  onContinue: (paymentMethod: PaymentMethod) => void;
}

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({ assetId, assetName, countryCode, onContinue }) => {
  const { fetchPaymentMethods, paymentMethods } = useWallet();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMethods = async () => {
      console.log(assetId);
      await fetchPaymentMethods(countryCode, assetId); // Replace 'countryId' with the actual country ID
      setLoading(false);
    };

    fetchMethods();
  }, []);

  const handleSelectPaymentMethod = (method: PaymentMethod) => {
    setSelectedPaymentMethod(method);
    toast.info(`Selected payment method: ${method.paymentName}`);
  };

  const handleContinue = () => {
    if (selectedPaymentMethod) {
      onContinue(selectedPaymentMethod);
    } else {
      toast.error('Please select a payment method');
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Select Payment Method</h2>
      {loading ? (
        <div className="flex justify-center py-10">
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-primary"></div>
        </div>
      ) : paymentMethods.length === 0 ? (
        <div className="flex justify-center py-10">
          <p className="text-gray-500">No payment methods available for this asset.</p>
        </div>
      ): (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {paymentMethods.map((method) => (
              <Card
                key={method._id}
                className={`cursor-pointer hover:border-primary transition-colors ${selectedPaymentMethod?._id === method._id ? 'border-primary' : ''}`}
                onClick={() => handleSelectPaymentMethod(method)}
              >
                <CardHeader className="pb-2">
                  <div className="flex items-center gap-2">
                    <img 
                      src={method.paymentMethodImage || defaultImage} 
                      alt={method.paymentName} 
                      className="h-16 w-16 object-contain" 
                      onError={(e) => (e.currentTarget.src = defaultImage)}
                    />
                    <CardTitle className="text-lg truncate">{method.paymentName}</CardTitle>
                  </div>
                </CardHeader>
                <CardContent>
                  <CardDescription >
                    {method.paymentMethodDescription}
                  </CardDescription>
                  <div className="mt-2">
                    <p className="text-sm truncate">Rate: {method.rate}</p>
                    <p className="text-sm truncate">Currency: {method.userCurrency}</p>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>

          <div className="rounded-md bg-muted p-4 text-sm">
            <p>
              Select a payment method to continue with your {assetName} deposit.
            </p>
          </div>

          {selectedPaymentMethod && (
            <Button onClick={handleContinue} className="mt-4">
              Continue
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentMethodSelector;