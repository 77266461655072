import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Phone, X, Volume2, Loader2, User, Settings, Mic, MicOff, Book, ChevronUp, ChevronDown, Brain, MoreHorizontal, AlertCircle, MessageSquare, HandHeart, Sparkles } from 'lucide-react';
import { callGemini } from '@/services/CallGemini';
import { GeminiResponse, UserProfile, SuggestionTopic } from '@/services/geminiService';
import { motion, AnimatePresence } from 'framer-motion';

interface CallBotProps {
  userProfile?: UserProfile;
  autoAnswer?: boolean;
  className?: string;
  onCallStart?: () => void;
  onCallEnd?: () => void;
  proactiveMode?: boolean;
  theme?: 'light' | 'dark' | 'auto';
}

type Message = {
  id: string;
  speaker: 'user' | 'ai' | 'system';
  text: string;
  timestamp: number;
  isImportant?: boolean;
  actions?: Array<{
    type: string;
    label: string;
    payload: any;
  }>;
  suggestedResponses?: string[];
};

const CallBot: React.FC<CallBotProps> = ({
  userProfile,
  autoAnswer = false,
  className = '',
  onCallStart,
  onCallEnd,
  proactiveMode = true,
  theme = 'auto'
}) => {
  // Core states
  const [isActive, setIsActive] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isAITalking, setIsAITalking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState<'connecting' | 'connected' | 'disconnected' | 'error'>('disconnected');
  const [interfaceExpanded, setInterfaceExpanded] = useState(true);
  const [suggestedTopics, setSuggestedTopics] = useState<SuggestionTopic[]>([]);
  const [micVolume, setMicVolume] = useState(0);
  const [currentIntent, setCurrentIntent] = useState<string | null>(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [actualTheme, setActualTheme] = useState(theme);
  
  // Conversation history with improved structure
  const [conversationHistory, setConversationHistory] = useState<Message[]>([]);
  
  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const currentTranscriptRef = useRef<string>('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const volumeMonitorRef = useRef<NodeJS.Timeout | null>(null);
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);
  const lastUserInteractionRef = useRef<number>(Date.now());

  // Add new state for transcript display
  const [currentTranscript, setCurrentTranscript] = useState<string>('');
  const [transcriptConfidence, setTranscriptConfidence] = useState<number>(0);

  // Detect system theme
  useEffect(() => {
    if (theme === 'auto') {
      const updateTheme = () => {
        const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setActualTheme(isDarkMode ? 'dark' : 'light');
      };
      
      updateTheme();
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);
      return () => window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateTheme);
    } else {
      setActualTheme(theme);
    }
  }, [theme]);

  // Call timer
  useEffect(() => {
    if (isActive && !timerRef.current) {
      timerRef.current = setInterval(() => {
        setCallDuration(prev => prev + 1);
      }, 1000);
    }
    
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isActive]);

  // Auto-scroll to bottom when conversation updates
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [conversationHistory, currentTranscript]);

  // Set up volume monitoring
  useEffect(() => {
    if (isActive && !volumeMonitorRef.current) {
      volumeMonitorRef.current = setInterval(() => {
        const volume = callGemini.getMicrophoneVolume();
        setMicVolume(volume);
      }, 100);
    }
    
    return () => {
      if (volumeMonitorRef.current) {
        clearInterval(volumeMonitorRef.current);
        volumeMonitorRef.current = null;
      }
    };
  }, [isActive]);

  // Check browser compatibility on mount
  useEffect(() => {
    const checkCompatibility = () => {
      const speechRecognitionSupported = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
      const speechSynthesisSupported = 'speechSynthesis' in window;
      
      if (!speechRecognitionSupported || !speechSynthesisSupported) {
        console.error('Speech capabilities not fully supported in this browser');
        addSystemMessage('Your browser may not fully support voice capabilities. Some features may be limited.');
      }
    };
    
    callGemini.setStatusChangeCallback((status) => {
      setConnectionStatus(status);
      if (status === 'error') {
        addSystemMessage('There was an issue with the voice connection. Please try again.');
      }
    });
    
    checkCompatibility();
  }, []);

  // Proactive suggestions when inactive for a while
  useEffect(() => {
    if (proactiveMode && isActive && !isAITalking && !isListening && !inactivityTimerRef.current) {
      inactivityTimerRef.current = setTimeout(() => {
        // Only offer suggestions if user has been inactive for a while
        const timeSinceLastInteraction = Date.now() - lastUserInteractionRef.current;
        if (timeSinceLastInteraction > 20000) { // 20 seconds
          offerProactiveSuggestion();
        }
      }, 20000); // Check after 20 seconds
    }
    
    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
        inactivityTimerRef.current = null;
      }
    };
  }, [isActive, isAITalking, isListening, proactiveMode]);

  // Add a system message to conversation
  const addSystemMessage = (text: string) => {
    setConversationHistory(prev => [
      ...prev,
      {
        id: `system-${Date.now()}`,
        speaker: 'system',
        text,
        timestamp: Date.now()
      }
    ]);
  };

  // Get context-aware suggestions
  const updateSuggestedTopics = useCallback(async () => {
    if (!isActive || conversationHistory.length === 0) return;
    
    try {
      // This would normally call the AI service to get suggestions based on conversation
      // For demo, we'll simulate this
      const topics = await callGemini.getSuggestedTopics(conversationHistory);
      setSuggestedTopics(topics);
    } catch (error) {
      console.error('Error getting topic suggestions:', error);
    }
  }, [isActive, conversationHistory]);

  // Update suggestions when conversation changes
  useEffect(() => {
    if (conversationHistory.length > 0) {
      updateSuggestedTopics();
    }
  }, [conversationHistory, updateSuggestedTopics]);

  // Offer a proactive suggestion based on context
  const offerProactiveSuggestion = async () => {
    if (isMuted || isAITalking || isListening || isLoading) return;
    
    try {
      const suggestion = await callGemini.getProactiveSuggestion(conversationHistory);
      
      if (suggestion) {
        setIsAITalking(true);
        
        // Add AI suggestion to conversation
        const newMessage: Message = {
          id: `ai-${Date.now()}`,
          speaker: 'ai',
          text: suggestion,
          timestamp: Date.now(),
          isImportant: true
        };
        
        setConversationHistory(prev => [...prev, newMessage]);
        
        try {
          await callGemini.speak(suggestion);
          setIsAITalking(false);
          
          if (!isMuted && isActive) {
            startListening();
          }
        } catch (error) {
          console.error('Error in AI proactive speech:', error);
          setIsAITalking(false);
          
          if (isActive && !isMuted) {
            setTimeout(() => startListening(), 1000);
          }
        }
      }
    } catch (error) {
      console.error('Error generating proactive suggestion:', error);
    }
  };

  // Start listening and process voice input with platform compatibility handling
  const startListening = async () => {
    if (isMuted || isAITalking || isLoading) return;
    
    lastUserInteractionRef.current = Date.now();
    
    // Check for iOS compatibility issues
    const isIOS = callGemini.getMemorizedContext('isIOS');
    const speechRecognitionWorks = callGemini.getMemorizedContext('speechRecognitionWorks');
    const permissionDenied = callGemini.getMemorizedContext('permissionDenied');
    
    // Handle permission issues
    if (permissionDenied) {
      addSystemMessage("Microphone access was denied. Please check your browser settings and grant microphone permissions.");
      return;
    }
    
    // Handle known iOS issues with speech recognition
    if (isIOS && speechRecognitionWorks === false) {
      // Offer text input as a fallback
      addSystemMessage("Voice input isn't available on this device. Please type your response instead or try a different browser like Chrome for desktop.");
      
      // Here you would show a text input field
      // For now, we'll just return without starting speech recognition
      return;
    }
    
    setIsListening(true);
    setCurrentTranscript('');
    setTranscriptConfidence(0);
    
    try {
      // For iOS, we might need a simpler approach with fewer features
      const options = isIOS ? { useSimpleMode: true } : {};
      
      const { transcript, confidence } = await callGemini.startListening((interimTranscript, interimConfidence) => {
        currentTranscriptRef.current = interimTranscript;
        setCurrentTranscript(interimTranscript);
        setTranscriptConfidence(interimConfidence);
      }, options);
      
      if (transcript) {
        setIsListening(false);
        setIsLoading(true);
        
        // Add user message to conversation
        const newMessage: Message = {
          id: `user-${Date.now()}`,
          speaker: 'user',
          text: transcript,
          timestamp: Date.now()
        };
        
        setConversationHistory(prev => [...prev, newMessage]);
        setCurrentTranscript('');
        
        // Attempt to detect intent early
        const predictedIntent = await callGemini.detectIntent(transcript);
        setCurrentIntent(predictedIntent);
        
        const response = await callGemini.processVoiceInput(transcript, conversationHistory);
        await handleAIResponse(response);
      }
    } catch (error) {
      console.error('Error in voice interaction:', error);
      setIsListening(false);
      setCurrentTranscript('');
      
      // Check if this is a permission error or iOS compatibility issue
      if (error.toString().includes('permission') || error.toString().includes('allow')) {
        addSystemMessage("Microphone access is required for voice input. Please check your browser settings.");
        callGemini.memorizeContext('permissionDenied', true);
      } else if (isIOS) {
        // On iOS, speech recognition failures are common - offer alternatives
        addSystemMessage("Voice input isn't working on this device. You can try typing instead or use a desktop browser.");
        callGemini.memorizeContext('speechRecognitionWorks', false);
      } else {
        // Generic error handling
        addSystemMessage("There was a problem with voice input. Please try again.");
        
        // Handle reconnection or fallback logic here
        if (isActive) {
          setTimeout(() => {
            if (isActive && !isMuted) startListening();
          }, 3000); // Try again after 3 seconds
        }
      }
    }
  };

  // Handle AI response with platform compatibility
  const handleAIResponse = async (response: GeminiResponse) => {
    setIsLoading(false);
    setIsAITalking(true);
    setCurrentIntent(null);
    
    // Add AI response to conversation with any actions
    const newMessage: Message = {
      id: `ai-${Date.now()}`,
      speaker: 'ai',
      text: response.text,
      timestamp: Date.now(),
      actions: response.actions,
      suggestedResponses: response.suggestedResponses
    };
    
    setConversationHistory(prev => [...prev, newMessage]);
    
    // Check for iOS speech synthesis compatibility
    const isIOS = callGemini.getMemorizedContext('isIOS');
    const speechSynthesisWorks = callGemini.getMemorizedContext('speechSynthesisWorks');
    
    // If we already know speech doesn't work on this device, don't try
    if (isIOS && speechSynthesisWorks === false) {
      setIsAITalking(false);
      // Just show the message without trying to speak it
      if (!isMuted && isActive) {
        setTimeout(() => startListening(), 500);
      }
      return;
    }
    
    try {
      // Special handling for iOS
      if (isIOS) {
        // iOS requires speech to be started from a user interaction
        // By now, we should be in a user gesture context chain
        const utterance = new SpeechSynthesisUtterance(response.text);
        
        // Set up event handlers
        utterance.onend = () => {
          setIsAITalking(false);
          callGemini.memorizeContext('speechSynthesisWorks', true);
          
          if (!isMuted && isActive) {
            startListening();
          }
        };
        
        utterance.onerror = (event) => {
          console.error('iOS speech synthesis error:', event);
          setIsAITalking(false);
          callGemini.memorizeContext('speechSynthesisWorks', false);
          
          // Add a message to let the user know about the limitation
          addSystemMessage("Voice output isn't working on this device. You can still read the responses.");
          
          if (!isMuted && isActive) {
            setTimeout(() => startListening(), 1000);
          }
        };
        
        // Attempt to speak (this might fail on iOS)
        window.speechSynthesis.speak(utterance);
        
        // iOS has a bug where it can stop speaking after 15 seconds
        // Use the workaround in our service
        callGemini.startIOSKeepAliveTicker?.();
      } else {
        // Standard flow for desktop browsers
        await callGemini.speak(response.text);
        setIsAITalking(false);
        
        if (!isMuted && isActive) {
          startListening();
        }
      }
    } catch (error) {
      console.error('Error in AI speech:', error);
      setIsAITalking(false);
      
      // If this is the first speech error, notify the user
      if (!callGemini.getMemorizedContext('speechErrorShown')) {
        addSystemMessage("There was an issue with voice output. You can still read the responses.");
        callGemini.memorizeContext('speechErrorShown', true);
      }
      
      // Try to restart listening if there was an error with speech
      if (isActive && !isMuted) {
        setTimeout(() => startListening(), 1000);
      }
    }
  };

  // Execute an action from AI response
  const executeAction = async (action: any) => {
    lastUserInteractionRef.current = Date.now();
    
    try {
      setIsLoading(true);
      const result = await callGemini.executeAction(action);
      setIsLoading(false);
      
      if (result) {
        await handleAIResponse(result);
      }
    } catch (error) {
      console.error('Error executing action:', error);
      setIsLoading(false);
      addSystemMessage('Unable to complete that action. Please try again.');
    }
  };

  // Use a suggested response
  const useSuggestedResponse = async (response: string) => {
    lastUserInteractionRef.current = Date.now();
    
    // Add user message to conversation
    const newMessage: Message = {
      id: `user-${Date.now()}`,
      speaker: 'user',
      text: response,
      timestamp: Date.now()
    };
    
    setConversationHistory(prev => [...prev, newMessage]);
    
    // Process the suggested response
    setIsLoading(true);
    const aiResponse = await callGemini.processVoiceInput(response, conversationHistory);
    await handleAIResponse(aiResponse);
  };

  // Start call with platform compatibility checks
  const startCall = () => {
    setIsActive(true);
    setCallDuration(0);
    setConnectionStatus('connecting');
    lastUserInteractionRef.current = Date.now();
    
    // Clear previous conversation if any
    setConversationHistory([]);
    
    // Run speech capability tests on iOS/mobile devices
    // These must be run from a user interaction
    const pendingRecognitionTest = callGemini.getMemorizedContext('pendingRecognitionTest');
    const pendingSynthesisTest = callGemini.getMemorizedContext('pendingSynthesisTest');
    
    if (typeof pendingRecognitionTest === 'function') {
      pendingRecognitionTest();
    }
    if (typeof pendingSynthesisTest === 'function') {
      pendingSynthesisTest();
    }
    
    // Notify parent component if handler provided
    if (onCallStart) onCallStart();
    
    // Simulate connection process
    setTimeout(() => {
      // Check for platform compatibility issues
      const isIOS = callGemini.getMemorizedContext('isIOS');
      const speechSynthesisWorks = callGemini.getMemorizedContext('speechSynthesisWorks');
      const speechRecognitionWorks = callGemini.getMemorizedContext('speechRecognitionWorks');
      
      if (isIOS && (speechSynthesisWorks === false || speechRecognitionWorks === false)) {
        // Add a warning message about limited functionality on iOS
        addSystemMessage("Voice capabilities may be limited on iOS devices. You might need to use text input or try a different browser.");
      }
      
      setConnectionStatus('connected');
      
      // Initial greeting with personalization if available
      setTimeout(() => {
        const userName = userProfile?.name ? `, ${userProfile.name}` : '';
        const greeting = `Hello${userName}! I'm EVA, your EvoCash assistant. How can I help you today?`;
        
        const initialMessage: Message = {
          id: `ai-${Date.now()}`,
          speaker: 'ai',
          text: greeting,
          timestamp: Date.now(),
          suggestedResponses: [
            "Check my account balance",
            "Tell me about new features",
            "Help me save money"
          ]
        };
        
        setConversationHistory([initialMessage]);
        
        if (!isMuted) {
          // Check if we're on iOS and need to attempt compatibility fixes
          if (isIOS) {
            // Try to trigger speech synthesis with a user gesture
            const utterance = new SpeechSynthesisUtterance(greeting);
            window.speechSynthesis.speak(utterance);
            
            utterance.onend = () => {
              // Successfully spoke - now we can try listening
              if (!isMuted) startListening();
            };
            
            utterance.onerror = () => {
              console.error('iOS speech synthesis failed in initial greeting');
              addSystemMessage("Unable to use voice output on this device. You can still type or try another browser.");
              if (!isMuted) startListening();
            };
          } else {
            // Normal flow for desktop browsers
            callGemini.speak(greeting)
              .then(() => startListening())
              .catch(err => {
                console.error('Error during initial greeting:', err);
                addSystemMessage("Voice output isn't working. Please check your audio settings.");
                if (!isMuted) startListening();
              });
          }
        } else {
          startListening();
        }
      }, 500);
    }, 1000);
  };

  // End call
  const endCall = () => {
    setConnectionStatus('disconnected');
    
    // Notify parent component if handler provided
    if (onCallEnd) onCallEnd();
    
    if (containerRef.current) {
      containerRef.current.classList.add('animate-fadeOut');
      setTimeout(() => {
        cleanupCallState();
        if (containerRef.current) {
          containerRef.current.classList.remove('animate-fadeOut');
        }
      }, 400);
    } else {
      cleanupCallState();
    }
  };

  // Cleanup call state
  const cleanupCallState = () => {
    setIsActive(false);
    setIsListening(false);
    setIsAITalking(false);
    setIsLoading(false);
    callGemini.stopListening();
    callGemini.stopSpeaking();
    
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    
    if (volumeMonitorRef.current) {
      clearInterval(volumeMonitorRef.current);
      volumeMonitorRef.current = null;
    }
    
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
      inactivityTimerRef.current = null;
    }
  };

  // Toggle call
  const toggleCall = () => {
    lastUserInteractionRef.current = Date.now();
    
    if (!isActive) {
      startCall();
    } else {
      endCall();
    }
  };

  // Toggle mute
  const toggleMute = () => {
    lastUserInteractionRef.current = Date.now();
    
    setIsMuted(!isMuted);
    if (!isMuted) {
      callGemini.stopListening();
      setIsListening(false);
    } else if (isActive && !isAITalking && !isLoading) {
      startListening();
    }
  };

  // Format duration
  const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Get status message
  const getStatusMessage = (): string => {
    if (connectionStatus === 'connecting') return 'Connecting...';
    if (connectionStatus === 'disconnected') return 'Call ended';
    if (connectionStatus === 'error') return 'Connection error';
    if (isMuted) return 'Microphone muted';
    if (isListening) return 'Listening...';
    if (isLoading) return currentIntent ? `Understanding ${currentIntent}...` : 'Processing...';
    if (isAITalking) return 'EVA is speaking...';
    if (isActive) return 'Ready - speak now';
    return 'Tap to call EVA';
  };

  // Get status badge color
  const getStatusBadgeColor = (): string => {
    if (connectionStatus === 'connecting') return 'bg-amber-500 animate-pulse';
    if (connectionStatus === 'disconnected') return 'bg-gray-500';
    if (connectionStatus === 'error') return 'bg-red-500';
    if (isMuted) return 'bg-red-500';
    if (isAITalking) return 'bg-purple-500';
    if (isListening) return 'bg-blue-500 animate-pulse';
    if (isLoading) return 'bg-amber-500 animate-pulse';
    return 'bg-green-500';
  };

  // Auto-answer on prop change if configured
  useEffect(() => {
    if (autoAnswer && !isActive) {
      startCall();
    }
  }, [autoAnswer]);

  // Render suggestion chips
  const renderSuggestionChips = () => {
    if (!suggestedTopics || suggestedTopics.length === 0) return null;
    
    return (
      <div className="flex flex-wrap gap-2 mt-2">
        {suggestedTopics.map((topic, index) => (
          <button
            key={`topic-${index}`}
            onClick={() => useSuggestedResponse(topic.text)}
            className="px-3 py-1 rounded-full bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100 
                     text-xs font-medium hover:bg-blue-200 dark:hover:bg-blue-800 transition-colors"
          >
            {topic.icon && React.createElement(topic.icon, { className: "w-3 h-3 mr-1 inline" })}
            {topic.text}
          </button>
        ))}
      </div>
    );
  };

  // Render message actions
  const renderMessageActions = (message: Message) => {
    if (!message.actions || message.actions.length === 0) {
      // Auto-generate actions for EvoCash content
      if (message.text.includes('EvoCash') && message.text.includes('AGC')) {
        const evoCashActions = [
          {
            type: 'navigate',
            label: 'Learn about AGC',
            payload: { destination: 'agc-info' }
          },
          {
            type: 'request',
            label: 'Portfolio Demo',
            payload: { feature: 'portfolio' }
          },
          {
            type: 'navigate',
            label: 'Visit News Page',
            payload: { destination: '/news' }
          }
        ];
        
        return (
          <div className="flex flex-wrap gap-2 mt-2">
            {evoCashActions.map((action, index) => (
              <button
                key={`evocash-action-${index}`}
                onClick={() => executeAction(action)}
                className="px-3 py-1.5 rounded-lg bg-gradient-to-r from-amber-500 to-yellow-500 dark:from-amber-600 dark:to-yellow-600 
                         text-white shadow-sm text-xs font-medium hover:opacity-90 transition-colors flex items-center gap-1"
              >
                {action.type === 'navigate' ? (
                  <MessageSquare className="w-3 h-3" />
                ) : (
                  <Sparkles className="w-3 h-3" />
                )}
                {action.label}
              </button>
            ))}
          </div>
        );
      }
      return null;
    }
    
    return (
      <div className="flex flex-wrap gap-2 mt-2">
        {message.actions.map((action, index) => (
          <button
            key={`action-${index}`}
            onClick={() => executeAction(action)}
            className="px-3 py-1 rounded-full bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-100 
                     text-xs font-medium hover:bg-purple-200 dark:hover:bg-purple-800 transition-colors"
          >
            {action.label}
          </button>
        ))}
      </div>
    );
  };

  // Render suggested responses
  const renderSuggestedResponses = (message: Message) => {
    if (!message.suggestedResponses || message.suggestedResponses.length === 0) return null;
    
    return (
      <div className="flex flex-wrap gap-2 mt-3">
        {message.suggestedResponses.map((response, index) => (
          <button
            key={`suggested-${index}`}
            onClick={() => useSuggestedResponse(response)}
            className="px-3 py-1.5 rounded-lg border border-gray-300 dark:border-gray-600 
                     bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-200
                     text-xs hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
          >
            {response}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className={`fixed bottom-[4.5rem] sm:bottom-6 sm:right-6 left-6 sm:left-auto flex flex-col items-center z-50 ${actualTheme === 'dark' ? 'dark' : ''} ${className}`}>
      {/* Main call button with listening indicator */}
      <motion.button 
        onClick={toggleCall}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        className={`relative w-16 h-16 rounded-full flex items-center justify-center shadow-lg 
          transform transition-all duration-500
          ${isActive ? 'bg-red-500 dark:bg-red-600 shadow-red-500/30 dark:shadow-red-600/30' : 
                     'bg-green-500 dark:bg-green-600 shadow-green-500/30 dark:shadow-green-600/30'}`}
      >
        {isActive ? (
          <X className="w-8 h-8 text-white animate-fadeIn" />
        ) : (
          <Phone className="w-8 h-8 text-white animate-fadeIn" />
        )}
        
        {/* Listening indicator rings */}
        <AnimatePresence>
          {isListening && (
            <>
              <motion.div 
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 0.3, scale: 1.2 }}
                exit={{ opacity: 0, scale: 1.4 }}
                transition={{ repeat: Infinity, duration: 1.5 }}
                className="absolute w-full h-full rounded-full border-4 border-blue-400 dark:border-blue-500" 
              />
              <motion.div 
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 0.7, scale: 1.1 }}
                exit={{ opacity: 0, scale: 1.2 }}
                transition={{ repeat: Infinity, duration: 1.5, delay: 0.5 }}
                className="absolute w-full h-full rounded-full border-2 border-blue-300 dark:border-blue-400" 
              />
            </>
          )}
        </AnimatePresence>
        
        {/* Mic volume indicator (circular progress) */}
        {isListening && (
          <svg className="absolute inset-0 w-full h-full -rotate-90" viewBox="0 0 100 100">
            <circle 
              cx="50" cy="50" r="48" 
              fill="none" 
              stroke="rgba(59, 130, 246, 0.2)" 
              strokeWidth="4"
            />
            <circle 
              cx="50" cy="50" r="48" 
              fill="none" 
              stroke="rgba(59, 130, 246, 0.8)" 
              strokeWidth="4"
              strokeDasharray={`${micVolume * 3}% 300%`}
              strokeLinecap="round"
            />
          </svg>
        )}
      </motion.button>

                  {/* Call interface */}
      <AnimatePresence>
        {isActive && (
          <motion.div 
            ref={containerRef}
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            transition={{ duration: 0.3 }}
            className={`mt-6 flex flex-col items-center 
              backdrop-blur-sm p-4 rounded-2xl shadow-xl max-w-md w-full
              ${actualTheme === 'dark' ? 
                'bg-gray-900/90 text-gray-100 border border-gray-700' : 
                'bg-white/90 text-gray-800 border border-gray-200'}`}
          >
            {/* iOS/Mobile compatibility indicator - shows when needed */}
            {callGemini.getMemorizedContext('isIOS') && (
              <div className="w-full mb-3">
                {(callGemini.getMemorizedContext('speechSynthesisWorks') === false || 
                  callGemini.getMemorizedContext('speechRecognitionWorks') === false) && (
                  <div className={`px-3 py-2 rounded-lg text-xs text-center
                    ${actualTheme === 'dark' ? 'bg-amber-700 text-amber-100' : 'bg-amber-100 text-amber-800'}`}>
                    <p className="flex items-center justify-center gap-1 font-medium">
                      <AlertCircle className="w-3.5 h-3.5" />
                      Voice features limited on this device
                    </p>
                    <p className="mt-1 opacity-80">Voice interaction may not work on iOS/iPadOS. Try using a desktop browser.</p>
                  </div>
                )}
                
                {callGemini.getMemorizedContext('permissionDenied') && (
                  <div className={`px-3 py-2 rounded-lg text-xs text-center
                    ${actualTheme === 'dark' ? 'bg-red-800 text-red-100' : 'bg-red-100 text-red-800'}`}>
                    <p className="flex items-center justify-center gap-1 font-medium">
                      <AlertCircle className="w-3.5 h-3.5" />
                      Microphone access required
                    </p>
                    <p className="mt-1 opacity-80">Please grant microphone permissions in your browser settings.</p>
                  </div>
                )}
              </div>
            )}
            {/* Header with status and controls */}
            <div className="flex items-center justify-between w-full mb-2">
              <div className={`px-3 py-1 rounded-full text-xs flex items-center gap-2 w-auto
                ${actualTheme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}>
                <div className={`w-2 h-2 rounded-full ${getStatusBadgeColor()}`}></div>
                <span>{formatDuration(callDuration)}</span>
                {userProfile?.accountStatus !== 'guest' && (
                  <span className={`border-l ${actualTheme === 'dark' ? 'border-gray-700' : 'border-gray-300'} pl-2 flex items-center gap-1`}>
                    <User className="w-3 h-3" /> 
                    {userProfile?.name || 'User'}
                  </span>
                )}
              </div>
              
              <div className="flex items-center gap-2">
                <button 
                  onClick={() => setInterfaceExpanded(!interfaceExpanded)}
                  className={`p-1.5 rounded-full ${actualTheme === 'dark' ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
                >
                  {interfaceExpanded ? 
                    <ChevronDown className="w-4 h-4" /> : 
                    <ChevronUp className="w-4 h-4" />
                  }
                </button>
                <button 
                  onClick={() => setShowContextMenu(!showContextMenu)}
                  className={`p-1.5 rounded-full ${actualTheme === 'dark' ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
                >
                  <MoreHorizontal className="w-4 h-4" />
                </button>
              </div>
            </div>
            
            {/* Context menu */}
            <AnimatePresence>
              {showContextMenu && (
                <motion.div 
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className={`w-full mb-3 rounded-lg overflow-hidden ${actualTheme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}
                >
                  <div className="p-2">
                    <div className="grid grid-cols-2 gap-2">
                      <button className={`flex items-center gap-2 p-2 rounded ${actualTheme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}>
                        <Book className="w-4 h-4" />
                        <span className="text-sm">View History</span>
                      </button>
                      <button className={`flex items-center gap-2 p-2 rounded ${actualTheme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}>
                        <Settings className="w-4 h-4" />
                        <span className="text-sm">Settings</span>
                      </button>
                      <button className={`flex items-center gap-2 p-2 rounded ${actualTheme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}>
                        <Sparkles className="w-4 h-4" />
                        <span className="text-sm">Suggestions</span>
                      </button>
                      <button className={`flex items-center gap-2 p-2 rounded ${actualTheme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}>
                        <HandHeart className="w-4 h-4" />
                        <span className="text-sm">Help & Tips</span>
                      </button>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Conversation history */}
            {interfaceExpanded && (
              <div 
                ref={scrollRef}
                className={`w-full mb-4 max-h-80 overflow-y-auto px-2 py-1 rounded-lg ${actualTheme === 'dark' ? 'scrollbar-dark' : 'scrollbar-light'}`}
              >
                <AnimatePresence initial={false}>
                  {conversationHistory.map((message, index) => (
                    <motion.div
                      key={message.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className={`mb-3 ${message.speaker === 'system' ? 'flex justify-center' : ''}`}
                    >
                      {message.speaker === 'system' ? (
                        <div className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-full text-xs
                          ${actualTheme === 'dark' ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'}`}>
                          <AlertCircle className="w-3 h-3" />
                          {message.text}
                        </div>
                      ) : (
                        <div className={`relative ${message.speaker === 'user' ? 'ml-auto' : 'mr-auto'}`}>
                          <div className={`p-3 rounded-2xl max-w-[85%] ${
                            message.speaker === 'user' 
                              ? actualTheme === 'dark' 
                                ? 'bg-blue-600 text-white ml-auto' 
                                : 'bg-blue-500 text-white ml-auto'
                              : actualTheme === 'dark'
                                ? 'bg-gray-800 text-white'
                                : 'bg-gray-100 text-gray-800'
                          } ${message.isImportant ? 'ring-2 ring-yellow-400 dark:ring-yellow-500' : ''}`}>
                            <p className="text-sm">{message.text}</p>
                            <div className="text-xs opacity-70 text-right mt-1 flex justify-end items-center gap-1">
                              {message.speaker === 'ai' && (
                                <Brain className="w-3 h-3" />
                              )}
                              {new Date(message.timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                            </div>
                          </div>
                          
                          {/* Message actions and suggested responses */}
                          {message.speaker === 'ai' && (
                            <div className="mt-2">
                              {renderMessageActions(message)}
                              {renderSuggestedResponses(message)}
                              
                              {/* Enhanced formatting for EvoCash info with special detection */}
                              {(message.text.includes('***') || message.text.includes('**1.') || message.text.includes('**2.') || message.text.includes('**3.')) && (
                                <div className={`mt-3 rounded-lg overflow-hidden ${
                                  actualTheme === 'dark' ? 'bg-gray-800/80' : 'bg-white'
                                } shadow-md border ${actualTheme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
                                  {/* Gold gradient header */}
                                  <div className="bg-gradient-to-r from-amber-500 to-yellow-500 px-4 py-3">
                                    <h3 className="font-medium text-white text-sm">
                                      {message.text.includes('strategies') ? 'EvoCash Savings Strategies' : 'EvoCash Features'}
                                    </h3>
                                  </div>
                                  
                                  <div className="p-4">
                                    {/* Section for "**1. Understanding AGC" type format */}
                                    {message.text.includes('**1.') && (
                                      <div className="space-y-4">
                                        {message.text.split('**').filter(item => item.trim().length > 0).map((section, i) => {
                                          if (section.match(/^\d+\./)) {
                                            // This is a main section heading (e.g., "1. Understanding AGC")
                                            const [number, title] = section.split('. ');
                                            return (
                                              <div key={i} className="mb-3">
                                                <h4 className="font-medium text-sm flex items-center mb-2">
                                                  <span className="flex items-center justify-center bg-amber-500 text-white w-6 h-6 rounded-full mr-2 text-xs font-bold">
                                                    {number}
                                                  </span>
                                                  <span className={`${actualTheme === 'dark' ? 'text-white' : 'text-gray-800'}`}>
                                                    {title}
                                                  </span>
                                                </h4>
                                                {/* The content will be handled in the subsection parsing */}
                                              </div>
                                            );
                                          } else if (section.includes('***')) {
                                            // Parse subsections with *** format
                                            const subsections = section.split('***').filter(s => s.trim().length > 0);
                                            return (
                                              <ul key={i} className="list-none space-y-2 pl-8">
                                                {subsections.map((subsection, j) => {
                                                  const [title, ...contentParts] = subsection.split(':');
                                                  const content = contentParts.join(':');
                                                  return (
                                                    <li key={j} className="relative">
                                                      <div className="flex">
                                                        <div className="absolute left-0 mt-1.5 w-2 h-2 bg-amber-500 rounded-full -ml-4"></div>
                                                        <div>
                                                          <span className="font-medium text-amber-600 dark:text-amber-400">{title.trim()}:</span>
                                                          <span className="text-sm">
                                                            {content}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            );
                                          } else {
                                            // Regular text
                                            return <p key={i} className="text-sm pl-8">{section}</p>;
                                          }
                                        })}
                                      </div>
                                    )}
                                    
                                    {/* Legacy format with just *** bullets */}
                                    {!message.text.includes('**1.') && message.text.includes('***') && (
                                      <ul className="list-disc pl-5 space-y-2 text-sm">
                                        {message.text.split('***').filter(item => item.trim().length > 0).map((item, i) => (
                                          <li key={i} className="leading-tight">
                                            <span className="font-medium text-amber-600 dark:text-amber-400">{item.split(':')[0]}:</span> 
                                            {item.includes(':') ? item.split(':').slice(1).join(':') : item}
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                    
                                    {/* URL navigation button if URL is present */}
                                    {message.text.includes('NAVIGATE:') && (
                                      <div className="mt-4 flex justify-center">
                                        <a 
                                          href={message.text.split('NAVIGATE:')[1].trim().split(' ')[0]} 
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="inline-flex items-center px-4 py-2 bg-gradient-to-r from-amber-500 to-yellow-500 text-white rounded-lg 
                                                   shadow-md hover:from-amber-600 hover:to-yellow-600 transition-colors text-sm font-medium"
                                        >
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                          </svg>
                                          Visit AGC Website
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            )}

            {/* Transcript display */}
            {(isListening || currentTranscript) && (
              <motion.div 
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className={`mb-4 px-4 py-2 rounded-lg w-full
                  ${actualTheme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}
              >
                <div className="flex items-center gap-2 mb-1">
                  <div className="flex gap-1">
                    <div className={`w-1 h-3 bg-blue-400 rounded-full ${isListening ? 'animate-bounce' : ''}`} style={{ animationDelay: '0ms' }} />
                    <div className={`w-1 h-3 bg-blue-400 rounded-full ${isListening ? 'animate-bounce' : ''}`} style={{ animationDelay: '150ms' }} />
                    <div className={`w-1 h-3 bg-blue-400 rounded-full ${isListening ? 'animate-bounce' : ''}`} style={{ animationDelay: '300ms' }} />
                  </div>
                  <span className="text-blue-500 dark:text-blue-400 text-xs font-medium">
                    {isListening ? 'Listening...' : 'Processing...'}
                  </span>
                  
                  {/* Confidence indicator for speech recognition */}
                  {transcriptConfidence > 0 && (
                    <div className="ml-auto flex items-center gap-1">
                      <div className="w-12 h-1.5 bg-gray-300 dark:bg-gray-700 rounded-full overflow-hidden">
                        <div 
                          className={`h-full rounded-full ${
                            transcriptConfidence > 0.8 ? 'bg-green-500' : 
                            transcriptConfidence > 0.5 ? 'bg-yellow-500' : 'bg-red-500'
                          }`}
                          style={{ width: `${transcriptConfidence * 100}%` }}
                        />
                      </div>
                      <span className="text-xs opacity-70">{Math.round(transcriptConfidence * 100)}%</span>
                    </div>
                  )}
                </div>
                <p className={`text-sm italic ${actualTheme === 'dark' ? 'text-white' : 'text-gray-700'}`}>
                  {currentTranscript || 'Waiting for speech...'}
                </p>
              </motion.div>
            )}

            {/* Topic suggestions */}
            {interfaceExpanded && !isListening && !isAITalking && !currentTranscript && (
              <div className="w-full mb-4">
                {renderSuggestionChips()}
              </div>
            )}

            {/* Status message */}
            <div className="mb-2 text-center text-sm text-gray-600 dark:text-gray-300">
              {getStatusMessage()}
            </div>

            {/* Controls */}
            <div className="flex items-center gap-3">
              {/* Mute button */}
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={toggleMute}
                className={`w-12 h-12 rounded-full flex items-center justify-center shadow-md
                  transition-colors duration-200
                  ${isMuted 
                    ? 'bg-red-500 dark:bg-red-600 hover:bg-red-600 dark:hover:bg-red-700' 
                    : 'bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600'}`}
              >
                {isMuted ? (
                  <MicOff className="w-6 h-6 text-white" />
                ) : (
                  <Mic className="w-6 h-6 text-gray-700 dark:text-white" />
                )}
              </motion.button>

              {/* End call button */}
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={endCall}
                className="w-14 h-14 rounded-full bg-red-500 dark:bg-red-600 hover:bg-red-600 dark:hover:bg-red-700
                  flex items-center justify-center shadow-md transition-colors duration-200"
              >
                <Phone className="w-7 h-7 text-white rotate-135" />
              </motion.button>

              {/* Volume button */}
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className="w-12 h-12 rounded-full bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600
                  flex items-center justify-center shadow-md transition-colors duration-200"
              >
                <Volume2 className="w-6 h-6 text-gray-700 dark:text-white" />
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CallBot;
