import { useState, useEffect } from 'react';
import { 
  ArrowUpRight, 
  ArrowDownLeft, 
  RefreshCw, 
  Clock, 
  CheckCircle2, 
  XCircle, 
  Search, 
  ChevronDown, 
  Filter,
  DollarSign
} from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from '@/components/ui/table';
import { 
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useTransaction } from '@/contexts/TransactionContext';
import { format, parseISO } from 'date-fns';
import { Spinner } from '@/components/ui/spinner';
import { Transaction } from '@/components/transaction/TransactionModels';
import { getTransactionType, getTransactionStatus } from '@/components/transaction/TransactionUtilities';

const transactionIcons: Record<string, JSX.Element> = {
  deposit: <ArrowDownLeft className="h-4 w-4" />,
  withdrawal: <ArrowUpRight className="h-4 w-4" />,
  transfer: <RefreshCw className="h-4 w-4" />,
  buy: <DollarSign className="h-4 w-4" />
};

const transactionStatus: Record<string, JSX.Element> = {
  Pending: <Clock className="h-4 w-4 text-warning" />,
  Successful: <CheckCircle2 className="h-4 w-4 text-success" />,
  Processing: <Clock className="h-4 w-4 text-primary" />,
  Failed: <XCircle className="h-4 w-4 text-destructive" />
};

interface TransactionListProps {
  className?: string;
  style?: React.CSSProperties;
}

const TransactionList = ({ className, style }: TransactionListProps) => {
  const { transactions, pagination, loading, error, fetchTransactionHistory } = useTransaction();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10); 
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [typeFilter, setTypeFilter] = useState<string[]>([]);

  useEffect(() => {
    fetchTransactionHistory({ page: currentPage, limit: limit });
  }, [currentPage, limit]);

  const filteredTransactions = transactions.filter(transaction => {
    const searchLower = searchTerm.toLowerCase();
    const matchesSearch = 
      transaction.transactionId.toLowerCase().includes(searchLower) || 
      (transaction.receiver && transaction.receiver.userId.toLowerCase().includes(searchLower));
    
    const matchesStatus = statusFilter.length === 0 || statusFilter.includes(getTransactionStatus(transaction.status));
    
    const matchesType = typeFilter.length === 0 || typeFilter.includes(getTransactionType(transaction.transactionType));
    
    return matchesSearch && matchesStatus && matchesType;
  });


  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= pagination.totalPages) { // ✅ Ensure page is valid
      setCurrentPage(page); // ✅ Update the state
    }
  };

  
  return (
    <Card className={className} style={style}>
      <CardHeader className="px-6 pt-6 pb-4">
        <div className="flex flex-col sm:flex-row sm:items-center justify-between space-y-2 sm:space-y-0">
          <CardTitle className="text-base font-semibold">Recent Transactions</CardTitle>
          <div className="flex items-center space-x-2">
            <div className="relative w-full sm:w-64">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search transactions..."
                className="pl-9 h-9 w-full text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" size="sm" className="h-9 px-2 lg:px-3 hidden sm:flex">
                  <Filter className="h-4 w-4 mr-1" />
                  <span className="hidden lg:inline-block">Filters</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-64 p-4" align="end">
                <div className="space-y-4">
                  <div>
                    <h4 className="text-sm font-medium mb-2">Transaction Type</h4>
                    <div className="flex flex-wrap gap-2">
                      {['deposit', 'withdrawal', 'transfer', 'buy'].map((type) => (
                        <Badge 
                          key={type}
                          variant={typeFilter.includes(type) ? 'default' : 'outline'}
                          className="cursor-pointer capitalize"
                          onClick={() => {
                            setTypeFilter(prev => 
                              prev.includes(type) 
                                ? prev.filter(t => t !== type) 
                                : [...prev, type]
                            );
                          }}
                        >
                          {type}
                        </Badge>
                      ))}
                    </div>
                  </div>
                  
                  <div>
                    <h4 className="text-sm font-medium mb-2">Status</h4>
                    <div className="flex flex-wrap gap-2">
                      {['Pending', 'Processing' ,'Successful', 'Failed'].map((status) => (
                        <Badge 
                          key={status}
                          variant={statusFilter.includes(status) ? 'default' : 'outline'}
                          className="cursor-pointer capitalize"
                          onClick={() => {
                            setStatusFilter(prev => 
                              prev.includes(status) 
                                ? prev.filter(s => s !== status) 
                                : [...prev, status]
                            );
                          }}
                        >
                          {status}
                        </Badge>
                      ))}
                    </div>
                  </div>
                  
                  <div className="flex justify-between">
                    <Button 
                      variant="ghost" 
                      size="sm"
                      onClick={() => {
                        setTypeFilter([]);
                        setStatusFilter([]);
                      }}
                    >
                      Reset
                    </Button>
                    <Button 
                      size="sm"
                      onClick={() => {
                        // Close popover (in a real implementation)
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </CardHeader>
      <CardContent className="px-0 overflow-auto">
        {loading ? (
          <div className="flex justify-center py-10">
            <Spinner />
          </div>
        ) : error ? (
          <div className="rounded-lg border border-border p-8 text-center">
            <h2 className="text-xl font-medium mb-2">Error</h2>
            <p className="text-muted-foreground">{error}</p>
          </div>
        ) : filteredTransactions.length === 0 ? (
          <div className="rounded-lg border border-border p-8 text-center">
            <h2 className="text-xl font-medium mb-2">No transactions found</h2>
            <p className="text-muted-foreground">
              Your transaction history will appear here once you start trading.
            </p>
          </div>
        ) : (
          <>
            <Table>
              <TableHeader className="bg-muted/30">
                <TableRow>
                  <TableHead className="w-[100px] pl-6">Type</TableHead>
                  <TableHead>Amount</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead className="hidden sm:table-cell">Date</TableHead>
                  <TableHead className="hidden md:table-cell">ID</TableHead>
                  <TableHead className="text-right pr-6">Details</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredTransactions.map((transaction) => (
                  <TransactionRow key={transaction._id} transaction={transaction} />
                ))}
              </TableBody>
            </Table>
            {pagination && (
              <div className="flex justify-between items-center mt-4 px-6">
                <Button
                  onClick={() => handlePageChange(pagination.currentPage - 1)}
                  disabled={pagination.currentPage === 1}
                >
                  Previous
                </Button>
                <span>
                  Page {pagination.currentPage} of {pagination.totalPages}
                </span>
                <Button
                  onClick={() => handlePageChange(pagination.currentPage + 1)}
                  disabled={pagination.currentPage === pagination.totalPages}
                >
                  Next
                </Button>
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

const TransactionRow = ({ transaction }: { transaction: Transaction }) => {
  const { transactionId, transactionType, amount, payload, status, createdAt } = transaction;
  const date = parseISO(createdAt);
  
  return (
    <TableRow className="group">
      <TableCell className="pl-6">
        <div className="flex items-center space-x-2">
          <div className={`p-1 rounded-full ${transactionType.toLowerCase().includes('deposit') ? 'bg-success/10 text-success' : transactionType.toLowerCase().includes('withdrawal') ? 'bg-warning/10 text-warning' : 'bg-primary/10 text-primary'}`}>
            {transactionIcons[getTransactionType(transactionType)]}
          </div>
          <span className="capitalize text-sm">{transactionType}</span>
        </div>
      </TableCell>
      <TableCell>
        <span className="font-medium">
          {getTransactionType(transactionType) === 'deposit' ? '+' : getTransactionType(transactionType) === 'withdrawal' ? '-' : ''}{payload?.data?.currency || ''} {payload?.data?.amount.toFixed(2)}
        </span>
      </TableCell>
      <TableCell>
        <div className="flex items-center space-x-1">
          {transactionStatus[status]}
          <span className="capitalize text-sm">{status}</span>
        </div>
      </TableCell>
      <TableCell className="hidden sm:table-cell text-muted-foreground text-sm">
        {format(date, 'MMM d, yyyy')}
      </TableCell>
      <TableCell className="hidden md:table-cell text-muted-foreground text-sm font-mono">
        {transactionId}
      </TableCell>
      <TableCell className="text-right pr-6">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[200px]">
            <div className="p-2">
              <div className="text-xs font-medium mb-1">Transaction Details</div>
              <div className="text-xs text-muted-foreground mb-2">{format(date, 'PPpp')}</div>
              
              {transaction.receiver && (
                <div className="mb-1">
                  <div className="text-xs font-medium">Receiver:</div>
                  <div className="text-xs font-mono truncate">{transaction.receiver.userId}</div>
                </div>
              )}
              
              <div className="mt-2 flex justify-end">
                <Button variant="ghost" size="sm" className="h-7 text-xs">
                  View Full Details
                </Button>
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
};

export default TransactionList;