import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Loader2 } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Link } from 'react-router-dom';
import { toast } from 'sonner';
import authService from '@/services/authService';
import { PersonalInfoFields } from './PersonalInfoFields';
import { ContactInfoFields } from './ContactInfoFields';
import { PasswordFields } from './PasswordFields';
import VerifyOTPModal from '@/components/profile/VerifyOTPModal';
import { AxiosError } from 'axios';

export interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  //country: string;
  //phoneNumber: string;
  //dialCode: string;
  //dateOfBirth: string;
  password: string;
  confirmPassword: string;
}

export const SignUpForm = () => {
  const [formData, setFormData] = useState<SignUpFormData>({
    firstName: '',
    lastName: '',
    email: '',
    //country: '',
    //phoneNumber: '',
    //dialCode: '',
    //dateOfBirth: '',
    password: '',
    confirmPassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleCountryChange = (selectedCountry: { name: string; dial_code: string }) => {
    setFormData(prev => ({
      ...prev,
      country: selectedCountry.name,
      dialCode: selectedCountry.dial_code
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Basic validation
    if (formData.password !== formData.confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    
    setIsLoading(true);
    
    try {
      await authService.register({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password
      });
      
      toast.success('Registration successful! Please verify your email.');
      setShowOTPModal(true);
    } catch (error) {
      console.error('Registration error:', error);
      const axiosError = error as AxiosError<{ message: string }>;
      toast.error(axiosError.response?.data?.message || 'Failed to register');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle successful verification
  const handleVerificationSuccess = () => {
    setShowOTPModal(false);
    navigate('/login');
    toast.success('Email verified successfully! You can now log in.');
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Create an Account</CardTitle>
          <CardDescription>Enter your details to sign up</CardDescription>
        </CardHeader>
        <form onSubmit={handleSubmit}>
          <CardContent className="space-y-4">
            <PersonalInfoFields 
              formData={formData} 
              handleChange={handleChange} 
            />
            
            {/* <ContactInfoFields 
              formData={formData} 
              handleChange={handleChange}
              handleCountryChange={handleCountryChange} 
            /> */}
            
            <PasswordFields 
              formData={formData} 
              handleChange={handleChange} 
            />
          </CardContent>
          <CardFooter className="flex flex-col space-y-4">
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
              {isLoading ? 'Creating account...' : 'Sign Up'}
            </Button>
            <div className="text-center text-sm">
              Already have an account?{' '}
              <Link to="/login" className="text-primary hover:underline">
                Log in
              </Link>
            </div>
          </CardFooter>
        </form>
      </Card>

      {/* OTP Verification Modal */}
      <VerifyOTPModal 
        isOpen={showOTPModal}
        onClose={() => setShowOTPModal(false)}
        email={formData.email}
        onVerificationSuccess={handleVerificationSuccess}
      />
    </>
  );
};
