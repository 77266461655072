import { useEffect, useState } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Card } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import AssetTable from '@/components/wallet/AssetTable';
import WalletSearch from '@/components/wallet/WalletSearch';
import { useWallet } from '@/contexts/WalletContext';
import { Button } from '@/components/ui/button';
import { AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/contexts/UserContext';
import TransactionList from '@/components/transaction/TransactionList';


interface ApiError {
  status?: boolean;
  code?: number;
  message?: string;
  response?: {
    data?: {
      status?: boolean;
      code?: number;
      message?: string;
    };
  };
}

const Wallet = () => {
  const { assets, fetchAssets, loading, error } = useWallet();
  const [searchTerm, setSearchTerm] = useState('');
  const [assetType, setAssetType] = useState('All');
  const [needsVerification, setNeedsVerification] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('assets'); // Track active tab


  // This useEffect should run only once on component mount
  useEffect(() => {
    console.log(user.kycLevel);
    const fetchData = async () => {
      try {
        await fetchAssets();
      } catch (error: unknown) {
        console.log("Error caught:", error);
      }
    };

    fetchData();
    document.title = 'EvoCash | Wallet';
  }, []); // Empty dependency array to run only once

  // Use a separate useEffect to check the error from context
  useEffect(() => {
    if (error) {
      console.log("Error from context:", error);
      // Set needsVerification to true for any error from context
      // This ensures users complete verification if we can't confirm their profile status
      if(user.kycLevel === 0){
        setNeedsVerification(true);
      }
    }
  }, [error]);

  // Navigate to profile for verification
  const handleVerificationRedirect = () => {
    navigate('/dashboard/profile');
  };

  // Filter assets based on search and type
  const filteredAssets = assets.filter(asset => {
    const matchesSearch = asset.assetName.toLowerCase().includes(searchTerm.toLowerCase()) || 
                          asset.symbol.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = assetType === 'All' || asset.type === assetType;
    return matchesSearch && matchesType;
  });

  // Verification Card Component
  const VerificationCard = () => (
    <Card className="p-6 mb-6 border-amber-500 dark:border-amber-400 border-2">
      <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
        <div className="flex items-center justify-center p-2 rounded-full bg-amber-100 dark:bg-amber-900">
          <AlertCircle className="h-6 w-6 text-amber-500 dark:text-amber-400" />
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-semibold mb-1">Verification Required</h3>
          <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
            You need to complete your profile by setting your country, date of birth, and phone number to access wallet features.
          </p>
          <Button 
            onClick={handleVerificationRedirect}
            className="bg-amber-500 hover:bg-amber-600 text-white dark:bg-amber-600 dark:hover:bg-amber-700"
          >
            Complete Verification
          </Button>
        </div>
      </div>
    </Card>
  );

  // Remove the conditional useEffect and replace with a simple derived state variable
  const shouldShowVerificationCard = needsVerification || (
    error && 
    typeof error === 'object' && 
    ((error as ApiError).code === 403 || 
     (error as ApiError).status === false && (error as ApiError).code === 403 ||
     (error as ApiError).message?.includes("country, date of birth and phone number") ||
     (error as ApiError).response?.data?.code === 403 ||
     (error as ApiError).response?.data?.message?.includes("country, date of birth and phone number"))
  );

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-6 animate-fade-in">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <h1 className="text-3xl font-bold tracking-tight">Wallet</h1>
          {activeTab === 'assets' && ( // Conditionally render WalletSearch
            <WalletSearch 
              searchTerm={searchTerm} 
              setSearchTerm={setSearchTerm} 
              setAssetType={setAssetType} 
            />
          )}
        </div>

        {/* Debug: Show error information
        {process.env.NODE_ENV !== 'production' && error && (
          <Card className="p-4 mb-4 border-red-500 dark:border-red-400 border-2">
            <h3 className="font-semibold">Debug: Error Information</h3>
            <pre className="text-xs overflow-auto mt-2 p-2 bg-gray-100 dark:bg-gray-800 rounded">
              {typeof error === 'object' ? JSON.stringify(error, null, 2) : error}
            </pre>
            <p className="mt-2">needsVerification state: {needsVerification ? 'true' : 'false'}</p>
            <p className="mt-2">shouldShowVerificationCard: {shouldShowVerificationCard ? 'true' : 'false'}</p>
          </Card>
        )} */}

        {/* Verification Card - Shown when user needs to verify */}
        {shouldShowVerificationCard && <VerificationCard />}

        <Tabs defaultValue="assets" className="w-full" onValueChange={setActiveTab}>
          <TabsList className="w-full sm:w-auto">
            <TabsTrigger value="assets" className="flex-1 sm:flex-none">Assets</TabsTrigger>
            <TabsTrigger value="transactions" className="flex-1 sm:flex-none">Transactions</TabsTrigger>
          </TabsList>
          <TabsContent value="assets" className="mt-6">
            <div className="mx-auto max-w-4xl">
              <Card className="overflow-hidden">
                <div className="overflow-x-auto">
                  {loading ? (
                    <div className="flex justify-center items-center p-6">
                      <span>Loading...</span> {/* Display loading message */}
                    </div>
                  ) : error && !needsVerification ? (
                    <div className="flex justify-center items-center p-6">
                      <span>{typeof error === 'string' ? error : 'An error occurred loading your assets'}</span>
                    </div>
                  ) : filteredAssets.length === 0 && !needsVerification ? (
                    <div className="flex justify-center items-center p-6">
                      <span>No assets available</span>
                    </div>
                  ) : !needsVerification ? (
                    <AssetTable assets={filteredAssets} />
                  ) : (
                    <div className="flex justify-center items-center p-6">
                      <span>Complete verification to view your assets</span>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </TabsContent>
          <TabsContent value="transactions" className="mt-6">
            <TransactionList/>
          </TabsContent>
        </Tabs>
      </div>
    </DashboardLayout>
  );
};

export default Wallet;
