
import { useEffect } from 'react';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import TransactionList from '@/components/transaction/TransactionList';
import { Button } from '@/components/ui/button';
import { CreditCard, Wallet } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const Transactions = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'EvoCash | Transactions';
  }, []);

  return (
    <DashboardLayout>
      <div className="flex flex-col space-y-8">
        <section className="mb-8">
          <div className="rounded-xl bg-gradient-to-r from-primary/10 via-primary/5 to-background p-8 backdrop-blur-sm border">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
                    <CreditCard className="h-4 w-4 text-primary" />
                  </div>
                  <h1 className="text-2xl font-bold tracking-tight">
                    Transactions
                  </h1>
                </div>
                <p className="text-muted-foreground">
                  Track and manage your transaction history across all activities
                </p>
              </div>
              
              <div className="flex gap-3">
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => navigate('/dashboard/wallet')}
                >
                  <Wallet className="mr-2 h-4 w-4" />
                  View Wallet
                </Button>
              </div>
            </div>
          </div>
        </section>

        <TransactionList />
      </div>
    </DashboardLayout>
  );
};

export default Transactions;

