
import { Search, Filter } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface WalletSearchProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setAssetType: (type: string) => void;
}

const WalletSearch = ({ searchTerm, setSearchTerm, setAssetType }: WalletSearchProps) => {
  return (
    <div className="flex flex-col sm:flex-row items-center gap-2 w-full sm:w-auto">
      <div className="relative w-full sm:w-60">
        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          type="search"
          placeholder="Search assets..."
          className="pl-8 w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" size="sm" className="ml-0 sm:ml-2 w-full sm:w-auto">
            <Filter className="h-4 w-4 mr-2" />
            <span>Filter</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => setAssetType('All')}>
            All Assets
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setAssetType('Fiat')}>
            Fiat Only
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setAssetType('Crypto')}>
            Crypto Only
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default WalletSearch;
