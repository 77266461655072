import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

interface GoldPriceData {
  gold: {
    usd: number;
    usd_24h_change: number;
  };
}

export const useGoldPrice = () => {
  return useQuery({
    queryKey: ['gold-price'],
    queryFn: async (): Promise<GoldPriceData> => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=tether-gold&vs_currencies=usd&include_24hr_change=true',
          { timeout: 5000 }
        );

        if (!response.data['tether-gold']) {
          throw new Error('Invalid response from CoinGecko API');
        }

        return {
          gold: {
            usd: response.data['tether-gold'].usd,
            usd_24h_change: response.data['tether-gold'].usd_24h_change || 0,
          },
        };
      } catch (error) {
        console.error('Error fetching gold price:', error);
        throw error;
      }
    },
    refetchInterval: 60000, // Refresh every minute
  });
};
