// import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import DashboardLayout from '@/components/dashboard/DashboardLayout';
// import { Button } from '@/components/ui/button';
// import { Input } from '@/components/ui/input';
// import { CountrySelect } from '@/components/ui/country-select';
// import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
// import { Label } from '@/components/ui/label';
// import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
// import { useToast } from '@/components/ui/use-toast';
// import { useUser } from '@/contexts/UserContext';
// import { usePageTitleEffect } from '@/hooks/usePageTitleEffect';
// import { Save, Loader2 } from 'lucide-react';


// // Define KYC level type
// export type KYCLevel = 0 | 1 | 2 | 3;

// const ProfilePage = () => {
//   const { user, fetchUserProfile, updateUser, updateUserProfile, updateUserEmail, updateUserKYC } = useUser();
//   const navigate = useNavigate();
//   const { toast } = useToast();
//   const [activeForm, setActiveForm] = useState<'names' | 'email' | 'kyc' | null>(null);
//   const [isLoadingNames, setIsLoadingNames] = useState(false);
//   const [isLoadingEmail, setIsLoadingEmail] = useState(false);
//   const [isLoadingKYC, setIsLoadingKYC] = useState(false);
//   const [isLoadingProfile, setIsLoadingProfile] = useState(false);

//   // Set page title
//   usePageTitleEffect('Profile Settings');

//   // Form state
//   const [formData, setFormData] = useState({
//     firstName: user?.firstName || '',
//     lastName: user?.lastName || '',
//     email: user?.email || '',
//     phoneNumber: user?.phoneNumber || '',
//     country: user?.country || '',
//     dialCode: '',
//   });

//   const [namesData, setNamesData] = useState({
//     firstName: user?.firstName || '',
//     lastName: user?.lastName || '',
//   });

//   const [emailData, setEmailData] = useState({
//     oldEmail: user?.email || '',
//     email: '',
//   });

//   const [kycData, setKycData] = useState({
//     country: user?.country || '',
//     phoneNumber: user?.phoneNumber || '',
//     dateOfBirth: '',
//     dialCode: '',
//   });



//   useEffect(() => {
//     // Update form if user data changes
//     if (user) {
//       setFormData({
//         firstName: user.firstName || '',
//         lastName: user.lastName || '',
//         email: user.email || '',
//         phoneNumber: user.phoneNumber || '',
//         country: user.country || '',
//         dialCode: '',
//       });

//       setNamesData({
//         firstName: user.firstName || '',
//         lastName: user.lastName || '',
//       });
//       setEmailData({
//         oldEmail: user.email || '',
//         email: '',
//       });
//       setKycData({
//         country: user.country || '',
//         phoneNumber: user.phoneNumber || '',
//         dateOfBirth: '',
//         dialCode: '',
//       });
//     }
//   }, [user]);

//   // Get user initials for avatar
//   const userInitials = formData.firstName && formData.lastName
//     ? `${formData.firstName[0]}${formData.lastName[0]}`.toUpperCase()
//     : formData.email?.[0]?.toUpperCase() || 'U';

//   const handleNamesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setNamesData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//   };

//   const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setEmailData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//   };

//   const handleCountryChange = (selectedCountry: { name: string; dial_code: string }) => {
//     setKycData(prev => ({
//       ...prev,
//       country: selectedCountry.name,
//       dialCode: selectedCountry.dial_code
//     }));
//   };

//   const handleKycChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     setKycData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//   };

//   const handleNamesSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsLoadingNames(true);

//     try {
//       // Update user profile in context
//       await updateUserProfile(namesData);
//       await fetchUserProfile();

//     } catch (error) {

//     } finally {
//       setIsLoadingNames(false);
//       setActiveForm(null); // Close the form after submission
//     }
//   };

//   const handleEmailSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsLoadingEmail(true);

//     try {
//       // Update user email in context
//       await updateUserEmail(emailData);
//       await fetchUserProfile();

//     } catch (error) {

//     } finally {
//       setIsLoadingEmail(false);
//       setActiveForm(null); // Close the form after submission
//     }
//   };

//   const handleKycSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     setIsLoadingKYC(true);

//     try {
//       // Update user KYC in context
//       const formattedPhoneNumber = `${kycData.dialCode}${kycData.phoneNumber.replace(/^0+/, '')}`;

//       // Update user KYC in context
//       await updateUserKYC({
//         country: kycData.country,
//         phoneNumber: formattedPhoneNumber,
//         dateOfBirth: kycData.dateOfBirth,
//       });
//       await fetchUserProfile();

//     } catch (error) {
//       console.error('Error updating KYC:', error);

//     } finally {
//       setIsLoadingKYC(false);
//       setActiveForm(null); // Close the form after submission
//     }
//   };



//   return (
//     <DashboardLayout>
//       <div className="container max-w-4xl py-6 space-y-6 animate-fade-in">
//         <div className="flex flex-col gap-2">
//           <p className="text-muted-foreground">
//             View and manage your personal information
//           </p>
//         </div>

//         <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
//           {/* Profile Summary Card */}
//           <Card className="lg:col-span-1">
//             <CardHeader>
//               <CardTitle>Your Profile</CardTitle>
//               <CardDescription>Your personal information</CardDescription>
//             </CardHeader>
//             <CardContent className="flex flex-col items-center justify-center pt-6 pb-8 space-y-4">
//               <Avatar className="h-24 w-24 mb-2">
//                 <AvatarImage src={user._id || ""} />
//                 <AvatarFallback className="text-2xl bg-primary text-primary-foreground">
//                   {userInitials}
//                 </AvatarFallback>
//               </Avatar>
//               <div className="text-center">
//                 <h3 className="text-xl font-medium">
//                   {formData.firstName ? `${formData.firstName} ${formData.lastName}` : 'User'}
//                 </h3>
//                 <p className="text-sm text-muted-foreground mt-1">{formData.email}</p>
//               </div>
//             </CardContent>
//           </Card>

//           {/* Edit Profile Form */}
//           <Card className="lg:col-span-2">
//             <CardHeader>
//               <CardTitle>Manage Profile</CardTitle>
//               <CardDescription>Manage your personal information</CardDescription>
//             </CardHeader>
//             <CardContent className="space-y-4">
//               <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                 <div className="space-y-2">
//                   <Label htmlFor="firstName">First Name</Label>
//                   <Input
//                     id="firstName"
//                     name="firstName"
//                     value={formData.firstName}
//                     disabled
//                   />
//                 </div>

//                 <div className="space-y-2">
//                   <Label htmlFor="lastName">Last Name</Label>
//                   <Input
//                     id="lastName"
//                     name="lastName"
//                     value={formData.lastName}
//                     disabled
//                   />
//                 </div>
//               </div>

//               <div className="space-y-2">
//                 <Label htmlFor="email">Email</Label>
//                 <Input
//                   id="email"
//                   name="email"
//                   type="email"
//                   value={formData.email}
//                   disabled
//                 />
//                 <p className="text-xs text-muted-foreground">Email cannot be changed</p>
//               </div>

//               <div className="space-y-2">
//                 <Label htmlFor="phoneNumber">Phone Number</Label>
//                 <Input
//                   id="phoneNumber"
//                   name="phoneNumber"
//                   value={formData.phoneNumber}
//                   disabled
//                 />
//               </div>

//               <div className="space-y-2">
//                 <Label htmlFor="country">Country</Label>
//                 <Input
//                   id="country"
//                   name="country"
//                   value={formData.country}
//                   disabled
//                 />
//               </div>
//             </CardContent>

//             {/* Edit Profile Form */}
//             <CardFooter className="flex justify-end gap-2">
//               <Button onClick={() => setActiveForm('names')}>Edit Profile</Button>
//               <Button onClick={() => setActiveForm('email')} disabled={!formData.email}>Update Email</Button>
//               <Button onClick={() => setActiveForm('kyc')} disabled={!formData.country}>Update Contact</Button>
//             </CardFooter>
//           </Card>
//         </div>

//         {activeForm === 'names' && (
//           <Card className="lg:col-span-2 lg:col-start-2">
//             <form onSubmit={handleNamesSubmit}>
//               <CardHeader>
//                 <CardTitle>Edit Names</CardTitle>
//                 <CardDescription>Update your personal information</CardDescription>
//               </CardHeader>
//               <CardContent className="space-y-4">
//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                   <div className="space-y-2">
//                     <Label htmlFor="firstName">First Name</Label>
//                     <Input
//                       id="firstName"
//                       name="firstName"
//                       value={namesData.firstName}
//                       onChange={handleNamesChange}
//                       placeholder="Enter your first name"
//                     />
//                   </div>

//                   <div className="space-y-2">
//                     <Label htmlFor="lastName">Last Name</Label>
//                     <Input
//                       id="lastName"
//                       name="lastName"
//                       value={namesData.lastName}
//                       onChange={handleNamesChange}
//                       placeholder="Enter your last name"
//                     />
//                   </div>
//                 </div>
//               </CardContent>
//               <CardFooter className="flex justify-end gap-2">
//                 <Button
//                   type="submit"
//                   disabled={isLoadingNames}
//                   className="w-full md:w-auto flex items-center gap-2"
//                 >
//                   {isLoadingNames && <Loader2 className="animate-spin h-4 w-4" />}
//                   Save Changes
//                   <Save className="h-4 w-4" />
//                 </Button>
//               </CardFooter>
//             </form>
//           </Card>
//         )}

//         {activeForm === 'email' && (
//           <Card className="lg:col-span-2 lg:col-start-2">
//             <form onSubmit={handleEmailSubmit}>
//               <CardHeader>
//                 <CardTitle>Update Email</CardTitle>
//                 <CardDescription>Update your email address</CardDescription>
//               </CardHeader>
//               <CardContent className="space-y-4">
//                 <div className="space-y-2">
//                   <Label htmlFor="oldEmail">Current Email</Label>
//                   <Input
//                     id="oldEmail"
//                     name="oldEmail"
//                     type="email"
//                     value={emailData.oldEmail}
//                     onChange={handleEmailChange}
//                     placeholder="Enter your current email"
//                     disabled
//                   />
//                 </div>
//                 <div className="space-y-2">
//                   <Label htmlFor="email">New Email</Label>
//                   <Input
//                     id="email"
//                     name="email"
//                     type="email"
//                     value={emailData.email}
//                     onChange={handleEmailChange}
//                     placeholder="Enter your new email"
//                   />
//                 </div>
//               </CardContent>
//               <CardFooter className="flex justify-end gap-2">
//                 <Button
//                   type="submit"
//                   disabled={isLoadingEmail}
//                   className="w-full md:w-auto flex items-center gap-2"
//                 >
//                   {isLoadingEmail && <Loader2 className="animate-spin h-4 w-4" />}
//                   Save Changes
//                   <Save className="h-4 w-4" />
//                 </Button>
//               </CardFooter>
//             </form>
//           </Card>
//         )}

//         {activeForm === 'kyc' && (
//           <Card className="lg:col-span-2 lg:col-start-2">
//             <form onSubmit={handleKycSubmit}>
//               <CardHeader>
//                 <CardTitle>Update Contact info</CardTitle>
//                 <CardDescription>Update your Contact information</CardDescription>
//               </CardHeader>
//               <CardContent className="space-y-4">
//                 <div className="space-y-2">
//                   <Label htmlFor="country-select-input">Country</Label>
//                   <CountrySelect
//                     id="country"
//                     value={kycData.country}
//                     onChange={handleCountryChange}
//                   />
//                 </div>

//                 <div className="space-y-2">
//                   <Label htmlFor="phone-number-input">Phone Number</Label>
//                   <div className="flex items-center space-x-2">
//                     <div className="rounded-md border border-input bg-background px-3 py-2 text-sm w-auto h-10 flex items-center">
//                       {kycData.dialCode || '+XX'}
//                     </div>
//                     <Input
//                       id="phone-number-input"
//                       name="phoneNumber"
//                       value={kycData.phoneNumber}
//                       onChange={handleKycChange}
//                       placeholder="Enter number without leading zeros"
//                       className="flex-1"
//                       required
//                     />
//                   </div>
//                 </div>

//                 <div className="space-y-2">
//                   <Label htmlFor="dateOfBirth">Date of Birth</Label>
//                   <Input
//                     id="dateOfBirth"
//                     name="dateOfBirth"
//                     type="date"
//                     value={kycData.dateOfBirth}
//                     onChange={handleKycChange}
//                     placeholder="Enter your date of birth"
//                     required
//                   />

//                 </div>
//               </CardContent>
//               <CardFooter className="flex justify-end gap-2">
//                 <Button
//                   type="submit"
//                   disabled={isLoadingKYC}
//                   className="w-full md:w-auto flex items-center gap-2"
//                 >
//                   {isLoadingKYC && <Loader2 className="animate-spin h-4 w-4" />}
//                   Save Changes
//                   <Save className="h-4 w-4" />
//                 </Button>
//               </CardFooter>
//             </form>
//           </Card>
//         )}

//       </div>
//     </DashboardLayout>
//   );
// };

// export default ProfilePage;





import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from '@/components/ui/card';
import { AlertCircle, Save, Loader2, RefreshCw } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { useUser } from '@/contexts/UserContext';
import ProfileImageUploader from '@/components/profile/ProfileImageUploader';
import KYCVerification from '@/components/profile/KYCVerification';
import KYCForms from '@/components/profile/KYCForms';
import VerifyOTPModal from '@/components/profile/VerifyOTPModal';
import DashboardLayout from '@/components/dashboard/DashboardLayout';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { z } from 'zod';
import { u } from 'node_modules/framer-motion/dist/types.d-B50aGbjN';

// Define KYC level type
export type KYCLevel = 0 | 1 | 2 | 3;

// Create a schema for form validation
const profileFormSchema = z.object({
  firstName: z.string().min(3, "First name must be at least 3 characters"),
  lastName: z.string().min(3, "Last name must be at least 3 characters"),
  email: z.string().email("Invalid email address").optional(),
  phoneNumber: z.string().optional(),
  country: z.string().optional(),
});

type ProfileFormValues = z.infer<typeof profileFormSchema>;

// Helper function to validate KYC level
const checkKYCLevel = (level: number | null | undefined): KYCLevel => {
  if (level === 1 || level === 2 || level === 3) {
    return level;
  }
  return 0; // Default to 0 for any invalid or unset value
};

const ProfilePage: React.FC = () => {
  const { user, fetchUserProfile, updateUserProfile, updateUserEmail, updateUserKYC } = useUser();
  const { toast } = useToast();

  // State management
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isKYCDialogOpen, setIsKYCDialogOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch fresh profile data from backend
        await fetchUserProfile();
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setIsLoadingProfile(false);
      }
    };

    fetchData();
  }, []);


  
  // Initialize form with user data
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
      country: user?.country || '',
    },
  });
  
  useEffect(() => {
    // Update form if user data changes
    if (user) {
      form.reset({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        phoneNumber: user.phoneNumber || '',
        country: user.country || '',
      });
    }
  }, [user, form]);
  
  // Get user initials for avatar
  const firstName = form.watch('firstName');
  const lastName = form.watch('lastName');
  const email = form.watch('email');
  
  const userInitials = firstName && lastName 
    ? `${firstName[0]}${lastName[0]}`.toUpperCase()
    : email?.[0]?.toUpperCase() || 'U';
  
 
  
  // Handle KYC verification button click
  const handleKYCVerify = (level: number) => {
    setIsKYCDialogOpen(true);
  };
  
  // Handle KYC verification completion
  const handleKYCComplete = async () => {
    try {
      await fetchUserProfile();
      toast({
        title: 'Success',
        description: 'KYC status updated successfully',
      });
    } catch (error) {
      console.error('Error updating KYC status:', error);
      toast({
        title: 'Error',
        description: 'Failed to update KYC status',
      });
    }
  };

  // Show loading state while fetching profile
  if (isLoadingProfile) {
    return (
      <DashboardLayout>
        <div className="container max-w-4xl py-6 space-y-6 animate-fade-in">
          <div className="flex items-center justify-center h-[400px]">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        </div>
      </DashboardLayout>
    );
  }
  

  return (
    <DashboardLayout>
      <div className="container max-w-4xl py-6 space-y-6 animate-fade-in">
        {/* Only show profile content if there are no errors */}
        {!isLoadingProfile && user ? (
          <>
            <div className="flex flex-col gap-2">
              <p className="text-muted-foreground">
                View and manage your personal information
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              {/* Profile Summary Card */}
              <Card className="lg:col-span-1">
                <CardHeader>
                  <CardTitle>Your Profile</CardTitle>
                  <CardDescription>Your personal information</CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col items-center justify-center pt-6 pb-8 space-y-4">
                  {/* Profile Image */}
                  <ProfileImageUploader 
                    imageUrl={user?.avatar || null}
                    initials={userInitials}
                  />
                  <div className="text-center">
                    <h3 className="text-xl font-medium">
                      {firstName ? `${firstName} ${lastName}` : 'User'}
                    </h3>
                    <p className="text-sm text-muted-foreground mt-1">{email}</p>
                  </div>
                  
                  {/* KYC Verification Component */}
                  <KYCVerification 
                    user={user}
                    onVerifyClick={handleKYCVerify}
                  />
                </CardContent>
              </Card>

              {/* Edit Profile Form */}
              <Card className="lg:col-span-2">
                <Form {...form}>
                  <form >
                    <CardHeader>
                      <CardTitle>Edit Profile</CardTitle>
                      <CardDescription>Update your personal information</CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <FormField
                          control={form.control}
                          name="firstName"
                          disabled
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>First Name</FormLabel>
                              <FormControl>
                                <Input {...field} placeholder="Enter your first name" />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        
                        <FormField
                          control={form.control}
                          name="lastName"
                          disabled
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>Last Name</FormLabel>
                              <FormControl>
                                <Input {...field} placeholder="Enter your last name" />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                              <Input {...field} type="email" placeholder="Enter your email" disabled />
                            </FormControl>
                            <p className="text-xs text-muted-foreground">Email cannot be changed</p>
                          </FormItem>
                        )}
                      />
                      
                      <FormField
                        control={form.control}
                        name="phoneNumber"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Phone Number</FormLabel>
                            <FormControl>
                              <Input {...field} placeholder="Enter your phone number" disabled />
                            </FormControl>
                            <p className="text-xs text-muted-foreground">Phone number cannot be changed</p>
                          </FormItem>
                        )}
                      />
                      
                      <FormField
                        control={form.control}
                        name="country"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Country</FormLabel>
                            <FormControl>
                              <Input {...field} placeholder="Enter your country" disabled />
                            </FormControl>
                            <p className="text-xs text-muted-foreground">Country cannot be changed</p>
                          </FormItem>
                        )}
                      />
                    </CardContent>
                    <CardFooter className="flex justify-end gap-2">
                    </CardFooter>
                  </form>
                </Form>
              </Card>
            </div>
          </>
        ) :
         (
          <Card className="p-6 mb-6 border-destructive dark:border-destructive border-2">
            <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
              <div className="flex items-center justify-center p-2 rounded-full bg-destructive/10 dark:bg-destructive/20">
                <AlertCircle className="h-6 w-6 text-destructive" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold mb-1">Unable to Load Profile</h3>
                <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
                  There was an error loading your profile information. Please try again later.
                </p>
                <Button 
                  onClick={() => window.location.reload()}
                  variant="destructive"
                >
                  Retry
                </Button>
              </div>
            </div>
          </Card>
        )}

        {/* KYC Verification Forms Dialog */}
        <KYCForms 
          isOpen={isKYCDialogOpen}
          onClose={() => setIsKYCDialogOpen(false)}
          currentLevel={checkKYCLevel(user?.kycLevel)}
          onVerificationComplete={handleKYCComplete}
        />
      </div>
    </DashboardLayout>
  );
};

export default ProfilePage;