
import { ReactNode } from 'react';

interface MainContentProps {
  children: ReactNode;
}

const MainContent = ({ children }: MainContentProps) => {
  return (
    <main className="flex-grow pt-20 pb-8 px-4 md:px-6 w-full max-w-7xl mx-auto">
      {children}
    </main>
  );
};

export default MainContent;
