
import React from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { toast } from 'sonner';
import {
  LayoutDashboard,
  CreditCard,
  ArrowLeftRight,
  Settings,
  HelpCircle,
  LogOut,
  Bell,
  Wallet,
  HistoryIcon,
  ChevronLeft,
  ChevronRight,
  Home,
  Activity
} from 'lucide-react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { useIsMobile } from '@/hooks/use-mobile';
import authService from '@/services/authService';
import { useUser } from '@/contexts/UserContext';
import { SidebarProps } from './sidebar/types';
import ActionButton from './sidebar/ActionButton';

const DashboardSidebar: React.FC<SidebarProps> = ({ isOpen, setIsOpen }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { clearUser, user } = useUser();

  const handleLogout = async () => {
    try {
      await authService.logout();
      clearUser();
      navigate('/');
      toast.success('Logged out successfully');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Failed to log out');
    }
  };

  const navigationItems = [
    { 
      path: '/dashboard', 
      icon: LayoutDashboard, 
      label: 'Dashboard',
      description: 'Overview of your account',
      end: true 
    },
    { 
      path: '/dashboard/transactions', 
      icon: Activity, 
      label: 'Transactions',
      description: 'View your transaction history'
    },
    { 
      path: '/dashboard/wallet', 
      icon: Wallet, 
      label: 'Wallet',
      description: 'Manage your digital assets'
    },
    { 
      path: '/dashboard/trade', 
      icon: ArrowLeftRight, 
      label: 'Trade',
      description: 'Buy and sell cryptocurrencies'
    },
  ];

  if (isMobile && !isOpen) return null;

  return (
    <aside
      className={cn(
        "border-r bg-card/50 backdrop-blur-sm flex flex-col",
        isOpen ? 'w-72' : 'w-20',
        'transition-all duration-300 ease-in-out relative',
        isMobile ? [
          'fixed bottom-0 left-0 z-50',
          'h-[85vh]',
          'rounded-t-xl',
          'border-t border-r-0',
          'shadow-lg',
        ] : 'h-screen'
      )}
    >
      {/* Header */}
      <div className="p-6 border-b flex items-center justify-between">
        {isOpen ? (
          <Link 
            to="/" 
            className="flex items-center gap-2 group"
          >
            <div className="p-2 rounded-xl bg-primary/10 text-primary">
              <Home size={22} />
            </div>
            <span className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/70">
              EvoCash
            </span>
          </Link>
        ) : (
          <Link 
            to="/" 
            className="w-full flex justify-center"
          >
            <div className="p-2 rounded-xl bg-primary/10 text-primary">
              <Home size={22} />
            </div>
          </Link>
        )}
        <Button
          variant="ghost"
          size="icon"
          className={cn(
            "h-8 w-8 rounded-full border bg-background/80 shadow-sm hover:bg-background transition-colors",
            isMobile ? 'ml-2' : 'ml-2'
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <ChevronLeft size={16} /> : <ChevronRight size={16} />}
        </Button>
      </div>

      {/* Navigation */}
      <ScrollArea className="flex-1 px-4">
        <nav className="space-y-1 py-4">
          {navigationItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              end={item.end}
              className={({ isActive }) =>
                cn(
                  "flex items-center gap-3 px-4 py-3 rounded-xl",
                  "transition-all duration-200 group relative",
                  isActive
                    ? "bg-primary/10 text-primary hover:bg-primary/15"
                    : "text-muted-foreground hover:bg-muted hover:text-foreground",
                  !isOpen && "justify-center"
                )
              }
            >
              <div className={cn(
                "flex items-center gap-3",
                !isOpen && "justify-center"
              )}>
                <item.icon size={22} className="shrink-0" />
                {isOpen && (
                  <div className="flex flex-col">
                    <span className="font-medium">{item.label}</span>
                    <span className="text-xs text-muted-foreground">{item.description}</span>
                  </div>
                )}
              </div>
            </NavLink>
          ))}
        </nav>
      </ScrollArea>

      {/* Footer Actions */}
      <div className={cn(
        "p-4 border-t bg-card/50 backdrop-blur-sm space-y-2",
        !isOpen && "flex flex-col items-center"
      )}>
        <div className="space-y-1">
          <ActionButton
            icon={Settings}
            label="Settings"
            description="Account preferences"
            disabled
            isOpen={isOpen}
          />
          <NavLink to="/dashboard/notifications">
            <ActionButton
              icon={Bell}
              label="Notifications"
              description="View alerts"
              disabled={false}
              isOpen={isOpen}
            />
          </NavLink>
          <ActionButton
            icon={HelpCircle}
            label="Help Center"
            description="Get support"
            disabled
            isOpen={isOpen}
          />
        </div>
        
        <Button
          variant="destructive"
          size="lg"
          className={cn(
            "w-full mt-4 flex items-center gap-2 h-11",
            !isOpen && "px-0 justify-center",
            "hover:bg-destructive/90 transition-colors"
          )}
          onClick={handleLogout}
        >
          <LogOut size={18} />
          {isOpen && <span>Sign Out</span>}
        </Button>
      </div>
    </aside>
  );
};

export default DashboardSidebar;
