import { useState } from 'react';
import { Progress } from '@/components/ui/progress';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Check, AlertTriangle, Info } from 'lucide-react';
import { User } from '@/contexts/UserContext';
import { cn } from '@/lib/utils';

// Interface for KYC verification props
interface KYCVerificationProps {
  user: User | null;
  onVerifyClick: (level: number) => void;
}

// KYC verification levels
export type KYCLevel = 0 | 1 | 2 | 3 | null;

// Get KYC level text based on the current level
const getKYCLevelText = (level: KYCLevel) => {
  switch (level) {
    case 0: return '0/3';
    case 1: return '1/3';
    case 2: return '2/3';
    case 3: return 'Fully Verified';
    default: return '0/3';
  }
};

// Get KYC progress percentage based on the current level
const getKYCProgressPercent = (level: KYCLevel) => {
  switch (level) {
    case 0: return 0;
    case 1: return 33;
    case 2: return 67;
    case 3: return 100;
    default: return 0;
  }
};

// Get KYC progress bar color based on the current level
const getKYCProgressColor = (level: KYCLevel) => {
  switch (level) {
    case 0: return 'bg-red-400';
    case 1: return 'bg-rose-400';
    case 2: return 'bg-amber-400';
    case 3: return 'bg-emerald-500';
    default: return 'bg-red-400';
  }
};

// Get button text based on the current level
const getVerifyButtonText = (level: KYCLevel) => {
  switch (level) {
    case 0: return 'Begin Verification';
    case 1: return 'Continue Verification';
    case 2: return 'Complete Verification';
    case 3: return 'Verified';
    default: return 'Begin Verification';
  }
};

// Helper function to validate KYC level
const validateKYCLevel = (level: number | null | undefined): KYCLevel => {
  if (level === 1 || level === 2 || level === 3) {
    return level;
  }
  return 0; // Default to 0 for any invalid or unset value
};

const KYCVerification = ({ user, onVerifyClick }: KYCVerificationProps) => {
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  
  // Extract and validate KYC level from user data
  const kycLevel = validateKYCLevel(user?.kycLevel);
  
  // Calculate the progress percentage
  const progressPercent = getKYCProgressPercent(kycLevel);
  
  // Get the color for the progress bar
  const progressColor = getKYCProgressColor(kycLevel);
  
  // Get the text for the current level
  const levelText = getKYCLevelText(kycLevel);
  
  // Get the button text
  const buttonText = getVerifyButtonText(kycLevel);
  
  // Determine if the button should be disabled
  const isButtonDisabled = kycLevel === 3;
  
  return (
    <div className="bg-blue-50 dark:bg-slate-800 p-6 rounded-2xl mt-4">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h3 className="text-lg font-medium">KYC</h3>
          <p className="text-sm text-muted-foreground">
            {kycLevel === 3 ? 'Your account is fully verified' : 'Complete verification to unlock all features'}
          </p>
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setIsInfoDialogOpen(true)}
        >
          <Info className="h-5 w-5" />
        </Button>
      </div>
      
      <div className="space-y-4">
        <div className="flex items-center justify-between mb-1">
          <span className="text-sm font-medium">Verification Level</span>
          <span className="text-sm text-muted-foreground">{levelText}</span>
        </div>
        <Progress value={progressPercent} className={progressColor} />
        <Button 
          onClick={() => onVerifyClick(kycLevel)}
          className="w-full"
          disabled={isButtonDisabled}
          variant={kycLevel === 3 ? "secondary" : "default"}
        >
          {kycLevel === 3 ? (
            <span className="flex items-center">
              {buttonText} <Check className="ml-2 h-4 w-4" />
            </span>
          ) : (
            buttonText
          )}
        </Button>
      </div>
      
      {/* Info Dialog */}
      <Dialog open={isInfoDialogOpen} onOpenChange={setIsInfoDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>About KYC Verification</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className={cn("border rounded-lg p-4", kycLevel >= 1 ? "bg-muted/20" : "")}>
              <h4 className="font-medium flex items-center">
                <span className={cn("p-1 rounded-full mr-2 text-xs", kycLevel >= 1 ? "bg-green-100 text-green-800" : "bg-blue-100 text-blue-800")}>1</span>
                Personal Information
                {kycLevel >= 1 && <Check className="ml-2 h-4 w-4 text-green-500" />}
              </h4>
              <p className="text-sm text-muted-foreground mt-2">
                Verify your basic information including your name, date of birth, and phone number.
              </p>
            </div>
            
            <div className={cn("border rounded-lg p-4", kycLevel >= 2 ? "bg-muted/20" : "")}>
              <h4 className="font-medium flex items-center">
                <span className={cn("p-1 rounded-full mr-2 text-xs", kycLevel >= 2 ? "bg-green-100 text-green-800" : "bg-blue-100 text-blue-800")}>2</span>
                Identity Verification
                {kycLevel >= 2 && <Check className="ml-2 h-4 w-4 text-green-500" />}
              </h4>
              <p className="text-sm text-muted-foreground mt-2">
                Upload a valid ID document (passport or ID card) to verify your identity.
              </p>
            </div>
            
            <div className={cn("border rounded-lg p-4", kycLevel >= 3 ? "bg-muted/20" : "")}>
              <h4 className="font-medium flex items-center">
                <span className={cn("p-1 rounded-full mr-2 text-xs", kycLevel >= 3 ? "bg-green-100 text-green-800" : "bg-blue-100 text-blue-800")}>3</span>
                Address Verification
                {kycLevel >= 3 && <Check className="ml-2 h-4 w-4 text-green-500" />}
              </h4>
              <p className="text-sm text-muted-foreground mt-2">
                Upload a document to verify your residential address.
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default KYCVerification;
