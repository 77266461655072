import React, { createContext, useContext, useEffect, useState } from 'react';
import notificationService from '@/services/notificationService';
import { Notification } from '@/components/dashboard/notifications/NotificationModel'; // Ensure you import the Notification model
import { useUser } from '@/contexts/UserContext';

interface NotificationContextType {
  notifications: Notification[];
  loading: boolean;
  error: string | null;
  fetchNotifications: (page: number) => Promise<any[]>;
  markAsRead: (notificationId: string) => Promise<void>;
  deleteNotification: (notificationId: string) => Promise<void>;
  deleteAllNotifications: () => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useUser();

  const fetchNotifications = async (page: number) => {
    if (!user || !user._id) return;
    if(error != null ) return [];
    setLoading(true);

    try {
        const newNotifications = await notificationService.getNotifications(page);
        // Combine previous notifications with new ones and filter for uniqueness
        if (Array.isArray(newNotifications) && newNotifications.length > 0) {
            setNotifications(prev => {
                // Create a Set to store unique notification IDs
                const uniqueNotifications = new Map();
        
                // Add previous notifications
                prev.forEach(n => uniqueNotifications.set(n._id, n));
        
                // Add new notifications (only if they don't already exist)
                newNotifications.forEach(n => uniqueNotifications.set(n._id, n));
        
                // Convert back to an array
                return Array.from(uniqueNotifications.values());
            });
            return newNotifications
        } else if (newNotifications.length === 0 ) {
          setError('no more data');
          return [];
        }else if (newNotifications === null ) {
            setError('Error: API returned null or undefined notifications');
        } else {
            setError('No new notifications found'); // Handle empty response more gracefully
        }
    } catch (err) {
      setError('Error fetching notifications');
      //setNotifications([]);
    } finally {
      setLoading(false);
    }
  };

  const markAsRead = async (notificationId: string) => {
    try {
      const updatedNotification = await notificationService.markAsRead(notificationId);
      if (updatedNotification.read) {
        setNotifications(prev => prev.map(notification => 
          notification._id === notificationId ? { ...notification, read: true } : notification
        ));
      }
    } catch (err) {
      setError('Error marking notification as read');
    }
  };

  const deleteNotification = async (notificationId: string) => {
    try {
      const response = await notificationService.deleteNotification(notificationId);
      if (response.status) {
        setNotifications(prev => prev.filter(notification => notification._id !== notificationId));
      } else {
        setError(response.message || 'Failed to delete notification');
      }
    } catch (err) {
      setError('Error deleting notification');
    }
  };

  const deleteAllNotifications = async () => {
    if (!user || !user._id) return;
    try {
      const response = await notificationService.deleteAllNotifications(user._id);
      if (response.status) {
        setNotifications([]);
      } else {
        setError(response.message || 'Failed to delete all notifications');
      }
    } catch (err) {
      setError('Error deleting all notifications');
    }
  };

  return (
    <NotificationContext.Provider value={{
      notifications,
      loading,
      error,
      fetchNotifications,
      markAsRead,
      deleteNotification,
      deleteAllNotifications,
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
