
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { ArrowLeft, Coins, Home, Search } from 'lucide-react';
import { motion } from 'framer-motion';

const NotFound = () => {
  useEffect(() => {
    document.title = 'EvoCash | Page Not Found';
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.8,
        staggerChildren: 0.15
      }
    }
  };

  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  const coinVariants = {
    hidden: { scale: 0, rotate: -180 },
    visible: { 
      scale: 1, 
      rotate: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 20
      }
    }
  };
  
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-background relative overflow-hidden">
      {/* Animated background pattern - representing gold particles */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute size-2 rounded-full bg-primary/20"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, -30, 0],
              opacity: [0.2, 0.5, 0.2],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              delay: i * 0.2,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>

      {/* Main content */}
      <motion.div 
        className="relative z-10 text-center max-w-2xl mx-auto px-6"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* AGC Token Symbol */}
        <motion.div 
          className="flex justify-center mb-8"
          variants={coinVariants}
        >
          <div className="relative">
            <div className="size-32 rounded-full bg-gradient-to-br from-[#FFD700] to-[#FFA500] flex items-center justify-center shadow-2xl">
              <Coins className="size-16 text-white" />
            </div>
            <div className="absolute -inset-2 bg-gradient-to-r from-primary/30 to-primary/10 rounded-full blur-xl opacity-50" />
          </div>
        </motion.div>
        
        <motion.h1 
          className="text-8xl font-bold mb-4 font-mono"
          variants={childVariants}
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#FFD700] to-[#FFA500]">
            404
          </span>
        </motion.h1>
        
        <motion.p 
          className="text-3xl font-semibold mb-3"
          variants={childVariants}
        >
          Asset Not Found
        </motion.p>
        
        <motion.p 
          className="text-muted-foreground mb-8 text-lg"
          variants={childVariants}
        >
          The digital asset you're looking for seems to be missing from our vault. 
          Perhaps it's been moved or traded to a different address.
        </motion.p>
        
        <motion.div 
          className="flex flex-col sm:flex-row gap-4 justify-center"
          variants={childVariants}
        >
          <Button 
            asChild 
            variant="outline" 
            size="lg" 
            className="gap-2 border-primary/20 hover:border-primary/40 hover:bg-primary/5"
          >
            <Link to="/markets">
              <Search className="size-4" />
              Browse Markets
            </Link>
          </Button>
          
          <Button 
            asChild 
            size="lg" 
            className="gap-2 bg-gradient-to-r from-[#FFD700] to-[#FFA500] hover:opacity-90 transition-opacity"
          >
            <Link to="/">
              <Home className="size-4" />
              Return to Dashboard
            </Link>
          </Button>
        </motion.div>

        {/* Quick Links */}
        <motion.div 
          variants={childVariants}
          className="mt-12 pt-8 border-t border-primary/10"
        >
          <p className="text-sm text-muted-foreground mb-4">
            Looking for something specific?
          </p>
          <div className="flex flex-wrap gap-4 justify-center text-sm">
            <Link to="/dashboard/trade" className="text-primary hover:underline">Trade AGC</Link>
            <Link to="/markets" className="text-primary hover:underline">Market Stats</Link>
            <Link to="/faq" className="text-primary hover:underline">Help Center</Link>
            <Link to="/contact" className="text-primary hover:underline">Contact Support</Link>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default NotFound;
