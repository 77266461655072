
import authenticationService from './authenticationService';
import passwordService from './passwordService';
import userService from './userService';

// Combine all services to maintain the same API for existing code
export const authService = {
  // Authentication methods
  register: authenticationService.register,
  login: authenticationService.login,
  logout: authenticationService.logout,
  verifyOTP: authenticationService.verifyOTP,
  resendOTP: authenticationService.resendOTP,
  otpLogin: authenticationService.otpLogin,
  isAuthenticated: authenticationService.isAuthenticated,
  getCurrentUser: authenticationService.getCurrentUser,
  
  // Password methods
  forgotPassword: passwordService.forgotPassword,
  resetPassword: passwordService.resetPassword,
  verifyOTPResetPassword: passwordService.verifyOTPResetPassword,
  updatePassword: passwordService.updatePassword,
  
  // User methods
  getProfile: userService.getProfile,
  updateProfile: userService.updateProfile,
  updateEmail: userService.updateEmail,
  userKYC: userService.userKYC,


};

export default authService;
