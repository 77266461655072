import apiClient from './apiClient';

interface RegisterParams {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
}

interface LoginParams {
  email: string;
  password: string;
}

interface VerifyOTPParams {
  email: string;  // Changed from 'login' to 'email' to match the usage
  otp: string;
}

interface ResendOTPParams {
  login: string
}

interface ResetPasswordParams {
  token: string;
  newPassword: string;
}

const testConnection = async () => {
  try {
    const response = await apiClient.get('/health-check');
    console.log('Backend connection successful:', response.data);
    return true;
  } catch (error) {
    console.error('Backend connection failed:', error);
    return false;
  }
};

const authenticationService = {
  testConnection,
  // Register a new user
  register: async (userData: RegisterParams) => {
    try {
      const response = await apiClient.post('/register', userData);
      return response.data;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  },

  // Login a user
  login: async (credentials: LoginParams) => {
    try {
      console.log('Attempting login with:', { email: credentials.email });
      const response = await apiClient.post('/login', credentials);
      console.log('Login response:', response.data);
      
      // The API returns data in a "data" property
      if (response.data && response.data.data && response.data.data.token) {
        const token = response.data.data.token;
        const userData = response.data.data;
        
        // Store token
        localStorage.setItem('authToken', token);
        
        // Store user data
        console.log('Storing user data:', userData);
        localStorage.setItem('user', JSON.stringify(userData));
      } else {
        console.warn('Login response missing token or user data');
      }
      
      return response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  },

  // Logout a user
  logout: async () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        await apiClient.post('/logout',{});
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
      } catch (error) {
        console.error('Logout error:', error);
        // Clear local storage even if the API call fails
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
      }
    }
  },

  // Verify OTP
  verifyOTP: async (params: VerifyOTPParams) => {
    const response = await apiClient.post('/verify-otp', params);
    
    // Handle auth token if present in response
    if (response.data && response.data.data && response.data.data.token) {
      const token = response.data.data.token;
      const userData = response.data.data;
      
      // Store token
      localStorage.setItem('authToken', token);
      
      // Store user data
      console.log('Storing user data:', userData);
      localStorage.setItem('user', JSON.stringify(userData));
    }
    
    return response.data;
  },

  // Resend OTP
  resendOTP: async (params: ResendOTPParams) => {
    const response = await apiClient.post('/resend-otp', params);
    return response.data;
  },

  // OTP Login
  otpLogin: async (params: ResendOTPParams) => {
    const response = await apiClient.post('/otp-login', params);
    return response.data;
  },

  // Check if user is authenticated
  isAuthenticated: () => {
    const hasToken = !!localStorage.getItem('authToken');
    console.log('Auth check - Has token:', hasToken);
    return hasToken;
  },

  // Get the current authenticated user
  getCurrentUser: () => {
    const userStr = localStorage.getItem('user');
    const user = userStr ? JSON.parse(userStr) : null;
    console.log('Getting current user:', user);
    return user;
  },
  
};

export default authenticationService;
