import apiClient from './apiClient';

interface TransactionHistoryParams {
  status?: string;
  transactionType?: string;
  fromDate?: string;
  toDate?: string;
  totalAmount?: number;
  recipient?: string;
  assetName?: string;
  page?: number;
  limit?: number;
}

const transactionService = {
  getTransactionHistory: async (params: TransactionHistoryParams) => {
    const queryParams = new URLSearchParams();

    if (params.status) queryParams.append('status', params.status);
    if (params.transactionType) queryParams.append('transactionType', params.transactionType);
    if (params.fromDate) queryParams.append('fromDate', params.fromDate);
    if (params.toDate) queryParams.append('toDate', params.toDate);
    if (params.totalAmount) queryParams.append('totalAmount', params.totalAmount.toString());
    if (params.recipient) queryParams.append('recipient', params.recipient);
    if (params.assetName) queryParams.append('assetName', params.assetName);
     // 🟢 Ensure page & limit are always sent
     queryParams.append('page', params.page?.toString()); // Default to page 1 if missing
     queryParams.append('limit', params.limit?.toString()); // Default limit if missing
 
     console.log("API Request URL:", `/transaction-history?${queryParams.toString()}`);
    const response = await apiClient.get(`/transaction-history?${queryParams.toString()}`);
    return response.data;
  },

};

export default transactionService;