
import { useEffect, useState, useRef } from 'react';
import Dashboard from '@/components/layout/Dashboard';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Shield,
  AlertCircle,
  Mail,
  ChevronDown,
  CheckCircle2,
  Scale,
  UserCheck,
  Wallet,
  ScrollText,
  BadgeAlert,
  Lock,
  XCircle,
  MessageSquare,
  ExternalLink,
  Clock,
  ArrowRight
} from 'lucide-react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface Section {
  id: string;
  title: string;
  icon: React.ReactNode;
  description: string;
}

const sections: Section[] = [
  { 
    id: 'definitions', 
    title: 'Definitions', 
    icon: <ScrollText className="h-5 w-5" />,
    description: 'Key terms and their meanings'
  },
  { 
    id: 'eligibility', 
    title: 'Eligibility', 
    icon: <UserCheck className="h-5 w-5" />,
    description: 'Who can use our services'
  },
  { 
    id: 'services', 
    title: 'Services', 
    icon: <Wallet className="h-5 w-5" />,
    description: 'What we offer'
  },
  { 
    id: 'obligations', 
    title: 'User Obligations', 
    icon: <Scale className="h-5 w-5" />,
    description: 'Your responsibilities'
  },
  { 
    id: 'risks', 
    title: 'Risks', 
    icon: <BadgeAlert className="h-5 w-5" />,
    description: 'Understanding potential risks'
  },
  { 
    id: 'privacy', 
    title: 'Privacy', 
    icon: <Lock className="h-5 w-5" />,
    description: 'How we protect your data'
  },
  { 
    id: 'termination', 
    title: 'Termination', 
    icon: <XCircle className="h-5 w-5" />,
    description: 'Account closure terms'
  },
  { 
    id: 'disputes', 
    title: 'Disputes', 
    icon: <MessageSquare className="h-5 w-5" />,
    description: 'How we handle disagreements'
  },
];

const Terms = () => {
  const [activeSection, setActiveSection] = useState<string>('definitions');
  const [hasAccepted, setHasAccepted] = useState<boolean>(false);
  const sectionRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  useEffect(() => {
    document.title = 'EvoCash | Terms of Service';
    const accepted = localStorage.getItem('termsAccepted');
    if (accepted) setHasAccepted(true);

    sections.forEach(section => {
      sectionRefs.current[section.id] = document.getElementById(section.id);
    });

    const observerOptions = {
      root: null,
      rootMargin: '-20% 0px -75% 0px',
      threshold: 0
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    Object.values(sectionRefs.current).forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  const handleAcceptTerms = () => {
    localStorage.setItem('termsAccepted', 'true');
    setHasAccepted(true);
  };

  const scrollToSection = (sectionId: string) => {
    const element = sectionRefs.current[sectionId];
    if (element) {
      const headerOffset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Dashboard>
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-12"
        >
          <div className="relative overflow-hidden rounded-3xl bg-gradient-to-br from-primary/20 via-primary/10 to-background p-8 md:p-12">
            <div className="absolute inset-0 bg-grid-white/10 [mask-image:radial-gradient(white,transparent_70%)]" />
            <div className="relative z-10">
              <div className="flex items-center gap-3 mb-6">
                <div className="p-3 bg-primary/10 rounded-xl">
                  <Shield className="h-8 w-8 text-primary" />
                </div>
                <div>
                  <h1 className="text-4xl font-bold tracking-tight">Terms of Service</h1>
                  <div className="flex items-center gap-2 mt-1 text-muted-foreground">
                    <Clock className="h-4 w-4" />
                    <span>Last updated: {new Date().toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })}</span>
                  </div>
                </div>
              </div>

              {/* Important Notice Card */}
              <Card className="bg-destructive/5 border-destructive/20 mt-6">
                <CardContent className="p-4">
                  <div className="flex gap-3">
                    <AlertCircle className="h-5 w-5 text-destructive shrink-0 mt-0.5" />
                    <div>
                      <h3 className="font-medium text-destructive">Important Notice</h3>
                      <p className="text-sm text-destructive/90 mt-1">
                        These terms govern your use of EvoCash's digital asset platform. By accessing our services,
                        you agree to these terms and our compliance with regulatory requirements.
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Sidebar Navigation */}
          <div className="lg:col-span-3">
            <div className="sticky top-24">
              <ScrollArea className="h-[calc(100vh-120px)]">
                <Card className="shadow-md">
                  <CardContent className="p-4">
                    <div className="flex items-center gap-2 mb-6">
                      <Shield className="h-5 w-5 text-primary" />
                      <h2 className="font-semibold">Quick Navigation</h2>
                    </div>
                    <nav className="space-y-2">
                      {sections.map((section) => (
                        <motion.button
                          key={section.id}
                          onClick={() => scrollToSection(section.id)}
                          className={cn(
                            "w-full text-left p-3 rounded-lg transition-all duration-200",
                            activeSection === section.id
                              ? "bg-primary text-primary-foreground shadow-sm"
                              : "hover:bg-muted"
                          )}
                          whileHover={{ scale: 1.01 }}
                          whileTap={{ scale: 0.99 }}
                        >
                          <div className="flex items-center gap-3">
                            {section.icon}
                            <div>
                              <div className="font-medium">{section.title}</div>
                              <div className="text-xs text-muted-foreground/80 line-clamp-1">
                                {section.description}
                              </div>
                            </div>
                          </div>
                        </motion.button>
                      ))}
                    </nav>
                  </CardContent>
                </Card>
              </ScrollArea>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:col-span-9">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {/* Terms Sections */}
              <div className="space-y-6">
                <Accordion type="single" collapsible className="space-y-4">
                  {/* Definitions */}
                  <AccordionItem value="definitions" id="definitions" className="rounded-lg border bg-card">
                    <AccordionTrigger className="px-6 hover:no-underline hover:bg-muted/50 rounded-t-lg [&[data-state=open]]:rounded-b-none">
                      <div className="flex items-center gap-3">
                        <ScrollText className="h-5 w-5 text-primary" />
                        <div className="text-left">
                          <div className="font-semibold">Definitions</div>
                          <div className="text-sm text-muted-foreground">Key terms and their meanings</div>
                        </div>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="px-6 pb-4">
                      <div className="grid gap-4">
                        {[
                          {
                            term: "EvoCash Platform",
                            definition: "The comprehensive digital asset platform operated by EvoCash, including websites, mobile applications, and related services."
                          },
                          {
                            term: "AGC Tokens",
                            definition: "Digital tokens backed by physical gold, issued and managed on the EvoCash platform."
                          },
                          {
                            term: "Smart Contracts",
                            definition: "Self-executing contracts with terms directly written into code on the blockchain."
                          },
                          {
                            term: "KYC/AML",
                            definition: "Know Your Customer and Anti-Money Laundering verification procedures required for platform access."
                          }
                        ].map((item, index) => (
                          <div key={index} className="bg-muted/50 p-4 rounded-lg">
                            <h4 className="font-medium mb-1">{item.term}</h4>
                            <p className="text-sm text-muted-foreground">{item.definition}</p>
                          </div>
                        ))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Services */}
                  <AccordionItem value="services" id="services">
                    <AccordionTrigger className="text-xl font-semibold">
                      <div className="flex items-center gap-2">
                        <Wallet className="h-5 w-5" />
                        Platform Services
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="pt-4 pb-2 px-4">
                      <div className="grid gap-6">
                        {[
                          {
                            title: "Digital Asset Trading",
                            description: "Purchase, sell, and trade AGC tokens through our secure platform.",
                            features: [
                              "Real-time market prices",
                              "Automated order matching",
                              "Multiple payment methods",
                              "Instant settlements"
                            ]
                          },
                          {
                            title: "Custody Services",
                            description: "Secure storage solutions for your digital assets.",
                            features: [
                              "Multi-signature wallets",
                              "Cold storage options",
                              "24/7 monitoring",
                              "Insurance coverage"
                            ]
                          },
                          {
                            title: "Gold Redemption",
                            description: "Convert your AGC tokens to physical gold.",
                            features: [
                              "Verified gold reserves",
                              "Secure delivery options",
                              "Flexible redemption amounts",
                              "Global availability"
                            ]
                          }
                        ].map((service, index) => (
                          <div key={index} className="border rounded-lg p-4">
                            <h4 className="font-medium mb-2">{service.title}</h4>
                            <p className="text-sm text-muted-foreground mb-3">{service.description}</p>
                            <ul className="grid grid-cols-2 gap-2">
                              {service.features.map((feature, fIndex) => (
                                <li key={fIndex} className="flex items-center gap-2 text-sm">
                                  <CheckCircle2 className="h-4 w-4 text-primary" />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Eligibility */}
                  <AccordionItem value="eligibility" id="eligibility">
                    <AccordionTrigger className="text-xl font-semibold">
                      <div className="flex items-center gap-2">
                        <UserCheck className="h-5 w-5" />
                        Eligibility Requirements
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="pt-4 pb-2 px-4">
                      <div className="grid gap-4">
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Age Requirements</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Must be at least 18 years old
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Legal capacity to enter into contracts
                            </li>
                          </ul>
                        </div>
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Identity Verification</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Valid government-issued ID required
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Proof of residence documentation
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Successful KYC/AML verification
                            </li>
                          </ul>
                        </div>
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Geographic Restrictions</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <AlertCircle className="h-4 w-4 text-warning" />
                              Service not available in sanctioned countries
                            </li>
                            <li className="flex items-center gap-2">
                              <AlertCircle className="h-4 w-4 text-warning" />
                              Compliance with local regulations required
                            </li>
                          </ul>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Obligations */}
                  <AccordionItem value="obligations" id="obligations">
                    <AccordionTrigger className="text-xl font-semibold">
                      <div className="flex items-center gap-2">
                        <Scale className="h-5 w-5" />
                        User Obligations
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="pt-4 pb-2 px-4">
                      <div className="grid gap-4">
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Account Security</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Maintain strong password security
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Enable two-factor authentication
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Report unauthorized access immediately
                            </li>
                          </ul>
                        </div>
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Prohibited Activities</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <XCircle className="h-4 w-4 text-destructive" />
                              No market manipulation
                            </li>
                            <li className="flex items-center gap-2">
                              <XCircle className="h-4 w-4 text-destructive" />
                              No fraudulent transactions
                            </li>
                            <li className="flex items-center gap-2">
                              <XCircle className="h-4 w-4 text-destructive" />
                              No unauthorized automated trading
                            </li>
                          </ul>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Risks */}
                  <AccordionItem value="risks" id="risks">
                    <AccordionTrigger className="text-xl font-semibold">
                      <div className="flex items-center gap-2">
                        <BadgeAlert className="h-5 w-5" />
                        Risk Disclosure
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="pt-4 pb-2 px-4">
                      <div className="grid gap-4">
                        <div className="bg-warning/10 border-warning/20 border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Market Risks</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <AlertCircle className="h-4 w-4 text-warning" />
                              Price volatility and market fluctuations
                            </li>
                            <li className="flex items-center gap-2">
                              <AlertCircle className="h-4 w-4 text-warning" />
                              Liquidity risks in trading
                            </li>
                          </ul>
                        </div>
                        <div className="bg-warning/10 border-warning/20 border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Technical Risks</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <AlertCircle className="h-4 w-4 text-warning" />
                              Platform downtime possibilities
                            </li>
                            <li className="flex items-center gap-2">
                              <AlertCircle className="h-4 w-4 text-warning" />
                              Smart contract vulnerabilities
                            </li>
                          </ul>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Privacy */}
                  <AccordionItem value="privacy" id="privacy">
                    <AccordionTrigger className="text-xl font-semibold">
                      <div className="flex items-center gap-2">
                        <Lock className="h-5 w-5" />
                        Privacy & Data Protection
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="pt-4 pb-2 px-4">
                      <div className="grid gap-4">
                        <p className="text-muted-foreground">
                          For detailed information about how we collect, use, and protect your data,
                          please refer to our <Link to="/privacy" className="text-primary hover:underline">Privacy Policy</Link>.
                        </p>
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Key Privacy Points</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Data encryption and security measures
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Limited data sharing with third parties
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              User control over personal data
                            </li>
                          </ul>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Termination */}
                  <AccordionItem value="termination" id="termination">
                    <AccordionTrigger className="text-xl font-semibold">
                      <div className="flex items-center gap-2">
                        <XCircle className="h-5 w-5" />
                        Account Termination
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="pt-4 pb-2 px-4">
                      <div className="grid gap-4">
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">User-Initiated Termination</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Right to close account at any time
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Asset withdrawal requirements
                            </li>
                          </ul>
                        </div>
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Platform-Initiated Termination</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <AlertCircle className="h-4 w-4 text-warning" />
                              Violation of terms or policies
                            </li>
                            <li className="flex items-center gap-2">
                              <AlertCircle className="h-4 w-4 text-warning" />
                              Suspicious activity detection
                            </li>
                          </ul>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {/* Disputes */}
                  <AccordionItem value="disputes" id="disputes">
                    <AccordionTrigger className="text-xl font-semibold">
                      <div className="flex items-center gap-2">
                        <MessageSquare className="h-5 w-5" />
                        Dispute Resolution
                      </div>
                    </AccordionTrigger>
                    <AccordionContent className="pt-4 pb-2 px-4">
                      <div className="grid gap-4">
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Resolution Process</h4>
                          <ul className="space-y-2">
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Initial support ticket submission
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Escalation to dispute resolution team
                            </li>
                            <li className="flex items-center gap-2">
                              <CheckCircle2 className="h-4 w-4 text-primary" />
                              Final binding arbitration if needed
                            </li>
                          </ul>
                        </div>
                        <div className="border rounded-lg p-4">
                          <h4 className="font-medium mb-2">Governing Law</h4>
                          <p className="text-sm text-muted-foreground">
                            All disputes shall be governed by and construed in accordance with the laws
                            of [Jurisdiction], without regard to conflict of law principles.
                          </p>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>

              {/* Terms Acceptance Section */}
              {!hasAccepted && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                  className="mt-8"
                >
                  <Card className="bg-primary/5 border-primary/20">
                    <CardContent className="p-6">
                      <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                        <div>
                          <h3 className="text-lg font-semibold">Accept Terms of Service</h3>
                          <p className="text-muted-foreground">
                            Please review and accept our terms to continue using EvoCash
                          </p>
                        </div>
                        <Button onClick={handleAcceptTerms} size="lg" className="min-w-[200px]">
                          <CheckCircle2 className="mr-2 h-4 w-4" />
                          Accept Terms
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </motion.div>
              )}

              {/* Footer Navigation */}
              <div className="mt-12 flex items-center justify-between">
                <Button variant="outline" asChild>
                  <Link to="/privacy" className="flex items-center gap-2">
                    <Lock className="h-4 w-4" />
                    Privacy Policy
                  </Link>
                </Button>
                <Button asChild>
                  <Link to="/contact" className="flex items-center gap-2">
                    Contact Support
                    <ArrowRight className="h-4 w-4" />
                  </Link>
                </Button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default Terms;
