
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { InfoIcon } from 'lucide-react';

const metricCardVariants = cva(
  "transition-all duration-300",
  {
    variants: {
      variant: {
        default: "bg-card border-border",
        primary: "bg-primary/5 border-primary/20",
        success: "bg-success/5 border-success/20",
        warning: "bg-warning/5 border-warning/20",
        destructive: "bg-destructive/5 border-destructive/20",
        glass: "glass-card"
      },
      size: {
        default: "p-4",
        sm: "p-3",
        lg: "p-6"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export interface MetricCardProps extends VariantProps<typeof metricCardVariants> {
  title: string;
  value: string | number;
  icon?: React.ReactNode;
  description?: string;
  trend?: {
    value: number;
    isPositive: boolean;
  };
  tooltip?: string;
  className?: string;
  valueClassName?: string;
  style?: React.CSSProperties;
}

const MetricCard = ({
  title,
  value,
  icon,
  description,
  trend,
  tooltip,
  variant = "default",
  size = "default",
  className,
  valueClassName,
  style
}: MetricCardProps) => {
  return (
    <Card className={cn(metricCardVariants({ variant, size }), "overflow-hidden group", className)} style={style}>
      <CardHeader className="p-4 pb-0 flex flex-row items-center justify-between">
        <div className="flex items-center space-x-2">
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
          {tooltip && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <InfoIcon className="h-3.5 w-3.5 text-muted-foreground" />
                </TooltipTrigger>
                <TooltipContent>
                  <p className="text-xs max-w-xs">{tooltip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
        {icon && <div className="text-muted-foreground">{icon}</div>}
      </CardHeader>
      <CardContent className="p-4 pt-2">
        <div className="flex flex-col">
          <div className={cn("text-2xl font-bold tracking-tight", valueClassName)}>
            {value}
          </div>
          {(description || trend) && (
            <div className="flex items-center mt-1 text-xs">
              {trend && (
                <span 
                  className={cn(
                    "flex items-center font-medium mr-2",
                    trend.isPositive ? "text-success" : "text-destructive"
                  )}
                >
                  {trend.isPositive ? "+" : "-"}{Math.abs(trend.value)}%
                </span>
              )}
              {description && (
                <span className="text-muted-foreground">{description}</span>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default MetricCard;
