
import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import authService from '@/services/authService';
import { SignUpForm } from '@/components/auth/signup/SignUpForm';
import { ArrowLeft, Sparkles, Globe, CreditCard, Brain, Coins } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

const SignUpPage = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    document.title = 'EvoCash | Sign Up';
    if (authService.isAuthenticated()) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className="min-h-[100dvh] bg-background relative flex flex-col lg:flex-row">
      {/* Enhanced Gradient Background */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-primary/5 rounded-full blur-[120px]" />
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-primary/10 rounded-full blur-[120px]" />
      </div>

      {/* Back Button - Adjusted for all screens */}
      <Button
        variant="ghost"
        size="sm"
        className="absolute top-safe-top left-4 z-50 pointer-events-auto md:left-6 md:top-8"
        onClick={handleBackToHome}
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        <span className="hidden sm:inline">Back to Home</span>
      </Button>

      {/* Main Content - Improved sizing for all screens */}
      <div className="flex-1 flex items-center justify-center p-4 pt-16 sm:pt-4 md:p-8 lg:p-12 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-[min(500px,100%-2rem)] mx-auto"
        >
          <Card className="p-6 sm:p-8 md:p-10 backdrop-blur-xl bg-background/95 border-border/50 shadow-xl">
            {/* Enhanced Header - Adjusted spacing */}
            <div className="text-center space-y-4 mb-8">
              <div className="flex items-center justify-center mb-6">
                <div className="size-14 sm:size-16 md:size-20 rounded-full bg-primary/10 flex items-center justify-center">
                  <Sparkles className="size-7 sm:size-8 md:size-10 text-primary" />
                </div>
              </div>
              <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-2xl sm:text-3xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary via-primary/80 to-primary/60"
              >
                Welcome to EvoCash
              </motion.h1>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.5 }}
                className="text-sm sm:text-base md:text-lg text-muted-foreground max-w-md mx-auto"
              >
                Join the future of finance with tokenized assets, AI-powered trading, and borderless transactions
              </motion.p>
            </div>

            {/* Sign Up Form */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="max-w-md mx-auto"
            >
              <SignUpForm />
            </motion.div>
          </Card>
        </motion.div>
      </div>

      {/* Side Banner - Enhanced for larger screens */}
      <div className="hidden lg:flex flex-1 relative overflow-hidden bg-gradient-to-br from-primary/10 via-primary/5 to-background border-l border-border/50">
        <div className="absolute inset-0 bg-grid-white/5" />
        <div className="relative w-full h-full flex items-center justify-center p-12 xl:p-16">
          <div className="max-w-2xl space-y-10">
            <motion.h2
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="text-4xl xl:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-foreground to-foreground/70"
            >
              The Future of Finance
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="space-y-8"
            >
              <p className="text-lg xl:text-xl text-muted-foreground leading-relaxed">
                Experience the power of blockchain technology combined with traditional finance, powered by advanced AI.
              </p>
              <ul className="space-y-8">
                {[
                  {
                    icon: Coins,
                    title: "Asset Tokenization",
                    description: "Transform real-world assets into digital tokens for fractional ownership"
                  },
                  {
                    icon: CreditCard,
                    title: "Virtual Debit Card",
                    description: "Spend your digital assets globally with our integrated virtual card"
                  },
                  {
                    icon: Brain,
                    title: "EVA - Your Financial AI Agent",
                    description: "First-of-its-kind AI agent which helps you navigate the platform and execute tasks"
                  },
                  {
                    icon: Globe,
                    title: "Cross-Border Transactions",
                    description: "Send and receive tokenized assets globally without traditional banking limitations"
                  }
                ].map((feature, index) => (
                  <motion.li
                    key={feature.title}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.7 + (index * 0.1), duration: 0.5 }}
                    className="flex items-start gap-5 text-foreground/80"
                  >
                    <div className="mt-1 size-10 rounded-lg bg-primary/10 flex items-center justify-center shrink-0">
                      <feature.icon className="size-5 text-primary" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-foreground">{feature.title}</h3>
                      <p className="text-base text-muted-foreground">{feature.description}</p>
                    </div>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
