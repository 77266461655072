import { NavLink, useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
  LayoutDashboard,
  CreditCard,
  ArrowLeftRight,
  Wallet,
  MoreHorizontal,
  Settings,
  HelpCircle,
  LogOut,
  Home,
  ExternalLink,
} from 'lucide-react';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useUser } from '@/contexts/UserContext';
import authService from '@/services/authService';
import { toast } from 'sonner';

const DashboardBottomNav = () => {
  const navigate = useNavigate();
  const { clearUser, user } = useUser();

  const handleLogout = async () => {
    try {
      await authService.logout();
      clearUser();
      navigate('/');
      toast.success('Logged out successfully');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Failed to log out');
    }
  };

  const mainNavItems = [
    { path: '/dashboard', icon: LayoutDashboard, label: 'Home', end: true },
    { path: '/dashboard/wallet', icon: Wallet, label: 'Wallet' },
    { path: '/dashboard/trade', icon: ArrowLeftRight, label: 'Trade' },
    { path: '/dashboard/transactions', icon: CreditCard, label: 'Activity' },
  ];

  const moreMenuItems = [
    { 
      icon: Settings, 
      label: 'Settings', 
      path: '/dashboard/settings',
      description: 'Account preferences and security'
    },
    { 
      icon: HelpCircle, 
      label: 'Help Center', 
      path: '/help',
      description: 'FAQs and support resources'
    },
  ];

  const quickLinks = [
    {
      title: 'Trading Guide',
      path: '/help#trading',
      icon: ArrowLeftRight,
    },
    {
      title: 'Security Tips',
      path: '/help#security',
      icon: HelpCircle,
    },
  ];

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border-t border-border pb-[env(safe-area-inset-bottom)] z-50">
      <div className="grid grid-cols-5 h-16">
        {mainNavItems.map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            end={item.end}
            className={({ isActive }) => cn(
              "flex flex-col items-center justify-center space-y-1 transition-colors",
              isActive 
                ? "text-primary" 
                : "text-muted-foreground hover:text-foreground"
            )}
          >
            <item.icon className="h-5 w-5" />
            <span className="text-[10px] font-medium">{item.label}</span>
          </NavLink>
        ))}

        <Sheet>
          <SheetTrigger asChild>
            <button className="flex flex-col items-center justify-center space-y-1 text-muted-foreground hover:text-foreground">
              <MoreHorizontal className="h-5 w-5" />
              <span className="text-[10px] font-medium">More</span>
            </button>
          </SheetTrigger>
          <SheetContent 
            side="bottom" 
            className="h-[85vh] px-0 rounded-t-3xl"
          >
            <SheetHeader className="px-4 pb-4">
              <SheetTitle className="text-xl">More Options</SheetTitle>
            </SheetHeader>

            {/* User Profile Summary */}
            <div className="px-4 pb-4">
              <div className="flex items-center gap-3 p-3 rounded-xl bg-muted/50">
                <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                  <span className="text-primary font-semibold text-lg">
                    {user?.firstName?.[0] || 'E'}
                  </span>
                </div>
                <div className="flex-1">
                  <p className="font-medium">{user?.firstName || 'User'}</p>
                  <p className="text-sm text-muted-foreground">{user?.email}</p>
                </div>
              </div>
            </div>

            {/* Main Menu Items */}
            <div className="px-2">
              {moreMenuItems.map((item) => (
                <Button
                  key={item.path}
                  variant="ghost"
                  className="w-full justify-start h-16 gap-4 px-4 group"
                  onClick={() => navigate(item.path)}
                >
                  <div className="p-2 rounded-xl bg-primary/10 text-primary group-hover:bg-primary group-hover:text-primary-foreground transition-colors">
                    <item.icon className="h-5 w-5" />
                  </div>
                  <div className="text-left">
                    <div className="font-medium">{item.label}</div>
                    <div className="text-sm text-muted-foreground">{item.description}</div>
                  </div>
                </Button>
              ))}
            </div>

            {/* Quick Links */}
            <div className="px-4 mt-6">
              <h3 className="text-sm font-medium text-muted-foreground mb-2">Quick Links</h3>
              <div className="grid grid-cols-2 gap-2">
                {quickLinks.map((link) => (
                  <Button
                    key={link.path}
                    variant="outline"
                    className="h-auto py-3 px-3 flex flex-col items-center gap-2"
                    onClick={() => navigate(link.path)}
                  >
                    <link.icon className="h-5 w-5" />
                    <span className="text-xs">{link.title}</span>
                  </Button>
                ))}
              </div>
            </div>

            <Separator className="my-6" />

            {/* Bottom Actions */}
            <div className="px-2">
              <Button
                variant="ghost"
                className="w-full justify-start h-12 gap-3 px-4"
                onClick={() => navigate('/')}
              >
                <Home className="h-5 w-5" />
                <span>Back to Landing</span>
                <ExternalLink className="h-4 w-4 ml-auto opacity-50" />
              </Button>
              
              <Button
                variant="ghost"
                className="w-full justify-start h-12 gap-3 px-4 text-destructive hover:text-destructive hover:bg-destructive/10"
                onClick={handleLogout}
              >
                <LogOut className="h-5 w-5" />
                <span>Logout</span>
              </Button>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </nav>
  );
};

export default DashboardBottomNav;
