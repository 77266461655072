
import React, { useState, useRef, useEffect } from 'react';
import { Search } from 'lucide-react';
import { cn } from '@/lib/utils';
import { countries } from '@/data/countries';

interface CountrySelectProps {
  value: string;
  onChange: (country: { name: string; dial_code: string }) => void;
  className?: string;
  id?: string;
}

export const CountrySelect = ({
  value,
  onChange,
  className,
  id = 'country-select-input',
}: CountrySelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  const selectedCountry = countries.find(country => country.name === value);
  
  const filteredCountries = countries.filter(country => 
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectCountry = (country: typeof countries[0]) => {
    onChange({ 
      name: country.name, 
      dial_code: country.dial_code 
    });
    setIsOpen(false);
    setSearchTerm('');
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={cn("relative w-full", className)}>
      <div 
        id={id}
        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm cursor-pointer items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
        role="combobox"
        aria-expanded={isOpen}
        aria-controls={`${id}-dropdown`}
      >
        {selectedCountry ? (
          <div className="flex items-center">
            <span className="mr-2 text-base">{selectedCountry.flag}</span>
            <span>{selectedCountry.name}</span>
          </div>
        ) : (
          <span className="text-muted-foreground">Select a country</span>
        )}
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none" 
          stroke="currentColor" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          className="h-4 w-4 opacity-50"
        >
          <path d="m6 9 6 6 6-6"/>
        </svg>
      </div>
      
      {isOpen && (
        <div 
          id={`${id}-dropdown`}
          className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md border bg-popover text-popover-foreground shadow-md"
        >
          <div className="sticky top-0 bg-background p-2 border-b">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <input
                id={`${id}-search`}
                placeholder="Search countries..."
                className="h-10 w-full rounded-md border border-input bg-background pl-8 pr-3 py-2 text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                aria-label="Search countries"
              />
            </div>
          </div>
          
          <div role="listbox">
            {filteredCountries.length > 0 ? (
              filteredCountries.map((country) => (
                <div
                  key={country.code}
                  role="option"
                  aria-selected={country.name === value}
                  className="flex items-center px-3 py-2 cursor-pointer hover:bg-accent hover:text-accent-foreground"
                  onClick={() => handleSelectCountry(country)}
                >
                  <span className="mr-2 text-base">{country.flag}</span>
                  <span>{country.name}</span>
                  <span className="ml-auto text-sm text-muted-foreground">
                    {country.dial_code}
                  </span>
                </div>
              ))
            ) : (
              <div className="px-3 py-4 text-center text-sm text-muted-foreground">
                No countries found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
