import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from 'sonner';
import { Loader2 } from 'lucide-react';
import authService from '@/services/authService';

const VerifyOTPPage = () => {
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'EvoCash | Verify Email';
    
    // Get email from session storage
    const storedEmail = sessionStorage.getItem('verificationEmail');
    if (!storedEmail) {
      toast.error('No email found for verification. Please sign up again.');
      navigate('/signup');
      return;
    }
    
    setEmail(storedEmail);
  }, [navigate]);

  const handleVerify = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!otp || otp.length !== 5) {
      toast.error('Please enter a valid 5-digit verification code');
      return;
    }
    
    setIsVerifying(true);
    
    try {
      const response = await authService.verifyOTP({ login: email, otp: otp });
      // Check if we received an auth token
      if (response.data && response.data.token) {
        toast.success('Account verified successfully!');
        // Clear the verification email from session storage
        sessionStorage.removeItem('verificationEmail');
        // Redirect to dashboard since user is now authenticated
        navigate('/dashboard');
      } else {
        toast.success('Email verified successfully!');
        // Clear the verification email from session storage
        sessionStorage.removeItem('verificationEmail');
        // Redirect to login page if no auth token received
        navigate('/login');
      }
    } catch (error: Error | unknown) {
      const err = error as { response?: { data?: { message?: string } } };
      console.error('Verification error:', error);
      toast.error(err.response?.data?.message || 'Failed to verify email');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResendOTP = async () => {
    setIsResending(true);
    
    try {
      await authService.resendOTP({login: email});
      toast.success('Verification code resent to your email');
    } catch (error: Error | unknown) {
      const err = error as { response?: { data?: { message?: string } } };
      console.error('Resend OTP error:', error);
      toast.error(err.response?.data?.message || 'Failed to resend verification code');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4">
      <div className="w-full max-w-md">
        <h1 className="text-3xl font-bold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary/70">
          EvoCash
        </h1>
        
        <Card>
          <CardHeader>
            <CardTitle>Verify Your Email</CardTitle>
            <CardDescription>
              We've sent a verification code to {email}. Please enter the 5-digit code to verify your account.
            </CardDescription>
          </CardHeader>
          
          <form onSubmit={handleVerify}>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Input
                  type="text"
                  id="otp"
                  placeholder="Enter 5-digit code"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value.replace(/\D/g, '').slice(0, 5))}
                  maxLength={5}
                  className="text-center text-xl tracking-widest"
                />
              </div>
            </CardContent>
            
            <CardFooter className="flex-col space-y-4">
              <Button type="submit" className="w-full" disabled={isVerifying}>
                {isVerifying && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                {isVerifying ? 'Verifying...' : 'Verify Email'}
              </Button>
              
              <div className="text-center text-sm">
                Didn't receive a code?{' '}
                <Button
                  variant="link"
                  className="p-0 h-auto"
                  onClick={handleResendOTP}
                  disabled={isResending}
                >
                  {isResending ? (
                    <>
                      <Loader2 className="mr-1 h-3 w-3 animate-spin" />
                      Resending...
                    </>
                  ) : (
                    'Resend code'
                  )}
                </Button>
              </div>
              
              <div className="text-center text-sm">
                <Link to="/login" className="text-primary hover:underline">
                  Back to login
                </Link>
              </div>
            </CardFooter>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default VerifyOTPPage;
