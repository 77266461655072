
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { SignUpFormData } from './SignUpForm';

interface PasswordFieldsProps {
  formData: SignUpFormData;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordFields = ({ formData, handleChange }: PasswordFieldsProps) => {
  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="password-input">Password</Label>
        <Input 
          id="password-input" 
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
      </div>
      
      <div className="space-y-2">
        <Label htmlFor="confirm-password-input">Confirm Password</Label>
        <Input 
          id="confirm-password-input" 
          name="confirmPassword"
          type="password"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />
      </div>
    </>
  );
};
